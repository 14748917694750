import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Fade } from "react-reveal";
// import setting from "../images/Frame 94setting_home.png";
// import app from "../images/Frame 94app_home.png";
// import cloud from "../images/Frame 94cloud_home.png";
// import automation from "../images/Frame 94automatio_home.png";
// import {GoArrowRight} from "react-icons/go";
// import { Link } from "react-router-dom";
// import { CircularProgress } from "@mui/material";

const OurService = () => {
  const styleFont = { fontFamily: "Montserrat" };
  const headingText = "Our Services";
  const headingPara = "Our Offerings to Help You achieve Business Goal";
  const headingParaDes = "At Startup Starter, we are dedicated to empowering startups and fueling their journey towards business success.Our comprehensive range of services is specifically designed to support and accelerate your growth, ensuring that you achieve your business goals.";
  return (
    <div>
      <Fade left>
        <Container>
          <Row>
            <b className="color-primary mt-3" style={styleFont}>
              {headingText}
            </b>
          </Row>
          <Row>
            <Col xl={6} lg={6} md={6} sm={12} xs={12} className="mt-3">
              <h3 className="fw-bold" style={styleFont}>{headingPara}</h3>
            </Col>
            <Col
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className="mt-3"
              style={styleFont}
            >
              {" "}
              {headingParaDes}
            </Col>
          </Row>
        </Container>
      </Fade>
    </div>
  );
};
export default OurService;
