import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import image from "../images/Looper-2 (3).png"
import noimage from "../images/noimage2.webp"

import { HelmetCustomInclude } from "../../hooks/HelmetCustomInclude";
import { getDataByUrl } from "../API/HelpingMethod";
import SkeletonTermsandcondition from "../Homepage/Skeleton-files/SkeletonTermsandcondition"; 
import { Skeleton } from "@mui/material";
import {get_date_formatted} from "../../hooks/useCustomHelpingMethod";
const RefundPolicy = () => { 
    const [loading, setLoading] = useState(false);
    const [refundPolicy, setRefundPolicy] = useState({});
    const data = {
        content_type: "refund",
    };

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);


    const apiCall = async () => {
        setLoading(true);
        const response = await getDataByUrl("admin_setting", data);

        if (!response?.error) {

            setRefundPolicy(response?.data);
        } else {
            // console.log("Error fetching data:", response?.message);
        }
        setLoading(false);
    };
    useEffect(() => {
        apiCall();
    }, {});

    const [pageTitle, setPageTitle] = useState("");
    
    const [metaImageUrl, setMetaImageUrl] = useState(null);

    return (
        <div className="pt-3">

            <HelmetCustomInclude url={"refund"} title={pageTitle} image={metaImageUrl} />

            <img id="Servicebgimage" src={image} onError={(e) => e.target.src = noimage} alt="image" />
            <Container className="pt-5" fluid id="privacy_policy">
                <Row>
                    <Col className="text-center mt-5 mb-5 text-light">
                    { loading ? <Row>
                                <Col></Col>
                                <Col className="d-flex justify-content-center "><Skeleton height={30} width={210} /></Col>
                                <Col></Col> </Row> : <span>Effective Date: {get_date_formatted(refundPolicy?.updated_at)}</span>
                        }
                        
                        { loading ? <Row>
                                <Col></Col>
                                <Col className="d-flex justify-content-center mt-4"><Skeleton height={50} width={240} /></Col>
                                <Col></Col> </Row> : <h2 className="mt-4 fw-bold">{refundPolicy?.title}</h2>
                        }
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    { loading ? <SkeletonTermsandcondition /> : <Col>
                            <div className="fontFamily mt-3"
                                dangerouslySetInnerHTML={{
                                    __html: refundPolicy?.description || "",
                                }}
                            />
                        </Col>
                    }

                </Row>
            </Container>

        </div>
    )
}
export default RefundPolicy;