import { BiUpArrow, BiUpArrowAlt } from "react-icons/bi";
import ScrollToTop from "react-scroll-to-top";

function CoolPage() {
  return (
    <div>
      <ScrollToTop
        style={{backgroundColor:"var(--primary)"}}
        smooth
        component={<BiUpArrowAlt className="fs-2" style={{ color: "white" }} />} // Apply color to the arrow
      />
    </div>
  );
}

export default CoolPage;
