import React, {useEffect, useState} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import {Card, Col, Container, Row} from "react-bootstrap";
import "../css/Home.css";
import OurService from "./OurService";
import FormIdea from "./FormIdea";
import WhyChoose from "./WhyChoose";
import ShareIdea from "./ShareIdea";
import {Link} from "react-router-dom";
import MobileApp from "./MobileApp";
import {getDataByUrl} from "../API/HelpingMethod";
import {Avatar, Skeleton} from "@mui/material";
import {FaLongArrowAltRight} from "react-icons/fa";
import SkeletonService from "./Skeleton-files/SkeletonService";
import {CroppedText} from "../../helper/custom_helper";
import SkeletonBenefit from "./Skeleton-files/SkeletonBenefit";
import Carousel from "react-bootstrap/Carousel";
import Fade from "react-reveal/Fade";
import {Flip} from "react-reveal";
import {HelmetCustomInclude} from "../../hooks/HelmetCustomInclude";
import noimage from "../images/noimage2.webp"
import {loginUserData} from "../API/client";
import SuccessStories from "./SuccessStories";
import Footer from "./Footer";

const Home = () => {

    const loginuser = loginUserData;
    const [isHoveredService, setIsHoveredService] = useState(false);
    const [isHoveredBenefit, setIsHoveredBenefit] = useState(false);
    const [banners, setBanners] = useState([]);
    const [services, setServices] = useState([]);
    const [benefit, setBenefit] = useState([]);
    const [loading, setLoading] = useState(false);
    const inputData = {
        offset: 0,
        limit: 4,
    };

    const apiCall = async () => {
        setLoading(true);
        if (loginuser) {
            inputData.login_user_id = loginuser?.id
        }
        const response = await getDataByUrl("front_home", inputData);
        if (!response?.error) {
            setServices(response?.data?.services || []);
            setBenefit(response?.data?.benefits || []);
            setBanners(response?.data?.banenrs || []);
            setLoading(false);
        } else {
            // console.log("Error fetching data:", response?.message);
        }
    };
    useEffect(() => {
        apiCall();
        console.log("loginuser----", loginuser)

    }, []);
    const numberOfCols = 4;
    const numberOfColsBenefit = 4;


    const styleHeading = {whiteSpace: "nowrap", overflow: "hidden"};
    const styleHeadingFont = {fontFamily: "Montserrat"};
    const styleServiceList = {
        bgcolor: "var(--primary)",
        width: 50,
        height: 50,
    };

    const styleLearnMoreButton = {color: "#066D98", fontFamily: "Montserrat"};
    const words = ["Navigating the Path to Startup "];
    const [text, setText] = useState("");
    const [isTyping, setIsTyping] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const speed = 70; // Adjust the typing speed
        const eraseDelay = 1000; // Delay before starting to erase

        const typeWriter = () => {
            if (isTyping) {
                setText((prev) => words[currentIndex].slice(0, prev.length + 1));

                if (text.length === words[currentIndex].length) {
                    setIsTyping(false);
                    setTimeout(() => setIsTyping(true), eraseDelay);
                }
            } else {
                setText((prev) => prev.slice(0, prev.length - 1));

                if (text === "") {
                    setCurrentIndex((prev) => (prev + 1) % words.length);
                    setIsTyping(true);
                }
            }
        };

        const interval = setInterval(typeWriter, speed);
        return () => clearInterval(interval);
    }, [text, isTyping, currentIndex]);
    const styleFont = {fontFamily: "Montserrat"};

    const ExcellentText = "Excellent";
    const styleRow = {position: "absolute"};

    const LearnMoreText = "Learn More";
    const viewMoreText = "View More";

    return (
        <div>

            <HelmetCustomInclude url={"home"} title={"home"}/>


            <section>
                {loading ? (
                    <Skeleton variant="rectangle" height={550}/>
                ) : (
                    <>
                        <Fade top>
                            <Carousel>
                                {banners && Array.isArray(banners) ? (
                                    banners.map((banner, index) => {

                                        return (
                                            <Carousel.Item key={index}>
                                                <div
                                                    className="img-fluid d-flex align-items-center justify-content-center"
                                                    id="main"
                                                    style={{
                                                        backgroundImage:
                                                            "url(" +
                                                            (banner?.url_image ? banner.url_image : "") +
                                                            ")",
                                                    }}
                                                >

                                                </div>
                                            </Carousel.Item>
                                        );
                                    })
                                ) : (
                                    <Carousel.Item>
                                        <div
                                            className="img-fluid d-flex align-items-center justify-content-center"
                                            id="main"
                                        >
                                            <Row className="" style={styleRow}>
                                                <Col className="text-center  text-light">
                                                    <Fade left>
                                                        <h1
                                                            id="typing-effect"
                                                            className=""
                                                            style={styleHeading}
                                                        >
                                                            {text}
                                                        </h1>
                                                    </Fade>
                                                    <h1 className="fw-bold" style={styleHeadingFont}>
                                                        {ExcellentText}
                                                    </h1>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Carousel.Item>
                                )}


                            </Carousel>
                        </Fade>
                    </>
                )}


            </section>

            <FormIdea/>

            <OurService/>

            <Container>
                <Row className="mt-3">
                    {loading ? (
                        <SkeletonService numberOfCols={numberOfCols}/>
                    ) : (
                        <>
                            {services.map((serviceItem, index) => {
                                return (
                                    <Col lg={6} xl={6} md={6} sm={12} xs={12} key={index}>
                                        <Link
                                            className="text-decoration-none"
                                            // to={"/service-detail?serviceid=" + serviceItem.id}
                                            to={`/service/${serviceItem.slug} `}
                                        >
                                            <Flip left>
                                                <Card style={{cursor: "pointer", transition: "transform 0.3s"}}
                                                      onMouseOver={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
                                                      onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")}
                                                      className="mt-4 shadow rounded-4 p-2 border-0">
                                                    <Card.Body className="">
                                                        <Row>
                                                            <Col sm={12} lg={12} md={12} xl={12} xs={12}>
                                                                <Avatar
                                                                    sx={styleServiceList}
                                                                    variant="rounded"
                                                                    alt={serviceItem.url_image}
                                                                    src={serviceItem.url_image}
                                                                    onError={(e) => e.target.src = noimage}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col sm={12} lg={12} md={12} xl={12} xs={12}>
                                                                <Card.Title style={styleFont}
                                                                            className="mt-2 fw-bold fs-5">
                                                                    {serviceItem.name}
                                                                </Card.Title>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col sm={12} lg={12} md={12} xl={12} xs={12}>
                                                                <Card.Text className="mt-2 fontFamily">
                                                                    <small>
                                                                        {serviceItem.description &&
                                                                        CroppedText(
                                                                            serviceItem?.description || "",
                                                                            150
                                                                        )}
                                                                    </small>
                                                                </Card.Text>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col sm={12} lg={12} md={12} xl={12} xs={12}
                                                                 className="mt-2">
                                                                <Link
                                                                    // to={
                                                                    //   "/service-detail?serviceid=" +
                                                                    //   serviceItem.id
                                                                    // }
                                                                    to={`/service/${serviceItem.slug} `}
                                                                    className="text-decoration-none"
                                                                    style={styleLearnMoreButton}
                                                                >
                                                                    {LearnMoreText} <FaLongArrowAltRight/>
                                                                </Link>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Flip>
                                        </Link>
                                    </Col>
                                );
                            })}
                        </>
                    )}
                </Row>
            </Container>

            {
                loading ? <></> :
                    <Row className="text-center">
                        <Col className="mt-5">
                            <Link
                                to="/service"
                                className={`slide-button text-decoration-none ${isHoveredService ? "hovered" : ""
                                }`}
                                onMouseEnter={() => setIsHoveredService(true)}
                                onMouseLeave={() => setIsHoveredService(false)}
                            >
                                {viewMoreText} <FaLongArrowAltRight/>
                            </Link>
                        </Col>
                    </Row>
            }

            {loginuser?.user_type && loginuser?.user_type !== "startup" ? <></> : <ShareIdea/>}

            <WhyChoose/>
            <Container className="mt-4">
                <Row className="mb-2">
                    {loading ? (
                        <SkeletonBenefit numberOfColsBenefit={numberOfColsBenefit}/>
                    ) : (
                        <>
                            {benefit.map((benefitItem, index) => {
                                return (
                                    <Col lg={6} xl={6} sm={12} xs={12} md={6} className="mb-3" key={index}>
                                        <Flip left>
                                            <Card style={{cursor: "pointer", transition: "transform 0.3s"}}
                                                  onMouseOver={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
                                                  onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")}
                                                  className="shadow border-0">
                                                <Card.Body>
                                                    <Row className="d-flex align-items-center">
                                                        <Col sm={4} xs={4} lg={3} xl={3} md={3}>
                                                            <Card.Img className="w-100" src={benefitItem.url_image}
                                                                      onError={(e) => e.target.src = noimage} alt="./"/>
                                                        </Col>
                                                        <Col lg={9} xl={9} md={9} className="">
                                                            <Card.Title
                                                                className="fw-bold  overflow-hidden"
                                                                style={{
                                                                    textOverflow: "ellipsis",
                                                                    whiteSpace: "nowrap",
                                                                    overflow: "hidden",
                                                                    fontFamily: "Montserrat"
                                                                }}
                                                            >
                                                                {benefitItem.title}
                                                            </Card.Title>
                                                            <Card.Text
                                                                className="fw-lighter  overflow-hidden"
                                                                style={{
                                                                    textOverflow: "ellipsis",
                                                                    whiteSpace: "nowrap",
                                                                    overflow: "hidden",
                                                                    ...styleHeadingFont,
                                                                }}
                                                            >
                                                                {benefitItem.description}
                                                            </Card.Text>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Flip>
                                    </Col>
                                );
                            })}
                        </>
                    )}
                </Row>
            </Container>

            {loading ? <></> :
                <Row className="text-center ">
                    <Col className="">
                        <Link
                            // to={loginuser ? "/benefit" : "/login"}
                            to="/benefit"
                            className={`slide-button text-decoration-none ${isHoveredBenefit ? "hovered" : ""
                            }`}
                            onMouseEnter={() => setIsHoveredBenefit(true)}
                            onMouseLeave={() => setIsHoveredBenefit(false)}
                        >
                            {viewMoreText} <FaLongArrowAltRight/>
                        </Link>
                    </Col>
                </Row>
            }


            {/*<SuccessStories/>*/}

            <MobileApp/>
        </div>
    );
};

export default Home;
