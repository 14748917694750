import { Skeleton } from '@mui/material'
import React from 'react'
import { Accordion } from 'react-bootstrap'

const SkeletonFaqType = () => {
    return (
        <>
            <Accordion>
                <Accordion.Item className='mt-2' eventKey="0">
           
                    <Accordion.Header><Skeleton  className='rounded' variant='rectangle' /></Accordion.Header>
                    <Accordion.Body className="fontFamily">
                        <Skeleton className='rounded' variant='rectangle' />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <Accordion>
                <Accordion.Item className='mt-2' eventKey="0">
                    <Accordion.Header><Skeleton className='rounded' variant='rectangle' /></Accordion.Header>
                    <Accordion.Body className="fontFamily">
                        <Skeleton className='rounded' variant='rectangle' />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <Accordion>
                <Accordion.Item className='mt-2' eventKey="0">
                    <Accordion.Header><Skeleton className='rounded' variant='rectangle' /></Accordion.Header>
                    <Accordion.Body className="fontFamily">
                        <Skeleton className='rounded' variant='rectangle' />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            
        <Accordion>
                <Accordion.Item className='mt-2' eventKey="0">
                  <Accordion.Header><Skeleton className='rounded' variant='rectangle'/></Accordion.Header>
                  <Accordion.Body className="fontFamily">
                  <Skeleton className='rounded' variant='rectangle'/>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
        </>
    )
}

export default SkeletonFaqType
