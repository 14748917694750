import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import image from "../images/Looper-2 (3).png";
import { getDataByUrl } from "../API/HelpingMethod";
import SkeletonRequirement from "../Homepage/Skeleton-files/SkeletonRequirement";
import noimage from "../images/noimage2.webp"

const Requirement = () => {
  const [loading, setLoading] = useState(false);

  const [startupSteps, setStartupSteps] = useState([]);
  const inputData = {
    // device_type: "android",
    // sstoken: "95vdb549w0zp58lchu",
    login_user_id: "8",
    login_user_type: "startup",
    limit: 10,
    offset: 0,
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const apiCall = async () => {
    setLoading(true);
    const response = await getDataByUrl("startup_steps", inputData);
    if (!response?.error) { 
      setStartupSteps(response?.data);
    } else {
      // console.log("Error fetching data:", response?.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    apiCall();
  }, []);
  //   const styleCards = { boxShadow: "0 1px 6px 0px gray" };
  const styleCol = {
    borderRadius: "90%",
    height: "30px",
    width: "30px",
    fontSize: "20px",
  };
  const styleHeight = {
    height: "400px",
  };
  const text = "You have successfully registered your startup with us.";
  const startupIdeaText = "Startup idea";
  const StartupRequeriments = "Startup Requirements";
  return (
    <div className="pt-3">
      <img id="Servicebgimage" src={image} alt="image"  onError={(e) => e.target.src = noimage}/>
      <Container fluid className="mt-5">
        <Row className="text-center text-light primaryColor">
          <p className="mt-5">{startupIdeaText}</p>
          <h2 className="mt-2 mb-5 fw-bold">{StartupRequeriments}</h2>
        </Row>

        <Container>
          <Row className="mt-5">
            {loading ? (
              <SkeletonRequirement />
            ) : (
              <>
                {startupSteps?.map((item, ind) => {
                  return (
                    <>
                      <Col xl={6} lg={6} md={6} sm={12} xs={12} key={ind}>
                        <Container>
                          <Card className="mb-5 startup-step-card ">
                            <Card.Body>
                              <Col xl={12} sm={12} lg={12} md={12} xs={12} className="secondaryColor text-light text-center"
                                style={styleCol} >
                                {item?.id}
                              </Col>
                              <br />
                              <Row>
                                <Col xl={12} sm={12} lg={12} md={12} xs={12}>
                                  <h5 className="card-title fw-bold">
                                    {item?.title}
                                  </h5>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl={12} sm={12} lg={12} md={12} xs={12} className="">
                                  <Card.Text className="fontFamily">
                                    {item?.description || text}
                                  </Card.Text>
                                </Col>
                              </Row>
                              <Row className=" pt-5 ">
                                <Col className=" " >
                                  <Link className="color-secondary">
                                    {item?.status}
                                  </Link>
                                </Col>
                                <Col className="text-end" >
                                  <Link className="color-secondary">
                                    {item?.action}
                                  </Link>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Container>
                      </Col>
                    </>
                  );
                })}
              </>
            )}
          </Row>
        </Container>
      </Container>
    </div>
  );
};
export default Requirement;
