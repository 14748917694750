import { Skeleton } from "@mui/material";
import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

export default function SkeletonMarketplace({ titleWidth, descriptionWidth, categoryWidth, imageWidth }) {

  const styleCard = {
    border: "1px solid var(--primary) none",
  };
  const renderSkeletonCard = (key) => (
    <Card key={key} className="mt-4 shadow" style={styleCard}>
      <Card.Body>
        <Container>
          <Row>
            <Col md={3} className="market-category-list-img">
              <Skeleton className="rounded" variant="rectangle mb-3" width={imageWidth} height={200} />
            </Col> 
            <Col md={5} xl={6}>
              <Container>
                <Row className="fw-bold">
                  <Skeleton variant="rectangle" className="rounded" width={titleWidth} height={30} />
                </Row>
                <Row>
                  <Skeleton variant="rectangle" className="rounded mt-3 mb-3" width={descriptionWidth} height={20} />
                </Row>
              </Container>
            </Col>
            <Col md={4} xl={3}>
              <Container>
                <Row className="text-center fw-bold">
                  <Col>
                    <Skeleton variant="rectangle" className="rounded" width={categoryWidth} height={30} />
                  </Col>
                </Row>
                <Row>
                  <Skeleton variant="rectangle" className="rounded mt-3" width={titleWidth} height={30} />
                </Row>
                <Row>
                  <Skeleton variant="rectangle" className="rounded mt-3" width={titleWidth} height={30} />
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );

  const renderSkeletonCards = (count) => {
    const skeletonCards = [];
    for (let i = 0; i < count; i++) {
      skeletonCards.push(renderSkeletonCard(`skeleton-card-${i}`));
    }
    return skeletonCards;
  };

  return <>{renderSkeletonCards(5)}</>;
}
