import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { BsChevronRight, BsPencilFill, BsXLg } from "react-icons/bs";
import { getDataByUrl } from "../API/HelpingMethod";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Chip, CircularProgress, Skeleton } from "@mui/material";
import image from "../images/Looper-2 (1).png";
import "../css/Home.css";
import UserProfileSidebar from "./UserProfileSidebar";
import { useNotification } from "../../hooks";
import { FaEdit } from "react-icons/fa";

const ContributorProfile = () => {
    var loginuser = JSON.parse(localStorage.getItem("userstorage"));

    const navigate = useNavigate()
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [teamSizeModal, setTeamSizeModal] = useState(false);
    const [roleModal, setRoleModal] = useState(false);
    const [segmentModal, setSegmentModal] = useState(false);
    const [services, setServices] = useState([]);
    const [segments, setSegments] = useState([]);



    const [activeTeamSize, setActiveTeamSize] = useState(loginuser?.contributor_team_size);
    const [activeRole, setActiveRole] = useState(loginuser?.contributor_user_role);
    const [serviceToken, setServiceToken] = useState([]);
 



    const [btnLoading, setBtnLoading] = useState(false);
    const { showError, showSuccess, setOpenBackDrop } = useNotification();
    const [formData, setFormData] = useState({
        contributor_segments: serviceToken
    });

    useEffect(() => {
        if (!loginuser) {
            navigate("/");
        }
        let token = [];
        loginuser?.contributor_segment_services.forEach(element => {
            token.push(element?.token_contributor_segment);
        });
        setServiceToken(token || []);
        console.log("loginuser-----", loginuser)
        setSegments(loginuser?.contributor_segment.split(","))

    }, [])


    console.log("segments----", segments)


    const teamSize = [
        { id: 1, size: "0 to 10 Members" },
        { id: 2, size: "10 to 20 Members" },
        { id: 3, size: "20 to 50 Members" },
        { id: 4, size: "50 to 100 Members" },
        { id: 5, size: "100 to 150 Members" },
        { id: 6, size: "150 to 200 Members" },
        { id: 7, size: "200 to 300 Members" },
        { id: 8, size: "300 to 500 Members" },
        { id: 9, size: "500+ Members" }
    ]
    const userRole = [
        { id: 1, role: "Founder" },
        { id: 2, role: "CEO" },
        { id: 3, role: "Director" },
        { id: 4, role: "Project Manager" },
        { id: 5, role: "Project Coordinator" },
        { id: 6, role: "Team Leader" },
        { id: 7, role: "Designer" },
        { id: 8, role: "Database" },
        { id: 9, role: "Digital Marketer" },
        { id: 10, role: "DevOps" },
        { id: 11, role: "Developer" },
        { id: 12, role: "Tester" }
    ]

    const inputData = {
        login_user_id: loginuser?.id,
        login_user_type: loginuser?.user_type || "startup",
    };

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        apiCall();
    }, []);

    const apiCall = async () => {
        setLoading(true);
        const response = await getDataByUrl("user_profile", inputData);
        if (!response?.error) {
            // setData(response?.data);
            setData(response?.data);
            setLoading(false);
        } else {
            // console.log("Error fetching data:", response?.message);
        }
    };

    const getSegment = async () => {
        // setLoading(true);
        const segmentBody = {
            login_user_id: loginuser?.id,
            limit: 10,
            offset: 0,
            type: "parent",
        };
        const response = await getDataByUrl("service", segmentBody);
        if (!response?.error) {
            setServices(response?.data);
            // setLoading(false);
        }
    };

    useEffect(() => {
        getSegment();
    }, []);




    const handleClickService = (e, serviceId, childs, token) => {

        let tokenService = [];
        childs.forEach(element => {
            tokenService.push(element?.token)
        });


        if (e.target.checked === true) {
            setServiceToken([...serviceToken, ...tokenService, token]);
            setFormData({ ...formData, contributor_segments: [...tokenService, token] });
        }
        else {
            var a = serviceToken.filter(function (value, index, arr) {
                return value !== token;
            });

            childs?.map((child) => {
                a = a.filter(function (value, index, arr) {
                    return value !== child.token;
                });
            })
            setServiceToken(a);
            setFormData({ ...formData, contributor_segments: a });

            // setBodyData({ ...bodyData, contributor_segments: [...bodyData.contributor_segments,a] })

        }

    }


    const handleClickChildService = (e, id, childs, token) => {
        if (e.target.checked === true) {
            setServiceToken([...serviceToken, token]);
            setFormData({ ...formData, contributor_segments: [...serviceToken, token] });


        } else {

            var a = serviceToken.filter(function (value, index, arr) {
                return value !== token;
            });

            setServiceToken(a);
            setFormData({ ...formData, contributor_segments: a });

        }



    };
 
    const handleChangeTeamSize = (value) => {
        setActiveTeamSize(value);
        setFormData({ ...formData, contributor_team_size: value })
    } 
    const handleChangeUserRole = (value) => {
        setActiveRole(value);
        setFormData({ ...formData, contributor_user_role: value })
    } 


    const bodyData = {
        login_user_id: loginuser?.id || 0,
        login_user_type: loginuser?.user_type || "startup",
        token: loginuser?.token,
        values: { ...formData },
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        // formData.contributor_segments = serviceToken
        setBtnLoading(true);
        const response = await getDataByUrl("user_profile_update", bodyData);


        if (!response?.error) {
            localStorage.setItem("userstorage", JSON.stringify(response?.data));
            showSuccess(response?.message);
            setTeamSizeModal(false);
            setRoleModal(false);

            //   window.location.reload()
        } else {
            showError(response?.message);
        }
        setBtnLoading(false);
    };


    const textState = "State"
    const textCity = "City"
    var dob = "15 Aug 2001";
    const styleSpan = {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    };
    const textUserProfile = "User Profile";
    const UserProfileInformation = "Personal Information";
    const textName = "Name";
    const emailText = "Email";
    const mobileNumber = "Mobile Number";
    const textGender = "Gender";

    const dateOfBirth = "Date Of Birth";
    const AddressDetails = "Address Details";
    const textAddress = "Address";
    const CompanyDetails = "Company Details";


    const styleBorder = {
        border: "1px solid var(--primary)",
        paddingBottom: "52px"
    }
    return (
        <>
            <Modal show={teamSizeModal} onHide={() => setTeamSizeModal(false)} size="lg">
                <Modal.Body className="plans-modal">
                    <Container>
                        <Row>
                            <Col xs={10}>
                                <div><span className="h6">Update Team Size :</span> <span>{activeTeamSize}</span></div>
                            </Col>
                            <Col xs={2} className="text-end pe-0">
                                <div>
                                    <BsXLg className="close-button" size={30} onClick={() => setTeamSizeModal(false)} />
                                </div>
                            </Col>
                        </Row>
                        <Row sm={{ cols: 2 }} xs={{ cols: 1 }} className="change-startup-stage-box">
                            {teamSize && Array.isArray(teamSize) && teamSize.map((data, index) => {
                                return (<Col key={data?.token || index}
                                    onClick={() => { handleChangeTeamSize(data?.size) }}
                                    className=" mt-2 ">
                                    <Card className={(activeTeamSize === data?.size) ? "active-startup-stage startup-stage-card " : " startup-stage-card"}>
                                        <Container>
                                            <Row className="">
                                                <Col>
                                                    {/* <BsGearFill size={17} color="var(--secondary)" /> &nbsp; */}
                                                    <span className="h6">{data?.size}</span>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Card>
                                </Col>);
                            })}
                        </Row>

                    </Container>
                </Modal.Body>
                <Container>
                    <Row className="mb-3 text-center">
                        <Col>
                            <div className="ms-2">
                                <button disabled={btnLoading}
                                    className={btnLoading ? "disabled-btn" : "slide-button1"}
                                    onClick={handleSubmit}
                                >
                                    {btnLoading && <><CircularProgress color="inherit" className="mt-1" size={16} />&nbsp;</>}Update
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal>

            <Modal show={roleModal} onHide={() => setRoleModal(false)} size="lg">
                <Modal.Body className="plans-modal">
                    <Container>
                        <Row>
                            <Col xs={10}>
                                <div><span className="h6">Update User Role :</span> <span>{activeRole}</span></div>
                            </Col>
                            <Col xs={2} className="text-end pe-0">
                                <div>
                                    <BsXLg className="close-button" size={30} onClick={() => setRoleModal(false)} />
                                </div>
                            </Col>
                        </Row>
                        <Row sm={{ cols: 2 }} xs={{ cols: 1 }} className="change-startup-stage-box">
                            {userRole && Array.isArray(userRole) && userRole.map((data, index) => {
                                return (<Col key={data?.token || index}
                                    onClick={() => { handleChangeUserRole(data?.role) }}
                                    className=" mt-2 ">
                                    <Card className={(activeRole === data?.role) ? "active-startup-stage startup-stage-card " : " startup-stage-card"}>
                                        <Container>
                                            <Row>
                                                <Col>
                                                    {/* <BsGearFill role={17} color="var(--secondary)" /> &nbsp; */}
                                                    <span className="h6">{data?.role}</span>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Card>
                                </Col>);
                            })}
                        </Row>

                    </Container>
                </Modal.Body>
                <Container>
                    <Row className="mb-3 text-center">
                        <Col>
                            <div className="ms-2">
                                <button disabled={btnLoading}
                                    className={btnLoading ? "disabled-btn" : "slide-button1"}
                                    onClick={handleSubmit}
                                >
                                    {btnLoading && <><CircularProgress color="inherit" className="mt-1" size={16} />&nbsp;</>}Update
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal>

            <Modal show={segmentModal} onHide={() => setSegmentModal(false)} size="lg">
                <Modal.Body className="plans-modal">
                    <Container>
                        <Row>
                            <Col xs={10}>
                                <div><span className="h6">Update Segments </span> </div>
                            </Col>
                            <Col xs={2} className="text-end pe-0">
                                <div>
                                    <BsXLg className="close-button" size={30} onClick={() => setSegmentModal(false)} />
                                </div>
                            </Col>
                        </Row>
                        <Row sm={{ cols: 1 }} className="change-startup-stage-box">
                            {!loading && services && Array.isArray(services) && services.map((data, index) => {
                                return (
                                    <Col key={index} className="mt-3 mx-auto register-segment">
                                        <Accordion >
                                            <AccordionSummary
                                                // expandIcon={<ArrowDownwardIcon />}
                                                aria-controls="panel1-content"
                                                id={"panel1-header" + data?.id} >
                                                <Container>
                                                    <Row>
                                                        <Col xs={10} className="d-flex ps-0">
                                                            <div style={{ width: "60px", height: "50px" }}>
                                                                <img style={{ width: "100%", height: "100%" }} className=" rounded" src={data?.url_image} />
                                                            </div>
                                                            <div className="ps-2 align-self-center">{data?.name}</div>

                                                        </Col>
                                                        <Col xs={2} className=" text-end">
                                                            <Form.Check
                                                                type={'checkbox'}
                                                                id={`checkbox1`}
                                                                className="register-perent-segment-checkbox"
                                                                checked={serviceToken?.includes(data.token)}

                                                                onChange={(e) =>
                                                                    handleClickService(e, data.id, data.child_services, data.token
                                                                    )
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Container>

                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {data?.child_services && Array.isArray(data?.child_services) && data?.child_services.map((child) => {
                                                    return (
                                                        <Row className="mt-1 ">
                                                            <Col xs={8} className=" ms-auto d-flex  ps-0">
                                                                <div style={{ width: "50px", height: "40px" }}>
                                                                    <img className="img-fluid rounded" src={child?.url_image} />
                                                                </div>
                                                                <div className="ps-2 align-self-center border-bottom">{child?.name}</div>
                                                            </Col>
                                                            <Col xs={2} className=" text-end pe-4">
                                                                <Form.Check
                                                                    type={'checkbox'}
                                                                    id={`checkbox2`}
                                                                    checked={serviceToken?.includes(child.token)}
                                                                    onChange={(e) => handleClickChildService(e, child.id, child, child.token)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                                }
                                            </AccordionDetails>
                                        </Accordion>
                                    </Col>
                                )
                            })}

                        </Row>

                    </Container>
                </Modal.Body>
                <Container>
                    <Row className="mb-3 text-center">
                        <Col>
                            <div className="ms-2">
                                <button disabled={btnLoading}
                                    className={btnLoading ? "disabled-btn" : "slide-button1"}
                                    onClick={handleSubmit}
                                >
                                    {btnLoading && <><CircularProgress color="inherit" className="mt-1" size={16} />&nbsp;</>}Update
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal>

            <Container className=" pt-5 notification-container">
                <img id="Servicebgimage" src={image} alt="img" />
                <Row className="mt-2 mb-2">
                    <Col className="ms-3">
                        <Link to="/" className="text-decoration-none color-secondary">Home</Link>&nbsp;<BsChevronRight />&nbsp;<span>Profile</span>
                    </Col>
                </Row>

                <Row className="">
                    <Col sm={12} xs={12} md={5} lg={4} xl={3} className="mb-5" >
                    <UserProfileSidebar />
                    </Col>
                    <Col sm={12} xs={12} xl={9} md={7} lg={8} className=" pb-5">
                        <Card style={styleBorder} className="">
                            <Card.Body className="">
                                <Row>
                                    <h5 className="fontFamily fw-bold ">{textUserProfile}</h5>
                                    <h6 className="fontFamily fw-bold mt-2">{UserProfileInformation}</h6>
                                    <Col md={12} sm={12} xs={12} className="">

                                        {
                                            loading ? <Row className="ps-2 pe-2">
                                                <Skeleton height={80} />
                                            </Row> : <Row> <Col className="mt-4"  >
                                                <div className="card">
                                                    <div className="row g-0">
                                                        <Col >
                                                            <div className="card-body pt-2 pb-2">
                                                                <span className="card-text fontFamily">
                                                                    {textName}
                                                                </span>
                                                            </div>
                                                        </Col>

                                                        <Col className="text-end">
                                                            <div className="card-body pt-2">
                                                                <span className="h6  fontFamily"  >
                                                                    {data?.name || ""}
                                                                </span>
                                                            </div>
                                                        </Col>
                                                    </div>
                                                </div>
                                            </Col>
                                            </Row>
                                        }



                                    </Col>
                                </Row>

                                <Row>

                                    {
                                        loading ? <Col xl={6} lg={12} md={12} sm={12} xs={12} className="ps-2 pe-2">
                                            <Skeleton height={80} />
                                        </Col> :
                                            <Col xl={6} lg={12} md={12} sm={12} xs={12} className="mt-3">
                                                <div className="card">
                                                    <div className="row g-0">
                                                        <Col xs={4}>
                                                            <div className="card-body pt-2 pb-2">
                                                                <span className="card-text fontFamily">
                                                                    {emailText}
                                                                </span>
                                                            </div>
                                                        </Col>

                                                        <Col xs={8} className="text-end">
                                                            <div className="card-body pt-2 pb-2">
                                                                {loading ? (
                                                                    <Skeleton
                                                                        className="rounded"
                                                                        variant="rectangle"
                                                                        width={250}
                                                                        height={20}
                                                                    />
                                                                ) : (
                                                                    <span
                                                                        className="h6  fontFamily"
                                                                    >
                                                                        {data?.email}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </Col>
                                                    </div>
                                                </div>
                                            </Col>
                                    }



                                    {
                                        loading ? <Col xl={6} lg={12} md={12} sm={12} xs={12} className="ps-2 pe-2">
                                            <Skeleton height={80} />
                                        </Col> :
                                            <Col xl={6} lg={12} md={12} sm={12} xs={12} className="mt-3">
                                                <div className="card">
                                                    <div className="row g-0">
                                                        <Col xs={8}>
                                                            <div className="card-body pt-2 pb-2">
                                                                <span

                                                                    className="card-text mt-4 fontFamily"
                                                                >
                                                                    {/* <MdOutlineCall className="me-2 color-secondary" /> */}
                                                                    {mobileNumber}
                                                                </span>
                                                            </div>
                                                        </Col>

                                                        <Col xs={4} className="text-end">
                                                            <div className="card-body pt-2 pb-2">
                                                                {loading ? (
                                                                    <Skeleton
                                                                        className="rounded"
                                                                        variant="rectangle"
                                                                        width={100}
                                                                        height={20}
                                                                    />
                                                                ) : (
                                                                    <span
                                                                        className=" h6  fontFamily"
                                                                    >
                                                                        {data?.mobile}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </Col>
                                                    </div>
                                                </div>
                                            </Col>

                                    }

                                </Row>

                                <Row>
                                    {
                                        loading ? <Col xl={6} lg={6} md={12} sm={12} xs={12} className="ps-2 pe-2">
                                            <Skeleton height={80} />
                                        </Col> :
                                            <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mt-2">
                                                <div className="card">
                                                    <div className="row g-0">
                                                        <Col xs={6}>
                                                            <div className="card-body pt-2 pb-2">
                                                                <span className="card-text mt-4 fontFamily">
                                                                    {/* <CiUser className="me-2 color-secondary" /> */}
                                                                    {textGender}
                                                                </span>
                                                            </div>
                                                        </Col>

                                                        <Col xs={6} className="text-end">
                                                            <div className="card-body pt-2 pb-2">

                                                                <span className=" h6  fontFamily">
                                                                    {data?.gender || ""}
                                                                </span>
                                                            </div>
                                                        </Col>
                                                    </div>
                                                </div>
                                            </Col>
                                    }
                                    {
                                        loading ? <Col xl={6} lg={6} md={12} sm={12} xs={12} className="ps-2 pe-2">
                                            <Skeleton height={80} />
                                        </Col> :
                                            <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mt-2">
                                                <div className="card">
                                                    <div className="row g-0">
                                                        <Col xs={6} lg={6} md={6} xl={6}>
                                                            <div className="card-body pt-2 pb-2">
                                                                <span
                                                                    className="card-text mt-4 fontFamily"
                                                                    style={styleSpan}
                                                                >
                                                                    {dateOfBirth}
                                                                </span>
                                                            </div>
                                                        </Col>

                                                        <Col xs={6} lg={6} md={6} xl={6} className="text-end">
                                                            <div className="card-body pt-2 pb-2">
                                                                <span
                                                                    className=" h6  fontFamily"
                                                                    style={styleSpan}
                                                                >
                                                                    {data?.dob || dob}
                                                                </span>
                                                            </div>
                                                        </Col>
                                                    </div>
                                                </div>
                                            </Col>
                                    }

                                </Row>

                                <Row>
                                    <Col className="mt-3">
                                        {loading ? <> </> : <h6 className="fontFamily fw-bold">{AddressDetails}</h6>}
                                    </Col>
                                </Row>

                                <Row>

                                    {loading ? <Col xl={6} lg={6} md={12} sm={12} xs={12} className="ps-2 pe-2">
                                        <Skeleton height={80} />
                                    </Col> : <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mt-2">
                                        <div className="card">
                                            <div className="row g-0">
                                                <Col xs={6}>
                                                    <div className="card-body pt-2 pb-2">
                                                        <span className="card-text mt-4 fontFamily">
                                                            {textCity}
                                                        </span>
                                                    </div>
                                                </Col>

                                                <Col xs={6} className="text-end">
                                                    <div className="card-body pt-2 pb-2">
                                                        <span className=" h6  fontFamily">
                                                            {data?.city || ""}
                                                        </span>
                                                    </div>
                                                </Col>
                                            </div>
                                        </div>
                                    </Col>}

                                    {loading ? <Col xl={6} lg={6} md={12} sm={12} xs={12} className="ps-2 pe-2">
                                        <Skeleton height={80} />
                                    </Col> : <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mt-2">
                                        <div className="card">
                                            <div className="row g-0">
                                                <Col xs={6}>
                                                    <div className="card-body pt-2 pb-2">
                                                        <span className="card-text mt-4 fontFamily">
                                                            {textState || ""}
                                                        </span>
                                                    </div>
                                                </Col>

                                                <Col xs={6} className="text-end">
                                                    <div className="card-body pt-2 pb-2">
                                                        <span className=" h6  fontFamily" >
                                                            {data?.state || ""}
                                                        </span>
                                                    </div>
                                                </Col>
                                            </div>
                                        </div>
                                    </Col>}
                                </Row>

                                <Row>

                                    {loading ? <Col className="ps-2 pe-2">
                                        <Skeleton height={80} />
                                    </Col> : <Col className="mt-2">
                                        <div className="card">
                                            <div className="row g-0">
                                                <Col>
                                                    <div className="card-body pt-2 pb-2">
                                                        <span
                                                            style={styleSpan}
                                                            className="card-text mt-4 fontFamily"
                                                        >
                                                            {textAddress}
                                                        </span>
                                                    </div>
                                                </Col>

                                                <Col className="text-end">
                                                    <div className="card-body pt-2 pb-2">
                                                        <span className=" h6  fontFamily">
                                                            {data?.address ||
                                                                "2nd Floor, Plot No. 10"}
                                                        </span>
                                                    </div>
                                                </Col>
                                            </div>
                                        </div>
                                    </Col>}
                                </Row>

                                <Row>
                                    <Col className="mt-3">
                                        {loading ? <> </> : <h6 className="fontFamily fw-bold">{CompanyDetails}</h6>}
                                    </Col>
                                </Row>

                                <Row>
                                    {loading ? <Col xl={6} lg={6} md={12} sm={12} xs={12} className="ps-2 pe-2">
                                        <Skeleton height={80} />
                                    </Col> : <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mt-2">
                                        <div className="card">
                                            <div className="row g-0">
                                                <Col xs={6}>
                                                    <div className="card-body pt-2 pb-2">
                                                        <span className="card-text mt-4 fontFamily">
                                                            Company Name
                                                        </span>
                                                    </div>
                                                </Col>

                                                <Col xs={6} className="text-end">
                                                    <div className="card-body pt-2 pb-2">
                                                        <span className=" h6  fontFamily">
                                                            {data?.name_company || "Aimerse"}
                                                        </span>
                                                    </div>
                                                </Col>
                                            </div>
                                        </div>
                                    </Col>}
                                    {loading ? <Col xl={6} lg={6} md={12} sm={12} xs={12} className="ps-2 pe-2">
                                        <Skeleton height={80} />
                                    </Col> : data?.contributor_user_type === "company" && data?.contributor_team_size ? <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mt-2">
                                        <div className="card">
                                            <div className="row g-0">
                                                <Col xs={6}>
                                                    <div className="card-body pt-2 pb-2">
                                                        <span className="card-text mt-4 fontFamily">
                                                            Team Size
                                                        </span>
                                                    </div>
                                                </Col>

                                                <Col xs={6} className="text-end">
                                                    <div className="card-body pt-2 pb-2">
                                                        <span className=" h6  fontFamily" >
                                                            {data?.contributor_team_size}&nbsp; <FaEdit
                                                                style={{ transition: "transform 0.3s" }}
                                                                onMouseOver={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
                                                                onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")}
                                                                className="pointer" color="var(--secondary)" onClick={() => setTeamSizeModal(true)} />
                                                        </span>
                                                    </div>
                                                </Col>
                                            </div>
                                        </div>
                                    </Col> : <></>}
                                </Row>


                                <Row>
                                    {loading ? <Col xl={6} lg={6} md={12} sm={12} xs={12} className="ps-2 pe-2">
                                        <Skeleton height={80} />
                                    </Col> : data?.contributor_user_type === "company" ? <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mt-2">
                                        <div className="card">
                                            <div className="row g-0">
                                                <Col xs={6}>
                                                    <div className="card-body pt-2 pb-2">
                                                        <span className="card-text mt-4 fontFamily">
                                                            Contributer Type
                                                        </span>
                                                    </div>
                                                </Col>

                                                <Col xs={6} className="text-end">
                                                    <div className="card-body pt-2 pb-2">
                                                        <span className=" h6  fontFamily">
                                                            {data?.contributor_user_type}
                                                        </span>
                                                    </div>
                                                </Col>
                                            </div>
                                        </div>
                                    </Col> : <></>}
                                    {loading ? <Col xl={6} lg={6} md={12} sm={12} xs={12} className="ps-2 pe-2">
                                        <Skeleton height={80} />
                                    </Col> : data?.contributor_user_type === "company" && data?.contributor_user_role ? <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mt-2">
                                        <div className="card">
                                            <div className="row g-0">
                                                <Col xs={6}>
                                                    <div className="card-body pt-2 pb-2">
                                                        <span className="card-text mt-4 fontFamily">
                                                            User Role
                                                        </span>
                                                    </div>
                                                </Col>

                                                <Col xs={6} className="text-end">
                                                    <div className="card-body pt-2 pb-2">
                                                        <span className=" h6  fontFamily" >
                                                            {data?.contributor_user_role} {/* &nbsp; <FaEdit
                                                                style={{ transition: "transform 0.3s" }}
                                                                onMouseOver={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
                                                                onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")}
                                                                className="pointer" color="var(--secondary)"
                                                                onClick={() => setRoleModal(true)} /> */}
                                                        </span>
                                                    </div>
                                                </Col>
                                            </div>
                                        </div>
                                    </Col> : <></>}
                                </Row>

                                <Row>
                                    <Col className="mt-3">
                                        {loading ? <></> : <h6 className="fontFamily fw-bold">User Segment Select</h6>}
                                    </Col>
                                </Row>


                                <Row>
                                    {loading ? <Col className="ps-2 pe-2">
                                        <Skeleton height={80} />
                                    </Col> :
                                        <Col className="mt-1">
                                            <div className="card mb-1 ">
                                                <div className="row ps-3 pe-3">

                                                    {Array.isArray(segments) && segments.map((data) => {
                                                        return (
                                                            <div className="col-6 col-sm-3 col-md-4 col-lg-2 p-2">
                                                                <Chip size="small" variant="outlined" color="success" label={data} />
                                                            </div>
                                                        )
                                                    })}



                                                    {/* {data?.contributor_segment}  */}
                                                    {/* &nbsp; <FaEdit
                                                                    style={{ transition: "transform 0.3s" }}
                                                                    onMouseOver={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
                                                                    onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")}
                                                                    className="pointer" color="var(--secondary)" onClick={() => setSegmentModal(true)} /> */}

                                                </div>
                                            </div>
                                        </Col>

                                    }

                                </Row>

                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default ContributorProfile;


