// import logo from './logo.svg';
// import logo from 'logo.png';
import {useEffect, useState} from 'react';
import {Toast} from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './firebase/firebase.js'
import {getDeviceToken, onMessageListener} from './firebase/firebase.js';
import {useNotification} from "../hooks";
import {appFooterLogo} from "../components/API/HelpingMethod";

export default function WebNotification() {
    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({title: '', body: ''});
    const [isTokenFound, setTokenFound] = useState(false);
    const {showError, showSuccess} = useNotification();

    onMessageListener().then(payload => {
        setShow(true);
        setNotification({
            image: payload.notification.image,
            title: payload.notification.title,
            body: payload.notification.body
        })
        // console.log(payload);
        showSuccess(payload.notification.body);
    }).catch(err => console.log('failed: ', err));

    useEffect(() => {
        if (!localStorage.getItem('device_token')) {
            const token = getDeviceToken(setTokenFound).then(r => r);
        }
        if (!localStorage.getItem('device_latitude') || !localStorage.getItem('device_longitude')) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    localStorage.setItem('device_latitude', position?.coords?.latitude);
                    localStorage.setItem('device_longitude', position?.coords?.longitude);
                },
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );
        }
    }, []);

    // useEffect(() => {
    //     console.log(localStorage.getItem('device_token'), localStorage.getItem('deviceLatitude'), localStorage.getItem('deviceLongitude'))
    // }, [localStorage.getItem('device_token'), localStorage.getItem('deviceLatitude'), localStorage.getItem('deviceLongitude')])

    return (<>
        <Toast
            onClose={() => setShow(false)}
            show={show} delay={3000} autohide animation style={{
            position: 'absolute',
            top: 20,
            right: 20,
        }}>
            <Toast.Header>
                {/* <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            /> */}
                <img
                    src={notification.image || appFooterLogo}
                    className="rounded mr-2"
                    style={{height: "25px", width: "25px"}}
                    alt={notification.title}
                />
                <strong className="mr-auto">{notification.title}</strong>
                <small>just now</small>
            </Toast.Header>
            <Toast.Body><p className='text-dark text-start'>{notification.body}</p></Toast.Body>
        </Toast>

        {/*{isTokenFound && " Notification permission enabled 👍🏻 "}*/}
        {/*{!isTokenFound && "Need notification permission ❗️ "}*/}
        {/*<Button onClick={() => setShow(true)}>Show Toast</Button>*/}
    </>);
}