import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Services from "../Services/Services";
import ServiceDetails from "../Services/ServiceDetails";
import BusinessPlan from "../Services/BusinessPLan";
import ProjectIdea from "../ProjectIdeas/ProjectIdea";
import ProjectIdeaDetails from "../ProjectIdeas/ProjectIdeaDetails";
import Marketplace from "../Marketplace/Marketplace";
import Login from "../Resigtation/Login";
import VerifyOtp from "../Resigtation/VerifyOtp";
import ContactUs from "./ContactUs";
import AboutUs from "../Blog/AboutUs";
import Favorite from "../UserProfile/Favorite";
import MarketplaceProductDetails from "../Marketplace/MarketplaceProductDetails";
import StartupIdea from "../ProjectIdeas/StartupIdea";
import FrontBlog from "../Blog/FrontBlog";
import BlogDetail from "../Blog/BlogDetails";
import ServiceDialogBox from "../Services/ServiceDialogBox";
import SelectRequirement from "../SelectService/SelectRequirement";
import Summary from "../SelectService/Summary";
import ConfirmPayment from "../SelectService/ConfirmPayment";
import Requirement from "../SelectService/Requirement";
import PrivacyPolicy from "../Blog/PrivacyPolicy";
import RefundPolicy from "../Blog/RefundPolicy";
import TermsCondition from "../Blog/TermsCondition";
import ShoppingCart from "../Marketplace/ShoppingCart";
import Benefit from "./Benifit";
import Home from "./Home";
import Navigation from "./Navigation"; 
import Notification from "../UserProfile/Notification";
import ProjectIdeasCard from "../ProjectIdeas/ProjectIdeasCard";
import SelectService from "../SelectService/SelectService";
import image from "../images/6325254.jpg";
import UserSummary from "../UserProfile/UserSummary";
import VerifyUsername from "../Resigtation/VerifyUsername"
import PasswordChange from "../UserProfile/PasswordChange";
import UploadProfilePicture from "../UserProfile/UploadProfilePicture";
import CoolPage from "../ScrollToTopButton";
import FaqCategory from "../Faq/FaqCategory";
import FaqType from "../Faq/FaqType";
import NotificationDetail from "../UserProfile/NotificationDetail";
import SubmitIdeaStepper from "../SelectService/SubmitIdeaStepper";
import UpdateStartupIdea from "../SelectService/UpdateStartupIdea";
import ContributorRegistrationSteps from "../Resigtation/ContributorRegistration/ContributorRegistrationSteps";
import StartupRegistrationStep from "../Resigtation/StartupRegistration/StartupRegistrationStep";
import SelectUserType from "../Resigtation/SelectUserType";
import InvestorRegistrationStep from "../Resigtation/InvestorRegistration/InvestorRegistrationStep"; 
import { loginUserData } from "../API/client";
import ProfileUpdate from "../UserProfile/ProfileUpdate";
import AllUserProfile from "../UserProfile/AllUserProfile"; 
import Order from "../Marketplace/Order/Order";
import OrderDetail from "../Marketplace/Order/OrderDetail";

const Map = () => {

  const [loginNavigate, setLoginNavigate] = useState("/")
  const loginuser = loginUserData;

  const [loginLocation, setLoginLocation] = useState("/")

  return (
    <div className="overflow-hidden">
      <Routes>
        <Route path="/verify" element={<VerifyUsername />} />
        <Route path="/verify-otp" element={<VerifyOtp />} />
        {/* <Route path="/register" element={<Resigter/>}/> */}


        <Route path="/register/contributor" element={<ContributorRegistrationSteps />} />
        <Route path="/register/startup" element={<StartupRegistrationStep />} />
        <Route path="/register/investor" element={<InvestorRegistrationStep />} />


        <Route path="/register" element={<SelectUserType />} /> 

        <Route path="/login" element={<Login loginNavigate={loginNavigate} loginLocation={loginLocation} />} />
        <Route path="/forgot_password" element={<VerifyOtp />} />
        <Route path="/" element={<Navigation />}>
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />

          <Route path="/blog" element={<FrontBlog />} />
          <Route path="/blog/:slugBlog" element={<BlogDetail />} />
          <Route path="/Service_Dialog" element={<ServiceDialogBox />} />

          <Route path="/benefit" element={<Benefit />} />
          <Route path="/contact" element={<ContactUs />} />
          {/*<Route path="/start_now" element={<StartupIdea/>}/>*/}
          {/*<Route path="/startup_idea" element={<StartupIdea/>}/>*/}
          {/* Not startup idea */}
          {/*<Route path="/submit-idea" element={<SubmitIdea />}/>*/}

          <Route path="/marketplace" element={<Marketplace />} />
          <Route path="/marketplace/:slugMarketProduct" element={<MarketplaceProductDetails />} />
          <Route path="/cart" element={<ShoppingCart />} />

          <Route path="/service" element={<Services />} />
          <Route path="/service/:slugService" element={<ServiceDetails />} />
          <Route path="/service-plan/:slugServicePlan" element={<BusinessPlan />} />

          <Route path="/project-idea" element={<ProjectIdea />} />
          <Route path="/project-idea/:slugIdea" element={<ProjectIdeaDetails />} />

          {/*Startup idea step*/}
          <Route path="/startup-idea" element={<StartupIdea setLoginLocation={setLoginLocation} />} />
          <Route path="/requirement" element={<Requirement />} />

          <Route path="/submit-idea" element={<SubmitIdeaStepper />} />
          <Route path="/submit-idea/:startupIdeaId" element={<SubmitIdeaStepper />} />
          <Route path="/startup-Idea-update/:startupIdeaId" element={<UpdateStartupIdea />} />

          {/*workgroup process routes*/}
          <Route path="/select-service/:tokenWorkGroup" element={<SelectService />} />
          <Route path="/proceed/:tokenWorkGroup" element={<SelectRequirement />} />
          <Route path="/selected-summary/:tokenWorkGroup" element={<Summary />} />
          <Route path="/confirm-order/:tokenWorkGroup" element={<ConfirmPayment />} />

          {/*Projects workgroup*/}
          <Route path="/user-project" element={<ProjectIdeasCard />} />
          <Route path="/user-project/:tokenWorkGroup" element={<UserSummary />} />

          {/*general routes*/}
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/refund" element={<RefundPolicy />} />
          <Route path="/terms" element={<TermsCondition />} />
          <Route path="/faq" element={<FaqCategory />} />
          <Route path="/faq/:slugFaqCategory" element={<FaqType />} />


          {/*users routes*/}
          <Route path={"/profile"} element={<AllUserProfile />} /> 

          <Route path="/profile-update" element={<ProfileUpdate />} />

          <Route path="/favorite" element={<Favorite />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/notification/:slugNotification" element={<NotificationDetail />} />
          <Route path="/change-password" element={<PasswordChange />} />
          <Route path="/upload-picture" element={<UploadProfilePicture />} />
          <Route path="/order" element={<Order />} />
          <Route path="/order/:slugorder" element={<OrderDetail />} />


          <Route path="*" element={<img alt="404" src={image} className="text-center w-100" />} />
        </Route>
      </Routes>
      <CoolPage />
    </div>
  );
};
export default Map;


