import { Card, Col } from "react-bootstrap";
import { useMediaQuery } from "@mui/material";
import { Skeleton } from "@mui/material";

export default function SkeletonServiceDetailsChild({ numberOfServiceDetailsChild }) {
  const isXSmallScreen = useMediaQuery('(max-width:400px)');
  const isSmallScreen = useMediaQuery('(min-width:401px) and (max-width:600px)');
  const isMediumScreen = useMediaQuery('(min-width:601px) and (max-width:960px)');
  const isLargeScreen = useMediaQuery('(min-width:961px) and (max-width:1280px)');
  const isXLargeScreen = useMediaQuery('(min-width:1281px)');

  const renderSkeletonCard = () => (
    <Col xs={12} sm={12} md={6} xl={5} lg={6}>
      <Card id="service" className={`mt-4 shadow w-100 ${isXSmallScreen ? 'p-2' : ''}`}>
        <Card.Body className="d-flex align-items-center">
          <Col  sm={4} xs={6} md={5} xl={4}>
            <Skeleton
              className="rounded"
              variant="rectangle"
              width={
                isXSmallScreen
                  ? 100
                  : isSmallScreen
                  ? 120
                  : isMediumScreen
                  ? 100
                  : isLargeScreen
                  ? 100
                  : isXLargeScreen
                  ? 100
                  : 100
              }
              height={
                isXSmallScreen
                  ? 70
                  : isLargeScreen
                  ? 100
                  : isXLargeScreen
                  ? 100
                  : 100
              }
            />
          </Col>
          <Col xs={5} md={12} sm={12}>
            <Skeleton
              variant="rounded"
              width={
                isXSmallScreen
                  ? 120
                  : isSmallScreen
                  ? 150
                  : isMediumScreen
                  ? 180
                  : isLargeScreen
                  ? 200
                  : isXLargeScreen
                  ? 240
                  : 220
              }
              height={
                isXSmallScreen
                  ? 20
                  : isLargeScreen
                  ? 30
                  : isXLargeScreen
                  ? 30
                  : 25
              }
            />
          </Col>
        </Card.Body>
      </Card>
    </Col>
  );

  return <>{Array(numberOfServiceDetailsChild).fill().map((_, index) => renderSkeletonCard(index))}</>;
}
