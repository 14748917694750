import React, {createContext, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useNotification} from "../hooks";
import {getDataByUrl} from "../components/API/HelpingMethod";

export const AuthContext = createContext();
const info = JSON.parse(localStorage.getItem("user-info"));
const defaultAuthInfo = {
    profile: {...info},
    isLoggedIn: false,
    isPending: false,
    error: false,
};

export default function AuthProvider({children}) {
    const [authInfo, setAuthInfo] = useState({...defaultAuthInfo});
    const [verified, setVerified] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const {showError, showSuccess} = useNotification();

    const handleLoginWithPassword = async (details) => {
        const {error, data, message} = await getDataByUrl("profile_update", {...details});
        if (error) {
            showError(message);
            return;
        }

        if (data) {
            if (data.status !== 'active') {
                showError("User is not activated or verification pending. Please contact admin to verify you account.");
                // if (localStorage.getItem("prevLocation") && localStorage.getItem("prevLocation") !== location?.state + "" + location?.search) {
                navigate("/");
                return;
            }
            if (data.user_type !== 'client' && data.user_type !== 'dealer') {
                showError("You are not register with us as dealer or client. Only Dealer or Client can login this website.");
                // showError("User with given number is not authorize to login. Only Dealer or Client can assess this website.");
                navigate("/");
                return;
            }
        }

        //console.log(data);
        setAuthInfo({
            profile: {...data},
            isLoggedIn: true,
            isPending: false,
            error: "",
        });

        sessionStorage.setItem("user-name", data.name);
        sessionStorage.setItem("email", data.email);
        sessionStorage.setItem("mobile", data.mobile);
        sessionStorage.setItem("user-info", JSON.stringify(data));
        sessionStorage.setItem("image", data?.url_profile);
        //console.log(sessionStorage);
        showSuccess("Login Successfully");
        if (sessionStorage.getItem("prevLocation") && sessionStorage.getItem("prevLocation") !== location?.state + "" + location?.search) {
            navigate(sessionStorage.getItem("prevLocation"));
        } else {
            navigate("/");
        }
    };

    const setSessionData = async (data) => {
        //console.log(data);
        setAuthInfo({
            profile: {...data},
            isLoggedIn: true,
            isPending: false,
            error: "",
        });

        sessionStorage.setItem("user-name", data.name);
        sessionStorage.setItem("email", data.email);
        sessionStorage.setItem("mobile", data.mobile);
        sessionStorage.setItem("user-info", JSON.stringify(data));
        sessionStorage.setItem("image", data?.url_profile);
        //console.log(sessionStorage);
    };

    const resetpassword = async (details) => {
        const {error, data, message} = await getDataByUrl("password_update", {...details});
        if (error) {
            showError(message);
            return;
        }
        // // console.log(message);
        navigate("/auth");
        showSuccess("Password Reset Successful");
    };

    const createUser = async (details, uploadImage) => {
        const {error, data, message} = await getDataByUrl("profile_update", {
            ...details,
            uploadImage: uploadImage || false
        });
        if (error) {
            showError(message);
            return {error, data, message};
        }
        // console.log("data");
        // console.log(data);
        if (data.step === 'completed') {
            if (data.status === 'active') {
                showSuccess("Registration Complete");
                sessionStorage.setItem("user-name", data.name);
                sessionStorage.setItem("email", data.email);
                setAuthInfo({
                    profile: {...data},
                    isLoggedIn: true,
                    isPending: false,
                    error: "",
                });
                sessionStorage.setItem("user-info", JSON.stringify(data));
            } else {
                showSuccess("Registration completed successfully. Please wait until our admin verify you.");
            }
            navigate("/", {
                replace: true,
            });

        } else {

            showSuccess(message);
        }
        // console.log("step");
        // console.log(data);
        // if (setRegUserToken) {
        //     setRegUserToken(data.token);
        // }
        return {error, data, message}
    };

    const verifyUser = async (details, reason) => {
        const {error, data, message} = await getDataByUrl("verify_user", {...details});
        // // console.log(data)
        if (error) {
            setAuthInfo({...authInfo, isPending: false, error: true});
            return {notVerified: true, data};
        }
        /*if (data) {
            if (data.status !== 'active') {
                showError("User is not activated or verification pending. Please contact admin to verify you account.");
                return {notVerified: false, data};
            }
            if (data.user_type !== 'client' && data.user_type !== 'dealer') {
                showError("You are not register with us as dealer or client. Only Dealer or Client can login this website.");
                return {notVerified: false, data};
            }
        }*/
        if (reason === "verification-for-password-reset") {
            return {notVerified: false, data};
        }

        if (data?.step) {
            if (data?.step !== "completed" && data?.status === "incomplete") {
                return {notVerified: true, data};
            }
        }

        showSuccess("Please login to proceed further");
        sessionStorage.setItem("is_verified", "true");
        setVerified(true);
        navigate("/signin", {
            state: {username: details.username}, email: details.email, mobile: details.mobile,
            replace: true,
        });
        // setTimeout(() => {

        // }, 2000);

        // console.log(data);
        return {notVerified: false, data};
    };

    const checkUser = async (details) => {
        const {error, data, message} = await  getDataByUrl("verify_user",{...details});
        return {error, data, message};
    };

    const userLogout = async (details) => {
        const {error, data, message} = await getDataByUrl("verify_user",{...details});
        if (error) {
            return showError("Invalid User");
        }
        showSuccess(message);
        sessionStorage.clear();
        setAuthInfo({...defaultAuthInfo});
    };

    const sendUserOtp = async (details, reason) => {
        const {error, data, message, user} = await getDataByUrl("verify_user_by_otp",{...details});
        if (error) {
            showError(message);
            return {sendStatus: false, data};
        }
        // console.log(data);
        // if (user) {
        //     console.log(user);
        // }
        if (details.otp) {
            showSuccess("OTP Verification Successful");
        } else {
            showSuccess(message);
        }
        // console.log("Details")
        // console.log(details)

        if (reason) {
            if (reason === "verification-before-registration") {
                navigate("/signup", {
                    state: {email: details.email || "", mobile: details.mobile || "", username: details.username || ""},
                    replace: true,
                });
                sessionStorage.setItem("is_verified", "true");
            } else if (reason === "login-by-otp") {
                const new_user = data.user;
                if (new_user) {
                    if (new_user.status !== 'active') {
                        showError("User is not activated or verification pending. Please contact admin to verify you account.");
                        // if (sessionStorage.getItem("prevLocation") && sessionStorage.getItem("prevLocation") !== location?.state + "" + location?.search) {
                        //     navigate(sessionStorage.getItem("prevLocation"));
                        // } else {
                        navigate("/");
                        // }
                        return;
                    }
                    if (new_user.user_type !== 'client' && new_user.user_type !== 'dealer') {
                        showError("You are not register with us as dealer or client. Only Dealer or Client can login this website.");
                        // showError("User with given number is not authorize to login. Only Dealer or Client can assess this website.");
                        navigate("/");
                        return;
                    }
                }

                // navigate("/", {
                //     state: {email: details.email, mobile: details.mobile, username: details.username},
                //     replace: true,
                // });
                if (sessionStorage.getItem("prevLocation") && sessionStorage.getItem("prevLocation") !== location?.state + "" + location?.search) {
                    navigate(sessionStorage.getItem("prevLocation"), {
                        state: {email: details.email, mobile: details.mobile, username: details.username},
                        replace: true,
                    });
                } else {
                    navigate("/", {
                        state: {email: details.email, mobile: details.mobile, username: details.username},
                        replace: true,
                    });
                }


                setAuthInfo({
                    profile: {...new_user},
                    isLoggedIn: true,
                    isPending: false,
                    error: "",
                });
                sessionStorage.setItem("image", data?.user?.url_profile);
                sessionStorage.setItem("user-name", data?.user?.name);
                sessionStorage.setItem("email", data?.user?.email);
                sessionStorage.setItem("user-info", JSON.stringify(data?.user));
            } else if (reason === "verification-for-reset-password") {
                navigate("/reset-password", {
                    state: {id: data.user.id, email: details.email, mobile: details.mobile, username: details.username},
                    replace: true,
                });
            }
        }
        return {sendStatus: true, data};
    };

    //   const handleLogout = () => {
    //     localStorage.removeItem("auth-token");
    //     setAuthInfo({ ...defaultAuthInfo });
    //   };

    //  handleLogout
    return (
        <AuthContext.Provider
            value={{
                handleLoginWithPassword,
                createUser,
                sendUserOtp,
                verifyUser,
                userLogout,
                resetpassword,
                authInfo,
                checkUser,
                setSessionData
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
