import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { Bs1CircleFill } from "react-icons/bs";
import { useNotification } from "../../../hooks";

const InvestorPersonalDetails = ({ setActiveStep, handleBack, setBodyData, bodyData }) => {
    const [btnLoading, setBtnLoading] = useState(false);
    const { showError, showSuccess, setOpenBackDrop } = useNotification();

    const handlechange = (e) => {
        const { name, value } = e.target;
        setBodyData({ ...bodyData, [name]: value });
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!bodyData?.name) {
            showError("Please Enter Your Name")
        } else if (!bodyData?.dob) {
            showError("Please Enter Your Date Of Birth")
        }
        else if (!bodyData?.email) {
            showError("Please Enter Your Email")
        }
        else if (!bodyData?.mobile) {
            showError("Please Enter Your Mobile")
        } else {
            setActiveStep(2)

        }
    }

    return (
        <>
            <Container>
                <Row xs={{ cols: 1 }}>
                    <Col className="text-center mb-3 mt-4 pt-2 fw-bold"> <span>Personal Details</span> </Col>
                    <form onSubmit={handleSubmit}>
                        <Col className="mt-3"><span className="h6">What's Your Name?</span></Col>
                        <Col className="mt-1 mx-auto d-flex justify-content-center">
                            <Form.Control type="text"
                                className="idea-stepper-input"
                                value={bodyData?.name}
                                onChange={handlechange}
                                name="name" />
                        </Col>
                        <Col className=" mt-3"><span className="h6">What's Your Birthday?</span></Col>
                        <Col className="mt-1 mx-auto d-flex justify-content-center">
                            <Form.Control
                                onChange={handlechange}
                                type="date"
                                className="idea-stepper-input"
                                value={bodyData?.dob}
                                name="dob" />
                        </Col>
                        <Col className=" mt-3"><span className="h6">What's Your Email Address?</span></Col>
                        <Col className="mt-1 mx-auto d-flex justify-content-center">
                            <Form.Control type="email"
                                onChange={handlechange}
                                className="idea-stepper-input"
                                value={bodyData?.email}
                                name="email" />
                        </Col>
                        <Col className=" mt-3"><span className="h6">What's Your Mobile Number?</span></Col>
                        <Col className="mt-1 mx-auto d-flex justify-content-center">
                            <Form.Control type="mobile"
                                className="idea-stepper-input"
                                onChange={handlechange}
                                value={bodyData?.mobile}
                                name="mobile" />
                        </Col>
                        <Col className="d-flex justify-content-center mt-5">
                            <div className="me-2">
                                <button onClick={handleBack} className="input-primary-buttons" >
                                    Back
                                </button>
                            </div>

                            <div className="ms-2">
                                <button disabled={btnLoading} className={btnLoading ? "disabled-btn" : "input-buttons"} type="submit" >
                                    {btnLoading && <><CircularProgress color="inherit" className="mt-1" size={16} />&nbsp;</>}Next
                                </button>
                            </div>
                        </Col>
                    </form>
                </Row>

            </Container>
        </>
    )
}
export default InvestorPersonalDetails