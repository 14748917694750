// import React from 'react';
// import { Skeleton, useMediaQuery } from '@mui/material';
// import { Card, Col, Row } from 'react-bootstrap';

// export default function SkeletonBenefit({ numberOfColsBenefit }) {
//   const isXSmallScreen = useMediaQuery('(max-width: 600px)');
//   const isSmallScreen = useMediaQuery('(min-width: 601px) and (max-width: 960px)');
//   const isMediumScreen = useMediaQuery('(min-width: 961px) and (max-width: 1280px)');
//   const isLargeScreen = useMediaQuery('(min-width: 1281px)');

//   const renderSkeletonCard = (index) => (
//     <Col className="" md={6} key={index}>
//       <Card id="service" className="mt-4 shadow">
//         <Card.Body className="">
//           <Row className="d-flex align-items-center">
//             <Col md={3}>
//               <Skeleton variant="circular" width={isXSmallScreen ? 60 : 100} height={isXSmallScreen ? 60 : 100} />
//             </Col>
//             <Col md={9} className="">
//               <Skeleton width={isXSmallScreen ? 120 : isSmallScreen ? 200 : isMediumScreen ? 100 : isLargeScreen ? 100 : 200} height={30} />
//               <Skeleton width={isXSmallScreen ? 200 : isSmallScreen ? 300 : isMediumScreen ? 300 : isLargeScreen ? 350 : 100} height={30} />
//               <Skeleton width={isXSmallScreen ? 200 : isSmallScreen ? 200 : isMediumScreen ? 500 : isLargeScreen ? 350 : 100} height={30} />
//             </Col>
//           </Row> 
//         </Card.Body>
//       </Card>
//     </Col>
//   );

//   return <>{Array(numberOfColsBenefit).fill().map((_, index) => renderSkeletonCard(index))}</>;
// }


import React from 'react';
import { Skeleton, useMediaQuery } from '@mui/material';
import { Card, Col, Row } from 'react-bootstrap';

export default function SkeletonBenefit({ numberOfColsBenefit }) {
  const isXSmallScreen = useMediaQuery('(max-width: 600px)');
  const isSmallScreen = useMediaQuery('(min-width: 601px) and (max-width: 960px)');
  const isMediumScreen = useMediaQuery('(min-width: 961px) and (max-width: 1280px)');
  const isLargeScreen = useMediaQuery('(min-width: 1281px)');
  const isXLargeScreen = useMediaQuery('(min-width: 1920px)');

  const renderSkeletonCard = (index) => (
    <Col className="" md={6} key={index}>
      <Card id="service" className="mt-4 shadow border-0">
        <Card.Body className="">
          <Row className="d-flex align-items-center">
            <Col md={3}>
              <Skeleton
                variant="circular"
                width={
                  isXSmallScreen ? 60 :
                    isSmallScreen ? 80 :
                      isMediumScreen ? 70 :
                        isLargeScreen ? 120 :
                          isXLargeScreen ? 150 : 100
                }
                height={
                  isXSmallScreen ? 60 :
                    isSmallScreen ? 80 :
                      isMediumScreen ? 70 :
                        isLargeScreen ? 120 :
                          isXLargeScreen ? 150 : 100
                }
              />
            </Col>
            <Col md={9} className="">
              <Skeleton width={isXSmallScreen ? 120 : isSmallScreen ? 150 : isMediumScreen ? 100 : isLargeScreen ? 100 : isXLargeScreen ? 250 : 200} height={30} />
              <Skeleton width={isXSmallScreen ? 200 : isSmallScreen ? 200 : isMediumScreen ? 200 : isLargeScreen ? 350 : isXLargeScreen ? 500 : 100} height={30} />
              <Skeleton width={isXSmallScreen ? 200 : isSmallScreen ? 200 : isMediumScreen ? 200 : isLargeScreen ? 100 : isXLargeScreen ? 500 : 100} height={30} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );

  return <>{Array(numberOfColsBenefit).fill().map((_, index) => renderSkeletonCard(index))}</>;
}


























