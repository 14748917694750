import {Helmet} from "react-helmet";
import {React, useEffect, useState} from "react";
import {apiHepingMethod, appFooterLogo} from "../components/API/HelpingMethod";
import {getFullPageTitle} from "./useCustomHelpingMethod";

export function HelmetCustomInclude({url, ref_type, ref_id, title, image, keywords, description}) {
    const [pageTitle, setPageTitle] = useState("");
    const [metaKeywords, setMetaKeywords] = useState("");
    const [metaDescription, setMetaDescription] = useState("");
    const [metaImageUrl, setMetaImageUrl] = useState(appFooterLogo);
    const [author, setAuthor] = useState('Startup Starter');
    const [metaData, setMetaData] = useState(null);

    const getMetaData = async () => {
        let isUpdated = false;
        let inputData = {}
        if (url) {
            inputData = {page_url: url}
            isUpdated = true;
        } else if (ref_id && ref_type) {
            inputData = {ref_id: ref_id, ref_type: ref_type}
            isUpdated = true;
        } else {
            isUpdated = false;
        }
        console.log(isUpdated, keywords);
        if (isUpdated && !keywords) {
            const response = await apiHepingMethod('meta_data', inputData).then((r) => r.data);
            console.log(response);
            if (response) {
                setMetaData(response?.data);
                isUpdated = true;
            } else {
                isUpdated = false;
            }
        }
        if (!isUpdated && (keywords || description)) {
            // setMetaData({
            //     title: title || "",
            //     author: "Startup Starter",
            //     keywords: keywords || "",
            //     description: description || "",
            //     url_image: image || appFooterLogo,
            // })
            // setPageTitle(getFullPageTitle(title || ""));
            // setMetaKeywords(keywords);
            // setMetaDescription(description);
            // setMetaImageUrl(image);
        }
    };
    useEffect(() => {
        getMetaData().then(r => r);
    }, [url, ref_id, ref_type, image, keywords, description])

    useEffect(() => {
        if (metaData?.title && metaData?.title !== "") {
            setPageTitle(getFullPageTitle(metaData?.title, metaData?.author));
        } else {
            setPageTitle(getFullPageTitle(title || "", metaData?.author));
        }
        if (metaData?.keywords) {
            setMetaKeywords(metaData?.keywords);
        }

        if (metaData?.description) {
            setMetaDescription(metaData?.description);
        }
        if (metaData?.url_image) {
            setMetaImageUrl(metaData?.url_image);
        } else {
            if (image) setMetaImageUrl(image);
        }
    }, [metaData]);

    return (
        <>
            <Helmet>
                <title>{pageTitle || title || ""}</title>
                <meta name="title" content={metaData?.title || pageTitle || ""}/>
                <meta name="keywords" content={metaKeywords || keywords || ""}/>
                <meta name="description" content={metaDescription || description || ""}/>
                <meta property="og:title" content={pageTitle || ""}/>
                <meta property="og:url" content={window.location.href || ""}/>
                <meta property="og:description" content={metaDescription || ""}/>
                <meta property="og:image" content={metaImageUrl || image || appFooterLogo}/>
            </Helmet>
        </>
    )
}