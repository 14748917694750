import React from "react";
import './App.css';
import Map from "./components/Homepage/Map";

const App = () => {
    return (
        <div>
            <Map/>
        </div>
    ) 
}
export default App;