import React, { useEffect } from "react";
import { Card, Col, Container, Row, CloseButton, Button } from "react-bootstrap";
import setting from "../images/Frame 94setting_home.png";
import idea from "../images/Frame 104idea.png";
import business from "../images/Frame 104Business.png";
import noimage from "../images/noimage2.webp"

const ServiceDialogBox = () => {


    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }, []);


    return (
        <div>
            <Container>
                <Row>
                    <Col className="">

                        <Col>
                            <img src={setting} className="img-fluid" onError={(e) => e.target.src = noimage} />
                            <h5 className="mt-3x">Strategic Planning</h5>
                            <p className="mt-3">You can select multiple sub-services for your project requirement.</p>
                        </Col>
                        <Row>
                            <div className="d-flex flex-row mt-2">
                                <div className="" >
                                    <div className="card mb-3" style={{ boxShadow: "0 1px 6px 0px gray" }} >
                                        <label htmlFor="mCheck">
                                            <div className="row g-0">
                                                <div className="col-4">
                                                    <img src={business} onError={(e) => e.target.src = noimage} className="img-fluid rounded-start p-1" />
                                                </div>
                                                <div className="col-6">
                                                    <p className="card-text mt-3 col-sm-ms-2" style={{ fontSize: "12px", fontWeight: "bold" }}>Business idea</p>
                                                </div>
                                                <div className="col-2 mt-3">
                                                <input id="mCheck" type="checkbox" />
                                                </div>
                                            </div>
                                        </label>

                                    </div>
                                </div>
                                <div className="ms-2">
                                    <div className="card mb-3 " style={{ boxShadow: "0 1px 6px 0px gray" }}>
                                        <label htmlFor="secondCheck">
                                            <div className="row  g-0">
                                                <div className="col-4">
                                                    <img src={idea} onError={(e) => e.target.src = noimage} className="img-fluid rounded-start p-1" />
                                                </div>
                                                <div className="col-6">
                                                    <p className="card-text mt-3 " style={{ fontSize: "10px", fontWeight: "bold" }}>Idea Validation</p>
                                                </div>

                                                <div className="col-2 mt-3">
                                                <input id="secondCheck" type="checkbox" />
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default ServiceDialogBox;