import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import React, { useState } from "react";
import { apiHepingMethod, getDataByUrl } from "../API/HelpingMethod";
import { useNotification } from "../../hooks";
import { FaCircleArrowLeft } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import { CircularProgress } from "@mui/material";

export default function VerifyUsernameForm() {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false)
  const [buttonText, setButtonText] = useState("Verify");
  const [buttonClicked, setButtonClicked] = useState(false);
  const { showError, showSuccess } = useNotification();
  const [bodyData, setBodyData] = useState({
    username: "",
  });

  const handlechange = (e) => {
    const { name, value } = e.target;
    setBodyData({ ...bodyData, [name]: value });
  };

  async function handleClick(e) {
    e.preventDefault();
    // setButtonText(<CircularProgress color="inherit" size={20} />);
    setButtonClicked(true)
    let response = await getDataByUrl("verify_user", bodyData); 
    if (response.error === false) {
      navigate("/login?username=" + response?.data?.email);
    }
    // setButtonText("Verify");.
    setButtonClicked(false)
  }

  return (
    <>
      <Container className=" " >
        <p className={"py-2"}>
          <Link to="/" className="color-secondary">
            <FaCircleArrowLeft /> Back to home
          </Link>
        </p>

        <Row className="ps-4 ps-sm-0 pe-4 pe-sm-0 mb-5 mt-5">
          <Col sm={1} md={1} lg={2} xl={3}></Col>
          <Col sm={10} md={10} lg={8} xl={6} className="border rounded pb-5 ps-3 pe-3">
            <section className={"pt-5 pb-3"}>
              <h4 className={" fw-bold text-center"}>User Verification</h4>
              <p className={"mb-2 text-center"}>
                Please provide following details to verify
              </p>
              <form onSubmit={handleClick}>
                <Row xs={{ cols: 1 }} className={"g-3 mt-2"}>
                  <Col>
                    <label className="mb-1" htmlFor="">Email/Mobile</label>
                    <InputGroup>
                      <Form.Control
                        className="input-border-secondary"
                        type="text"
                        name="username"
                        onChange={handlechange}
                        required
                        value={bodyData?.username}
                      />
                    </InputGroup>
                  </Col>
                  <Col className="text-center">
                  

                    {/* <div style={{ position: "relative" }}> */}
                    {buttonClicked && (
                      <Row className="ms-1 me-1 text-center">
                        <Col>
                          <button className='fontFamily text-decoration-none mb-4 disabled-btn w-sm-50 w-75' disabled>
                            <CircularProgress style={{ color: "var(--secondary)" }} className="mt-1" size={16} />&nbsp;&nbsp; Loading...
                          </button>
                        </Col>
                      </Row>
                    )}
                    {!buttonClicked && (
                      <button
                        type={"submit"}
                        className={`slide-button1 fontFamily text-decoration-none mb-4 w-50  ${isHovered ? "hovered" : ""}`}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                      >
                        {buttonText}
                      </button>
                    )}
                    {/* </div> */}
                  </Col>
                </Row>
              </form>
              <hr />
              <Row className="">
                <Col className="text-center">or</Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <button className="form-control ">
                    <FcGoogle className="me-2" />
                    Continue With Google
                  </button>
                </Col>
              </Row>
            </section>

          </Col>
          <Col sm={1} md={1} lg={2} xl={3}></Col>

        </Row>

      </Container>
    </>
  );
}
