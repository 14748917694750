import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, CircularProgress, FormControl, InputLabel, MenuItem, Select, Skeleton, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { getDataByUrl } from "../../API/HelpingMethod";
import { useNotification } from "../../../hooks";

const SegmentList = ({ setActiveStep, handleBack, setBodyData, bodyData }) => {
    const [btnLoading, setBtnLoading] = useState(false);
    const [member, setMember] = useState('');
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [serviceToken, setServiceToken] = useState(bodyData?.contributor_segments || []);
    const { showError, showSuccess, setOpenBackDrop } = useNotification();

    var loginuser = JSON.parse(localStorage.getItem("userstorage"));
    const inputData = {
        login_user_id: loginuser?.id,
        limit: 10,
        offset: 0,
        type: "parent",
    };

    const apiCall = async () => {
        setLoading(true);
        // setOpenBackDrop(true);
        const response = await getDataByUrl("service", inputData);
        if (!response?.error) {
            setServices(response?.data);
            setLoading(false);
        }
        // setOpenBackDrop(false);

    };

    useEffect(() => {
        apiCall();
    }, []);



    const handleChange = (event) => {
        setMember(event.target.value);
    };



    const handleClickService = (e, serviceId, childs, token) => {

        let tokenService = [];
        childs.forEach(element => {
            tokenService.push(element?.token)
        });


        if (e.target.checked === true) {
            setServiceToken([...serviceToken, ...tokenService, token]);
            // setBodyData({ ...bodyData, contributor_segments:[...bodyData.contributor_segments,[...tokenService]]})

        }
        else {
            var a = serviceToken.filter(function (value, index, arr) {
                return value !== token;
            });

            childs?.map((child) => {
                a = a.filter(function (value, index, arr) {
                    return value !== child.token;
                });
            })
            setServiceToken(a);
            // setBodyData({ ...bodyData, contributor_segments: [...bodyData.contributor_segments,a] })

        }

    }


    const handleClickChildService = (e, id, childs, token) => {
        if (e.target.checked === true) {
            setServiceToken([...serviceToken, token]);
            // setBodyData({ ...bodyData, contributor_segments: [...bodyData.contributor_segments,token]})

        } else {

            var a = serviceToken.filter(function (value, index, arr) {
                return value !== token;
            });

            setServiceToken(a);
            // setBodyData({ ...bodyData, contributor_segments: [...bodyData.contributor_segments, a] })

        }



    };

    const handleSubmit = () => {

        if (!serviceToken.length) {
            showError("Please Select Segment")
        } else {
            setActiveStep(5)
            setBodyData({ ...bodyData, contributor_segments: serviceToken })

        }




    }


    return (
        <>
            <Container>
                <Row xs={{ cols: 1 }}>
                    <Col className="text-center mb-3 mt-4 pt-2 fw-bold"> <span>What's Segment Your Company Deal With?</span> </Col>

                    {loading && <>
                        <Col className="mx-auto skeleton-m-p mt-3"><Skeleton width="100%" height={50} /></Col>
                        <Col className="mx-auto skeleton-m-p mt-3"><Skeleton width="100%" height={50} /></Col>
                        <Col className="mx-auto skeleton-m-p mt-3"><Skeleton width="100%" height={50} /></Col>
                        <Col className="mx-auto skeleton-m-p mt-3"><Skeleton width="100%" height={50} /></Col>
                    </>}
                    {!loading && services && Array.isArray(services) && services.map((data) => {
                        return (
                            <Col key={data?.id} className="mt-3 mx-auto register-segment">
                                <Accordion >
                                    <AccordionSummary
                                        // expandIcon={<ArrowDownwardIcon />}
                                        aria-controls="panel1-content"
                                        id={"panel1-header" + data?.id} >
                                        <Container>
                                            <Row>
                                                <Col xs={10} className="d-flex ps-0">
                                                    <div style={{ width: "60px", height: "50px" }}>
                                                        <img style={{ width: "100%", height: "100%" }} className=" rounded" src={data?.url_image} />
                                                    </div>
                                                    <div className="ps-2 align-self-center">{data?.name}</div>

                                                </Col>
                                                <Col xs={2} className=" text-end">
                                                    <Form.Check
                                                        type={'checkbox'}
                                                        id={`checkbox1`}
                                                        className="register-perent-segment-checkbox"
                                                        checked={serviceToken?.includes(data.token)}

                                                        onChange={(e) =>
                                                            handleClickService(e, data.id, data.child_services, data.token
                                                            )
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Container>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {data?.child_services && Array.isArray(data?.child_services) && data?.child_services.map((child) => {
                                            return (
                                                <Row className="mt-1 ">
                                                    <Col xs={8} className=" ms-auto d-flex  ps-0">
                                                        <div style={{ width: "50px", height: "40px" }}>
                                                            <img className="img-fluid rounded" src={child?.url_image} />
                                                        </div>
                                                        <div className="ps-2 align-self-center border-bottom">{child?.name}</div>
                                                    </Col>
                                                    <Col xs={2} className=" text-end pe-4">
                                                        <Form.Check
                                                            type={'checkbox'}
                                                            id={`checkbox2`}
                                                            checked={serviceToken?.includes(child.token)}
                                                            onChange={(e) => handleClickChildService(e, child.id, child, child.token)}
                                                        />
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            </Col>
                        )
                    })}




                    <Col className="d-flex justify-content-center mt-5">
                        <div className="me-2">
                            <button onClick={handleBack} className="input-primary-buttons" >
                                Back
                            </button>
                        </div>

                        <div className="ms-2">
                            <button disabled={btnLoading} className={btnLoading ? "disabled-btn" : "input-buttons"} onClick={handleSubmit}>
                                {btnLoading && <><CircularProgress color="inherit" className="mt-1" size={16} />&nbsp;</>}Next
                            </button>
                        </div>
                    </Col>
                </Row>

            </Container>
        </>
    )
}
export default SegmentList