import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap"; 
import { Link, useParams, useSearchParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel'; 
import { loginUserData } from "../../API/client"; 
import { getDataByUrl } from "../../API/HelpingMethod";
import SelectCountry from "./SelectCountry"; 
import PersonalDetails from "./PersonalDetails";
import RegisterType from "./RegisterType";
import { FaCircleArrowLeft } from "react-icons/fa6";
import noimage from "../../images/noimage2.webp"
import logo from "../../../assets/logos/logo.webp";
import CompanyDetails from "./CompanyDetails";
import SegmentList from "./SegmentList";
import CreatePassword from "./CreatePassword";

const ContributorRegistrationSteps = () => {
    const userDetails = JSON.parse(localStorage.getItem("user-register"));
   
    const [pageLoading, setPageLoading] = useState(false);
    let [searchParams] = useSearchParams()
    const { startupIdeaId } = useParams();
    const [bodyData, setBodyData] = useState({
        mobile: userDetails?.mobile || "",
        email: userDetails?.email || "",
        user_type: "contributor"

    })
 
    
 
    const [data, setData] = useState(null)

    const steps = ['Select Country', 'Describes', 'Personal Details', 'Describes2', 'step5', 'step6'];
    const [activeStep, setActiveStep] = React.useState(0);

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const styleImage = { maxHeight: "50px", alignContent: "center" }


    const updateStepOnDataLoad = (data) => {
        if (data) {
            if (!data?.startup_name) {
                setActiveStep(0);
            } else if (!data?.startup_idea) {
                setActiveStep(1);
                // } else if (!data?.startup_idea_description) {
                //     setActiveStep(1);
            } else if (!data?.startup_audience) {
                setActiveStep(2);
            } else if (!data?.startup_problem) {
                setActiveStep(3);
            } else if (!data?.startup_process) {
                setActiveStep(4);
            } else if (!data?.startup_uniqueness) {
                setActiveStep(5);
            } else if (!data?.startup_competitor) {
                setActiveStep(6);
            } else if (!data?.startup_requirement) {
                setActiveStep(7);
            } else {
                setActiveStep(7);
            }
        }
    }

    const getStartupIdea = async () => {
        if (startupIdeaId) {
            bodyData.startup_idea_id = startupIdeaId
        }
        setPageLoading(true);
        const response = await getDataByUrl("startup_idea", bodyData);
        // console.log("response---", response)

        if (response?.error === false) {
            setData(response?.data)
            updateStepOnDataLoad(response?.data);
        }

        setPageLoading(false);
    }

    useEffect(() => {
        if (startupIdeaId) {
            getStartupIdea().then(r => r);
        }
    }, [startupIdeaId])

 

    return (
        <>

            <Container fluid className="forms-container" >
                <Row>
                    <Col lg={6} md={6} className="p-0">
                        <div className="login-image-container"></div>
                    </Col>

                    <Col lg={6} md={6} style={{ overflow: 'scroll', height: "99vh" }}>

                        <Row>
                            <Col className="">
                                <Link to="/" className="color-secondary">
                                    <FaCircleArrowLeft /> Back to home
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <img
                                    src={logo}
                                    alt="logo"
                                    style={styleImage}
                                    onError={(e) => e.target.src = noimage}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-4">
                            <Col sm={10} className="mx-auto idea-stepper">
                                <Box sx={{ width: '100%' }}>
                                    <Stepper activeStep={activeStep}>
                                        {activeStep !== null && steps.map((label, index) => {
                                            return (<Step key={label}> <StepLabel> </StepLabel> </Step>);
                                        })}
                                    </Stepper>

                                </Box>
                            </Col>
                        </Row>
                        <Row className=" " >
                            <Col sm={10} className="mx-auto">
                                {activeStep === 0 && <SelectCountry bodyData={bodyData} setBodyData={setBodyData} setActiveStep={setActiveStep} />}
                                {/* {activeStep === 1 && <Describes bodyData={bodyData} setBodyData={setBodyData} handleBack={handleBack} setActiveStep={setActiveStep} />} */}

                                {activeStep === 1 &&
                                    <PersonalDetails bodyData={bodyData} setBodyData={setBodyData} handleBack={handleBack} setActiveStep={setActiveStep} />}
                                {activeStep === 2 &&
                                    <RegisterType bodyData={bodyData} setBodyData={setBodyData} handleBack={handleBack} setActiveStep={setActiveStep} />}
                                {activeStep === 3 &&
                                    <CompanyDetails bodyData={bodyData} setBodyData={setBodyData} handleBack={handleBack} setActiveStep={setActiveStep} />}
                                {activeStep === 4 &&
                                    <SegmentList bodyData={bodyData} setBodyData={setBodyData} handleBack={handleBack} setActiveStep={setActiveStep} />}
                                {activeStep === 5 &&
                                    <CreatePassword  bodyData={bodyData} setBodyData={setBodyData} handleBack={handleBack} setActiveStep={setActiveStep} />}
                            </Col>

                        </Row>
                    </Col>

                </Row>



            </Container>
        </>
    )
}
export default ContributorRegistrationSteps