import {apiHepingMethod, appFavicon, appFooterLogo} from "../API/HelpingMethod";
import {appAddress, appLogo, appTitle, loginUserData} from "../API/client";


const loadRazorpayScript = () => {
    const script = document.createElement("script");
    script.src = `https://checkout.razorpay.com/v1/checkout.js`;
    document.body.appendChild(script);
};

const handlePaymentUpdate = async ({inputData, data, response, user, ref_type, setLoading}) => {
    setLoading(true);
    const inData = data;
    // console.log(inputData, data, response, user, ref_type);
    if (inData) {
        const inputDataCustom = {
            login_user_id: inputData?.login_user_id || user?.id || "",
            login_user_type: inputData?.login_user_type || user?.user_type || "",
            payment_source: "razerpay",
            payment_mode: "razerpay", //'debit_card','credit_card','account','upi'
            type: inputData?.type || "razerpay", //'debit_card','credit_card','account','upi'
            amount: inputData?.amount || response?.amount || 0,
            amount_currency: inputData?.amount_currency || response?.amount_currency || "USD",
            ref_id: inData?.id || null,
            token_ref: inData?.token || null,
            ref_type: ref_type || "work_group",
            status: "success",
            txn_order_id: response?.razorpay_payment_id,
            txn_ref: response?.razorpay_payment_id,
            remark: "Payment done by razorpay"
        }
        console.log("Payment input", inputDataCustom);
        const responseSt = await apiHepingMethod("user_client_payment_update", inputDataCustom);
        console.log("Payment success", responseSt?.data);
        return responseSt?.data;
    }
}

export default function handlePaymentTransaction({
                                                     Razorpay,
                                                     inputBodyData,
                                                     data,
                                                     amount_payable,
                                                     amount_currency,
                                                     ref_id,
                                                     ref_type,
                                                     redirectOnSuccess,
                                                     redirectOnError,
                                                     setLoading
                                                 }) {

    // if (!isAppPaymentShow) {
    //     setNotAvOpen(true);
    //     return;
    // }
    loadRazorpayScript();

    // const amount = (amount_payable.toFixed(2)) * 100; // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    const amount = Math.round(amount_payable * 100); // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    const currency = amount_currency || "INR"
    const name = appTitle || "Startup Starter"
    const description = "Payment For " + (ref_type ? ref_type + ":" : "") + (data?.title || data?.name || "") + "";

    console.log(amount, currency, name, description);
    // return;
    const options = {
        key: "rzp_live_E0P5QfFU2Fkc3h", // Enter the Key ID generated from the Dashboard aimerse
        amount: amount,
        currency: currency || "USD",
        name: name,
        description: description || "Test Transaction",
        image: appFavicon || appLogo || appFooterLogo,
        // order_id: "", //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
        handler: async function (response) {
            // console.log(response);
            // alert(response.razorpay_payment_id);
            // alert(response.razorpay_order_id);
            // alert(response.razorpay_signature);
            // const payment = await capture_payment(response);
            // if (payment) {
            setLoading(true);
            const res = await handlePaymentUpdate({
                inputData: inputBodyData,
                data: data,
                response: response,
                user: loginUserData,
                ref_type: ref_type,
                setLoading: setLoading
            });
            let responseRes = {};
            if (res) {
                responseRes = res?.data || res;
                // responseRes.error = false;
                // responseRes.status = 'success';
                // responseRes.message = 'Payment is successfully completed.';
                if (redirectOnSuccess) redirectOnSuccess(responseRes)
                setLoading(false);
                return responseRes;
            } else {
                return {
                    error: true,
                    status: "fail",
                    message: "Payment Fail, Please try again later.",
                    data: res
                }
            }
            // return responseRes;
            // navigate("/order-complete?order=" + data.token, {
            //     state: {id: order?.order_parts[0]?.part?.token_brand},
            //     replace: true,
            // });
            // }
        },
        modal: {
            confirm_close: true, // this is set to true, if we want confirmation when clicked on cross button.
            // This function is executed when checkout modal is closed
            // There can be 3 reasons when this modal is closed.
            ondismiss: async (reason) => {
                console.log(reason);
                let response = {
                    error: true,
                    status: "fail",
                    message: "Payment cancelled, Please try again later.",
                    data: reason
                };
                const {
                    reason: paymentReason, field, step, code,
                } = reason && reason.error ? reason.error : {};
                // Reason 1 - when payment is cancelled. It can happend when we click cross icon or cancel any payment explicitly.
                if (reason === undefined) {
                    // console.log('cancelled');
                    response.message = "You cancelled your payment, Please try again later.";
                }
                // Reason 2 - When modal is auto closed because of time out
                else if (reason === 'timeout') {
                    console.log('timedout');
                    response.message = "Transaction payment timeout so your payment failed, Please try again.";

                }
                // Reason 3 - When payment gets failed.
                else {
                    console.log('failed');
                    response.message = "Payment getaway unable to process your request";
                }
                if (redirectOnError) {
                    redirectOnError(response);
                } else {
                    return response;
                }

                // navigate("/order-complete?order=" + order.token, {
                //     state: {id: order?.order_parts[0]?.part?.token_brand},
                //     replace: true,
                // });
            },
        },
        prefill: {
            name: loginUserData?.name || " ",
            email: loginUserData?.email || " ",
            contact: loginUserData?.mobile || " ",
        },
        notes: {
            address: appAddress || "",
        },
        theme: {
            // color: "#1286b7",
            color: "#1ec571",
            width: "500px !important",
        },
        // width: "500px",
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
        console.log(response);
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
        // showError("Payment failed, Try again");

        // navigate("/order-complete?order=" + order.token, {
        //     state: {id: order?.order_parts[0]?.part?.token_brand},
        //     replace: true,
        // });
        const res = {
            error: true,
            status: "fail",
            message: "Payment failed, Payment getaway unable to process your request. Please try again later",
            data: response
        };
        if (redirectOnError) {
            redirectOnError(res);
        } else {
            return res;
        }
    });

    rzp1.open();
}
