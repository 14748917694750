// import { Skeleton } from "@mui/material";
// import React from "react";
// import { Card, Col, Container, Row } from "react-bootstrap";

// export default function SkeletonSelectService() {
//   const renderSkeletonCard = () => {
//     const cardData = Array.from({ length: 10 }, (_, index) => index + 1);

//     return cardData.map((index) => (
//       <Col key={index} md={6} sm={6} className="mb-3">
//         <Card className="p-3">
//           <Card.Body className="p-0">
//             <Container className="pt-0 pt-sm-2 pb-2 ms-sm-1 ms-0 ">
//               <Row className="d-flex align-items-center">
//                 <Col md={2} sm={2} xs={2} className="">
//                   <Skeleton variant="rectangle" className="rounded" width={50} height={50} />
//                 </Col>
//                 <Col className="fw-bold"  md={8} sm={8} xs={8}>
//                   <Skeleton variant="rectangle" className="rounded" width={200} height={20} />
//                 </Col>
//                 <Col md={1} sm={1}  xs={1} className="text-center text-end">
//                   <Skeleton variant="rectangle" className="rounded" width={20} height={20} />
//                 </Col>
//               </Row>
//             </Container>
//           </Card.Body>
//         </Card>
//       </Col>
//     ));
//   };

//   return <>{renderSkeletonCard()}</>;
// }


import { Skeleton } from "@mui/material";
import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

export default function SkeletonSelectService({ titleWidth, iconWidth }) {
  const renderSkeletonCard = () => {
    const cardData = Array.from({ length: 10 }, (_, index) => index + 1);

    return cardData.map((index) => (
      <Col key={index} md={6} sm={6} className="mb-3">
        <Card className="p-3 border-0 shadow">
          <Card.Body className="p-0"> 
            <Container className="pt-0 pt-sm-2 pb-2 ms-sm-1 ms-0 ">
              <Row className="d-flex align-items-center">
                <Col md={2} sm={2} xs={3} className="">
                  <Skeleton variant="rectangle" className="rounded" width={iconWidth} height={50} />
                </Col>
                <Col className="fw-bold"  md={8} sm={8} xs={7}>
                  <Skeleton variant="rectangle" className="rounded" width={titleWidth} height={20} />
                </Col>
                <Col md={1} sm={1}  xs={1} className="text-center text-end">
                  <Skeleton variant="rectangle" className="rounded" width={20} height={20} />
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      </Col>
    ));
  };

  return <>{renderSkeletonCard()}</>;
}

