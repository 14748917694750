import React from "react";
import { Col, Container, Row } from "react-bootstrap";
// import setting from "../images/Frame 98app_design.png";
// import company from "../images/Frame 98company.png";
// import development from "../images/Frame 104Customized_Development.png";
// import future from "../images/Frame 104Future_Provider.png";
// import {GoArrowRight} from "react-icons/go";
 
const WhyChoose = () => {

    const headingText="Why Choose Us ?" 
    const BenefitText="Benefits You Get With Us"
    const paragraph="We understand that choosing the right mobile app for your startup is crucial. Here's why you should consider our Startup Starter Mobile App:"
    const styleFont={fontFamily:"Montserrat"}
    return ( 
        <div>
            <Container>
                <Row ><b style={styleFont} className="color-primary mt-5">{headingText}</b></Row>
                <Row>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12} className="">
                        <h3 className="mt-3 fw-bold" style={styleFont}>{BenefitText}</h3>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12} className="mt-3">
                        <p style={styleFont}>
                  {paragraph}
                        </p>
                    </Col>

                </Row>
         
          


            </Container>

        </div>
    )
}
export default WhyChoose;