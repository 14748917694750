import React, {useEffect, useState} from "react";
import {Card, Col, Container, Form, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {amountFormate, getDataByUrl} from "../API/HelpingMethod";
import {CircularProgress, Skeleton} from "@mui/material";
import image from "../images/Looper-2 (1).png";
import {useNotification} from "../../hooks";
import noimage from "../images/noimage2.webp"
import {loginUserData, paymentGatewayCurrency} from "../API/client";
import {get_date_formatted, getFirstWordCap} from "../../hooks/useCustomHelpingMethod";
import handlePaymentTransaction from "../razorpay/handel_payment_razorpay";
import useRazorpay from "react-razorpay";

const ConfirmPayment = () => {
    const authLoginUser = loginUserData;
    const [isHovered, setIsHovered] = useState(false);
    const [loading, setLoading] = useState();
    const {showError, showSuccess} = useNotification();
    const [button, setButton] = useState("Pay and Start Now");
    const [buttonClicked, setButtonClicked] = useState(false);
    const [data, setData] = useState();
    const navigate = useNavigate();
    const [amountTotalCost, setAmountTotalCost] = useState(0);
    const [amountTotalTax, setAmountTotalTax] = useState(0);
    const [amountTotalPayable, setAmountTotalPayable] = useState(0);
    const [amountTotalPayableCurrent, setAmountTotalPayableCurrent] = useState(0);
    const [paymentType, setPaymentType] = useState("amount_token");
    const [amountCurrency, setAmountCurrency] = useState("USD");
    const [amountTotalPayableCurrentUSD, setAmountTotalPayableCurrentUSD] = useState(0);
    const [amountCurrencyUSD, setAmountCurrencyUSD] = useState("USD");
    const {tokenWorkGroup} = useParams();
    const [Razorpay] = useRazorpay();

    useEffect(() => {
        if (!localStorage.getItem("userstorage")) {
            navigate("/project-idea")
        }
    }, [localStorage.getItem("userstorage")])

    const [isActive, setIsActive] = useState(1);

    const inputData = {
        login_user_id: authLoginUser?.id,
        login_user_type: authLoginUser?.user_type || "startup",
        token: tokenWorkGroup,
    };
    const apiCall = async () => {
        setLoading(true);
        const response = await getDataByUrl("user_work_group", inputData);
        // console.log(response?.data);
        if (!response?.error) {
            setData(response?.data);
            setAmountCurrencyUSD(response?.data?.amount_currency || "USD")
            setAmountCurrency(response?.data?.amount_currency_user_country || "USD")
            setAmountTotalCost(response?.data?.amount_total_user_country);
            setAmountTotalTax(response?.data?.amount_total_tax_user_country);
            setAmountTotalPayable(response?.data?.amount_total_payable_user_country);


            //token amount 1% default select
            setAmountTotalPayableCurrent(response?.data?.amount_total_token_user_country);
            setAmountTotalPayableCurrentUSD(response?.data?.amount_total_token);
        } else {
            // console.log("Error fetching data:", response?.message);
        }
        setLoading(false);
    };

    useEffect(() => {
        apiCall();
    }, []);

    const handleChangePayment = (val, valUSD, cardIndex, amountType) => {
        setAmountTotalPayableCurrent(val);
        setAmountTotalPayableCurrentUSD(valUSD);
        setIsActive(cardIndex);
        setPaymentType(amountType);
    };

    const [inputBodyData, setInputBodyData] = useState({
        payment_source: "razorpay",
        ref_type: "work_group",
        login_user_id: authLoginUser?.id,
    });

    const redirectOnSuccess = (response) => {
        showSuccess(response?.message || "Payment successfully completed, Thank you for your interest with our services, our executive will contact you soon.");
        navigate("/user-project");
    }
    const redirectOnError = (response) => {
        showError(response?.message || "Payment failed or cancelled so process unable to complete. Please try again later");
        // navigate("/user-project");
    }

    const handleSubmitPayment = async () => {
        setButtonClicked(true)
        inputBodyData.ref_id = data?.id;
        inputBodyData.amount = amountTotalPayableCurrentUSD;
        inputBodyData.amount_currency = amountCurrencyUSD;
        inputBodyData.txn_order_id = "";
        inputBodyData.txn_ref = "";
        inputBodyData.type = paymentType;

        handlePaymentTransaction({
            Razorpay: Razorpay,
            inputBodyData: inputBodyData,
            data: data,
            amount_payable: paymentGatewayCurrency === 'USD' ? amountTotalPayableCurrentUSD : amountTotalPayableCurrent,
            amount_currency: paymentGatewayCurrency === 'USD' ? amountCurrencyUSD : amountCurrency,
            //amount_payable: 1,
            // amount_currency: "INR",
            ref_id: data?.id,
            ref_type: "work_group",
            redirectOnSuccess: redirectOnSuccess,
            redirectOnError: redirectOnError,
            setLoading: setLoading
        });
        setButtonClicked(false)
        return;

        // const response = await getDataByUrl("user_client_payment_update", inputBodyData);
        //
        // if (!response?.error) {
        //   showSuccess(response?.message);
        //   navigate("/user-project");
        // } else {
        //   showError(response?.message);
        //   // console.log("Error fetching data:", response?.message);
        // }
        // setButton("Start Now");
        // setButtonClicked(false)
    };

    const textConfirmPayment = "Confirm Payment";
    const textPaymentSummary = "Payment Summary";
    const textTotalCost = "Total Service Cost";
    const textTotalTax = "Tax Amount";
    const textTotalPayable = "Total Payable Amount";
    const textSelectPaymentType = "Select Payment Type";
    const textPaymentTypeTokenAmount = "Pay 1% Token Amount";
    const textPaymentTypeTokenAmountDescription = "You are paying token payment for the services.Your complete payment is safe with us and would be charged as per the service availed.";
    const textPaymentTypeAdvanceAmount = "Pay 20% Advance Amount";
    const textPaymentTypeAdvanceAmountDescription = "You are paying token payment for the services. Your complete payment is safe with us and would be charged as per the service availed.";
    const textPaymentTypeTotalAmount = "Pay Total Amount";
    const textPaymentTypeTotalAmountDescription = "You are paying token payment for the services. Your complete payment is safe with us and would be charged as per the service availed.";
    const textPayNow = "Paying Now";

    return (
        <div>

            <img id="Servicebgimage" src={image} alt="img" onError={(e) => e.target.src = noimage}/>
            <Container fluid className="mt-3 pt-5">
                <Container className="mb-3">
                    {/* <Link to={"/selected-summary/" + tokenWorkGroup} className="color-secondary text-decoration-none">
            <BsChevronLeft color="#000000" />&nbsp;{textBackButton}
          </Link> */}
                </Container>
                <Container className="mb-5">
                    <Row>
                        <Card>
                            <Card.Body>
                                <Container>
                                    <Row className="mb-2 fw-bold h3">{textConfirmPayment}</Row>

                                    {loading &&
                                    <div className="skeleton-m-p"><Skeleton width="100%" height={100}/></div>}

                                    {!loading && <Row><Card>
                                        <Card.Body>
                                            <Container>
                                                <Row>
                                                    <Col lg={12}><b>{data?.title}</b></Col>
                                                    <Col xl={10} lg={10} className="color-secondary">
                                                        {get_date_formatted(data?.created_at || data?.updated_at || null, 'date-time')}
                                                    </Col>
                                                    <Col xl={2} lg={2}
                                                         className="text-end color-secondary">{getFirstWordCap(data?.status)}</Col>
                                                </Row>
                                            </Container>
                                        </Card.Body>
                                    </Card> </Row>}


                                    {loading &&
                                    <div className="skeleton-m-p mt-3"><Skeleton width="100%" height={160}/></div>}
                                    {!loading && <Row className="mt-4">
                                        <Card>
                                            <Card.Body className=" p-1">
                                                <Container>
                                                    <Row className="fw-bold">{textPaymentSummary}</Row>
                                                    <Row className="mt-2">
                                                        <hr/>
                                                    </Row>

                                                    <Row className="fw-bold ">
                                                        <Col>{textTotalCost}</Col>
                                                        {
                                                            loading ? <Skeleton className="rounded" variant="rectangle"
                                                                                width={100} height={15}/> : <>
                                                                <Col className="text-end text-primary">
                                                                    {amountFormate(amountTotalCost)}&nbsp;{amountCurrency}
                                                                </Col>
                                                            </>
                                                        }

                                                    </Row>
                                                    <Row className="fw-bold  ">
                                                        <Col>{textTotalTax}</Col>

                                                        {
                                                            loading ? <Skeleton className="rounded" variant="rectangle"
                                                                                width={100} height={15}/> : <>
                                                                <Col
                                                                    className="text-end text-danger">+ {amountFormate(amountTotalTax)}&nbsp;{amountCurrency}</Col>
                                                            </>
                                                        }


                                                    </Row>
                                                    <Row className="fw-bold ">
                                                        <Col>{textTotalPayable}</Col>
                                                        {
                                                            loading ? <Skeleton className="rounded" variant="rectangle"
                                                                                width={100} height={15}/> : <>
                                                                <Col
                                                                    className="text-end text-success">{amountFormate(amountTotalPayable)}&nbsp;{amountCurrency}</Col>
                                                            </>
                                                        }
                                                    </Row>

                                                </Container>
                                            </Card.Body>
                                        </Card>
                                    </Row>}

                                    <Row className="mb-1 mt-4 fw-bold">{textSelectPaymentType}</Row>

                                    {loading &&
                                    <div className="skeleton-m-p mt-1"><Skeleton width="100%" height={100}/></div>}
                                    {loading &&
                                    <div className="skeleton-m-p mt-3"><Skeleton width="100%" height={100}/></div>}
                                    {loading &&
                                    <div className="skeleton-m-p mt-3"><Skeleton width="100%" height={100}/></div>}

                                    {!loading && <>
                                        <Row className="mt-3">
                                            <Card className={`payment-card ${isActive === 1 ? "payment-card-active" : ""
                                            }`}
                                                  onClick={() => handleChangePayment(data?.amount_total_token_user_country, data?.amount_total_token, 1, 'amount_token')}
                                                  style={{
                                                      cursor: "pointer",
                                                      border: isActive === 1 ? "2px solid var(--secondary)" : "",
                                                  }}
                                            >
                                                <Card.Body className="p-0">
                                                    <Container className="pt-2 pb-2">
                                                        <Row className="d-flex align-items-center">
                                                            <Col sm={10} xs={10}>
                                                                <Row className="fw-bold">
                                                                    <Col sm={12} xs={12}>
                                                                        {textPaymentTypeTokenAmount}
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col className="fontFamily" sm={12} xs={12}>
                                                                        {textPaymentTypeTokenAmountDescription}
                                                                    </Col>
                                                                </Row>
                                                            </Col>


                                                            <Col xs={2} sm={2}
                                                                 className="d-sm-flex d-block align-items-center text-center">
                                                                <Form.Check
                                                                    onChange={() => handleChangePayment(data?.amount_total_token_user_country, data?.amount_total_token, 1, 'amount_token')}
                                                                    checked={isActive === 1}
                                                                    name="radio"
                                                                    className="ms-sm-auto ms-0 paymentradio"
                                                                    type="radio"
                                                                />
                                                            </Col>
                                                        </Row>

                                                    </Container>
                                                </Card.Body>
                                            </Card>
                                        </Row>
                                        <Row className="mt-3">
                                            <Card
                                                className={`payment-card ${isActive === 2 ? "payment-card-active" : ""}`}
                                                onClick={() => handleChangePayment(data?.amount_total_advance_user_country, data?.amount_total_advance, 2, 'amount_advance')}
                                                style={{
                                                    cursor: "pointer",
                                                    border: isActive === 2 ? "2px solid var(--secondary)" : "",
                                                }}
                                            >
                                                <Card.Body className="p-0">
                                                    <Container className="pt-2 pb-2">
                                                        <Row className="d-flex align-items-center">
                                                            <Col sm={10} xs={10}>
                                                                <Container>
                                                                    <Row
                                                                        className="fw-bold">{textPaymentTypeAdvanceAmount}</Row>
                                                                    <Row
                                                                        className=" fontFamily">{textPaymentTypeAdvanceAmountDescription}</Row>
                                                                </Container>
                                                            </Col>

                                                            <Col xs={2} sm={2}
                                                                 className="d-sm-flex d-block align-items-center text-center">
                                                                <Form.Check
                                                                    onChange={() => handleChangePayment(data?.amount_total_advance_user_country, data?.amount_total_advance, 2, 'amount_advance')}
                                                                    checked={isActive === 2}
                                                                    name="radio"
                                                                    className="ms-sm-auto ms-0 paymentradio"
                                                                    type="radio"
                                                                />
                                                            </Col>
                                                        </Row>

                                                    </Container>
                                                </Card.Body>
                                            </Card>
                                        </Row>
                                        <Row className="mt-3">
                                            <Card
                                                className={`payment-card ${isActive === 3 ? "payment-card-active" : ""}`}
                                                onClick={() => handleChangePayment(data?.amount_total_payable_user_country, data?.amount_total_payable, 3, 'amount_full')}
                                                style={{
                                                    cursor: "pointer",
                                                    border: isActive === 3 ? "2px solid var(--secondary)" : "",
                                                }}
                                            >
                                                <Card.Body className="p-0">
                                                    <Container className="pt-2 pb-2">
                                                        <Row className="d-flex align-items-center">
                                                            <Col sm={10} xs={10}>
                                                                <Container>
                                                                    <Row
                                                                        className="fw-bold">{textPaymentTypeTotalAmount}</Row>
                                                                    <Row className=" fontFamily">
                                                                        {textPaymentTypeTotalAmountDescription}
                                                                    </Row>
                                                                </Container>
                                                            </Col>

                                                            <Col xs={2} sm={2}
                                                                 className="d-sm-flex d-block align-items-center text-center">
                                                                <Form.Check
                                                                    onChange={() => handleChangePayment(data?.amount_total_payable_user_country, data?.amount_total_payable, 3, 'amount_full')}
                                                                    checked={isActive === 3}
                                                                    name="radio"
                                                                    className="ms-sm-auto ms-0 paymentradio"
                                                                    type="radio"
                                                                />
                                                            </Col>

                                                        </Row>

                                                    </Container>
                                                </Card.Body>
                                            </Card>
                                        </Row>
                                    </>}

                                    <Row className="mt-4">
                                        <hr/>
                                    </Row>

                                    {!loading && <Row className="mb-2 mt-4">
                                        <Container>
                                            <Row className="d-flex align-items-center">
                                                <Col>
                                                    <Container>
                                                        <Row>
                                                            <Col xs={12}> {textPayNow}</Col>
                                                            <Col xs={12}
                                                                 className=" fw-bold">{amountFormate(amountTotalPayableCurrent)}&nbsp;{amountCurrency}
                                                                <small>approx.</small></Col>
                                                            <Col xs={12}
                                                                 className=" fw-bold">{amountFormate(amountTotalPayableCurrentUSD)}&nbsp;{amountCurrencyUSD}</Col>
                                                        </Row>
                                                    </Container>
                                                </Col>


                                                {loading ? <> <Col/> <Col/> <Col/> <Col><Skeleton height={50}/></Col></>
                                                    : <Col className="text-end" style={{position: "relative"}}>
                                                        {buttonClicked && (
                                                            <button
                                                                className='fontFamily text-decoration-none mb-4 disabled-btn'
                                                                disabled>
                                                                <>
                                                                    <CircularProgress
                                                                        style={{color: "var(--secondary)"}}
                                                                        className="mt-1"
                                                                        size={16}/>&nbsp;&nbsp; Loading...
                                                                </>
                                                            </button>
                                                        )}
                                                        {!buttonClicked && (
                                                            <button
                                                                onClick={handleSubmitPayment}
                                                                className={`slide-button1 fontFamily text-decoration-none  mb-4 ${isHovered ? "hovered" : ""}`}
                                                                // onMouseEnter={() => setIsHovered(true)}
                                                                // onMouseLeave={() => setIsHovered(false)}
                                                            >
                                                                {button}
                                                            </button>
                                                        )}
                                                    </Col>
                                                }


                                            </Row>
                                        </Container>
                                    </Row>}
                                </Container>
                            </Card.Body>
                        </Card>
                    </Row>
                </Container>
            </Container>
        </div>
    );
};
export default ConfirmPayment;
