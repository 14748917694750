import React, {useEffect, useState} from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import {FiChevronRight} from "react-icons/fi";
import {Link} from "react-router-dom";
import {getDataByUrl} from "../API/HelpingMethod";
import SkeletonBlog from "../Homepage/Skeleton-files/SkeletonBlog";
import {FaLongArrowAltRight, FaRegUserCircle} from "react-icons/fa";
import {SlCalender} from "react-icons/sl";
import image from "../images/Looper-2 (1).png";
import {CircularProgress} from "@mui/material";
import {HelmetCustomInclude} from "../../hooks/HelmetCustomInclude";
import noimage from "../images/noimage2.webp"
import {cropText, get_date_formatted} from "../../hooks/useCustomHelpingMethod";

const FrontBlog = () => {
  var loginuser = JSON.parse(localStorage.getItem("userstorage"));
  const [blog, setBlog] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredBlog, setFilteredBlog] = useState([]);
  const [services, setServices] = useState([]);
  const [limit, setLimit] = useState(6);
  const [offset, setOffset] = useState(0);
  const [btnLoading, setBtnLoading] = useState(false);

  const bodydata = {
    // login_user_id: 7,  
    limit: limit,
    offset: offset,
    search_text: searchInput,
  };


  const [pageTitle, setPageTitle] = useState("blog");


  const filterBlogItems = () => {
    const filteredItems = blog.filter((item) =>
      item.title.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredBlog(filteredItems);
  };

  const cardStyles = {
    marginBottom: "20px",
  };

  const styleBridgcrumb = { textDecoration: "none" };
  const styleFont = { fontFamily: "Montserrat" };

  const styleFontText = {
    fontSize: "22px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "'Nunito Sans', sans-serif",
  };

  const apiCall = async () => {
    setLoading(true);
    if (loginuser) {
      bodydata.login_user_id = loginuser?.id
      bodydata.login_user_type = "startup"
    }
    
    try {
      const [blogResponse, serviceResponse] = await Promise.all([
        getDataByUrl("blog", bodydata),
      ]);

      if (!blogResponse?.error) {
        setBlog(blogResponse?.data);
      } else {
      }

    } catch (error) {
      // console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    apiCall().then(r => r);
    setOffset(0);
  }, [searchInput]);



  const nextData = async () => {
    setBtnLoading(true);

    const inputData = {offset: offset || 0}

    const response = await getDataByUrl("blog", { ...bodydata, ...inputData })

    // console.log("response---next", response)
    if (response?.error === false) {
      setBlog((prev) => [...new Set([...prev, ...response?.data])]);

    }
    setBtnLoading(false);

  }


  const loadMoreItems = () => {
    // setVisibleItems((prevVisibleItems) => prevVisibleItems + itemsPerPage);
    // setOffset((prevOffset) => prevOffset + itemsPerPage);
    // apiCall();
    setOffset(offset + 1);
  };


  useEffect(() => {
    if (offset !== 0) {
      nextData();
    }
  }, [offset]);


  const headingText = "Our blogs";
  const headingPara = "Discover, Learn, and Inspire with Our Blog";
  const headingParagraph =
    "Explore a variety of topics and stay updated with the latest insights and trends in the startup world. Our blog covers everything from technology and innovation to strategic planning and success stories. Let the knowledge shared here inspire and guide you on your entrepreneurial journey.";

  const breadcrumbItems = [
    { to: "/", label: "Home" },
    { to: "", label: "Blog" },
  ];
  const readMore = "Read More";
  const loadMore = "Load More";




  let titleDescriptionWidth;
  let cardImageWidth;
  let titleWidth;


  return (
    <>
      <HelmetCustomInclude url={"blog"} title={pageTitle} />

      <img id="Servicebgimage" src={image} onError={(e) => e.target.src = noimage} alt={"img"} />
      <Container className="mt-5 pt-3 mb-5">
        <Row>
          <div className="d-flex flex-row">
            {breadcrumbItems.map((item, index) => (
              <div key={index}>
                {item.to ? (
                  <Link to={item.to} className="color-secondary" style={styleBridgcrumb} >
                    {item.label}
                    {index < breadcrumbItems.length - 1 && <FiChevronRight />}
                  </Link>
                ) : (
                  <div className="text-decoration">{item.label}</div>
                )}
              </div>
            ))}
          </div>
        </Row>
        <Row>
          <Col className="text-center">
            <h5 className="mt-2 color-secondary" style={styleFont}>
              {headingText}
            </h5>
            <h3 className="mt-4 fw-bold fs-2">{headingPara}</h3>
            <p className="mt-4 mb-5" style={styleFont}>
              {headingParagraph}
            </p>
          </Col>
        </Row>
        <Row>
          {/* <Col lg={3} xl={3} md={4} sm={12} xs={12} className="mt-3">
            <Form>
              <InputGroup className="mb-3 blog-search">
                <InputGroup.Text id="basic-addon2" style={{ backgroundColor: "white" }} >
                  {
                    searchInput ? <BsXLg onClick={() => setSearchInput("")} color="var(--primary)" style={{ cursor: "pointer" }} /> :
                      <BsSearch color="var(--primary)" />
                  }
                </InputGroup.Text>
                <Form.Control
                  className="marketplace-search"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                    filterBlogItems();
                  }}
                />

              </InputGroup>

              <Card style={styleCard}>
                <Card.Body>
                  <Row>
                    {loading ? (
                      Array.from({ length: numberOfItems }, (_, index) => (
                        <SkeletonBlogItem
                          key={index}
                          isXSmallScreen={isXSmallScreen}
                          isSmallScreen={isSmallScreen}
                          isMediumScreen={isMediumScreen}
                          isLargeScreen={isLargeScreen}
                          isXLargeScreen={isXLargeScreen}
                        />
                      ))
                    ) : (
                      <>
                        {services &&
                          services.map((item, ind) => (
                            <Row
                              key={ind}
                              className="mb-3 mt-3 d-flex align-items-center"
                            >
                              <Col sm={3} xs={3} md={1}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                />
                              </Col>

                              <Col sm={8} xs={8} md={9} style={styleTitle} className="fontFamily" >
                                {item.name}
                              </Col>
                            </Row>
                          ))}
                      </>
                    )}
                  </Row>
                </Card.Body>
              </Card>
            </Form>
          </Col> */}

          <Col  >
            {loading ? (
              <SkeletonBlog
                titleDescriptionWidth={titleDescriptionWidth}
                cardImageWidth={cardImageWidth}
                titleWidth={titleWidth}
              />
            ) : (
              <>
                <Row>
                  {searchInput.length === 0
                    ? blog.map((blogItem, ind) => (
                      <Col md={6} lg={4} className="g-3" key={ind} style={cardStyles} >
                        <Card id="service" className="shadow h-100">
                          <Card.Body>
                            <Card.Img
                              className="w-100"
                              src={blogItem.media_url}
                              alt="./"
                              onError={(e) => e.target.src = noimage}
                            />
                            <Card.Text
                              style={styleFontText}
                              className="fw-bold mt-3"
                            >
                              {blogItem.title}
                            </Card.Text>
                            <p className="fontFamily m-0">
                              {cropText(blogItem?.page_description || "", 70)}
                            </p>
                            <Link
                              style={{ fontSize: "17px" }}
                              // to={`/blog-detail?blogid=${blogItem.id}`}
                              to={`/blog/${blogItem.slug}`}
                              className="color-secondary text-decoration-none"
                            >
                              {readMore}
                              <FaLongArrowAltRight />
                            </Link>
                            <hr />
                            <div className="d-flex justify-content-between">
                             
                              {/* <h6 className="mx-2">{blogItem.count_view}</h6> */}
                              <h6 className="mx-2"> <FaRegUserCircle className="color-secondary" /> &nbsp; Startup Starter</h6>


                              <h6 className=" ms-auto">
                                <SlCalender
                                    className="color-secondary "/> &nbsp;  {get_date_formatted(blogItem?.date_schedule || blogItem?.created_at || "")}
                              </h6>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))
                    : filteredBlog 
                      .map((blogItem, ind) => (
                        <Col md={6} className="g-3" key={ind} style={cardStyles} >
                          <Card className="shadow h-100">
                            <Card.Body>
                              <Card.Img className="w-100" src={blogItem.media_url} alt="./" onError={(e) => e.target.src = noimage}
                              />
                              <Card.Text  style={styleFontText} className="fw-bold mt-3" >
                                {blogItem.title}
                              </Card.Text>
                              <p className="fontFamily">
                                {cropText(blogItem?.page_description || "", 70)}
                              </p>
                              <Link
                                // to={`/blog-detail?blogid=${blogItem.id}`}
                                to={`/blog/${blogItem.slug}?slug=${blogItem.slug}`}
                                className="color-secondary text-decoration-none"
                              >
                                {readMore} <FaLongArrowAltRight />
                              </Link>
                              <hr />
                              <div className="d-flex">
                                <FaRegUserCircle className="color-secondary" />
                                <h6 className="mx-2">
                                  {blogItem.count_view}
                                </h6>
                                <SlCalender className="color-secondary mx-3" />
                                <h6 className="mx-2">
                                  {get_date_formatted(blogItem?.date_schedule || '')}
                                </h6>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      ))}
                </Row>

                {loading && <SkeletonBlog />}
                {(blog?.length >= 3 &&
                  (btnLoading ? <div className="mt-3 text-center">
                    <button disabled className={`slide-button text-decoration-none loading-hover`} >
                      <CircularProgress className="mt-1" color="inherit" size={16} />&nbsp; {loadMore}
                    </button>
                  </div> : <div className="mt-3 text-center">
                    <button className={`slide-button text-decoration-none`} onClick={loadMoreItems} >
                      {loadMore}
                    </button>
                  </div>)
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FrontBlog;







