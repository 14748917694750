import React, {useEffect, useState} from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import NotificationMessage from "./NotificationMessage";
import {getDataByUrl} from "../API/HelpingMethod";
import image from "../images/Looper-2 (1).png";
import {Skeleton} from "@mui/material";
import NotificationSidebar from "./NotificationSidebar";
import {Link, useNavigate} from "react-router-dom";
import {BsChevronRight} from "react-icons/bs";
import noimage from "../images/noimage2.webp"
import {loginUserData} from "../API/client";
import UserProfileSidebar from "./UserProfileSidebar";

const Notification = () => {
  const navigate = useNavigate()
  const loginuser = loginUserData;
  const [notification, setNotification] = useState([]);
  const [loading, setLoading] = useState(false);
  const [markAllRead, setMarkAllRead] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const inputData = {
    login_user_id: loginUserData?.id || 0,
    login_user_type: loginUserData?.user_type || "startup",
    offset: 0,
    limit: 10
  };
  const handleMarkAllRead = () => {
    setMarkAllRead(true);
  };

console.log("loginuser--",loginuser)


  const apiCall = async () => {
    setLoading(true);
    const response = await getDataByUrl("user_notification", inputData);
    if (!response?.error) {
      setNotification(response?.data);
    } else {

    }
    setLoading(false);
  };

  useEffect(() => {
    if (!loginuser) navigate("/");
    apiCall();
  }, [loginuser])
 

  const styleBorder = {
    border: "1px solid var(--primary)",
    paddingBottom: "45px"
  }


  return (


    <Container className="pt-5">  
      <img id="Servicebgimage" src={image} alt="img" onError={(e) => e.target.src = noimage} />
      <Row className="mt-2 mb-2">
        <Col className="ms-3">
          <Link to="/" className="text-decoration-none color-secondary">Home</Link>&nbsp;<BsChevronRight />&nbsp;<span>Notification</span>
        </Col>
      </Row>
      <Row>
        <Col sm={12} xs={12} md={5} lg={4} xl={3} className="pb-5">
        <UserProfileSidebar />
        </Col>
        <Col sm={12} xs={12} xl={9} md={7} lg={8}>
          <Container className=" mb-5">
            <Card style={styleBorder} className="">
              <Card.Body>
                <Row>
                  <Col xl={9} lg={7} md={5} xs={12} sm={6}>
                    <h5 className="fw-bold" >
                      Notification
                    </h5>
                  </Col>
                  <Col xl={3} lg={5} md={7} xs={12} sm={6}>
                    {loading ? <></> : <button
                      style={{ whiteSpace: "nowrap" }}
                      className={`slide-button1 text-decoration-none ${isHovered ? "hovered" : ""
                        }`}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                      onClick={handleMarkAllRead}
                    >
                      Mark all as read
                    </button>}
                  </Col>
                </Row>
                <hr className="mt-3" />

                {loading ? <Row className="ps-2 pe-2">
                  <Skeleton height={70} />
                  <Skeleton height={70} />
                  <Skeleton height={70} />
                  <Skeleton height={70} />
                </Row> : (
                  notification && notification.length > 0 ? (
                    notification.map((item, ind) => {
                      // console.log(item);
                      return <NotificationMessage
                          key={ind}
                          url_image={item.url_image}
                          title={item?.title}
                          message={item.message}
                          updated_at={item.updated_at}
                          formated_date={item?.datetime_formatted||item?.updated_at||item?.created_at}
                          isRead={markAllRead}
                          id={item.id}
                      />
                    })
                  ) : (
                    <p className="fontFamily">No data available</p>
                  )
                )}
              </Card.Body>
            </Card>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default Notification;



