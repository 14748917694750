import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { getDataByUrl } from "../../API/HelpingMethod";
import { useNotification } from "../../../hooks";
import { useNavigate, useSearchParams } from "react-router-dom";

const StartupIdea = ({ data, setActiveStep, setData }) => {
    var loginuser = JSON.parse(localStorage.getItem("userstorage"));
    const [btnLoading, setBtnLoading] = useState(false);
    const { showError, showSuccess } = useNotification();
    let [searchParams] = useSearchParams()
    const [startupIdeaId, setStartupIdeaId] = useState(searchParams.get("ideaId"));
    const navigate = useNavigate()

    const [formData, setFormData] = useState({ startup_idea: data?.startup_idea || "" });

    const bodyData = {
        login_user_id: loginuser?.id,
        user_type: loginuser.user_type,
        id: startupIdeaId
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleSubmit = async () => {
        setBtnLoading(true);
        bodyData.values = formData
        const response = await getDataByUrl("startup_idea_update", bodyData);
        //console.log("response----", response)
        if (response?.error === false) {
            if(response?.data) setData(response?.data);
            showSuccess(response?.message)
            setActiveStep(2);
            navigate("/submit-idea?ideaId=" + response?.data?.id)
        } else {
            showError(response?.message)
        }
        setBtnLoading(false)
    }


    return (
        <>
            <Col>
                <Container>
                    <Row><Col sm={8} className="mx-auto h6 mt-3"> Write down your Startup Idea*</Col></Row>
                    <Row>
                        <Col sm={8} className="mx-auto">
                            <Form.Control
                                as="textarea"
                                rows={3}
                                className="idea-stepper-input"
                                type="text"
                                name="startup_idea"
                                value={formData.startup_idea || ""}
                                onChange={handleInputChange}
                                placeholder="Please write down your Startup Idea" />
                        </Col>
                    </Row>

                    <Row>
                        <Col className="text-center mt-4">
                            <button disabled={btnLoading} className={btnLoading ? "disabled-btn" : "slide-button1"}
                                onClick={handleSubmit}>
                                {btnLoading && <><CircularProgress color="inherit" className="mt-1" size={16} />&nbsp;</>}Next
                            </button>
                        </Col>
                    </Row>
                </Container>
            </Col>

        </>
    )
}
export default StartupIdea;