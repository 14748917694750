import React, {useEffect, useState} from "react";
import startup_idea from "../images/brostartup_idea.png";
import {useNotification} from "../../hooks";
import {getDataByUrl} from "../API/HelpingMethod";
import image from "../images/Looper-2 (1).png";
import noimage from "../images/noimage2.webp";
import {Card, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FiChevronRight} from "react-icons/fi";
import {BiBulb} from "react-icons/bi";
import {getFirstWordCap} from "../../hooks/useCustomHelpingMethod";
import SkeletonService from "../Homepage/Skeleton-files/SkeletonService";

const StartupIdea = ({setLoginLocation}) => {
  const [token, setToken] = useState({});
  const [loading, setLoading] = useState(true);
  var loginuser = JSON.parse(localStorage.getItem("userstorage"));
  const [data, setData] = useState(null);
  const [dataProgress, setDataProgress] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const {setShow} = useNotification();
  const headingText = "Do You Have A Startup Idea ?";
  const headingPara = "Tell us about your idea. We can’t wait to hear from you!We are here to help startups with fabulous idea with technology and investments. We invite you to share your idea with us, to get initial support to develop your MVP.";
  const submitIdea = "Submit Idea";
  setLoginLocation("/startup-idea")

  const breadcrumbItems = [
    {to: "/", label: "Home"},
    {label: "Startup Idea"},
  ];
  const styleBridgcrumb = {
    whiteSpace: "nowrap",
    overflow: "hidden",
  }
  const apiCall = async () => {
    setLoading(true);
    const response = await getDataByUrl("startup_idea", {
      login_user_id: loginuser?.id,
      login_user_type: loginuser?.user_type || "startup",
      type: "latest",
      limit: 10,
      offset: 0
    });
    console.log(response);
    if (!response?.error) {
      setData(response?.data);
    }
    setLoading(false);
  };
  const apiCallProgress = async () => {
    const response = await getDataByUrl("startup_step_progress", {
      login_user_id: loginuser?.id,
      login_user_type: loginuser?.user_type || "startup",
      limit: 20,
      offset: 0
    });
    console.log(response);
    if (!response?.error) {
      setDataProgress(response?.data);
    }
  };
  useEffect(() => {
    apiCall().then(r => r);
    apiCallProgress().then(r => r);
  }, []);

  const styleCol = {
    borderRadius: "90%",
    height: "30px",
    width: "30px",
    fontSize: "20px",
  };
  const text = "You have successfully registered your startup with us.";
  return (<>
    <div>
      <img id="Servicebgimage" src={image} alt="img" onError={(e) => e.target.src = noimage}/>
      <Container className="mt-3">
        {loading && <Row><SkeletonService numberOfCols={6} /></Row>}
        {!loading && <>
          <Row>
            <div className="d-flex flex-row mt-5">
              {breadcrumbItems.map((item, index) => (
                  <div key={index} className="p-2">
                    {item.to ?
                        <Link style={styleBridgcrumb} to={item.to} className="text-decoration-none color-secondary">
                          {item.label} {index < breadcrumbItems.length - 1 && <FiChevronRight/>}
                        </Link> :
                        <span style={styleBridgcrumb}>{item.label}</span>}
                  </div>
              ))}
            </div>
          </Row>
          {!data || data?.status === 'incomplete' ?
              <Row>
                <div className="text-ceter">
                  <img src={startup_idea} onError={(e) => e.target.src = noimage} className="rounded mx-auto d-block"
                       alt={'ind'}/>
                </div>
                <div className="mt-5 text-center mb-5">
                  <h4>{headingText}</h4>
                  <p className="fontFamily">{headingPara}</p>
                  {loginuser ? <Link to={`/submit-idea`}
                                     className={`slide-button1 fontFamily text-decoration-none ${isHovered ? "hovered" : ""}`}
                                     onMouseEnter={() => setIsHovered(true)}
                                     onMouseLeave={() => setIsHovered(false)}>
                    {submitIdea} <BiBulb className="fs-4"/>
                  </Link> : <Link
                      onClick={() => loginuser ? setShow(false) : setShow(true)}
                      className={`slide-button1 fontFamily text-decoration-none ${isHovered ? "hovered" : ""}`}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}>
                    {submitIdea} <BiBulb className="fs-4"/>
                  </Link>
                  }
                </div>
              </Row> :
              <Row className="my-5">
                {dataProgress && Array.isArray(dataProgress) && dataProgress?.map((item, ind) => {
                  return (<Col xl={6} lg={6} md={6} sm={12} xs={12} key={ind} className={"p-3"}>
                    <Card className="startup-step-card ">
                      <Card.Body>
                        <Col xl={12} sm={12} lg={12} md={12} xs={12}
                             className="secondaryColor text-light text-center" style={styleCol}>
                          <b>{item?.startup_step_id || (ind + 1)}</b>
                        </Col>
                        <br/>
                        <Row>
                          <Col xl={12} sm={12} lg={12} md={12} xs={12}>
                            <h6 className="card-title fw-bold mb-1">{item?.title}</h6>
                          </Col>
                          <Col xl={12} sm={12} lg={12} md={12} xs={12} className="">
                            <Card.Text className="fontFamily"><small>{item?.description || text}</small></Card.Text>
                          </Col>
                        </Row>
                        <Row className=" pt-2 ">
                          <Col className=" ">
                            {/*<Link className="color-secondary"></Link>*/}
                          </Col>
                          <Col className="text-end">
                            {item?.status === 'completed' ?
                                <b className={'text-success'}>{getFirstWordCap(item?.status || "")}</b> : (
                                    item?.status === 'active' ?
                                        <Link className="color-secondary">{item?.action}</Link> :
                                        <b className={'text-warning'}>{getFirstWordCap(item?.status || "")}</b>
                                )}
                            {/*<Link className="color-secondary">{item?.action}</Link>*/}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>);
                })}
              </Row>
          }
        </>}

      </Container>
    </div>
  </>);
};
export default StartupIdea;
