import React, {useEffect, useState} from "react";
import {Card, Col, Container, Form, Modal, Row} from "react-bootstrap";
import {Link, useParams, useSearchParams} from "react-router-dom";
import {getDataByUrl} from "../API/HelpingMethod";
import {CircularProgress, Skeleton} from "@mui/material";
import {useNotification} from "../../hooks";
import {BsChevronLeft, BsXLg} from "react-icons/bs";
import {removeHtmlTags} from "../API/client";


const UpdateStartupIdea = () => {
    const {startupIdeaId} = useParams();
    var loginuser = JSON.parse(localStorage.getItem("userstorage"));
    const [data, setData] = useState({})
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [show5, setShow5] = useState(false);
    const [show6, setShow6] = useState(false);
    const [show7, setShow7] = useState(false);
    const [show8, setShow8] = useState(false);
    const [show9, setShow9] = useState(false);

    const handleClose = () => {
        setShow(false)
        setShow2(false)
        setShow3(false)
        setShow4(false)
        setShow5(false)
        setShow6(false)
        setShow7(false)
        setShow8(false)
        setShow9(false)
    }
    const handleShow = () => setShow(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [loading, setLoading] = useState(false);


    const {showError, showSuccess} = useNotification();
    const [startup_name, setstartup_name] = useState({startup_name: ""});
    const [startup_idea, setStartup_idea] = useState({startup_name: ""});
    const [startup_description, setStartup_description] = useState({startup_description: ""});
    const [startup_audience, setStartup_audience] = useState({startup_audience: ""});
    const [startup_problem, setStartup_problem] = useState({startup_problem: ""});
    const [startup_process, setStartup_process] = useState({startup_process: ""});
    const [startup_uniqueness, setStartup_uniqueness] = useState({startup_uniqueness: ""});
    const [startup_competitor, setStartup_competitor] = useState({startup_competitor: ""});
    const [requirement, setRequirement] = useState([])
    const [dataUpdated, setDataUpdated] = useState({})

    const handleCheckbox = (e) => {
        if (e.target.checked === true) {
            setRequirement([...requirement, e.target.value])
        } else {
            var a = requirement?.filter(function (value, index, arr) {
                return value !== e.target.value;
            });
            setRequirement(a)
        }
    }

    const onchangeStartupname = (e) => {
        const { name, value } = e.target;
        setstartup_name({ ...startup_name, [name]: value });
    };

    const onchangeStartup_idea = (e) => {
        const { name, value } = e.target;
        setStartup_idea({ ...startup_idea, [name]: value });
    };

    const onchangeStartup_description = (e) => {
        const { name, value } = e.target;
        setStartup_description({ ...startup_description, [name]: value });
    };

    const onchangestartup_audience = (e) => {
        const { name, value } = e.target;
        setStartup_audience({ ...startup_audience, [name]: value });
    };

    const onchangestartup_problem = (e) => {
        const { name, value } = e.target;
        setStartup_problem({ ...startup_problem, [name]: value });
    };

    const onchangestartup_process = (e) => {
        const { name, value } = e.target;
        setStartup_process({ ...startup_process, [name]: value });
    };


    const onchangestartup_uniqueness = (e) => {
        const { name, value } = e.target;
        setStartup_uniqueness({ ...startup_uniqueness, [name]: value });
    };

    const onchangestartup_competitor = (e) => {
        const {name, value} = e.target;
        setStartup_competitor({...startup_competitor, [name]: value});
    };

    const bodyBata = {
        login_user_id: loginuser?.id,
        user_type: loginuser.user_type,
        id: startupIdeaId
    }
    const updateRequirementOnLoad = (data) => {
        let tempRequirement = [];
        const re = data?.startup_requirement || "";
        if (re && (typeof re === 'string' || re instanceof String)) {
            tempRequirement = data?.startup_requirement.split(',');
        }
        // console.log(reqes);
        setRequirement([...tempRequirement]);
    }

    const getStartupIdea = async () => {
        setLoading(true)
        if (startupIdeaId) {
            bodyBata.startup_idea_id = startupIdeaId
        }
        const response = await getDataByUrl("startup_idea", bodyBata);
        console.log("response---", response)
        if (response?.error === false) {
            setData(response?.data)

            startup_name.startup_name = response?.data?.startup_name
            startup_idea.startup_idea = response?.data?.startup_idea
            startup_description.startup_description = response?.data?.startup_idea_description
            startup_audience.startup_audience = response?.data?.startup_audience
            startup_problem.startup_problem = response?.data?.startup_problem
            startup_process.startup_process = response?.data?.startup_process
            startup_uniqueness.startup_uniqueness = response?.data?.startup_uniqueness
            startup_competitor.startup_competitor = response?.data?.startup_competitor

            updateRequirementOnLoad(response?.data);
        }
        setLoading(false)
    }

    useEffect(() => {
        getStartupIdea()
    }, [dataUpdated])

    const openModal = (e, label, placeholder, name, value) => {
        handleShow()
    }



    const handleSubmit = async () => {

        const formData = {
            startup_name: startup_name.startup_name,
            startup_idea: startup_idea.startup_idea,
            startup_audience: startup_audience.startup_audience,
            startup_problem: startup_problem.startup_problem,
            startup_is_running: "yes",
            startup_process: startup_process.startup_process,
            startup_competitor: startup_competitor.startup_competitor,
            startup_uniqueness: startup_uniqueness.startup_uniqueness,
            startup_idea_description: startup_description.startup_description,
            startup_requirement: requirement.join(",")
        };

        setBtnLoading(true);
        bodyBata.values = formData
        const response = await getDataByUrl("startup_idea_update", bodyBata);

        if (response?.error === false) {
            showSuccess(response?.message)
            setDataUpdated(response?.data)
            handleClose();
        } else {
            showError(response?.message)
        }
        setBtnLoading(false)
    }

    return (
        <>
            <Modal show={show} onHide={() => setShow(false)} size="lg" centered >
                <Modal.Body className="plans-modal">
                    <Container>
                        <Row className="">
                            <Col className="fw-bold">
                                Update Startup Name
                            </Col>
                            <Col className="m-2 me-0 text-end">
                                <BsXLg className="close-button" size={30} onClick={() => setShow(false)} />
                            </Col>
                        </Row>
                        <Row><Col sm={12} className="mx-auto h6 mt-3">Write down your Startup Name</Col></Row>
                        <Row>
                            <Col sm={12} className="mx-auto">
                                <Form.Control
                                    as="textarea"
                                    rows={4}
                                    className="idea-stepper-input"
                                    type="text"
                                    name={'startup_name'}
                                    value={startup_name.startup_name}
                                    onChange={onchangeStartupname}
                                    placeholder="Please enter your startup name"/>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <div className="ms-2">
                                <button disabled={btnLoading}
                                        className={"float-end " + (btnLoading ? "disabled-btn" : "slide-button1")}
                                        onClick={handleSubmit}>
                                    {btnLoading && <><CircularProgress color="inherit" className="mt-1"
                                                                       size={16}/>&nbsp;</>}Update
                                </button>
                            </div>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
            <Modal show={show2} onHide={() => setShow2(false)} size="lg" centered>
                <Modal.Body className="plans-modal">
                    <Container>
                        <Row className="">
                            <Col className="fw-bold">
                                Update Startup Idea
                            </Col>
                            <Col className="m-2 me-0 text-end">
                                <BsXLg className="close-button" size={30} onClick={() => setShow2(false)}/>
                            </Col>
                        </Row>
                        <Row><Col sm={12} className="mx-auto h6 mt-3">Write down your Startup Idea</Col></Row>
                        <Row>
                            <Col sm={12} className="mx-auto">
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    className="idea-stepper-input"
                                    type="text"
                                    name={'startup_idea'}
                                    value={startup_idea.startup_idea}
                                    onChange={onchangeStartup_idea}
                                    placeholder="Please write down your Startup Idea"/>
                            </Col>
                        </Row>

                        <Row className="mt-4">
                            <div className="ms-2">
                                <button disabled={btnLoading}
                                        className={"float-end " + (btnLoading ? "disabled-btn" : "slide-button1")}
                                        onClick={handleSubmit}>
                                    {btnLoading && <><CircularProgress color="inherit" className="mt-1"
                                                                       size={16}/>&nbsp;</>}Update
                                </button>
                            </div>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
            <Modal show={show3} onHide={() => setShow3(false)} size="lg" centered>
                <Modal.Body className="plans-modal">
                    <Container>
                        <Row className="">
                            <Col className="fw-bold">
                                Update Startup Idea Description
                            </Col>
                            <Col className="m-2 me-0 text-end">
                                <BsXLg className="close-button" size={30} onClick={() => setShow3(false)}/>
                            </Col>
                        </Row>
                        <Row><Col sm={12} className="mx-auto h6 mt-3">Describe your Startup Idea</Col></Row>
                        <Row>
                            <Col sm={12} className="mx-auto">
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    className="idea-stepper-input"
                                    type="text"
                                    name={'startup_description'}
                                    value={startup_description?.startup_description ? removeHtmlTags(startup_description.startup_description) : ""}
                                    onChange={onchangeStartup_description}
                                    placeholder="Please describe your Startup Idea"/>
                            </Col>
                        </Row>

                        <Row className="text-center mt-4">
                            <div className="ms-2">
                                <button disabled={btnLoading}
                                        className={"float-end " + (btnLoading ? "disabled-btn" : "slide-button1")}
                                        onClick={handleSubmit}>
                                    {btnLoading && <><CircularProgress color="inherit" className="mt-1"
                                                                       size={16}/>&nbsp;</>}Update
                                </button>
                            </div>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
            <Modal show={show4} onHide={() => setShow4(false)} size="lg" centered>
                <Modal.Body className="plans-modal">
                    <Container>
                        <Row className="">
                            <Col className="fw-bold">
                                Update Primary Audience
                            </Col>
                            <Col className="m-2 me-0 text-end">
                                <BsXLg className="close-button" size={30} onClick={() => setShow4(false)}/>
                            </Col>
                        </Row>
                        <Row><Col sm={12} className="mx-auto h6 mt-3">Who is your primary audience?</Col></Row>
                        <Row>
                            <Col sm={12} className="mx-auto">
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    className="idea-stepper-input"
                                    type="text"
                                    name={'startup_audience'}
                                    value={startup_audience.startup_audience}
                                    onChange={onchangestartup_audience}
                                    placeholder="Please describe your primary audience"/>
                            </Col>
                        </Row>

                        <Row className="text-center mt-4">
                            <div className="ms-2">
                                <button disabled={btnLoading}
                                        className={"float-end " + (btnLoading ? "disabled-btn" : "slide-button1")}
                                        onClick={handleSubmit}>
                                    {btnLoading && <><CircularProgress color="inherit" className="mt-1"
                                                                       size={16}/>&nbsp;</>}Update
                                </button>
                            </div>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
            <Modal show={show5} onHide={() => setShow5(false)} size="lg" centered>
                <Modal.Body className="plans-modal">
                    <Container>
                        <Row className="">
                            <Col className="fw-bold">
                                Update Startup Problem
                            </Col>
                            <Col className="m-2 me-0 text-end">
                                <BsXLg className="close-button" size={30} onClick={() => setShow5(false)}/>
                            </Col>
                        </Row>
                        <Row><Col sm={12} className="mx-auto h6 mt-3">What problem is solved by your startup idea?</Col></Row>
                        <Row>
                            <Col sm={12} className="mx-auto">
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    className="idea-stepper-input"
                                    type="text"
                                    name={'startup_problem'}
                                    value={startup_problem.startup_problem}
                                    onChange={onchangestartup_problem}
                                    placeholder="Please describe what problem is solved by your startup idea"/>
                            </Col>
                        </Row>

                        <Row className="text-center mt-4">
                            <div className="ms-2">
                                <button disabled={btnLoading}
                                        className={"float-end " + (btnLoading ? "disabled-btn" : "slide-button1")}
                                        onClick={handleSubmit}>
                                    {btnLoading && <><CircularProgress color="inherit" className="mt-1"
                                                                       size={16}/>&nbsp;</>}Update
                                </button>
                            </div>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
            <Modal show={show6} onHide={() => setShow6(false)} size="lg" centered>
                <Modal.Body className="plans-modal">
                    <Container>
                        <Row className="">
                            <Col className="fw-bold">
                                Update Startup Process
                            </Col>
                            <Col className="m-2 me-0 text-end">
                                <BsXLg className="close-button" size={30} onClick={() => setShow6(false)}/>
                            </Col>
                        </Row>
                        <Row><Col sm={12} className="mx-auto h6 mt-3">How does it work?</Col></Row>
                        <Row>
                            <Col sm={12} className="mx-auto">
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    className="idea-stepper-input"
                                    type="text"
                                    name={'startup_process'}
                                    value={startup_process.startup_process}
                                    onChange={onchangestartup_process}
                                    placeholder="Please describe how it works"/>
                            </Col>
                        </Row>

                        <Row className="text-center mt-4">
                            <div className="ms-2">
                                <button disabled={btnLoading}
                                        className={"float-end " + (btnLoading ? "disabled-btn" : "slide-button1")}
                                        onClick={handleSubmit}>
                                    {btnLoading && <><CircularProgress color="inherit" className="mt-1"
                                                                       size={16}/>&nbsp;</>}Update
                                </button>
                            </div>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
            <Modal show={show7} onHide={() => setShow7(false)} size="lg" centered>
                <Modal.Body className="plans-modal">
                    <Container>
                        <Row className="">
                            <Col className="fw-bold">
                                Update Startup Uniqueness
                            </Col>
                            <Col className="m-2 me-0 text-end">
                                <BsXLg className="close-button" size={30} onClick={() => setShow7(false)}/>
                            </Col>
                        </Row>
                        <Row><Col sm={12} className="mx-auto h6 mt-3">What is different in your idea?</Col></Row>
                        <Row>
                            <Col sm={12} className="mx-auto">
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    className="idea-stepper-input"
                                    type="text"
                                    name={'startup_uniqueness'}
                                    value={startup_uniqueness.startup_uniqueness}
                                    onChange={onchangestartup_uniqueness}
                                    placeholder="Please describe the difference in your idea"/>
                            </Col>
                        </Row>

                        <Row className="text-center mt-4">
                            <div className="ms-2">
                                <button disabled={btnLoading}
                                        className={"float-end " + (btnLoading ? "disabled-btn" : "slide-button1")}
                                        onClick={handleSubmit}>
                                    {btnLoading && <><CircularProgress color="inherit" className="mt-1"
                                                                       size={16}/>&nbsp;</>}Update
                                </button>
                            </div>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
            <Modal show={show8} onHide={() => setShow8(false)} size="lg" centered>
                <Modal.Body className="plans-modal">
                    <Container>
                        <Row className="">
                            <Col className="fw-bold">
                                Update Similar solution availability
                            </Col>
                            <Col className="m-2 me-0 text-end">
                                <BsXLg className="close-button" size={30} onClick={() => setShow8(false)}/>
                            </Col>
                        </Row>
                        <Row><Col sm={12} className="mx-auto h6 mt-3"> Similar solution availability</Col></Row>
                        <Row>
                            <Col sm={12} className="mx-auto">
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    className="idea-stepper-input"
                                    type="text"
                                    name={'startup_competitor'}
                                    value={startup_competitor.startup_competitor}
                                    onChange={onchangestartup_competitor}
                                    placeholder="Please describe similar solution availability"/>
                            </Col>
                        </Row>

                        <Row className="text-center mt-4">
                            <div className="ms-2">
                                <button disabled={btnLoading}
                                        className={"float-end " + (btnLoading ? "disabled-btn" : "slide-button1")}
                                        onClick={handleSubmit}>
                                    {btnLoading && <><CircularProgress color="inherit" className="mt-1"
                                                                       size={16}/>&nbsp;</>}Update
                                </button>
                            </div>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
            <Modal show={show9} onHide={() => setShow9(false)} size="lg" centered>
                <Modal.Body className="plans-modal">
                    <Container>
                        <Row className="">
                            <Col className="fw-bold">
                                Update Startup Requirement
                            </Col>
                            <Col className="m-2 me-0 text-end">
                                <BsXLg className="close-button" size={30} onClick={() => setShow9(false)}/>
                            </Col>
                        </Row>
                        <Row><Col sm={12} className="mx-auto h6 mt-3"> What is your requirements (if any)</Col></Row>
                        <Row className=" ">
                            <Col className="mt-3 " sm={6} md={4}>
                                <Form.Check
                                    onChange={handleCheckbox}
                                    value={'Technical Support'}
                                    label="Technical Support"
                                    className="fontFamily"
                                    // checked={requirement.includes('Technical Support') ? true : false}
                                    checked={requirement.includes('Technical Support')}
                                    type="checkbox"
                                    id="radio4"
                                    name="requirements" />
                            </Col>
                            <Col className="mt-3 " sm={6} md={4}>
                                <Form.Check className="fontFamily"
                                    onChange={handleCheckbox}
                                    value={'MVP Development'}
                                    checked={requirement.includes('MVP Development')}
                                    label="MVP Development"
                                    type="checkbox"
                                    id="radio5"
                                    name="requirements" />
                            </Col>
                            <Col className="mt-3 " sm={6} md={4}>
                                <Form.Check className="fontFamily"
                                    onChange={handleCheckbox}
                                    value={'Digital Marketing'}
                                    checked={requirement.includes('Digital Marketing')}
                                    label="Digital Marketing"
                                    type="checkbox"
                                    id="radio6"
                                    name="requirements" />
                            </Col>
                            <Col className="mt-3 " sm={6} md={4}>
                                <Form.Check className="fontFamily"
                                    onChange={handleCheckbox}
                                    value={'Idea Validation'}
                                    checked={requirement.includes('Idea Validation')}
                                    label="Idea Validation"
                                    type="checkbox"
                                    id="radio7"
                                    name="requirements" />
                            </Col>
                            <Col className="mt-3 " sm={6} md={4}>
                                <Form.Check className="fontFamily"
                                    onChange={handleCheckbox}
                                    value={'Funding Support'}
                                    checked={requirement.includes('Funding Support')}
                                    label="Funding Support"
                                    type="checkbox"
                                    id="radio8"
                                    name="requirements" />
                            </Col>
                            <Col className="mt-3 " sm={6} md={4}>
                                <Form.Check className="fontFamily"
                                    onChange={handleCheckbox}
                                    value={'Business Plan Design'}
                                    checked={requirement.includes('Business Plan Design')}
                                    label="Business Plan Design"
                                    type="checkbox"
                                    id="radio9"
                                    name="requirements" />
                            </Col>
                        </Row>

                        <Row className="text-center mt-4">
                            <div className="ms-2">
                                <button disabled={btnLoading}
                                        className={"float-end " + (btnLoading ? "disabled-btn" : "slide-button1")}
                                        onClick={handleSubmit}>
                                    {btnLoading && <><CircularProgress color="inherit" className="mt-1"
                                                                       size={16}/>&nbsp;</>}Update
                                </button>
                            </div>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
            <Container fluid className="mt-3 pt-5">
                <Row>
                    <Col>
                        <Link to="/user-project" className="color-secondary text-decoration-none mx-5">
                            <BsChevronLeft color="#000000"/>&nbsp; Back
                        </Link>
                    </Col>
                </Row>
            </Container>
            <Container className="mt-1 mb-4">
                <Row className="">
                    <Col className="h5 mt-3  ">
                        Startup Idea Update : {data?.startup_name}
                    </Col>
                </Row>
                <Row>
                    <Card className="mt-2 border-0">
                        <Card.Body className="p-0">
                            <Container>
                                <Row className="p-0">

                                    <Col >
                                        <Container className="">
                                            <Row className="p-0 mt-2">
                                                <Col sm={12} xs={12}
                                                     className="fw-bold h6 ps-0  text-sm-start text-center">
                                                    Write down your Startup Name
                                                </Col>
                                            </Row>

                                            {loading && <div className="skeleton-m-p "><Skeleton width="100%" height={40} /></div>}
                                            {!loading && <Row className="mt-1 mb-2 pt-1 pb-1 ps-2 pe-1 border rounded ">
                                                <Col sm={11} xs={12} className="ps-0 ">
                                                    <div className="d-flex"> <span className="h6">
                                                        {/* <CroppedText text={data?.startup_name} /> */}
                                                        {data?.startup_name}
                                                    </span></div>
                                                </Col>
                                                <Col sm={1} xs={12} className="justify-content-end d-flex">

                                                    <div style={{transition: "transform 0.3s"}}
                                                         onMouseOver={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
                                                         onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")}
                                                         onClick={(e) => openModal(e)}

                                                         className="text-decoration-underline color-secondary pointer">Update
                                                    </div>
                                                </Col>
                                            </Row>}


                                            <Row className="p-0 mt-2">
                                                <Col sm={12} xs={12}
                                                     className="fw-bold h6 ps-0  text-sm-start text-center">
                                                    Write down your Startup Idea
                                                </Col>
                                            </Row>
                                            {loading && <div className="skeleton-m-p mt-2"><Skeleton width="100%" height={40} /></div>}
                                            {!loading && <Row className="mt-1 mb-2 pt-1 pb-1 ps-2 pe-1 border rounded ">
                                                <Col sm={11} xs={12} className="ps-0 ">
                                                    <div className="d-flex"><span
                                                        className="h6"> {data?.startup_idea}</span></div>
                                                </Col>
                                                <Col sm={1} xs={12} className="justify-content-end d-flex">

                                                    <div style={{transition: "transform 0.3s"}}
                                                         onMouseOver={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
                                                         onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")}
                                                         onClick={(e) => setShow2(true)}

                                                         className="text-decoration-underline color-secondary pointer">Update
                                                    </div>
                                                </Col>
                                            </Row>}

                                            <Row className="p-0 mt-2">
                                                <Col sm={12} xs={12}
                                                     className="fw-bold h6 ps-0  text-sm-start text-center">
                                                    Describe your Startup Idea
                                                </Col>
                                            </Row>
                                            {loading && <div className="skeleton-m-p  mt-2 "><Skeleton width="100%" height={40} /></div>}
                                            {!loading && <Row className="mt-1 mb-2 pt-1 pb-1 ps-2 pe-1 border rounded ">
                                                <Col md={11} sm={9} xs={12} className="ps-0 " >
                                                    <div className="d-flex">
                                                        <span className=""  dangerouslySetInnerHTML={{__html: data?.startup_idea_description || "",}}/>
                                                    </div>
                                                </Col>
                                                <Col md={1} sm={3} xs={12} className="justify-content-end d-flex" >

                                                    <div style={{ transition: "transform 0.3s" }}
                                                        onMouseOver={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
                                                        onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")}
                                                        onClick={(e) => setShow3(true)}

                                                        className="text-decoration-underline color-secondary pointer">Update</div>
                                                </Col>
                                            </Row>}
                                            <Row className="p-0 mt-2">
                                                <Col sm={12} xs={12}
                                                     className="fw-bold h6 ps-0  text-sm-start text-center">
                                                    Who is your primary audience?
                                                </Col>
                                            </Row>
                                            {loading && <div className="skeleton-m-p  mt-2 "><Skeleton width="100%" height={40} /></div>}
                                            {!loading && <Row className="mt-1 mb-2 pt-1 pb-1 ps-2 pe-1 border rounded ">
                                                <Col sm={11} xs={12} className="ps-0 ">
                                                    <div className="d-flex"><span
                                                        className="h6"> {data?.startup_audience}</span></div>
                                                </Col>
                                                <Col sm={1} xs={12} className="justify-content-end d-flex">

                                                    <div style={{transition: "transform 0.3s"}}
                                                         onMouseOver={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
                                                         onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")}
                                                         onClick={(e) => setShow4(true)}

                                                         className="text-decoration-underline color-secondary pointer">Update
                                                    </div>
                                                </Col>
                                            </Row>}
                                            <Row className="p-0 mt-2">
                                                <Col sm={12} xs={12}
                                                     className="fw-bold h6 ps-0  text-sm-start text-center">
                                                    What problem is solved by your startup idea?
                                                </Col>
                                            </Row>
                                            {loading && <div className="skeleton-m-p  mt-2"><Skeleton width="100%" height={40} /></div>}
                                            {!loading && <Row className="mt-1 mb-2 pt-1 pb-1 ps-2 pe-1 border rounded ">
                                                <Col sm={11} xs={12} className="ps-0 ">
                                                    <div className="d-flex"><span
                                                        className="h6"> {data?.startup_problem}</span></div>
                                                </Col>
                                                <Col sm={1} xs={12} className="justify-content-end d-flex">

                                                    <div style={{transition: "transform 0.3s"}}
                                                         onMouseOver={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
                                                         onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")}
                                                         onClick={(e) => setShow5(true)}

                                                         className="text-decoration-underline color-secondary pointer">Update
                                                    </div>
                                                </Col>
                                            </Row>}

                                            <Row className="p-0 mt-2">
                                                <Col sm={12} xs={12}
                                                     className="fw-bold h6 ps-0  text-sm-start text-center">
                                                    How does it work?
                                                </Col>
                                            </Row>
                                            {loading && <div className="skeleton-m-p  mt-2"><Skeleton width="100%" height={40} /></div>}
                                            {!loading && <Row className="mt-1 mb-2 pt-1 pb-1 ps-2 pe-1 border rounded ">
                                                <Col sm={11} xs={12} className="ps-0 ">
                                                    <div className="d-flex"><span
                                                        className="h6"> {data?.startup_process}</span></div>
                                                </Col>
                                                <Col sm={1} xs={12} className="justify-content-end d-flex">

                                                    <div style={{transition: "transform 0.3s"}}
                                                         onMouseOver={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
                                                         onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")}
                                                         onClick={(e) => setShow6(true)}

                                                         className="text-decoration-underline color-secondary pointer">Update
                                                    </div>
                                                </Col>
                                            </Row>}



                                            <Row className="p-0 mt-2">
                                                <Col sm={12} xs={12}
                                                     className="fw-bold h6 ps-0  text-sm-start text-center">
                                                    Similar solution availability
                                                </Col>
                                            </Row>
                                            {loading && <div className="skeleton-m-p  mt-2"><Skeleton width="100%" height={40} /></div>}
                                            {!loading && <Row className="mt-1 mb-2 pt-1 pb-1 ps-2 pe-1 border rounded ">
                                                <Col sm={11} xs={12} className="ps-0 ">
                                                    <div className="d-flex"> <span className="h6">
                                                        {/* <CroppedText text={data?.startup_competitor} /> */}
                                                        {data?.startup_competitor}

                                                    </span></div>
                                                </Col>
                                                <Col sm={1} xs={12} className="justify-content-end d-flex">

                                                    <div style={{transition: "transform 0.3s"}}
                                                         onMouseOver={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
                                                         onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")}
                                                         onClick={(e) => setShow8(true)}

                                                         className="text-decoration-underline color-secondary pointer">Update
                                                    </div>
                                                </Col>
                                            </Row>}
                                            <Row className="p-0 mt-2">
                                                <Col sm={12} xs={12}
                                                     className="fw-bold h6 ps-0  text-sm-start text-center">
                                                    What is your requirements (if any)
                                                </Col>
                                            </Row>
                                            {loading && <div className="skeleton-m-p  mt-2"><Skeleton width="100%" height={40} /></div>}
                                            {!loading && <Row className="mt-1 mb-2 pt-1 pb-1 ps-2 pe-1 border rounded ">
                                                <Col sm={11} xs={12} className="ps-0 ">
                                                    <div className="d-flex"> <span className="h6">
                                                       {/* {requirement.map((data)=>{
                                                            return(
                                                                <Row className="d-flex">
                                                                    <Col>{data},</Col>
                                                                </Row>
                                                            )
                                                        })}*/}
                                                        {data?.startup_requirement}
                                                    </span></div>
                                                </Col>
                                                <Col sm={1} xs={12} className="justify-content-end d-flex">

                                                    <div style={{transition: "transform 0.3s"}}
                                                         onMouseOver={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
                                                         onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")}
                                                         onClick={(e) => setShow9(true)}

                                                         className="text-decoration-underline color-secondary pointer">Update
                                                    </div>
                                                </Col>
                                            </Row>}


                                        </Container>
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </Row>
            </Container > 
        </>
    )
}
export default UpdateStartupIdea;