import React, {useState} from "react";
import {Container, Row, Col, Card, Button,} from "react-bootstrap";
import defaultimg from "../images/noimage2.webp";
import {Avatar} from "@mui/material";
import {amountFormate, apiHepingMethod} from "../API/HelpingMethod";
import {Checkbox, Skeleton, useMediaQuery} from "@mui/material";
import {FaHeart, FaRegHeart} from "react-icons/fa";
import {useNotification} from "../../hooks";
import {BsEye} from "react-icons/bs";
import {Link, Navigate, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import noimage from "../images/noimage2.webp"
import {cropText} from "../../hooks/useCustomHelpingMethod";
import {FaArrowRightLong} from "react-icons/fa6";

const ListProduct = ({setIsLike, product}) => {

    var loginuser = JSON.parse(localStorage.getItem("userstorage"));

    useEffect(() => {
        loginuser = JSON.parse(localStorage.getItem("userstorage"))
    }, [localStorage.getItem("userstorage")]);

    useEffect(() => {
        setSetUserCurrency(product?.amount_currency_user_country || userCurrency || "USD")
    }, [product])


    const [productLikeBody, setProductLikeBody] = useState({
        login_user_id: loginuser?.id,
    });
    const {setLoginModal} = useNotification();
    const [isHovered, setIsHovered] = useState(false);
    const [data, setData] = useState(product)
    const details = "View Detail";
    const [like, setLike] = useState()
    const [loading, setLoading] = useState(false)
    const [userCurrency, setSetUserCurrency] = useState("USD")
    const navigate = useNavigate()

    const ProductLike = async (id) => {
        setLoading(true);
        productLikeBody.product_id = id;
        productLikeBody.action_type = "like_dislike";
        const response = await apiHepingMethod("marketplace_product_action", productLikeBody);
        if (response?.data?.error === false) {
            setIsLike(response?.data?.data)
            setData(response?.data?.data)
        } else {
            setIsLike('not liked')
        }
        setLoading(false);
    };


    useEffect(() => {

        setLike(<Checkbox
            icon={data?.is_user_like === "yes" ? <FaHeart color="#1CC571"/> : <FaRegHeart color="#1CC571"/>}
            checkedIcon={<FaHeart color="#1CC571"/>}/>)

    }, [data])

    const styleAvatar = {
        bgcolor: "#ffffff",
        cursor: "pointer",
        textAlign: "end"
    };
    const styleCard = {
        border: "1px solid var(--primary) none",
        background: "#f5f9fa",
    };
    const styleImage = {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    };
    const styleFont = {fontFamily: "Montserrat"};
    const styleButton = {
        backgroundColor: "var(--primary)",
        borderRadius: "0.5rem",
        fontFamily: "'Nunito Sans', sans-serif",
    };

    const viewDemo = "View Demo";

    const likeButton = (product) => {
        return (
            <> <Checkbox
                icon={data?.is_user_like === "yes" ? <FaHeart color="#1CC571"/> : <FaRegHeart color="#1CC571"/>}
                checkedIcon={<FaHeart color="#1CC571"/>}/>
            </>
        )
    }
    return (
        <>
            <Card className="mt-4 shadow marketplace-product-card ms-0 me-0 ms-sm-4 me-sm-4" key={product.id}>
                <Card.Body>
                    <Row>
                        <Col md={3} xl={3} className="market-category-list-img">
                            <img onError={(e) => (e.target.src = defaultimg)}
                                 className="rounded pointer"
                                 onClick={() => navigate(`/marketplace/${product.slug}`)}
                                 style={styleImage}
                                 src={product.url_image || defaultimg}
                                 alt=""
                            />
                            {/*market-category-list-like-btn*/}
                            {loginuser ? <div className="badge-top-right text-end">
                                <Avatar sx={styleAvatar} onClick={() => ProductLike(product?.id)}>
                                    {
                                        loading ? <div className="loader-product">
                                            <div className="circle-product"/>
                                            <div className="circle-product"/>
                                            <div className="circle-product"/>
                                            <div className="circle-product"/>
                                        </div> : <>{
                                            data?.is_user_like === 'yes' ? <FaHeart color="#1CC571"/> :
                                                <FaRegHeart color="#1CC571"/>
                                        }</>
                                    }
                                </Avatar>
                                {/*market-category-list-like-btn*/}
                            </div> : <div className=" badge-top-right text-end">
                                <Avatar sx={styleAvatar} onClick={() => setLoginModal(true)}>
                                    <FaRegHeart color="var(--secondary)"/>
                                </Avatar>
                            </div>}
                        </Col>
                        <Col md={5} xl={6}>
                            <Container>
                                <Row className="fw-bold pointer text-capitalize"
                                     style={{fontFamily: "'Nunito Sans', sans-serif"}}
                                     onClick={() => navigate(`/marketplace/${product.slug}`)}>
                                    {product.title}
                                </Row>
                                <Row style={styleFont}>
                                    {/*<p dangerouslySetInnerHTML={{ __html: product.description || " " }} />*/}
                                    <p><small>{cropText(product?.description || "", 200)}</small></p>
                                </Row>
                            </Container>
                        </Col>
                        <Col md={4} xl={3}>
                            <Container>
                                <Row className="text-right fw-bold">
                                    {" "}
                                    <Col>
                                        <del className={"text-danger"}>
                                            {amountFormate(product?.amount_mrp_user_country || 0, 'int')} {userCurrency}
                                        </del>
                                        &nbsp;
                                        {amountFormate(product?.amount_rate_user_country || 0, 'int')} {userCurrency}
                                    </Col>
                                </Row>
                                {/* <Row className="mt-3">

                                        <Button
                                            className="border-0 mb-2 pb-2 pt-2"
                                            style={styleButton}>
                                            {viewDemo} <BsEye/>
                                        </Button>
                                    </Row>*/}
                                <Row className="mt-3">
                                    {
                                        <Link
                                            // to={`/marketplace-product-details?markettoken=${product.token}&productid=${product.id}`}
                                            to={`/marketplace/${product.slug}`}
                                            className={`slide-button text-decoration-none text-center fw-bold ${isHovered === product.id
                                                ? "hovered"
                                                : ""
                                            }`}
                                            onMouseEnter={() =>
                                                setIsHovered(product.id)
                                            }
                                            onMouseLeave={() =>
                                                setIsHovered(null)
                                            }
                                            key={product.id}
                                        >
                                            {details} <FaArrowRightLong/>
                                        </Link>
                                    }
                                </Row>
                            </Container>
                        </Col>
                        {/* <Col xs={12} className={'text-end'}>
                                <Link
                                    // to={`/marketplace-product-details?markettoken=${product.token}&productid=${product.id}`}
                                    to={`/marketplace/${product?.slug}`}
                                    className={`slide-button text-decoration-none text-center fw-bold ${isHovered === product.id
                                        ? "hovered"
                                        : ""
                                    }`}
                                    onMouseEnter={() =>
                                        setIsHovered(product.id)
                                    }
                                    onMouseLeave={() =>
                                        setIsHovered(null)
                                    }
                                    key={product.id}
                                >
                                    {details}
                                </Link>
                            </Col>*/}
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}
export default ListProduct;