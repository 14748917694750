import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap"; 
import LoginForm from "./LoginForm";
 

const Login = ({ loginNavigate, loginLocation }) => {
    

    return (
        <div>

            <Container fluid className="forms-container">
                <Row>
                    <Col lg={6} md={6} className="p-0">
                    <div className="login-image-container"></div>

                        {/*<img className="login-img ps-md-0 ps-4 pe-md-0 pe-4 w-100"  src={registerimg}/>*/}
                    </Col>
                    <Col lg={6} md={6} className="form-box d-flex align-item-center justify-content-center">
                        <LoginForm loginNavigate={loginNavigate} loginLocation={loginLocation} />
                    </Col>
                </Row>
            </Container>

        </div>
    )
}
export default Login;