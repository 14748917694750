import React from "react"; 
import {Container, Row, Col, Form, SplitButton, Dropdown} from "react-bootstrap"; 
import VerifyOtpForm from "./VerifyOtpForm";

export default function VerifyOtp() {

    return (
        <div>
            <Container fluid className="login-container ">
                <Row>
                    <Col md={5} className="login-image-container  p-0">
                        {/*<img className="login-img ps-md-0 ps-4 pe-md-0 pe-4 w-100"  src={registerimg}/>*/}
                    </Col>
                    <Col md={5} className="login-inputs offset-md-1">
                        <VerifyOtpForm/>
                    </Col> 
                </Row>
            </Container>
        </div>
    )
}
