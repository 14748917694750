import { Autocomplete, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { Bs1CircleFill } from "react-icons/bs";
import { useNotification } from "../../../hooks";
import { getDataByUrl } from "../../API/HelpingMethod";

const InvestorCompanyDetails = ({ setActiveStep, handleBack, setBodyData, bodyData }) => {
    const [btnLoading, setBtnLoading] = useState(false);
    const [member, setMember] = useState('');
    const { showError, showSuccess, setOpenBackDrop } = useNotification();
    const [investorStage, setInvestorStage] = useState([]);

    const invesmentRange = [
        { id: 1, range: "$1000 to $10000" },
        { id: 2, range: "$10000 to $25000" },
        { id: 3, range: "$25000 to $50000" },
        { id: 4, range: "$50000 to $75000" },
        { id: 5, range: "$75000 to $1M" },
        { id: 6, range: "$1M to $1.5M" },
        { id: 7, range: "$1.5M to $2M" },
        { id: 8, range: "$2M+" }
    ]


    async function getInvestorStage() {
        let response = await getDataByUrl("user_startup_stage", {
            limit: 0,
            offset: 0
        })
        console.log("response-----", response)
        if (response.error === false) {
            setInvestorStage(response?.data || []);
        } else {
            setInvestorStage(response?.data || []);
        }
    }

    useEffect(() => {
        getInvestorStage().then((e) => e);
    }, []);


    const handleStartupStage = (e, value) => {
        // console.log("value---",value)
        setBodyData({ ...bodyData, investor_stages: value?.name })
    }



    const handleinvesmentRange = (e, value) => {
        // console.log("value---",value)
        setBodyData({ ...bodyData, investor_range: value?.range })
    }

    const handlechange = (e) => {
        const { name, value } = e.target;
        setBodyData({ ...bodyData, [name]: value });
    }

    const handleSubmit = () => {

        if (!bodyData?.name_company) {
            showError("Please Enter Your Company Name");
        } else if (!bodyData?.investor_stages) {
            showError("Please Enter Your Investor Stage");
        } else if (!bodyData?.investor_range) {
            showError("Please Enter Your Investor Range");
        }
        else {
            setActiveStep(3)
        }
    }


    return (
        <>
            <Container>
                <Row xs={{ cols: 1 }}>
                    <Col className=" text-center mb-3 mt-4 pt-2 fw-bold"> <span>Company Details</span> </Col>

                    <Col className=" mt-3"><span className="h6">What's Your Company Name?</span></Col>
                    <Col className="mt-1 mx-auto d-flex justify-content-center">
                        <Form.Control type="text"
                            className="idea-stepper-input"
                            onChange={handlechange}
                            value={bodyData?.name_company}
                            name="name_company" />
                    </Col>



                    <Col className=" mt-3"><span className="h6">Stages you would like to invest on?</span></Col>
                    {/* <Col className="mt-1 mx-auto d-flex justify-content-center">
                        <Form.Control type="text"
                            className="idea-stepper-input"
                            onChange={handlechange}
                            value={bodyData?.investor_stages}
                            name={"investor_stages"} />
                    </Col> */}

                    <Col className="mt-1 mx-auto startup-register-autocomplete autocomplete-border">
                        <Autocomplete
                            disablePortal
                            fullWidth
                            id="combo-box-demo"
                            onChange={handleStartupStage}
                            options={investorStage}
                            getOptionLabel={(option) => option?.name || bodyData?.investor_stages}
                            value={(bodyData?.investor_stages)}
                            renderInput={(params) => <TextField  {...params} label="Investor Stages" />}
                        />
                    </Col>




                    <Col className=" mt-3"><span className="h6">'What's your invesment Range?</span></Col>
                    {/* <Col className="mt-1 mx-auto d-flex justify-content-center">
                        <Form.Control type="text"
                            className="idea-stepper-input"
                            onChange={handlechange}
                            value={bodyData?.investor_range}
                            name={"investor_range"} />
                    </Col> */}

                    <Col className="mt-1 mx-auto startup-register-autocomplete autocomplete-border">
                        <Autocomplete
                            disablePortal
                            fullWidth
                            id="combo-box-demo"
                            onChange={handleinvesmentRange}
                            options={invesmentRange}
                            getOptionLabel={(option) => option?.range || bodyData?.investor_range}
                            value={(bodyData?.investor_range)}
                            renderInput={(params) => <TextField  {...params} label="Investor Range" />}
                        />
                    </Col>



                    <Col className="d-flex justify-content-center mt-5">
                        <div className="me-2">
                            <button onClick={handleBack} className="input-primary-buttons" >
                                Back
                            </button>
                        </div>

                        <div className="ms-2">
                            <button disabled={btnLoading} className={btnLoading ? "disabled-btn" : "input-buttons"} onClick={handleSubmit}>
                                {btnLoading && <><CircularProgress color="inherit" className="mt-1" size={16} />&nbsp;</>}Next
                            </button>
                        </div>
                    </Col>
                </Row>

            </Container>
        </>
    )
}
export default InvestorCompanyDetails