import React from "react";
import { Skeleton, useMediaQuery } from "@mui/material";
import { Card, Col } from "react-bootstrap";

export default function SkeletonService({ numberOfCols }) {
  const isXSmallScreen = useMediaQuery("(max-width:400px)");
  const isSmallScreen = useMediaQuery(
    "(min-width:401px) and (max-width:600px)"
  );
  const isMediumScreen = useMediaQuery(
    "(min-width:601px) and (max-width:960px)"
  );
  const isLargeScreen = useMediaQuery(
    "(min-width:961px) and (max-width:1280px)"
  );
  // const isXLargeScreen = useMediaQuery('(min-width:1281px)');

  const renderSkeletonCol = (index) => (
    <Col md={6} lg={6} key={index}>
      <Card  className="mt-4 shadow">
        <Card.Body className="">
          <Skeleton
            className="rounded"
            variant="rectangular"
            width={
              isXSmallScreen
                ? 30
                : isSmallScreen
                ? 40
                : isMediumScreen
                ? 50
                : isLargeScreen
                ? 60
                : 70
            }
            height={60}
          />
          <Skeleton
            height={30}
            width={
              isXSmallScreen
                ? 100
                : isSmallScreen
                ? 150
                : isMediumScreen
                ? 100
                : isLargeScreen
                ? 200
                : 200
            }
          />
          <Skeleton
            height={30}
            width={
              isXSmallScreen
                ? 200
                : isSmallScreen
                ? 100
                : isMediumScreen
                ? 250
                : isLargeScreen
                ? 300
                : 500
            }
          />
          <Skeleton
            height={30}
            width={
              isXSmallScreen
                ? 200
                : isSmallScreen
                ? 100
                : isMediumScreen
                ? 250
                : isLargeScreen
                ? 300
                : 500
            }
          />
          <Skeleton
            className="rounded mt-2"
            variant="rectangle"
            height={20}
            width={
              isXSmallScreen
                ? 50
                : isSmallScreen
                ? 60
                : isMediumScreen
                ? 80
                : isLargeScreen
                ? 100
                : 120
            }
          />
        </Card.Body>
      </Card>
    </Col>
  );

  return (
    <>
      {Array(numberOfCols)
        .fill()
        .map((_, index) => renderSkeletonCol(index))}
    </>
  );
}
