import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { getDataByUrl, } from "../API/HelpingMethod";
import FormLabel from "@mui/material/FormLabel";
import image from "../images/Looper-2 (1).png";
import { CircularProgress, FormControlLabel, Radio, RadioGroup, } from "@mui/material";
import { useNotification } from "../../hooks";
import "../css/Home.css"
import ProfileUpdateSidebar from "./ProfileUpdateSidebar";
import { BsChevronRight } from "react-icons/bs";
import { loginUserData } from "../API/client";
import UserProfileSidebar from "./UserProfileSidebar";

const InvestorProfileUpdate = () => {
  const navigate = useNavigate()

  let loginuser = loginUserData;

  useEffect(() => {
    if (!loginUserData) {
      navigate("/");
    } else {
      loginuser = loginUserData;
    }
  }, [loginUserData])

  const [isHovered, setIsHovered] = useState(false);
  const { showError, showSuccess } = useNotification();
  const [button, setButton] = useState("Save Changes");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [formData, setFormData] = useState({
    name: loginuser?.name || "",
    email: loginuser?.email || "",
    mobile: loginuser?.mobile || "",
    name_company: loginuser?.name_company || "",
    name_brand: loginuser?.name_brand || "",
    dob: loginuser?.dob || "",
    gender: loginuser?.gender || "",
    country_name: loginuser?.country_name || "",
    state: loginuser?.state || "",
    city: loginuser?.city || "",
    address: loginuser?.address || "",
    landmark: loginuser?.landmark || "",
    investor_stages: loginuser?.investor_stages || "",
    investor_range: loginuser?.investor_range || "",
    name_user_preference: loginuser?.name_user_preference || "",  


  });

  const inputData = {
    login_user_id: loginUserData?.id || 0,
    login_user_type: loginUserData?.user_type || "startup",
    token: loginUserData?.token,
    values: { ...formData },
  };

  const [countries, setCountries] = useState([]);
  const [investorStage, setInvestorStage] = useState([]);
  const [prefrence, setPrefrence] = useState([]);

  const countryData = {
    offset: 0,
    limit: 0,
    // "name":"india"
  };




  async function getInvestorStage() {
    let response = await getDataByUrl("user_startup_stage", {
      limit: 0,
      offset: 0
    })
    if (response.error === false) {
      setInvestorStage(response?.data || []);
    } else {
      setInvestorStage(response?.data || []);
    }
  }

  useEffect(() => {
    getInvestorStage().then((e) => e);
  }, []);


  async function getPrefrence() {
    // setOpenBackDrop(true);
    let response = await getDataByUrl("user_preference", {
      limit: 0,
      offset: 0
    })

    if (response.error === false) {
      setPrefrence(response?.data || []);
    } else {
      setPrefrence([]);
    }
    // setOpenBackDrop(false);
  }

  useEffect(() => {
    getPrefrence().then((e) => e);

  }, []);


  const invesmentRange = [
    { id: 1, range: "$1000 to $10000" },
    { id: 2, range: "$10000 to $25000" },
    { id: 3, range: "$25000 to $50000" },
    { id: 4, range: "$50000 to $75000" },
    { id: 5, range: "$75000 to $1M" },
    { id: 6, range: "$1M to $1.5M" },
    { id: 7, range: "$1.5M to $2M" },
    { id: 8, range: "$2M+" }
  ]

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await getDataByUrl("country", countryData);
        if (!response?.error) {
          setCountries(response?.data);
        } else {
          // console.log("Error fetching countries:", response?.message);
        }
      } catch (error) {
        // console.error("API call failed:", error);
      }
    };

    fetchCountries();
  }, []);

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setButtonClicked(true);
    // setButton(<CircularProgress size={20} color="inherit" className="mx-5" />);


    const response = await getDataByUrl("user_profile_update", inputData);
    console.log("formData---", formData)
    console.log("response---", response)

    if (!response?.error) {
      localStorage.setItem("userstorage", JSON.stringify(response?.data));
      showSuccess(response?.message);
      //  setLoading(false) 
    } else {
      showError(response?.message);
      // console.log("Error fetching data:", response?.message);
    }

    // setButton("Save Changes");
    setButtonClicked(false);
  };

  const handlechange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };



  const gender = [
    {
      value: "EUR",
      label: "Male",
    },
    {
      value: "BTC",
      label: "Female",
    },
    {
      value: "JPY",
      label: "Other",
    },
  ];


  const nameInput = "Name";
  const emailInput = "Email";
  const mobileInput = "Mobile Number";
  const companyInput = "Company Name";
  const brandInput = "Brand";
  const dobInput = "Date of Birth";
  const genderInput = "Gender";
  const countryInput = "Country";
  const stateInput = "State";
  const cityInput = "City";
  const addressInput = "Address";
  const landmarkInput = "Landmark";
  const UpdateProfile = "Update Profile";

  const styleBorder = {
    border: "1px solid var(--primary)",
    paddingBottom: "15px"
  };

  return (
    <Container className="pt-5">

      <img id="Servicebgimage" src={image} alt="img" />
      <Row className="mt-2 mb-2">
        <Col className="ms-3">
          <Link to="/" className="text-decoration-none color-secondary">Home</Link>&nbsp;<BsChevronRight />&nbsp;<span>Update Profile</span>
        </Col>
      </Row>
      <Row>
        <Col sm={12} xs={12} md={5} lg={4} xl={3} className="pb-5">
        <UserProfileSidebar />
        </Col>
        <Col sm={12} xs={12} xl={9} md={7} lg={8} className="mb-5">
          <Container className="">
            <Card style={styleBorder}>
              <Card.Body>
                <Row>
                  <Col sm={12} xs={12} className="">
                    <h5 className="fw-bold">{UpdateProfile}</h5>
                  </Col>
                </Row>
                <Row className="">
                  <Col md={12} className="container-fluid">
                    <Form onSubmit={handleSubmitForm} className="mt-3">
                      <Row className="">
                        <Col xl={12} lg={12} md={12} sm={12} xs={12} >
                          <Form.Label className="fontFamily h6 m-0">{nameInput}</Form.Label>

                          <Form.Control
                            className="fontFamily mb-3"
                            fullWidth

                            onChange={handlechange}
                            name="name"
                            value={formData?.name}
                            type="text"
                            size="small"
                          />



                        </Col>
                      </Row>
                      <Row className="">
                        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="">

                          <Form.Label className="fontFamily fw-bold   m-0">{emailInput}</Form.Label>
                          <Form.Control
                            fullWidth
                            className="fontFamily mb-3"
                            onChange={handlechange}
                            name="email"
                            value={formData?.email}
                            type="text"
                            size="small"
                          />

                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="">
                          <div>
                            <Form.Label className="fontFamily fw-bold   m-0">{mobileInput}</Form.Label>
                            <Form.Control
                              fullWidth
                              className="fontFamily mb-3"
                              onChange={handlechange}
                              name="mobile"
                              value={formData?.mobile}
                              type="text"
                              size="small"
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="">
                        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="">
                          <div>
                            <Form.Label className="fontFamily fw-bold   m-0">{companyInput}</Form.Label>
                            <Form.Control
                              fullWidth
                              className="fontFamily mb-3"
                              onChange={handlechange}
                              name="name_company"
                              value={formData?.name_company || ""}
                              type="text"
                              size="small"
                            />
                          </div>
                        </Col>
                        {/* <Col xl={6} lg={6} md={12} sm={12} xs={12} className="">
                          <div>
                            <Form.Label className="fontFamily fw-bold   m-0">Investor Stages</Form.Label>
                            <Form.Select
                              fullWidth
                              className="mb-3"
                              size="small"
                              onChange={handlechange}
                              name="investor_stages"
                              value={formData?.investor_stages}
                            >
                              {investorStage?.map((data) => (
                                <option key={data?.id} value={data?.name}>
                                  {data?.name}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="">
                          <div>
                            <Form.Label className="fontFamily fw-bold   m-0">Investor Range</Form.Label>
                            <Form.Select
                              fullWidth
                              className="mb-3"
                              size="small"
                              onChange={handlechange}
                              name="investor_range"
                              value={formData?.investor_range}
                            >
                              {invesmentRange?.map((data) => (
                                <option key={data?.id} value={data?.range}>
                                  {data?.range}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="">
                          <div>
                            <Form.Label className="fontFamily fw-bold   m-0">User Preference</Form.Label>
                            <Form.Select
                              fullWidth
                              className="mb-3"
                              size="small"
                              onChange={handlechange}
                              name="name_user_preference"
                              value={formData?.name_user_preference}
                            >
                              {prefrence?.map((data) => (
                                <option key={data?.id} value={data?.name}>
                                  {data?.name}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                        </Col> */}
                      </Row>
                      <Row className="">
                        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="">
                          {/* <Form.Label>{genderInput}</Form.Label> */}
                          <FormLabel className="fontFamily fw-bold m-0" id="demo-radio-buttons-group-label">
                            {genderInput}
                          </FormLabel>

                          <RadioGroup
                            className="mb-3"
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            defaultValue="EUR"
                            name="gender"
                            onChange={handlechange}
                          >
                            {gender.map((option) => (
                              <FormControlLabel
                                control={<Radio />}
                                key={option.value}
                                value={option.label}
                                label={option.label}
                                checked={option.label === formData?.gender ? true : false}
                              >
                                {option.label}
                              </FormControlLabel>
                            ))}
                          </RadioGroup>
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="">

                          <Form.Label className="fontFamily fw-bold m-0" >{dobInput}</Form.Label>
                          <Form.Control
                            fullWidth
                            className="fontFamily"
                            onChange={handlechange}
                            name="dob"
                            value={formData?.dob || ""}
                            type="date"
                            size="small"
                          />

                        </Col>
                      </Row>
                      <Row className="">
                        <Col xl={6} lg={5} md={12} sm={12} xs={12} className="">

                          <Form.Label className="fontFamily fw-bold m-0">{countryInput}</Form.Label>

                          <Form.Select
                            fullWidth
                            className="mb-3"
                            size="small"
                            onChange={handlechange}
                            name="country_name"
                            value={formData?.country_name}
                          >
                            {countries?.map((country) => (
                              <option key={country.id} value={country.name}>
                                {country.name}
                              </option>
                            ))}
                          </Form.Select>

                        </Col>

                        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="">
                          <div>
                            <Form.Label className="fontFamily fw-bold   m-0">{stateInput}</Form.Label>

                            <Form.Control
                              fullWidth
                              className="fontFamily"
                              onChange={handlechange}
                              name="state"
                              value={formData?.state || ""}
                              type="text"
                              size="small"
                            />
                          </div>
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="">
                          <div>
                            <Form.Label className="fontFamily fw-bold   m-0">{cityInput}</Form.Label>

                            <Form.Control
                              fullWidth
                              className="fontFamily mb-3"
                              onChange={handlechange}
                              name="city"
                              value={formData?.city || ""}
                              type="text"
                              size="small"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row >
                        <Col xs={12} >
                          <div>
                            <Form.Label className="fontFamily fw-bold   m-0">{addressInput}</Form.Label>
                            <Form.Control
                              fullWidth
                              className="fontFamily"
                              onChange={handlechange}
                              name="address"
                              value={formData?.address || ""}
                              as="textarea" rows={3}
                              size="small"
                            />
                          </div>
                        </Col>
                        <Col xs={12} >
                          <div>
                            <Form.Label className="fontFamily fw-bold   mt-3 mb-0">{landmarkInput}</Form.Label>
                            <Form.Control
                              fullWidth
                              className="fontFamily"
                              onChange={handlechange}
                              name="landmark"
                              value={formData?.landmark || ""}
                              type="text"
                              size="small"
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="text-center">
                        <Col>

                          {/* <button
                            className={`slide-button text-decoration-none  ${isHovered ? "hovered" : ""
                              }`}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            onClick={handleSubmitForm}
                            type="submit"
                          >

                            {button}
                          </button> */}

                          <div style={{ position: "relative" }}>
                            {buttonClicked && (
                              <button className='fontFamily text-decoration-none mb-1 disabled-btn-primary mt-3' disabled={true}>
                                <>
                                  <CircularProgress style={{ color: "var(--primary)" }} className="mt-1" size={16} />&nbsp;&nbsp; Loading...
                                </>
                              </button>
                            )}
                            {!buttonClicked && (
                              <button
                                onClick={handleSubmitForm}
                                type="submit"
                                // style={{ color: "var(--primary)" }}
                                className={`slide-button fontFamily text-decoration-none mb-1 ${isHovered ? "hovered" : ""}`}
                              // onMouseEnter={() => setIsHovered(true)}
                              // onMouseLeave={() => setIsHovered(false)}
                              >
                                {button}
                              </button>

                            )}
                          </div>

                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};
export default InvestorProfileUpdate;
