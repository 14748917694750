import React, {useEffect, useState} from "react";
import {getDataByUrl} from "../API/HelpingMethod";
import {Card, Col, Container, Row} from "react-bootstrap";
import {Avatar} from "@mui/material";
import SkeletonFaqCategory from "../Homepage/Skeleton-files/SkeletonFaqCategory";
import {Link} from "react-router-dom";
import {HelmetCustomInclude} from "../../hooks/HelmetCustomInclude";
import noimage from "../images/noimage2.webp"

const FaqCategory = () => {
  const [faqcategory, setFaqcategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const loginuser = JSON.parse(localStorage.getItem("userstorage"));

  const inputData = {
    limit: 10,
    offset: 0,
  };
  const [pageTitle, setPageTitle] = useState("FAQ");
  const [metaImageUrl, setMetaImageUrl] = useState(null);

  const apiCall = async () => {
    setLoading(true);
    if (loginuser) {
      inputData.login_user_id = loginuser?.id
      inputData.login_user_type = loginuser?.user_type
    }
    const response = await getDataByUrl("help_faq_category", inputData);

    if (!response?.error) { 
      setFaqcategory(response?.data);
    } else {
      // console.log("Error fetching data:", response?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    apiCall();
  }, []);
  return (
    <>
      <Container className="mt-5 mb-5 pt-4">

        <HelmetCustomInclude url={"faq"} title={pageTitle}/>

        <Row>
          <Col className="fontFamily fw-bold mb-3">Faq Category</Col>
        </Row>
        <Row>  {loading ? <SkeletonFaqCategory /> : <>
          {faqcategory?.map((item, index) => {
            return (<Col xl={6} md={6} lg={6} sm={12} xs={12} key={index} className="mb-3" >
              <Link className="text-decoration-none" to={"/faq/" + item?.slug}>
                <Card id="service">
                  <Card.Body>
                    <Row className="d-flex align-items-center">
                      <Col>
                        <Avatar
                            style={{
                              color: "var(--primary)",
                              width: "80px",
                              height: "80px",
                            }}
                            src={item?.url_image}
                            onError={(e) => e.target.src = noimage}
                        />
                      </Col>
                      <Col>
                        <Card.Title className="fontFamily">
                          {item?.name}
                        </Card.Title>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Link>
            </Col>);
          })}
        </>
        }

        </Row>
      </Container>
      ;
    </>
  );
};

export default FaqCategory;
