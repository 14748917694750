import React, { useState, useEffect } from "react";
import { Card, CardText, Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import noimage from "../images/noimage2.webp"
import defaultimg from "../images/noimage2.webp";
import { amountFormate, apiHepingMethod, getDataByUrl } from "../API/HelpingMethod";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Avatar, CircularProgress, Skeleton, useMediaQuery } from "@mui/material";
import { FaLongArrowAltRight, FaRegUserCircle } from "react-icons/fa";
import SkeletonFavorite from "../Homepage/Skeleton-files/SkeletonFavorite";
import SkeletonFavoriteService from "../Homepage/Skeleton-files/SkeletonFavoriteService";
import SkeletonBlog from "../Homepage/Skeleton-files/SkeletonBlog";
import { SlCalender } from "react-icons/sl";
import image from "../images/Looper-2 (1).png";
import { BsChevronRight, BsTrash, BsTrash3 } from "react-icons/bs";





const Favorite = () => {
  var loginuser = JSON.parse(localStorage.getItem("userstorage"));
  const [idea, setIdea] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(2);
  const [services, setServices] = useState([]);
  const [blog, setBlog] = useState([]);
  const [product, setProduct] = useState([]);
  const [userCurrency, setSetUserCurrency] = useState("USD")
  const [buttonClicked, setButtonClicked] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const [productOfset, setProductOfset] = useState(0);
  const [ideaOfset, setIdeaOfset] = useState(0);
  const [serviceOfset, setServiceOfset] = useState(0);
  const [blogOfset, setBlogOfset] = useState(0);


  const [isProductUpdate, setIsProductUpdate] = useState(false);


  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("userstorage")) {
      navigate("/")
    }
  }, [localStorage.getItem("userstorage")])


  const projectIdeaData = {
    login_user_id: loginuser?.id,
    login_user_type: loginuser?.user_type,
    offset: ideaOfset,
    limit: 4,
  };

  const serviceData = {
    login_user_id: loginuser?.id,
    login_user_type: loginuser?.user_type,
    limit: 4,
    offset: serviceOfset,
    type: "parent",
  };

  const blogData = {
    login_user_id: loginuser?.id,
    login_user_type: loginuser?.user_type,
    limit: 4,
    offset: blogOfset,
  };

  const styleCard = {
    border: "1px solid var(--primary) none",
  };


  const fetchData = async () => {
    setLoading(true);

    try {
      const [ideasResponse, servicesResponse, blogResponse] = await Promise.all(
        [
          getDataByUrl("front_project_idea", projectIdeaData),
          getDataByUrl("service", serviceData),
          getDataByUrl("blog", blogData),
        ]
      );

      if (!ideasResponse?.error) {
        setIdea(ideasResponse?.data);
      }

      if (!servicesResponse?.error) {
        setServices(servicesResponse?.data);
      }

      if (!blogResponse?.error) {
        setBlog(blogResponse?.data);
      }

    } catch (error) {
      setLoading(false);
    }
    setLoading(false);

  };

  useEffect(() => {
    fetchData();
    if (loginuser?.user_type === "startup") {
      setValue(0)
    }
  }, []);

  const productBody = {
    login_user_id: loginuser?.id,
    login_user_type: loginuser?.user_type,
    limit: 4,
    type: "favourite",
    offset: productOfset,
  }

  const getProduct = async () => {
    // setLoading(true);
    const response = await getDataByUrl("marketplace_product", productBody)
    console.log("response--", response)
    if (response?.error === false) {
      setProduct(response?.data);
    }
    // setLoading(false);
  }
  const nextProductData = async () => {
    setBtnLoading(true);
    const inputData = { offset: productOfset || 0 }
    const response = await getDataByUrl("marketplace_product", { ...productBody, ...inputData })
    if (response?.error === false) {
      setProduct((prev) => [...new Set([...prev, ...response?.data])]);
    }
    setBtnLoading(false);
  }
  useEffect(() => {
    if (productOfset !== 0) {
      nextProductData();
    }
  }, [productOfset]);

  const nextIdeaData = async () => {
    setBtnLoading(true);
    const inputData = { offset: ideaOfset || 0 }
    const response = await getDataByUrl("front_project_idea", { ...projectIdeaData, ...inputData })
    console.log("response---next", response)
    if (response?.error === false) {
      setIdea((prev) => [...new Set([...prev, ...response?.data])]);
    }
    setBtnLoading(false);
  }
  useEffect(() => {
    if (ideaOfset !== 0) {
      nextIdeaData();
    }
  }, [ideaOfset]); 

  useEffect(() => {
    getProduct();
  }, [isProductUpdate])


  const nextServiceData = async () => {
    setBtnLoading(true);
    const inputData = { offset: serviceOfset || 0 }
    const response = await getDataByUrl("service", { ...serviceData, ...inputData })
    console.log("response---next", response)
    if (response?.error === false) {
      setServices((prev) => [...new Set([...prev, ...response?.data])]);
    }
    setBtnLoading(false);
  }
  useEffect(() => {
    if (serviceOfset !== 0) {
      nextServiceData();
    }
  }, [serviceOfset]);


  const nextBlogData = async () => {
    setBtnLoading(true);
    const inputData = { offset: blogOfset || 0 }
    const response = await getDataByUrl("blog", { ...blogData, ...inputData })
    console.log("response---next", response)
    if (response?.error === false) {
      setBlog((prev) => [...new Set([...prev, ...response?.data])]);
    }
    setBtnLoading(false);
  }
  useEffect(() => {
    if (blogOfset !== 0) {
      nextBlogData();
    }
  }, [blogOfset]);





  useEffect(() => {
    setSetUserCurrency(product?.amount_currency_user_country || userCurrency || "USD")
  }, [product])


  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    if (loginuser?.user_type === "startup") {
      setValue(newValue)
    } else {
      setValue(2);
    }
    console.log("newValue---", newValue)
  };
  const CroppedText = ({ text, maxLength }) => {
    const croppedText =
      text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

    return <div>{croppedText}</div>;
  };




  const handelProductRemove = async (id) => {
    setButtonClicked(true);
    const inputData = {
      login_user_id: loginuser?.id,
      product_id: id,
      action_type: "like_dislike"
    }

    const response = await apiHepingMethod("marketplace_product_action", inputData);
    console.log("response--", response)
    if (response?.data?.error === false) {
      setIsProductUpdate(true);
    }
    setButtonClicked(false);

  };






  const regex = /(<([^>]+)>)/gi;
  const numberOfSkeletons = 4;
  const styleInnerBox = { borderBottom: 1, borderColor: "divider" };

  const styleImage = {
    width: "100%",
    height: "200px",
    marginTop: "30px"
  };

  const cardStyles = {
    marginBottom: "20px", // Adjust the margin to create space between cards
  };
  const styleFontText = {
    fontSize: "22px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
  const styleButton = { color: "var(--primary)", fontFamily: "Montserrat" };
  const headingFavorite = "Favorite";
  const readMore = "Read More";
  const learnMore = "Learn More";


  const isXSmallScreen = useMediaQuery("(max-width: 600px)");
  const isSmallScreen = useMediaQuery(
    "(min-width: 601px) and (max-width: 960px)"
  );
  const isMediumScreen = useMediaQuery(
    "(min-width: 961px) and (max-width: 1280px)"
  );
  const isLargeScreen = useMediaQuery("(min-width: 1281px)");
  const isXLargeScreen = useMediaQuery("(min-width: 1920px)");

  const numberOfCols = 4


  const styleFont = { fontFamily: "Montserrat" }; 

  return (
    <div>
      <Container className="pt-5">
        <img id="Servicebgimage" src={image} alt="img" onError={(e) => e.target.src = noimage} />
        <Row className="mt-2 mb-2">
          <Col className="ms-3">
            <Link to="/" className="text-decoration-none color-secondary">Home</Link>&nbsp;<BsChevronRight />&nbsp;<span>Favorite</span>
          </Col>
        </Row>
        <Row>
          <Col sm={12} xl={10} className="mx-auto mb-5 mt-3">
            <Card className="">
              <Card.Body>
                <Row>
                  <Col className="h4 fw-bold">{headingFavorite}</Col>
                </Row>
                <Box className="mt-3 w-100">
                  <Box sx={styleInnerBox}>
                    <Tabs variant="scrollable" value={value} onChange={handleChange} aria-label="basic tabs example" >
                      {loginuser?.user_type === "startup" && <Tab label="Ideas" {...a11yProps(0)} />}
                      {loginuser?.user_type === "startup" && <Tab label="Services" {...a11yProps(1)} />}
                      <Tab label="blog" {...a11yProps(2)} />
                      <Tab label="Product" {...a11yProps(3)} />

                    </Tabs>
                  </Box>
                  {loginuser?.user_type === "startup" && <CustomTabPanel value={value} index={0}>
                    <Row>
                      {loading ? (
                        <SkeletonFavorite
                          numberOfSkeletons={numberOfSkeletons}
                          isXSmallScreen={isXSmallScreen}
                          isSmallScreen={isSmallScreen}
                          isMediumScreen={isMediumScreen}
                          isLargeScreen={isLargeScreen}
                          isXLargeScreen={isXLargeScreen}
                        />
                      ) : (
                        <>
                          {idea && Array.isArray(idea) && idea.map((projectItem, ind) => {
                            return (
                              <Col xl={6} lg={6} md={6} sm={12} xs={12} className="mt-4" key={ind} >
                                <Card className="shadow">
                                  <img
                                    variant="top"
                                    src={projectItem.url_image}
                                    alt={projectItem.url_image}
                                    onError={(e) => e.target.src = noimage}
                                    className="px-4"
                                    style={styleImage}
                                  />
                                  <Card.Body>
                                    <Card.Title className="fw-bold">
                                      {projectItem.name}
                                    </Card.Title>
                                    <Card.Text className="fontFamily m-0">
                                      <CroppedText
                                        className="fontFamily m-0"
                                        text={projectItem.description.replace(
                                          regex,
                                          " "
                                        )}
                                        maxLength={120}
                                      />
                                    </Card.Text>
                                    <Link
                                      // to={
                                      //   "/project-idea-detail?project-ideaid=" +
                                      //   projectItem.id
                                      // }
                                      to={`/project-idea/${projectItem.slug}?slug=${projectItem.slug}`}
                                      className="color-secondary"
                                      style={{ textDecoration: "none" }}
                                    >
                                      {readMore}
                                      <FaLongArrowAltRight className="ms-2 color-secondary" />
                                    </Link>
                                  </Card.Body>
                                </Card>
                              </Col>
                            );
                          })}
                        </>
                      )}
                    </Row>
                    <Row>
                      <Col>
                        {(idea?.length >= 3 &&
                          (btnLoading ? <div className="mt-3 text-center">
                            <button disabled className={`slide-button text-decoration-none loading-hover`} >
                              <CircularProgress className="mt-1" color="inherit" size={16} />&nbsp; Load More
                            </button>
                          </div> : <div className="mt-3 text-center">
                            <button className={`slide-button text-decoration-none`} onClick={() => setIdeaOfset(ideaOfset + 1)} >
                              Load More
                            </button>
                          </div>)
                        )}
                      </Col>
                    </Row>
                  </CustomTabPanel>}
                  {loginuser?.user_type === "startup" && <CustomTabPanel value={value} index={1}>
                    <Row className="mt-3">
                      {loading ? (
                        <SkeletonFavoriteService
                          numberOfCols={numberOfCols}
                        />
                      ) : (
                        <>
                          {services && Array.isArray(services) && services.map((serviceItem, index) => {
                            return (
                              <Col className="" md={6} key={index}>
                                <Link
                                  to={`/service/${serviceItem.slug}?slug=${serviceItem.slug}`}
                                  className="text-decoration-none"
                                >
                                  <Card
                                    id="service"
                                    className="mt-4 shadow rounded-4"
                                  >
                                    <Card.Body className="">
                                      <Avatar
                                        className="color-primary"
                                        variant="rounded"
                                        alt="Service"
                                        src={serviceItem.url_image}
                                      >
                                        {" "}
                                      </Avatar>
                                      <Card.Text className="mt-2 fw-bold fs-4">
                                        {serviceItem.name}
                                      </Card.Text>
                                      <p className="mt-2 fontFamily">
                                        <CroppedText
                                          className="fontFamily"
                                          text={serviceItem.description.replace(
                                            regex,
                                            " "
                                          )}
                                          maxLength={120}
                                        />
                                      </p>
                                      <Link
                                        to={`/service/${serviceItem.slug}?slug=${serviceItem.slug}`}
                                        className="text-decoration-none"
                                        style={styleButton}
                                      >
                                        {learnMore} <FaLongArrowAltRight />
                                      </Link>
                                    </Card.Body>
                                  </Card>
                                </Link>
                              </Col>
                            );
                          })}
                        </>
                      )}
                    </Row>

                    <Row>
                      <Col>
                        {(services?.length >= 3 &&
                          (btnLoading ? <div className="mt-3 text-center">
                            <button disabled className={`slide-button text-decoration-none loading-hover`} >
                              <CircularProgress className="mt-1" color="inherit" size={16} />&nbsp; Load More
                            </button>
                          </div> : <div className="mt-3 text-center">
                            <button className={`slide-button text-decoration-none`} onClick={() => setServiceOfset(serviceOfset + 1)} >
                              Load More
                            </button>
                          </div>)
                        )}
                      </Col>
                    </Row>
                  </CustomTabPanel>}
                  <CustomTabPanel value={value} index={2}>
                    <Row className="mt-3">
                      {loading ? (
                        <SkeletonBlog />
                      ) : (
                        <>
                          {blog && Array.isArray(blog) && blog?.map((blogItem, ind) => {
                            return (
                              <Col md={6} className="g-3" key={ind} style={cardStyles}
                              >
                                <Card id="service" className="shadow h-100">
                                  <Card.Body>
                                    <Card.Img className="w-100" onError={(e) => e.target.src = noimage} src={blogItem.media_url} alt="./"
                                    />
                                    <Card.Text
                                      style={styleFontText}
                                      className="fw-bold mt-3"
                                    >
                                      {blogItem.title}
                                    </Card.Text>
                                    <p className="fontFamily">
                                      <CroppedText text={blogItem.page_description} maxLength={70}
                                      />
                                    </p>
                                    <Link
                                      to={`/blog/${blogItem.slug}?slug=${blogItem.slug}`}
                                      className="color-secondary text-decoration-none"
                                    >
                                      {readMore}
                                      <FaLongArrowAltRight />
                                    </Link>
                                    <hr />
                                    <div className="d-flex">
                                      <FaRegUserCircle className="color-secondary" />
                                      <h6 className="mx-2">
                                        {blogItem.count_view}
                                      </h6>
                                      <SlCalender className="color-secondary mx-3" />
                                      <h6 className="mx-2">
                                        {formatDate(blogItem.date_schedule)}
                                      </h6>
                                    </div>
                                  </Card.Body>
                                </Card>
                              </Col>
                            );
                          })}
                        </>
                      )}
                    </Row>


                    <Row>
                      <Col>
                        {(blog?.length >= 3 &&
                          (btnLoading ? <div className="mt-3 text-center">
                            <button disabled className={`slide-button text-decoration-none loading-hover`} >
                              <CircularProgress className="mt-1" color="inherit" size={16} />&nbsp; Load More
                            </button>
                          </div> : <div className="mt-3 text-center">
                            <button className={`slide-button text-decoration-none`} onClick={() => setBlogOfset(blogOfset + 1)} >
                              Load More
                            </button>
                          </div>)
                        )}
                      </Col>
                    </Row>

                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={3}>
                    {loading && <>
                      <Col className="mx-auto skeleton-m-p mt-3"><Skeleton width="100%" height={200} /></Col>
                      <Col className="mx-auto skeleton-m-p mt-3"><Skeleton width="100%" height={200} /></Col>
                      <Col className="mx-auto skeleton-m-p mt-3"><Skeleton width="100%" height={200} /></Col>
                      <Col className="mx-auto skeleton-m-p mt-3"><Skeleton width="100%" height={200} /></Col>
                    </>}
                    {
                      Array.isArray(product) && product.map((product, index) => {
                        return (
                          <Row key={index}>
                            {/* <Col className=" ">
                              <Card className="mt-4 shadow marketplace-product-card " key={product.id}>
                                <Card.Body>
                                  <Row>
                                    <Col md={3} xl={3} className="market-category-list-img">
                                      <img onError={(e) => (e.target.src = defaultimg)}
                                        className="rounded pointer "
                                        onClick={() => navigate(`/marketplace/${product.slug}`)}
                                        src={product.url_image || defaultimg}
                                        style={{ width: "100%", height: "200px" }}
                                        alt="product image"
                                      />
                                    </Col>
                                    <Col md={5} xl={5}>
                                      <Container>
                                        <Row className="fw-bold pointer text-capitalize"
                                          style={{ fontFamily: "'Nunito Sans', sans-serif" }}
                                          onClick={() => navigate(`/marketplace/${product.slug}`)}>
                                          {product.title}
                                        </Row>

                                      </Container>
                                    </Col>
                                    <Col md={4} xl={4}>
                                      <Container>
                                        <Row className="text-right fw-bold">
                                          {" "}
                                          <Col>
                                            <del className={"text-danger"}>
                                              {amountFormate(product?.amount_mrp_user_country || 0, 'int')} {userCurrency}
                                            </del>
                                            &nbsp;
                                            {amountFormate(product?.amount_rate_user_country || 0, 'int')} {userCurrency}
                                          </Col>
                                        </Row>
                                        <Row className="mt-3 ">
                                          {
                                            <Link

                                              className={`slide-button text-decoration-none text-center fw-bold `}
                                              key={product.id}
                                            >
                                              <BsTrash /> Remove From Favorite
                                            </Link>
                                          }
                                        </Row>
                                      </Container>
                                    </Col>

                                  </Row>
                                </Card.Body>
                              </Card>
                            </Col> */}

                            <Card key={index} className="mt-2 shadow" style={styleCard}>
                              <Card.Body>
                                <Container>
                                  <Row>
                                    <Col md={3}>
                                      <img onError={(e) => (e.target.src = defaultimg)}
                                        className="w-100" src={product?.url_image || defaultimg} alt={'product'} />
                                    </Col>
                                    <Col md={6} xl={6}>
                                      <Container>
                                        <Row className="fw-bold" style={{ fontFamily: "'Nunito Sans', sans-serif", }}>
                                          {product?.title}
                                        </Row>
                                      </Container>
                                    </Col>
                                    <Col md={3} xl={3} className="d-flex ">
                                      <Container>
                                        <Row className="text-end fw-bold pb-1">
                                          <Col>{amountFormate(product?.amount_total_user_country)} {product?.amount_currency_user_country}</Col>
                                        </Row>
                                        <Row className="text-end">
                                          {buttonClicked && (
                                            <button className='fontFamily text-decoration-none disabled-btn w-100'
                                              disabled><CircularProgress style={{ color: "var(--secondary)" }}
                                                className="mt-1"
                                                size={16} />&nbsp;&nbsp; Loading...</button>
                                          )
                                          }
                                          {
                                            !buttonClicked && (
                                              <button
                                                onClick={() => handelProductRemove(product?.id)}
                                                className={`button-danger text-decoration-none `} >
                                                <BsTrash3 className="mb-1" />{" "} Remove
                                              </button>
                                            )
                                          }
                                        </Row>
                                      </Container>
                                    </Col>
                                  </Row>
                                </Container>
                              </Card.Body>
                            </Card>

                          </Row>
                        )
                      })
                    }

                    <Row>
                      <Col>
                        {(product?.length >= 3 &&
                          (btnLoading ? <div className="mt-3 text-center">
                            <button disabled className={`slide-button text-decoration-none loading-hover`} >
                              <CircularProgress className="mt-1" color="inherit" size={16} />&nbsp; Load More
                            </button>
                          </div> : <div className="mt-3 text-center">
                            <button className={`slide-button text-decoration-none`} onClick={() => setProductOfset(productOfset + 1)} >
                              Load More
                            </button>
                          </div>)
                        )}
                      </Col>
                    </Row>

                  </CustomTabPanel>
                </Box>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Favorite;
