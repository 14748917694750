import { Autocomplete, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { Bs1CircleFill } from "react-icons/bs";
import { useNotification } from "../../../hooks";
import { getDataByUrl } from "../../API/HelpingMethod";

const StartupCompanyDetails = ({ setActiveStep, handleBack, setBodyData, bodyData }) => {
    const [btnLoading, setBtnLoading] = useState(false);
    const [member, setMember] = useState('');
    const { showError, showSuccess, setOpenBackDrop } = useNotification();
    const [startupStage, setStartupStage] = useState([]);



    async function getStartupStage() {
        setOpenBackDrop(true);
        let response = await getDataByUrl("user_startup_stage", {
            limit: 0,
            offset: 0
        })
        console.log("response-----", response)
        if (response.error === false) {
            setStartupStage(response?.data || []);
        } else {
            setStartupStage(response?.data || []);
        }
        setOpenBackDrop(false);
    }
    useEffect(() => {
        getStartupStage().then((e) => e);
    }, []);



    const handleStartupStage = (e, value) => {
        // console.log("value---",value)
        setBodyData({ ...bodyData, startup_stage: value?.name })
    }

    const handlechange = (e) => {
        const { name, value } = e.target;
        setBodyData({ ...bodyData, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!bodyData?.name_company) {
            showError("Please Enter Your Company Name");
        }
        else if (!bodyData?.name_brand) {
            showError("Please Enter Your Brand Name");
        } else if (!bodyData?.startup_stage) {
            showError("Please Enter Your Startup Stage");
        }
        else {
            setActiveStep(3)
        }
    }


    return (
        <>
            <Container>
                <Row xs={{ cols: 1 }}>
                    <Col className=" text-center mb-3 mt-4 pt-2 fw-bold"> <span>Company Details</span> </Col>

                    <Col className=" mt-3"><span className="h6">What's Your Company Name?</span></Col>
                    <Col className="mt-1 mx-auto d-flex justify-content-center">
                        <Form.Control type="text"
                            className="idea-stepper-input"
                            onChange={handlechange}
                            value={bodyData?.name_company}
                            name="name_company" />
                    </Col>

                    <Col className=" mt-3"><span className="h6">What's Your Brand Name? </span></Col>
                    <Col className="mt-1 mx-auto d-flex justify-content-center">
                        <Form.Control type="text"
                            className="idea-stepper-input"
                            onChange={handlechange}
                            value={bodyData?.name_brand}
                            name={"name_brand"} />
                    </Col>
 
                    <Col className=" mt-3"><span className="h6">What's Your Startup Current Stage</span></Col>
                    {/* <Col className="mt-1 mx-auto d-flex justify-content-center">
                        <Form.Control type="text"
                            className="idea-stepper-input"
                            onChange={handlechange}
                            value={bodyData?.startup_stage}
                            name={"startup_stage"} />
                    </Col> */}

                    <Col className="mt-1 mx-auto startup-register-autocomplete autocomplete-border">
                        <Autocomplete
                            disablePortal
                            fullWidth
                            id="combo-box-demo"
                            onChange={handleStartupStage}
                            options={startupStage}
                            getOptionLabel={(option) => option?.name || bodyData?.startup_stage}
                            value={(bodyData?.startup_stage)}
                            renderInput={(params) => <TextField  {...params} label="Startup Stage" />}
                        />
                    </Col> 

                    <Col className="d-flex justify-content-center mt-5">
                        <div className="me-2">
                            <button onClick={handleBack} className="input-primary-buttons" >
                                Back
                            </button>
                        </div>

                        <div className="ms-2">
                            <button disabled={btnLoading} className={btnLoading ? "disabled-btn" : "input-buttons"} onClick={handleSubmit}>
                                {btnLoading && <><CircularProgress color="inherit" className="mt-1" size={16} />&nbsp;</>}Next
                            </button>
                        </div>
                    </Col>
                </Row>

            </Container>
        </>
    )
}
export default StartupCompanyDetails