import { Skeleton } from '@mui/material';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

export default function SkeletonBlog({ titleDescriptionWidth, cardImageWidth, titleWidth }) {
  const cardData = Array.from({ length: 3 }, (_, index) => index);
  const styleCard = {
    border: "1px solid var(--primary)"
  };
  return (
    <Row>
      {cardData.map((index) => (
        <Col key={index} md={6} lg={4} className="g-3">
          <Card className="shadow h-100">
            <Card.Body>
              <Skeleton variant='rectangle' className='rounded' width={cardImageWidth} height={300} />
              <Card.Text className="fw-bold">
                <Skeleton variant='rectangle' className='rounded mt-3' width={titleDescriptionWidth} height={30} />
              </Card.Text>
              <Skeleton variant='rectangle' className='rounded mt-3' width={titleDescriptionWidth} height={20} />
              <Skeleton variant='rectangle' className='rounded mt-3' width={titleWidth} height={20} />

              <hr /> 

              <div className="d-flex">
                <Skeleton variant='rectangle' className='rounded' width={20} height={20} />
                <Skeleton variant='rectangle' className='rounded mx-2' width={20} height={20} />
                <Skeleton variant='rectangle' className='rounded mx-5' width={20} height={20} />
                <Skeleton variant='rectangle' className='rounded' width={120} height={20} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
}
