const moneyFormatter = new Intl.NumberFormat('en-IN', {
    // maximumSignificantDigits: 3,
    maximumFractionDigits: 2,
})

// const moneyFormatter = (num) => num.toLocaleString('en-In', {
//     // maximumSignificantDigits: 3,
//     maximumFractionDigits: 2,
// });

// const cropText = (str, count = 50) => str.length > count ? str.substring(0, count) + "..." : str;


const getFirstWordCap = (str) => {
    if (str) {
        str = str.replace(/_/g, " ");
        str = str.charAt(0).toUpperCase() + str.slice(1);
        return str;
    } else {
        return "";
    }

};

const profileText = (str) => {
    const count = 5;
    if (str.length > count) {
        return str.substring(0, count);
    } else {
        return str;
    }

}


const formatMoney = (amount) => moneyFormatter.format(amount || 0);

// const formatMoney = (amount) => moneyFormatter(amount || 0);

function checkIfImageExists(url) {
    const img = new Image();
    img.src = url;

    if (img.complete) {
        return true;
    } else {
        img.onload = () => {
            return true;
        };
        img.onerror = () => {
            return false;
        };
    }
}

function get_date_formatted(dateCustom, type = 'date') {
    // let date = new Date();
    // if (dateCustom) {
    //     date = new Date(dateCustom);
    // }
    // const day = date.getDate();
    // const month = date.getMonth();
    // const year = date.getFullYear();
    //
    // return day + "/" + month + "/" + year;
    // console.log(dateCustom, type);
    if (dateCustom) {
        if (type === 'date-time') {
            return new Date(dateCustom).toLocaleString('en-US', {
                day: '2-digit', // Two-digit day
                month: 'short', // Abbreviated month name
                year: 'numeric',
                hour: "numeric",
                minute: "numeric",
                // second: "numeric",
                hour12: true, // Change to false if you want 24-hour format
            });
        } else {
            return new Date(dateCustom).toLocaleString('en-US', {
                day: '2-digit', // Two-digit day
                month: 'short', // Abbreviated month name
                year: 'numeric',
                // hour: 'numeric',
                // minute: 'numeric',
                // second: 'numeric',
                // hour12: true, // Change to false if you want 24-hour format
            });
        }
    } else {
        return "";
    }

}

function get_status_jobsheet() {
    return [
        'pending_quotation',
        'price_updated',
        'confirmed',
        'processing',
        'pick_up_completed',
        'delivered_to_repair_factory',
        'pending_delivery',
        'delivered',
        'completed',
        'return_requested',
        'return_rejected',
        'return_accepted',
        'refunded',
        'cancelled_by_user',
        'rejected_by_admin',
        'rejected_by_agent'
    ];
}

function get_color_by_status(status) {
    let color = "#fc8844";
    if (status === 'incomplete') {
        // color = "#f11616";
        color = "#757474";
    } else if (status === 'pending_quotation' || status === 'pending') {
        color = "#fc8844";
    } else if (status === 'delivered' || status === 'completed') {
        color = "#38d713";
    } else if (status === 'cancelled_by_user' || status === 'cancelled') {
        color = "#e70000";
    } else {
        color = "#1d68de";
    }
    return color;
}

const getFileSizes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const getFullPageTitle = (title, author) => {
    return (author ? title + " | " + author : title + " | " + "Startup Starter")
}

const cropText = (str, count = 50) => {
    if (str) {
        if (str.length > count) {
            const deTagged = str.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '');
            let deNewlined = deTagged.replace(/\n/g, '');
            deNewlined = deNewlined.replaceAll("&nbsp;", ' ');

            return deNewlined.substring(0, count) + "..."
        } else {
            return str;
        }
    } else {
        return "";
    }

};

const cropTextHelmet = (str, count = 50) => {
    if (str) {
        if (str.length > count) {
            const deTagged = str.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '');
            let deNewlined = deTagged.replace(/\n/g, '');
            deNewlined = deNewlined.replaceAll("&nbsp;", ' ');

            return deNewlined.substring(0, count) + "..."
        } else {
            return str;
        }
    } else {
        return "";
    }

};

const getMoneyDecimal = (amount_payable) => {
    return (Math.round(amount_payable * 100)) / 100;

}

export {
    getMoneyDecimal,
    getFullPageTitle,
    getFileSizes,
    get_color_by_status,
    get_status_jobsheet,
    cropText,
    moneyFormatter,
    formatMoney,
    checkIfImageExists,
    getFirstWordCap,
    get_date_formatted,
    profileText,
    cropTextHelmet
};