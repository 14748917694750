import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Mobile1 from "../images/Mobile-resize.png";
import Mobile2 from "../images/mobile-resize1.png";
import Mobile3 from "../images/mobile-resize3.png";
import noimage from "../images/noimage2.webp"

import AppStore from "../images/AppStore.png";
import GoogleStore from "../images/GooglePlay.png";
import "../css/Home.css"
const MobileApp = () => {
  const url = "https://play.google.com/store/apps/details?id=com.aimerse.startupstarter"
  return (
    <>
      <Container fluid id="SuccessStories" className="mt-4">
        <Container>
          <Row>
            <Col md={6} className="pt-5">
              <h4 className="text-light" style={{ fontFamily: "Montserrat" }}>
                Explore The Startup Starter Mobile App
              </h4>
              <p className="text-light fontFamily">
                Lorem ipsum dolor sit amet consectetur. Quisque odio risus nisi
                vel adipiscing consectetur interdum. Amet turpis facilisi
                ultricies molestie scelerisque semper in amet. Lorem proin
                fermentum in fermentum varius sit. Enim nullam in nisl lobortis
                commodo sed vel sed.{" "}
              </p>

              <Row className="pt-4 mb-3">
                <Col md={6} xl={5} sm={6} xs={6} lg={6} className="">
                  <img
                    style={{ cursor: "pointer", transition: "transform 0.3s" }}
                    onClick={() => window.open(url)}
                    onMouseOver={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
                    onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")}
                    src={AppStore}
                    alt=""
                    className="w-100"
                    onError={(e) => e.target.src = noimage}
                  />
                </Col>
                <Col md={6} xl={5} sm={6} xs={6} lg={6} className="">
                  <img
                    style={{ cursor: "pointer", transition: "transform 0.3s" }}
                    onClick={() => window.open(url)}
                    onMouseOver={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
                    onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")}
                    src={GoogleStore}
                    alt=""
                    className="w-100"

                  />
                </Col>
              </Row>
            </Col>

            <Col md={6} className="mt-md-0 mt-3">
              <Container>
                <Row className="text-end">
                  <Col>  <img src={Mobile1} onError={(e) => e.target.src = noimage} className=" mobile-img1" /> </Col>
                  <Col>  <img src={Mobile1} onError={(e) => e.target.src = noimage} className="mobile-img2" /> </Col>
                </Row> 
                <Row className="text-end bottom-mobile-images">
                  <Col>  <img src={Mobile2} onError={(e) => e.target.src = noimage} className="  mobile-img1" /> </Col>
                  <Col>  <img src={Mobile2} onError={(e) => e.target.src = noimage} className="mobile-img2" /> </Col>
                </Row>
              </Container>
            </Col>
            {/* <Col md={3} sm={12} lg={3} xl={3} xs={12} className="imageWithMargin" id="mediumScreenImage" >
              <img src={Mobile} alt="" />
            </Col>
            <Col md={2} sm={12} lg={2} xl={2} xs={12} className="imageWithMargin" id="mediumScreenImage">
              <img src={Mobile} alt="" />
            </Col> */}
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default MobileApp;
