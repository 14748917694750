// import { Skeleton } from '@mui/material'
// import React from 'react'
// import { Card, Col, Container, Row } from 'react-bootstrap'
// import { Link } from 'react-router-dom'

// export default function SkeletonRequirement() {
//     // const styleCol = {
//     //     borderRadius: "90%",
//     //     height: "30px",
//     //     width: "30px",
//     //     fontSize: "20px",
//     //   };

//   return (
//     <div>
//           <Col xl={6} lg={6} md={6} sm={12} xs={12} >
//                   <Container>
//                     <Card  className="mb-5">
//                       <Card.Body>
//                         <Col
//                           className="text-light text-center"
//                         >
//                           {/* {item?.id} */}

//                           <Skeleton variant="circular" width={40} height={40} />
//                         </Col>
//                         <br />
//                         <h5 className="card-title">
//                         {/* {item?.title} */}
                        
//                         <Skeleton/>
//                         </h5>
//                         <Card.Text className="fontFamily">
//                           {/* {item?.description || text} */}
                          
//                           <Skeleton/>
//                         </Card.Text>

//                         <Row>
//                           <Col className="mt-2">
//                             <Link className="color-secondary">
//                               {/* {item?.status} */}
                              
//                           <Skeleton/>
//                             </Link>
//                           </Col>
//                           <Col className="mt-2">
//                             <Link className="color-secondary">
//                               {/* {item?.action} */}
                              
//                           <Skeleton/>
//                             </Link>
//                           </Col>
//                         </Row>
//                       </Card.Body>
//                     </Card>
//                   </Container>
//                 </Col>
//     </div>
//   )
// }


import { Skeleton } from '@mui/material';
import React from 'react'; // Replace with the correct path
import { Col, Container, Card, Row,  } from 'react-bootstrap'; // Import necessary components
import { Link } from 'react-router-dom';

export default function SkeletonRequirement() {
  const items = Array.from({ length: 10 }, (_, index) => index + 1);

  return ( 
    <Row>
      {items.map((item) => (
        <Col key={item} xl={6} lg={6} md={6} sm={12} xs={12}>
          <Container>
            <Card className="mb-5">
              <Card.Body>  
                <Col className="text-light text-center">
                  <Skeleton variant="circular" width={40} height={40} />
                </Col>
                <br />
                <h5 className="card-title">
                  <Skeleton />
                </h5>
                <Card.Text className="fontFamily">
                  <Skeleton />
                </Card.Text>
                <Row>
                  <Col className="mt-2">
                    <Link className="color-secondary">
                      <Skeleton />
                    </Link>
                  </Col>
                  <Col className="mt-2">
                    <Link className="color-secondary">
                      <Skeleton />
                    </Link>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
        </Col>
      ))}
    </Row>
  );
}
