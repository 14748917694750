import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Skeleton } from "@mui/material";

export default function SkeletonFavoriteList({ numberOfSkeletons, cardWidth, titleWidth, descriptionWidth, readmoreButton }) {
  const skeletons = Array.from({ length: numberOfSkeletons }, (_, index) => index);
 
  return (
    <div> 
    <Row> 
      {skeletons.map((index) => (
        <Col key={index} xl={6} lg={6} md={6} sm={12} xs={12} className="mt-4">
          <Card className="shadow">
            <Card.Body>
              <Skeleton width={cardWidth} height={200} variant="rectangle" className="rounded" />
              <Skeleton variant="rectangle" className="rounded mt-3" width={titleWidth} height={20} />
              <Skeleton variant="rectangle" className="rounded mt-2" width={descriptionWidth} height={20} />
              <Skeleton variant="rectangle" className="rounded mt-3" width={readmoreButton} height={20} />
            </Card.Body>
          </Card>
        </Col>
      ))}
        </Row>
    </div>
  );
}

