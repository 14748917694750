import React, {useEffect, useState} from "react";
import {Card, Col, Container, Modal, Row} from "react-bootstrap";
import app from "../images/setting-2setting.png";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {amountFormate, getDataByUrl} from "../API/HelpingMethod";
import {BsChevronLeft, BsClock, BsPencilFill, BsXLg} from "react-icons/bs";
import image from "../images/Looper-2 (1).png";
import {CircularProgress, Skeleton} from "@mui/material";
import {useNotification} from "../../hooks";
import {cropText, get_date_formatted, getFirstWordCap} from "../../hooks/useCustomHelpingMethod";
import {FaEdit} from "react-icons/fa";
import handlePaymentTransaction from "../razorpay/handel_payment_razorpay";
import useRazorpay from "react-razorpay";
import {loginUserData, paymentGatewayCurrency} from "../API/client";

const loadRazorpayScript = () => {
    const script = document.createElement("script");
    script.src = `https://checkout.razorpay.com/v1/checkout.js`;
    document.body.appendChild(script);
};

const UserSummary = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const {tokenWorkGroup} = useParams();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const [data, setData] = useState();
    // const authLoginUser = JSON.parse(localStorage.getItem("userstorage"));

    const authLoginUser = loginUserData;

    const [isHovered, setIsHovered] = useState(false);
    const [idea, setIdea] = useState([]);
    const [selectedIdeaId, setSelectedIdeaId] = useState({});
    const [buttonClicked2, setButtonClicked2] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);


    const {showError, showSuccess} = useNotification();
    const [servicePlans, setServicePlans] = useState([]);
    const [servicePlan, setServicePlan] = useState({});
    const [serviceName, setServiceName] = useState({});
    const [serviceImg, setServiceImg] = useState({});
    const [serviceToken, setServiceToken] = useState({});
    const [planToken, setPlanToken] = useState();
    const [activePlan, setActivePlan] = useState(servicePlan?.token);
    const [changePlan, setChangePlan] = useState({})
    const [Razorpay] = useRazorpay();

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);
    const inputData = {
        login_user_type: authLoginUser?.user_type,
        login_user_id: authLoginUser?.id,
        token: tokenWorkGroup,
    };
    const apiCall = async () => {
        setLoading(true);
        const response = await getDataByUrl("user_work_group", inputData);
        // console.log("response---", response)
        if (!response?.error) {
            setData(response?.data);
        } else {
            // console.log("Error fetching data:", response?.message);
        }
        setLoading(false);
    };
    useEffect(() => {
        apiCall();
    }, [changePlan, tokenWorkGroup]);

    useEffect(() => {
        if (!loginUserData) {
            navigate("/")
        }
    }, [])

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const selectedIdea = "Selected Idea";
    const selectServices = "Select Services";
    const servicesDuration = "Services Duration";
    const styleText = {
        whiteSpace: 'nowrap', overflow: 'hidden',
    }

    const getProjectIdea = async () => {
        if (authLoginUser) {
            inputData.login_user_id = authLoginUser?.id
            inputData.login_user_type = "startup"
        }
        const response = await getDataByUrl("front_project_idea", {offset: 0, limit: 10});
        if (!response?.error) {
            setIdea(response?.data);
        } else {
            // console.log("Error fetching data:", response?.message);
        }
    };
    useEffect(() => {
        getProjectIdea();
    }, []);

    const selectIdea = (token) => {
        setSelectedIdeaId(token)
        // setPlanToken(token);
        // setPlanCardActive(status);
        // setActivePlan(token);
    };

    const handleClick = async () => {
        setButtonClicked(true)

        try {
            const response = await getDataByUrl("user_work_group_update", {
                login_user_id: authLoginUser?.id,
                login_user_type: authLoginUser?.user_type,
                ref_id: selectedIdeaId,
                ref_type: "project_idea",
                group_token: tokenWorkGroup
            });


            if (!response?.error) {
                showSuccess(response?.message);
            } else {
                showError(response?.message);
            }
        } catch (error) {
            // console.error("Error during async operation:", error);
        } finally {
            // setLoading(false);
        }
        setButtonClicked(false)

    };

    const ChangePlan = (plans, plan, token, name, image) => {
        setServicePlans(plans);
        setServicePlan(plan);
        setServiceToken(token);
        setServiceName(name);
        setServiceImg(image);
        handleShow2();
    };

    const selectPlan = (token, status) => {
        setServicePlan(token)
        setPlanToken(token);
        setActivePlan(token);
    };

    const plansbodydata = {
        group_token: tokenWorkGroup,
        token_service: serviceToken,
        token_service_plan: planToken || servicePlan?.token,
        login_user_id: authLoginUser?.id,
    };

    const handleSelectPlan = async () => {
        setButtonClicked2(true)
        const response = await getDataByUrl(
            "user_work_group_service_update",
            plansbodydata
        );
        // console.log("response---", response)
        if (response?.error === false) {
            setChangePlan(response?.data)
            showSuccess(response?.message);
            handleClose2();
        } else {
            showError(response?.message);
        }
        setButtonClicked2(false)
    };

    const redirectOnSuccess = (response) => {
        showSuccess(response?.message || "Payment successfully completed, Thank you for your interest with our services");
        // navigate("/user-project");
        apiCall().then(r => r);
    }
    const redirectOnError = (response) => {
        showError(response?.message || "Payment failed or cancelled so process unable to complete. Please try again later");
        // navigate("/user-project");
    }

    const handleSubmitPayment = async () => {
        setButtonClicked(true)
        let inputBodyData = {
            payment_source: "razorpay",
            ref_type: "work_group",
            login_user_id: authLoginUser?.id,
            ref_id: data?.id,
            amount: data?.amount_total_pending || 0,
            amount_currency: data?.amount_currency || "USD",
            txn_order_id: "",
            txn_ref: "",
            type: "rest"
        }

        handlePaymentTransaction({
            Razorpay: Razorpay,
            inputBodyData: inputBodyData,
            data: data,
            amount_payable: paymentGatewayCurrency === 'USD' ? data?.amount_total_pending : data?.amount_total_pending_user_country,
            amount_currency: paymentGatewayCurrency === 'USD' ? data?.amount_currency : data?.amount_currency_user_country,
            // amount_payable: 1,
            // amount_currency: "INR",
            ref_id: data?.id,
            ref_type: "work_group",
            redirectOnSuccess: redirectOnSuccess,
            redirectOnError: redirectOnError,
            setLoading: setLoading
        });
        setButtonClicked(false)
        return;
    };


    return (
        <div>
            <img id="Servicebgimage" src={image} alt="img"/>


            <Modal show={show2} onHide={handleClose2} size="lg">
                <Modal.Body className="plans-modal">
                    <Container>
                        <Row className="plans-modal-header">
                            <Col className="p-0">
                                <div style={{height: "100px", width: "100px"}}>
                                    <img className="rounded" style={{height: "100%", width: "100%"}}
                                         src={serviceImg || app}
                                         alt={"gc"}/>
                                </div>
                            </Col>
                            <Col className="text-end">
                                <BsXLg className="close-button" size={30} onClick={handleClose2}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="fw-bold p-0 mt-2">{serviceName}</Col>
                        </Row>
                        <Row> <Col className=" p-0 mb-2"> Select a service plan to change for your project
                            requirement. </Col>
                        </Row>
                        <Row>
                            {servicePlans && Array.isArray(servicePlans) && servicePlans.map((data, index) => {
                                return (<Col key={data?.token || index} style={{cursor: "pointer"}} onClick={() => {
                                    selectPlan(data?.token, data?.status)
                                }} sm={6} className=" mt-2">
                                    <Card
                                        className={servicePlan?.token === data?.token || activePlan === data?.token ? "active-plan-card p-1" : " p-1"}>
                                        <Container>
                                            <Row className="mt-1">
                                                <Col>{data?.title}</Col>
                                            </Row>
                                            <Row className="mt-2 mb-1">
                                                <Col
                                                    className="fw-bold"> {amountFormate(data?.total_value_user_country)}&nbsp; {data?.factor_value_user_country_currency}</Col>
                                            </Row>
                                        </Container>
                                    </Card>
                                </Col>);
                            })}
                        </Row>
                    </Container>
                </Modal.Body>
                <Container>
                    <Row className="mb-3">
                        <Col className="ms-3">
                            <div>
                                <button
                                    onClick={handleSelectPlan}
                                    disabled={buttonClicked2}
                                    className={` fontFamily text-decoration-none mb-4 ${buttonClicked2 ? "disabled-btn" : "slide-button1"}`}>
                                    {buttonClicked2 && <> <CircularProgress style={{color: "var(--secondary)"}}
                                                                            className="mt-1"
                                                                            size={16}/>&nbsp;&nbsp; </>} Confirm
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Body className="plans-modal">
                    <Container>
                        <Row className="plans-modal-header">

                            <Col className="text-end">
                                <BsXLg className="close-button" size={30} onClick={handleClose}/>
                            </Col>
                        </Row>
                        <Row>
                        </Row>
                        <Row> <Col className="p-0">Change Project Idea </Col> </Row>
                        <Row>
                            {idea && Array.isArray(idea) && idea.map((data, index) => {
                                return (<Col key={index} style={{cursor: "pointer"}} onClick={() => {
                                    selectIdea(data?.id)
                                }} sm={6} className=" mt-2">
                                    <Card className={selectedIdeaId === data?.id ? "active-plan-card p-1" : " p-1"}>
                                        <Container>
                                            <Row className="mt-1">
                                                <Col xs={6} sm={4} className="ps-1">
                                                    <div style={{height: "100%", width: "100%"}}>
                                                        <img alt={"app"} src={data?.url_image}
                                                             className="img-fluid rounded"/>
                                                    </div>
                                                </Col>
                                                <Col xs={6} sm={8}>{data?.name}</Col>
                                            </Row>
                                        </Container>
                                    </Card>
                                </Col>);
                            })}
                        </Row>
                    </Container>
                </Modal.Body>
                <Container>
                    <Row className="mb-1">
                        <Col className="ms-3">
                            <div>
                                <button onClick={handleClick} disabled={buttonClicked}
                                        className={` fontFamily text-decoration-none mb-4 ${buttonClicked ? "disabled-btn" : "slide-button1"}`}>
                                    {buttonClicked && <> <CircularProgress style={{color: "var(--secondary)"}}
                                                                           className="mt-1"
                                                                           size={16}/>&nbsp;&nbsp; </>} Confirm
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal>

            <Container fluid className="mt-3 pt-5">
                <Row>
                    <Col>
                        <Link to="/user-project" className="color-secondary text-decoration-none mx-5">
                            <BsChevronLeft color="#000000"/>&nbsp; Back
                        </Link>
                    </Col>
                </Row>
            </Container>
            <Container className=" pt-3 pb-5">
                <Row>
                    <Card>
                        <Card.Body>
                            <Container className="">
                                <Row className="mb-2 fw-bold h3">Summary</Row>
                                <Row>
                                    {loading &&
                                    <div className="skeleton-m-p"><Skeleton width="100%" height={100}/></div>}
                                    {!loading && <Card>
                                        <Card.Body>
                                            <Container>
                                                <Row> <Col className=""><b>{data?.title}</b> </Col> </Row>
                                                <Row>
                                                    <Col xl={10} lg={10} className="color-secondary">
                                                        <BsClock/> &nbsp; {get_date_formatted(data?.created_at || data?.updated_at || null, 'date-time')}
                                                    </Col>
                                                    <Col xl={2} lg={2}
                                                         className="color-secondary text-end"> {getFirstWordCap(data?.status)} </Col>
                                                </Row>
                                            </Container>
                                        </Card.Body>
                                    </Card>}
                                </Row>
                                <Row className="mb-2 mt-4 fw-bold">{selectedIdea}</Row>
                                {loading ? <div className="skeleton-m-p"><Skeleton width="100%" height={70}/></div> : <>
                                    <Row>
                                        {data && data?.startup_ideas && Array.isArray(data?.startup_ideas) && data?.startup_ideas.map((startupIdea) => {
                                            return (
                                                <Card key={startupIdea?.token} className="mt-2">
                                                    <Card.Body>
                                                        <Container>
                                                            <Row>
                                                                <Col xs={10}><b>{startupIdea?.name}</b></Col>
                                                                <Col xs={2} className="text-end">
                                                                    <BsPencilFill
                                                                        style={{transition: "transform 0.3s"}}
                                                                        onClick={(e) => navigate("/startup-idea-update/" + startupIdea?.ref_id)}
                                                                        className="pointer" color="var(--secondary)"
                                                                        size={20}
                                                                        onMouseOver={(e) => (e.currentTarget.style.transform = "translateY(-3px)")}
                                                                        onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")}/>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </Card.Body>
                                                </Card>
                                            );
                                        })}
                                    </Row>
                                </>
                                }
                                <Row className="mb-2 mt-4 fw-bold">{selectServices}</Row>

                                {loading ? (
                                    <div className="skeleton-m-p"><Skeleton width="100%" height={200}/></div>
                                ) : (
                                    <>
                                        {data?.services && Array.isArray(data?.services) && data?.services.map((data, index) => {
                                            let amount = (data?.total_value_user_country || 0);
                                            let currency = data?.factor_value_user_country_currency || "USD";

                                            return (
                                                <Row key={data?.token || index} className="p-0 mt-3">
                                                    <Card className="p-2">
                                                        <Card.Body className="p-0">
                                                            <Container>
                                                                <Row className="p-0">
                                                                    <Col lg={1} md={2}
                                                                         className=" text-md-start text-center ">
                                                                        <Container className="p-0">
                                                                            <Row className="p-0">
                                                                                <Col md={0} xs={3}
                                                                                     className="p-0 d-md-none d-block"></Col>
                                                                                <Col md={12} xs={6}
                                                                                     className="ps-0 pe-0">
                                                                                    <img className="rounded" alt=""
                                                                                         style={{
                                                                                             width: "100%",
                                                                                             height: "100%"
                                                                                         }}
                                                                                         src={data?.url_image || app}
                                                                                    />
                                                                                </Col>
                                                                                <Col md={0} xs={3}
                                                                                     className="p-0 d-sm-none d-block"></Col>
                                                                            </Row>
                                                                        </Container>
                                                                    </Col>

                                                                    <Col lg={11} md={10}>
                                                                        <Container className="">
                                                                            <Row className="p-0">
                                                                                <Col sm={6} xs={12}
                                                                                     className="fw-bold ps-0 text-sm-start text-center">
                                                                                    {data?.name}
                                                                                </Col>
                                                                                <Col sm={6} xs={12}
                                                                                     className="text-sm-end text-center fw-bold ">
                                                                                    {amount && amount !== "0.00" ? amountFormate(amount) + " " + currency : "FREE"}
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col
                                                                                    className="ps-0 fontFamily text-sm-start text-center text-primary">
                                                                                    <i>(Include all services)</i>
                                                                                </Col>
                                                                            </Row>

                                                                            {data?.child_services.map((child_services_data, index) => {
                                                                                    let amount = (child_services_data?.total_value_user_country || 0);
                                                                                    let currency = child_services_data?.factor_value_user_country_currency || "USD";

                                                                                    return (<Row
                                                                                        key={child_services_data?.token || index}
                                                                                        className="mt-1 pt-1 pb-1 ps-2 pe-1 border rounded ">
                                                                                        <Col sm={8} xs={12}
                                                                                             className="ps-0 ">
                                                                                            <div className="d-flex">
                                                                                                <small>
                                                                                                    {child_services_data?.name} (<small>{cropText(child_services_data?.plan?.title, 70)}</small>)
                                                                                                </small>
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col sm={4} xs={12}
                                                                                             className="justify-content-end d-flex">
                                                                                            <div>
                                                                                                <small>{amount && amount !== "0.00" ? amountFormate(amount) + " " + currency : "FREE"}</small>
                                                                                            </div>
                                                                                            <div
                                                                                                style={{transition: "transform 0.3s"}}
                                                                                                onMouseOver={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
                                                                                                onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")}
                                                                                                onClick={() => {
                                                                                                    ChangePlan(
                                                                                                        child_services_data?.plans,
                                                                                                        child_services_data?.plan,
                                                                                                        child_services_data?.token,
                                                                                                        child_services_data?.name,
                                                                                                        child_services_data?.url_image
                                                                                                    );
                                                                                                }}
                                                                                                className="text-decoration-none color-secondary pointer">&nbsp;
                                                                                                <FaEdit/></div>
                                                                                        </Col>
                                                                                    </Row>);
                                                                                }
                                                                            )}
                                                                        </Container>
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </Card.Body>
                                                    </Card>
                                                </Row>);
                                        })}
                                    </>
                                )}
                                <Row className="mb-2 mt-4 fw-bold"><Col>Payment Summary</Col></Row>
                                {loading && <div className="skeleton-m-p"><Skeleton width="100%" height={150}/></div>}

                                {!loading && <Row>
                                    <Card>
                                        <Card.Body>
                                            <Container>
                                                <Row className="mb-2">
                                                    <Col className="">Total Service Cost: </Col>
                                                    {loading ?
                                                        <Skeleton className="rounded" variant="rectangle" width={100}
                                                                  height={20}/>
                                                        : <Col className="text-end">
                                                            <b className="fw-bold">{amountFormate(data?.amount_total_user_country)}&nbsp;{data?.amount_currency_user_country}</b>
                                                        </Col>}
                                                </Row>
                                                <Row className="mb-2">
                                                    <Col>Tax Amount : </Col>
                                                    {loading ?
                                                        <Skeleton className="rounded" ariant="rectangle" width={100}
                                                                  height={20}/> :
                                                        <Col
                                                            className="text-end text-danger fw-bold ">+ {amountFormate(data?.amount_total_tax_user_country)} {data?.amount_currency_user_country}</Col>
                                                    }

                                                </Row>
                                                <hr/>
                                                <Row className="mb-2">
                                                    <Col>Payable Amount : </Col>
                                                    {loading ?
                                                        <Skeleton className="rounded" ariant="rectangle" width={100}
                                                                  height={20}/> :
                                                        <Col
                                                            className="fw-bold text-end">{amountFormate(data?.amount_total_payable_user_country)} {data?.amount_currency_user_country}</Col>
                                                    }

                                                </Row>
                                                <Row className=" mb-2">
                                                    <Col>Paid Amount : </Col>
                                                    {loading ?
                                                        <Skeleton className="rounded" variant="rectangle" width={100}
                                                                  height={20}/> :
                                                        <Col
                                                            className="fw-bold text-end text-success">- {amountFormate(data?.amount_total_paid_user_country)} {data?.amount_currency_user_country}</Col>
                                                    }
                                                </Row>
                                                <hr/>
                                                <Row className="">
                                                    <Col>Pending Amount : </Col>
                                                    {loading ?
                                                        <Skeleton className="rounded" variant="rectangle" width={100}
                                                                  height={20}/> :
                                                        <Col
                                                            className="fw-bold text-end text-primary">{amountFormate(data?.amount_total_pending_user_country)} {data?.amount_currency_user_country}</Col>
                                                    }
                                                </Row>
                                            </Container>
                                        </Card.Body>
                                    </Card>

                                </Row>}

                                <Row className="mb-2 mt-4 fw-bold">{servicesDuration}</Row>
                                {loading && <div className="skeleton-m-p"><Skeleton width="100%" height={50}/></div>}
                                {!loading && <Row>
                                    <Card>
                                        <Card.Body>
                                            <Container>
                                                <Row className="fontFamily">
                                                    <i><b>This work order includes 1 year maintenance and
                                                        support.</b></i>
                                                </Row>
                                            </Container>
                                        </Card.Body>
                                    </Card>
                                </Row>}

                                {!loading && <Row className="mb-2 mt-4">
                                    <Container>

                                        {(data?.status === 'paid' || data?.status === 'completed') && (!data?.amount_total_pending || data?.amount_total_pending === "0.00") ? (
                                            <h6 className={"text-success"}>Your payments successfully
                                                completed</h6>) : (
                                            <> <Row> <Col> <Container>
                                                <Row>Pending Amount (Payable)</Row>
                                                <Row className="">
                                                    {loading ? (
                                                        <Skeleton width={100} height={20} className="rectangle mt-2"
                                                                  variant="rounded"/>
                                                    ) : (
                                                        <>
                                                            <Col md={12} className="" style={styleText}>
                                                                <b><small>{amountFormate(data?.amount_total_pending_user_country)}&nbsp;{data?.amount_currency_user_country} approx.</small></b>
                                                            </Col>
                                                            <Col md={12} className="" style={styleText}>
                                                                <b><small>{amountFormate(data?.amount_total_pending)}&nbsp;{data?.amount_currency}</small></b>
                                                            </Col>
                                                        </>
                                                    )}
                                                </Row>
                                            </Container> </Col>
                                                <Col className="mt-3 text-end">
                                                    <button
                                                        onClick={handleSubmitPayment}
                                                        className={`slide-button1 fontFamily text-decoration-none ${isHovered ? "hovered" : ""
                                                        }`}
                                                        onMouseEnter={() => setIsHovered(true)}
                                                        onMouseLeave={() => setIsHovered(false)}>
                                                        Pay Now
                                                    </button>
                                                </Col>
                                            </Row>
                                                <div>
                                                    <b style={{fontSize: "12px", color: "red"}}>(You save 18% on market
                                                        price in this order.)</b>
                                                </div>
                                            </>
                                        )}
                                    </Container>
                                </Row>}
                            </Container>
                        </Card.Body>
                    </Card>
                </Row>
            </Container>
        </div>
    );
};
export default UserSummary;

