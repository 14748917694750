import React from 'react'
import { Skeleton } from "@mui/material";
import { Card, Col, Row } from "react-bootstrap";
import { useMediaQuery } from "@mui/material";
export default function SkeletonBuisnessplanChild() {
  const isXSmallScreen = useMediaQuery("(max-width:400px)");
  const isSmallScreen = useMediaQuery(
    "(min-width:401px) and (max-width:600px)"
  );
  const isMediumScreen = useMediaQuery(
    "(min-width:601px) and (max-width:960px)"
  );
  const isLargeScreen = useMediaQuery(
    "(min-width:961px) and (max-width:1280px)"
  );
  const isXLargeScreen = useMediaQuery("(min-width:1281px)");
  return (
    <>
      <Col xs={12} md={6} lg={6} xl={4} className="mt-3 mb-4">
        <Card>
          <Card.Body>
            <Skeleton
              variant="rectangle"
              className="rounded"
              width={
                isXSmallScreen
                  ? 150
                  : isSmallScreen
                    ? 180
                    : isMediumScreen
                      ? 200
                      : isLargeScreen
                        ? 220
                        : isXLargeScreen
                          ? 250
                          : 300
              }
              height={30}
            />
            <Skeleton
              variant="rectangle"
              className="rounded mt-3"
              width={
                isXSmallScreen
                  ? 200
                  : isSmallScreen
                    ? 250
                    : isMediumScreen
                      ? 300
                      : isLargeScreen
                        ? 270
                        : isXLargeScreen
                          ? 323
                          : 380
              }
              height={20}
            />
            <Row>
              <Col md={9} sm={9} xs={8}>
                <Skeleton
                  className="mt-3 rounded"
                  variant="rectangle"
                  height={30}
                  width={
                    isXSmallScreen
                      ? 80
                      : isSmallScreen
                        ? 100
                        : isMediumScreen
                          ? 120
                          : isLargeScreen
                            ? 140
                            : 160
                  }
                />
              </Col>
              <Col md={3} sm={3} xs={4}>
                <Skeleton
                  className="mt-3 rounded"
                  variant="rectangle"
                  height={30}
                  width={
                    isXSmallScreen
                      ? 60
                      : isSmallScreen
                        ? 100
                        : isMediumScreen
                          ? 60
                          : isLargeScreen
                            ? 65
                            : isXLargeScreen
                              ? 70
                              : 80
                  }
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>

      <Col xs={12} md={6} lg={6} xl={4} className="mt-3 mb-4">
        <Card>
          <Card.Body>
            <Skeleton
              variant="rectangle"
              className="rounded"
              width={
                isXSmallScreen
                  ? 150
                  : isSmallScreen
                    ? 180
                    : isMediumScreen
                      ? 200
                      : isLargeScreen
                        ? 220
                        : isXLargeScreen
                          ? 250
                          : 300
              }
              height={30}
            />
            <Skeleton
              variant="rectangle"
              className="rounded mt-3"
              width={
                isXSmallScreen
                  ? 200
                  : isSmallScreen
                    ? 250
                    : isMediumScreen
                      ? 300
                      : isLargeScreen
                        ? 270
                        : isXLargeScreen
                          ? 323
                          : 380
              }
              height={20}
            />
            <Row>
              <Col md={9} sm={9} xs={8}>
                <Skeleton
                  className="mt-3 rounded"
                  variant="rectangle"
                  height={30}
                  width={
                    isXSmallScreen
                      ? 80
                      : isSmallScreen
                        ? 100
                        : isMediumScreen
                          ? 120
                          : isLargeScreen
                            ? 140
                            : 160
                  }
                />
              </Col>
              <Col md={3} sm={3} xs={4}>
                <Skeleton
                  className="mt-3 rounded"
                  variant="rectangle"
                  height={30}
                  width={
                    isXSmallScreen
                      ? 60
                      : isSmallScreen
                        ? 100
                        : isMediumScreen
                          ? 60
                          : isLargeScreen
                            ? 65
                            : isXLargeScreen
                              ? 70
                              : 80
                  }
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>

      <Col xs={12} md={6} lg={6} xl={4} className="mt-3 mb-4">
        <Card>
          <Card.Body>
            <Skeleton
              variant="rectangle"
              className="rounded"
              width={
                isXSmallScreen
                  ? 150
                  : isSmallScreen
                    ? 180
                    : isMediumScreen
                      ? 200
                      : isLargeScreen
                        ? 220
                        : isXLargeScreen
                          ? 250
                          : 300
              }
              height={30}
            />
            <Skeleton
              variant="rectangle"
              className="rounded mt-3"
              width={
                isXSmallScreen
                  ? 200
                  : isSmallScreen
                    ? 250
                    : isMediumScreen
                      ? 300
                      : isLargeScreen
                        ? 270
                        : isXLargeScreen
                          ? 323
                          : 380
              }
              height={20}
            />
            <Row>
              <Col md={9} sm={9} xs={8}>
                <Skeleton
                  className="mt-3 rounded"
                  variant="rectangle"
                  height={30}
                  width={
                    isXSmallScreen
                      ? 80
                      : isSmallScreen
                        ? 100
                        : isMediumScreen
                          ? 120
                          : isLargeScreen
                            ? 140
                            : 160
                  }
                />
              </Col>
              <Col md={3} sm={3} xs={4}>
                <Skeleton
                  className="mt-3 rounded"
                  variant="rectangle"
                  height={30}
                  width={
                    isXSmallScreen
                      ? 60
                      : isSmallScreen
                        ? 100
                        : isMediumScreen
                          ? 60
                          : isLargeScreen
                            ? 65
                            : isXLargeScreen
                              ? 70
                              : 80
                  }
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </>


  )
}
