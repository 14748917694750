import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { apiHepingMethod, getDataByUrl } from "../../API/HelpingMethod";
import { useNotification } from "../../../hooks";

const StartupSetPassword = ({ setActiveStep, handleBack, setBodyData, bodyData }) => {
    const [btnLoading, setBtnLoading] = useState(false);
    const { showError, showSuccess, setOpenBackDrop } = useNotification();
    const [prefrence, setPrefrence] = useState([]);
    const navigate = useNavigate();

    const handlechange = (e) => {
        const { name, value } = e.target;
        setBodyData({ ...bodyData, [name]: value });
    }

    async function getPrefrence() {
        setOpenBackDrop(true);
        let response = await getDataByUrl("user_preference", {
            limit: 0,
            offset: 0
        })  
        if (response.error === false) {
            setPrefrence(response?.data || []);
        } else {
            setPrefrence([]);
        }
        setOpenBackDrop(false);

    }
    useEffect(() => {
        getPrefrence().then((e) => e);
    }, []);

    const handlechangePrefrence = (e, data) => {
        setBodyData({ ...bodyData, token_user_preference: data?.token, name_user_preference: data?.name })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!bodyData?.password) {
            showError("Please Enter Password")
        } else if (!bodyData?.confirm_password) {
            showError("Please Confirm Password")
        }
        else if (bodyData?.confirm_password !== bodyData?.password) {
            showError("your password does not match ")
        }
        else {
            setBtnLoading(true);
            const response = await apiHepingMethod("user_profile_update", bodyData)
            console.log("response---", response)
            if (response?.data?.error === false) {
                showSuccess(response?.data?.message);
                localStorage.setItem("userstorage", JSON.stringify(response?.data?.data));
                setActiveStep(4)
                window.location.reload();
                navigate("/");
                
            } else {
                showError(response?.data?.message);
            }
        }
        setBtnLoading(false);

    }

    return (
        <>
            <Container>
                <Row xs={{ cols: 1 }}>
                    <Col className="text-center mb-3 mt-4 pt-2 fw-bold"><span>Create a Password</span> </Col>
                    <Col className=" h6 mt-3"> <span>Select Your Prefrence</span> </Col>
                    <Col className="mt-1 mx-auto startup-register-autocomplete autocomplete-border">
                        <Autocomplete
                            disablePortal
                            disableClearable={false}
                            fullWidth
                            id="combo-box-demo"
                            onChange={handlechangePrefrence}
                            options={prefrence}
                            getOptionLabel={(option) => option?.name || bodyData?.name_user_preference}
                            value={(bodyData?.name_user_preference)}
                            renderInput={(params) => <TextField  {...params} label="Prefrence" />}
                        />
                    </Col> 
 
                    <Col className=" mt-3"><span className="h6">Password</span></Col>
                    <Col className="mt-1 mx-auto d-flex justify-content-center">
                        <Form.Control type="text"
                            className="idea-stepper-input"
                            name="password"
                            value={bodyData?.password}
                            onChange={handlechange} />
                    </Col>

                    <Col className=" mt-3"><span className="h6">Confirm Password</span></Col>
                    <Col className="mt-1 mx-auto d-flex justify-content-center">
                        <Form.Control type="text"
                            className="idea-stepper-input"
                            value={bodyData?.confirm_password}
                            name="confirm_password"
                            onChange={handlechange}
                        />
                    </Col>

                    <Col className="mt-3 mx-auto ">
                        By tapping Register, you are agree to our <Link className="color-secondary"> Privacy Policy </Link > and
                        <Link className="color-secondary"> Term and Conditions</Link >
                    </Col>

                    <Col className="d-flex justify-content-center mt-5">
                        <div className="me-2">
                            <button onClick={handleBack} className="input-primary-buttons" >
                                Back
                            </button>
                        </div>

                        <div className="ms-2">
                            <button disabled={btnLoading} className={btnLoading ? "disabled-btn" : "input-buttons"} onClick={handleSubmit}>
                                {btnLoading && <><CircularProgress color="inherit" className="mt-1" size={16} />&nbsp;</>}Submit
                            </button>
                        </div>
                    </Col>
                </Row>

            </Container>
        </>
    )
}
export default StartupSetPassword