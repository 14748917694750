// import React from 'react';
// import { Skeleton } from '@mui/material';
// import { Card, Col } from 'react-bootstrap';
// import { useMediaQuery } from '@mui/material';


// export default function SkeletonProjectIdea() {
//   // ... (previous useMediaQuery declarations)
//   const isXSmallScreen = useMediaQuery('(max-width: 600px)');
//   const isSmallScreen = useMediaQuery('(min-width: 601px) and (max-width: 960px)');
//   const isMediumScreen = useMediaQuery('(min-width: 961px) and (max-width: 1280px)');
//   const isLargeScreen = useMediaQuery('(min-width: 1281px)');
//   const isXLargeScreen = useMediaQuery('(min-width: 1920px)');
//   const renderSkeletonCols = (order) => {
//     const isEvenIteration = order % 2 === 0;
    
//     let titleDescriptionWidth;
//     let cardImageWidth;
//     let titleWidth;
//     // Set width for  description based on screen size
//     if (isXSmallScreen) titleDescriptionWidth = 200;
//     else if (isSmallScreen) titleDescriptionWidth = 320;
//     else if (isMediumScreen) titleDescriptionWidth = 500;
//     else if (isLargeScreen) titleDescriptionWidth = 800;
//     else if (isXLargeScreen) titleDescriptionWidth = 1000;
//     else titleDescriptionWidth = 850; // Default width

//     // Set width for card image based on screen size
//     if (isXSmallScreen) cardImageWidth = 160;
//     else if (isSmallScreen) cardImageWidth = 270;
//     else if (isMediumScreen) cardImageWidth = 300;
//     else if (isLargeScreen) cardImageWidth = 350;
//     else if (isXLargeScreen) cardImageWidth = 600;
//     else cardImageWidth = 500; // Default width


//         // Set width for title based on screen size
//         if (isXSmallScreen) titleWidth = 100;
//         else if (isSmallScreen) titleWidth = 200;
//         else if (isMediumScreen) titleWidth = 300;
//         else if (isLargeScreen) titleWidth = 400;
//         else if (isXLargeScreen) titleWidth = 1000;
//         else titleWidth = 850; // Default width for title
//     return (
//       <>
//         {isEvenIteration ? (
//           <>
//             <Col xl={8} lg={7} md={6}  key={`title-description-${order}`}>
//               <Skeleton
//                 variant="rectangle"
//                 className="rounded"
//                 width={titleWidth}
//                 height={20}
//               />
//               <Skeleton
//                 variant="rectangle"
//                 className="rounded mt-3"
//                 width={titleDescriptionWidth}
//                 height={20}
//               />
//               <Skeleton
//                 variant="rectangle"
//                 className="rounded mt-3"
//                 width={titleDescriptionWidth}
//                 height={20}
//               />
//             </Col>
//             <Col sm={4} xs={12} xl={4} lg={5} md={6} className="mb-3 mb-md-0">
//               <Card className="shadow w-100">
//                 <Card.Body>
//                   <Skeleton
//                     variant="rectangle"
//                     className="rounded"
//                     width={cardImageWidth}
//                     height={250}
//                   />
//                 </Card.Body>
//               </Card>
//             </Col>
//           </>
//         ) : (
//           <>
//             <Col sm={4} xs={12} xl={4} lg={6} md={6} className="mb-3 mb-md-0">
//               <Card className="shadow w-100">
//                 <Card.Body>
//                   <Skeleton
//                     variant="rectangle"
//                     className="rounded"
//                     width={cardImageWidth}
//                     height={250}
//                   />
//                 </Card.Body>
//               </Card>
//             </Col>
//             <Col xl={8} lg={6} md={6} key={`title-description-${order}`}>
//               <Skeleton
//                 variant="rectangle"
//                 className="rounded"
//                 width={titleWidth}
//                 height={20}
//               />
//               <Skeleton
//                 variant="rectangle"
//                 className="rounded mt-3"
//                 width={titleDescriptionWidth}
//                 height={20}
//               />
//               <Skeleton
//                 variant="rectangle"
//                 className="rounded mt-3"
//                 width={titleDescriptionWidth}
//                 height={20}
//               />
//             </Col>
//           </>
//         )}
//       </>
//     );
//   };

//   const renderSkeletonIterations = () => {
//     const iterations = 8;
//     const result = [];

//     for (let i = 1; i <= iterations; i++) {
//       result.push(renderSkeletonCols(i));
//     }

//     return result;
//   };

//   return <>{renderSkeletonIterations()}</>;
// }


import React from 'react';
import { Skeleton } from '@mui/material';
import { Card, Col } from 'react-bootstrap';

export default function SkeletonProjectIdea({ titleDescriptionWidth, cardImageWidth, titleWidth }) {
  const renderSkeletonCols = (order) => {
    const isEvenIteration = order % 2 === 0;

    return (
      <>
        {isEvenIteration ? (
          <>
            <Col xl={8} lg={7} md={6} key={`title-description-${order}`}>
              <Skeleton
                variant="rectangle"
                className="rounded"
                width={300}
                height={20}
              />
              <Skeleton
                variant="rectangle"
                className="rounded mt-3"
                width={titleDescriptionWidth}
                height={20}
              />
              <Skeleton
                variant="rectangle"
                className="rounded mt-3"
                width={titleDescriptionWidth}
                height={20}
              />
            </Col>
            <Col sm={12} xs={12} xl={4} lg={5} md={6} className="mb-3 mb-md-0">
              <Card className="shadow w-100 border-0">
                <Card.Body>
                  <Skeleton
                    variant="rectangle"
                    className="rounded"
                    width={cardImageWidth}
                    height={250}
                  />
                </Card.Body>
              </Card>
            </Col>
          </>
        ) : (
          <>
            <Col sm={12} xs={12} xl={4} lg={6} md={6} className="mb-3 mb-md-0">
              <Card className="shadow border-0 w-100">
                <Card.Body>
                  <Skeleton
                    variant="rectangle"
                    className="rounded"
                    width={cardImageWidth}
                    height={250}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col xl={8} lg={6} md={6} key={`title-description-${order}`}>
              <Skeleton
                variant="rectangle"
                className="rounded"
                width={300}
                height={20}
              />
              <Skeleton
                variant="rectangle"
                className="rounded mt-3"
                width={titleDescriptionWidth}
                height={20}
              />
              <Skeleton
                variant="rectangle"
                className="rounded mt-3"
                width={titleDescriptionWidth}
                height={20}
              />
            </Col>
          </>
        )}
      </>
    );
  };

  const renderSkeletonIterations = () => {
    const iterations = 8;
    const result = [];

    for (let i = 1; i <= iterations; i++) {
      result.push(renderSkeletonCols(i));
    }

    return result;
  };

  return <>{renderSkeletonIterations()}</>;
}









