import { Avatar, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import user from "../images/Frame 427319638user_profile.png";
import { LuUsers } from "react-icons/lu";
import { SlPencil } from "react-icons/sl";
import { RiLockPasswordLine } from "react-icons/ri";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "../css/Logout.css";
import { TbLogout2 } from "react-icons/tb";
import ProfileNav from "./ProfileNav";

function ChangePasswordSidebar() {
    const [isHovered, setIsHovered] = useState(false);
    const [isFixed, setIsFixed] = useState(true);
    const isSmallScreen = useMediaQuery("(max-width: 960px)");

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            const threshold = 400;
            setIsFixed(offset < threshold && !isSmallScreen);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isSmallScreen]);


    const navigate = useNavigate();
    const Logout = () => {
        localStorage.removeItem("userstorage");
        navigate("/verify");
    };
    const userName = "@userProfile";
    const styleList = {
        listStyle: "none",
        padding: "0",
    };
    const profileItems = [
        {
            to: "/profile",
            icon: <LuUsers />,
            text: "User Profile",
        },
        {
            to: "/notification",
            icon: <LuUsers />,
            text: "Notification",
        },
        {
            to: "/profile-update",
            icon: <SlPencil />,
            text: "Update Profile",
        },
        {
            to: "/upload-picture",
            icon: <SlPencil />,
            text: "Upload Picture",
        },
        {
            to: "/change-password",
            icon: <RiLockPasswordLine />,
            text: "Change Password",
        },
    ];
    const styleImage = { width: "150px", height: "150px" };
    const styleFont = {
        fontFamily: "Montserrat",
        whiteSpace: "nowrap",
        overflow: "hidden",
    };
    const styleBorder = {
        border: "1px solid var(--primary)",
        // paddingBottom:"140px",

    };

    var loginuser = JSON.parse(localStorage.getItem("userstorage"));
    const styleHeight = {
        height: "100vh",
    };

    return (
        <Container className=" " >
            <Card className="change-password-sidebar" style={styleBorder}>
                <Card.Body>
                    <Row className="text-center d-flex justify-content-center">
                        <Col className="d-flex justify-content-center">
                            {loginuser ? (
                                <Avatar
                                    className="mt-5"
                                    style={styleImage}
                                    src={loginuser?.url_profile}
                                />
                            ) : (
                                <Avatar className="mt-5" src={user} />
                            )}
                        </Col>
                        <h6 className="fw-bold mt-3 fontFamily">{loginuser?.name || ""}</h6>
                    </Row>

                    <ProfileNav />
                    {/* <Row>
                        <Col >
                            <Container className="change-password-logout-btn" >
                                <Row >
                                    <Col className="mt-auto d-flex align-items-end justify-content-center">
                                        <Link
                                            style={styleFont}
                                            to="/verify"
                                            onClick={Logout}
                                            className={`slide-button2 text-decoration-none text-center custom-col ${isHovered ? "hovered" : ""
                                                }`}
                                            onMouseEnter={() => setIsHovered(true)}
                                            onMouseLeave={() => setIsHovered(false)}
                                        >
                                            <TbLogout2 /> Logout
                                        </Link>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row> */}

                </Card.Body>
            </Card>
        </Container>
    );
}

export default ChangePasswordSidebar;
