import React, {useState, useEffect} from "react";
import {Container, Row, Col } from "react-bootstrap";
 
import VerifyUsernameForm from "./VerifyUsernameForm";

export default function VerifyUsername() {
    return ( 
        <div>
            <Container fluid className="forms-container">
                <Row>
                    <Col lg={6} md={6} className=" border p-0">
                        <div className="login-image-container"></div>
                        {/* <img className="login-image-container"  src={registerimg}/> */}
                    </Col>
                    <Col  lg={6} md={6}  className="form-box d-flex align-item-center justify-content-center ">
                        <VerifyUsernameForm/>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}