import React, { useState, useEffect } from "react";
import { Col, Row, Container, Card } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import price from "../images/note-2price.png";
import { getDataByUrl } from "../API/HelpingMethod";
import SkeletonProjectideaCard from "../Homepage/Skeleton-files/SkeletonProjectideaCard";
import logo from "../../assets/logos/favicon.webp";
import { IoSettingsOutline } from "react-icons/io5";
import { useMediaQuery } from "@mui/material";
import image from "../images/Looper-2 (1).png";
import noimage from "../images/noimage2.webp"

import { BsChevronRight } from "react-icons/bs";
const ProjectIdeasCard = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [projectToken, setProjectToken] = useState([]);
  var loginuser = JSON.parse(localStorage.getItem("userstorage"));

  const apicall = async () => {
    const inputData = {
      login_user_type: loginuser?.user_type || "startup",
      // login_user_type: "startup",
      login_user_id: loginuser?.id,
      limit: 10,
      offset: 0,
    };
    setLoading(true);
    const response = await getDataByUrl("user_work_group", inputData);
    console.log(response);
    if (!response?.error) {
      setData(response?.data);
    } else {
      // console.log("Error fetching data:", response?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    apicall();
  }, []);
  const styleCards = { height: "6rem", width: "6rem" };
  const navigate = useNavigate();
  
  const handleClick = async (token, status) => {

    console.log("token----", token)
    console.log("status----", status)



    if (status === "incomplete") {
      navigate(`/select-service/${token}`);
    } else if (status === "requested") {
      navigate(`/selected-summary/${token}`);
    } else {
      navigate(`/user-project/${token}`);
    }
  };

  const styleImage = { height: "20px", width: "20px" };

  const styleCol = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textDecoration: "none",
  };
  const breadcrumbItems = [
    { to: "/", label: "Home" },
    { label: "Project and Ideas" },
  ];
  const services = "Services";
  const isXSmallScreen = useMediaQuery("(max-width: 600px)");
  const isSmallScreen = useMediaQuery(
    "(min-width: 601px) and (max-width: 960px)"
  );
  const isMediumScreen = useMediaQuery(
    "(min-width: 961px) and (max-width: 1280px)"
  );
  const isLargeScreen = useMediaQuery("(min-width: 1281px)");
  const isXLargeScreen = useMediaQuery("(min-width: 1920px)");
  return (
    <div>
      <Container className="pt-5 ">

        <img id="Servicebgimage" src={image} alt="img" onError={(e) => e.target.src = noimage} />
        <Row className="mt-3 mb-4">
          <Col className="ms-3">
            <Link to="/" className="text-decoration-none color-secondary">Home</Link>&nbsp;<BsChevronRight />&nbsp;<span>User Project</span>
          </Col>
        </Row>

        <Row> <Col className="h4 ms-3 fw-bold">User Projects (Work Group)</Col></Row>

        <Row className="g-3 mb-5 mt-2">
          {loading ? (
            <SkeletonProjectideaCard
              isXSmallScreen={isXSmallScreen}
              isSmallScreen={isSmallScreen}
              isMediumScreen={isMediumScreen}
              isLargeScreen={isLargeScreen}
              isXLargeScreen={isXLargeScreen}
            />
          ) : (
            <>
              {data?.map((projectItem, ind) => {
                return (
                  <Col xl={6} lg={6} md={6} sm={12} xs={12} key={ind}>
                    <div
                      onClick={() => handleClick(projectItem?.token, projectItem?.status)}
                      className="text-decoration-none"
                    >
                      <Card className="shadow " id="service">
                        <Row className="d-flex align-items-center">
                          <Col xl={3} lg={3} md={3} sm={3} xs={3} className="">
                            <img variant="top" onError={(e) => e.target.src = noimage} src={projectItem?.url_image || logo} className="p-2 rounded-4" style={styleCards} alt={"img"} />
                          </Col>
                          <Col xl={9} lg={9} md={9} sm={9} xs={9}>
                            <Card.Body>
                              <Card.Subtitle className="fw-bold" style={{ fontFamily: "sans-serif" }}>
                                {projectItem?.title}
                              </Card.Subtitle>
                              <Row className={"mt-1"}>
                                <Col lg={5} xl={5} md={5} xs={5} sm={5} className="card.text" >
                                  <img src={price} className="me-1" style={styleImage} alt={"i"} />
                                  <span> {projectItem?.count_startup_idea || 0} Idea</span>
                                </Col>
                                <Col xs={7} sm={7} lg={7} md={7} xl={7} className="card.text" style={styleCol} >
                                  <IoSettingsOutline className="color-secondary" /> <span>{projectItem?.count_service || 0} Services </span>
                                </Col>
                                <Col md={3} sm={3} />
                              </Row>
                            </Card.Body>
                          </Col>
                        </Row>
                      </Card>
                    </div>
                  </Col>
                );
              })}
            </>
          )}
        </Row>
      </Container>
    </div>
  );
};
export default ProjectIdeasCard;
