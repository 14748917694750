import { React, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { BsEyeFill, BsEyeSlashFill, BsPencilSquare, BsXLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { logo } from "../Context/HelpingTool";
import { useNotification } from "../hooks";
import { ApiCallModal } from "../components/API/HelpingMethod";
import { isAppOTPShow } from "../components/API/client";

const LoginModal = () => {

    const [loading, setLoading] = useState(false);
    const [otpLoading, setOtpLoading] = useState(false);

    const [passwordType, setPasswordType] = useState('password')
    const navigate = useNavigate();
    const [sendOtp, setSendOtp] = useState(false)

    const { showError, showSuccess, setLoginModal } = useNotification();
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [LoginStep, setLoginStep] = useState("verify_user")
    const [isOtp, setIsOtp] = useState(false)
    const [otpButton, setOtpButton] = useState('Get Otp')
    const [otpInput, setOtpInput] = useState(false)

    const [bodydata, setBodydata] = useState({
        device_token: localStorage.getItem('device_token') || "ABCDEFGHIJK",
        username: "",
        email: "",
        mobile: ""
    })

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text");
            return;
        }
        setPasswordType("password");
    };
    const toggleOpen = () => {
        setLoginModal(false)
        setLoginStep('verify_user')
        setSendOtp(false)
        setIsOtp(false)
        bodydata.mobile = ''
        bodydata.username = ''
        bodydata.email = ''
        delete bodydata.otp
    };

    const handleChange = (e) => {

        const { name, value } = e.target
        setBodydata({ ...bodydata, [name]: value })
    }

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault()
        if (bodydata.password) {
            bodydata.password = bodydata.password
        }
        if (LoginStep === 'user_login') {
            bodydata.device_latitude = '72.02'
            bodydata.device_longitude = '23.02'
        }


        const response = await ApiCallModal(LoginStep, bodydata);

        console.log("response--", response)

        if (response.error === false && response.data && LoginStep === 'verify_user') {
            setLoginStep("user_login");
            setLoading(false);
        }
        else if (response.error === true && LoginStep === 'verify_user') {
            console.log("sssss--", response?.data) 
            getOtp(e)
        } if (response.error === false && LoginStep === 'user_login') {
            showSuccess(response.message)
            setLoginModal(false)
            localStorage.setItem("userstorage", JSON.stringify(response?.data));
            window.location.reload();
            setLoading(false);
            
        } else if (response.error === true && LoginStep === 'user_login') {
            showError(response.message);
            setLoading(false);

        }

        // setLoading(false);
    }


    const getOtp = async (e) => {
        setLoading(true);
        setOtpLoading(true);

        e.preventDefault()
        const response = await ApiCallModal("verify_user_by_otp", bodydata);
        console.log("otp--", response)

        if (response?.error === false && response?.data?.otp && !bodydata.otp) {
            setLoading(false);
            setIsOtp(true)
            setMinutes(5)
            setSeconds(0)
            if (isAppOTPShow) {
                bodydata.otp = response?.data?.otp
            }
            setOtpInput(true);
            setOtpButton('Verify Otp')

        } else if (response?.error === false && response?.data?.user === null && bodydata.otp) {
            navigate("/register");

            if (response?.data?.type === "mobile") {
                localStorage.setItem("user-register", JSON.stringify({
                    mobile: bodydata.username || ""
                }));
            } else {
                localStorage.setItem("user-register", JSON.stringify({
                    email: bodydata.username || ""
                }));
            }


            setLoginModal(false)
            setLoginStep('verify_user')
            setSendOtp(false)
            setIsOtp(false)
            bodydata.mobile = ''
            bodydata.username = ''
            bodydata.email = ''
            delete bodydata.otp

        }
        else if (response?.error === false && response?.data?.user?.id && bodydata.otp) {
            showSuccess(response.message)
            setLoginModal(false)
            localStorage.setItem("userstorage", JSON.stringify(response?.data?.user));
            window.location.reload();

        }
        else if (response?.error === true) {
            showError(response?.message)

        } else {
            showError(response?.message || "Error encounter")
        }
        setOtpLoading(false);

    }





    const ResendOtp = async (e) => {
        e.preventDefault()
        delete bodydata.otp
        const response = await ApiCallModal("verify_user_by_otp", bodydata, true);
        if (response.error === false && response?.data?.otp) {
            setIsOtp(true)
            setMinutes(5)
            setSeconds(0)
            if (isAppOTPShow) {
                bodydata.otp = response?.data?.otp
            }
            setOtpInput(true);
            setOtpButton('Verify Otp')
        }
        else {
            setOtpInput(false);
        }
    }


    const continueProcessNavigate = (e) => {
        e.preventDefault()
        setLoginModal(false)
        navigate("/login")
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - +1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds, minutes]);


    return (
        <>
            <Container>
                <Row>
                    <Col lg={6} xs={0} className="ps-0 pe-0 d-lg-flex d-none" style={{ height: "79vh" }}>
                        <div className="modal-img"> </div>

                    </Col>
                    <Col lg={6} xs={12} className=" " style={{ height: "79vh" }}>
                        <Row className="text-end">
                            <Col className="m-2 me-0">
                                <BsXLg className="close-button" size={30} onClick={toggleOpen} />
                            </Col>
                        </Row>
                        <Row className="text-center"><Col>
                            <img alt={'banner'} src={logo} className="img-fluid w-50" />
                        </Col></Row>
                        <Form
                        //  onSubmit={sendOtp ? registerNavigate : }
                        >

                            {isOtp === false && LoginStep === 'verify_user' &&
                                <Container className="mt-2">
                                    <Row className="mt-2">
                                        <Col className="text-center fw-bold h5">
                                            Verify your account.
                                        </Col>
                                    </Row>
                                    <Row className=" ">
                                        <Col className="text-center ">
                                            <small>Verify your email address or mobile number to login or
                                                register</small>
                                        </Col>
                                    </Row>
                                    <Row className="mt-5 ">
                                        <Col className="fw-bold">Mobile Number / Email Address</Col>
                                    </Row>
                                    <Row>
                                        <Col className="modal-input">
                                            <Form.Control value={bodydata?.username} onChange={handleChange}
                                                name="username"
                                                type="text"
                                                placeholder={"i.e. example@gmail.com or *******09"} />
                                        </Col>
                                    </Row>

                                    <Row className="mt-4 pb-4">
                                        <Col className="text-center ">
                                            <button onClick={handleSubmit}
                                                className={'input-buttons text-decoration-none mt-3 w-100 my-3'}
                                                disabled={loading}> {loading && <><CircularProgress color="inherit"
                                                    className="mt-1"
                                                    size={16} /> &nbsp;&nbsp;</>} Verify
                                            </button>
                                        </Col>
                                    </Row>

                                </Container>}

                            {isOtp === false && LoginStep === 'user_login' &&
                                <Container className="mt-3 ">
                                    <Row className="mt-2">
                                        <Col className="text-center fw-bold h5">
                                            Log/Sign in to your Account
                                        </Col>
                                    </Row>
                                    <Row className="">
                                        <Col className="text-center ">
                                            <small>Please enter your account password to login</small>
                                        </Col>
                                    </Row>
                                    <Row className="mt-5 ">
                                        <Col className="fw-bold">Mobile / Email Address</Col>
                                    </Row>
                                    <Row>
                                        <Col className="modal-input">
                                            <InputGroup className="mb-3 login-password-input">
                                                <Form.Control disabled onChange={handleChange}
                                                    value={bodydata?.username}
                                                    placeholder={"i.e. example@gmail.com or *******09"}
                                                    name="username" type="text" />
                                                <InputGroup.Text className="pointer" id="basic-addon1"
                                                    onClick={() => setLoginStep('verify_user')}><BsPencilSquare
                                                        className="color-secondary pointer" /></InputGroup.Text>
                                            </InputGroup>

                                        </Col>
                                    </Row>
                                    <Row className="mt-1 fw-bold">
                                        <Col className="">Password</Col>
                                    </Row>
                                    <Row>
                                        <Col className="modal-input">
                                            <InputGroup className="mb-3 login-password-input">
                                                <Form.Control onChange={handleChange} placeholder="password" name="password" type={passwordType} />
                                                <InputGroup.Text onClick={togglePassword} className="pointer"
                                                    id="basic-addon1">
                                                    {passwordType === "password" ? (
                                                        <BsEyeSlashFill className="color-secondary " />
                                                    ) : (
                                                        <BsEyeFill className="color-secondary " />
                                                    )}
                                                </InputGroup.Text>
                                            </InputGroup>

                                        </Col>
                                    </Row>

                                    <Row className="text-end">
                                        <Col className="text-end color-primary text-decoration-underline  "> <span
                                            onClick={(e) => getOtp(e)} className="pointer">Login With Otp</span> </Col>
                                    </Row>

                                    <Row className="mt-3 pb-1">
                                        <Col className="text-center ">
                                            <button onClick={handleSubmit} className={'input-buttons w-100'}
                                                disabled={loading}> {loading && <><CircularProgress color="inherit"
                                                    className="mt-1"
                                                    size={16} /> &nbsp;&nbsp;</>} Login
                                            </button>
                                        </Col>
                                    </Row>

                                </Container>}

                            {isOtp === true &&
                                <Container className="mt-3 ">
                                    <Row className="mt-2">
                                        <Col className="text-center fw-bold h5">
                                            Log/Sign in to your Account
                                        </Col>
                                    </Row>
                                    <Row className=" ">
                                        <Col className="text-center ">
                                            <small>Please enter OTP received on our mobile or email address to
                                                login</small>
                                        </Col>
                                    </Row>
                                    <Row className="mt-5 ">
                                        <Col className="fw-bold">Mobile / Email Address</Col>
                                    </Row>
                                    <Row>
                                        <Col className="modal-input">
                                            <InputGroup className="mb-3 login-password-input">
                                                <Form.Control disabled onChange={handleChange}
                                                    value={bodydata?.username}
                                                    placeholder={"i.e. example@gmail.com or *******09"}
                                                    name="username" type="text" />
                                                <InputGroup.Text className="pointer" id="basic-addon1"
                                                    onClick={() => {
                                                        setLoginStep('verify_user');
                                                        setOtpInput(false);
                                                    }}><BsPencilSquare
                                                        className="color-secondary pointer" /></InputGroup.Text>
                                            </InputGroup>

                                        </Col>
                                    </Row>
                                    {otpInput && <Row className="mt-2 fw-bold">
                                        <Col className="">OTP</Col>
                                    </Row>}
                                    {otpInput && <Row className={'mb-3'}>
                                        <Col xs={12} className="modal-input">
                                            <InputGroup className="mb-1 login-password-input">
                                                <Form.Control value={bodydata?.otp || ""}
                                                    placeholder="otp"
                                                    onChange={handleChange}
                                                    name="otp" type={passwordType} />
                                                <InputGroup.Text onClick={togglePassword} className="pointer">
                                                    {passwordType === "password" ?
                                                        <BsEyeSlashFill className="color-secondary fs-4" /> :
                                                        <BsEyeFill className="color-secondary fs-4" />}
                                                </InputGroup.Text>
                                            </InputGroup>

                                        </Col>
                                        {seconds === 0 && minutes === 0 ? <></> :
                                            <Col xs={12} className="text-end mt-0 ">
                                                <small>Resend Otp In 0{minutes + ":" + seconds}</small>
                                            </Col>}
                                        {otpButton === 'Get Otp' ? <></> : <>
                                            {seconds === 0 && minutes === 0 ?
                                                <Col xs={12} onClick={ResendOtp}
                                                    className="text-end mt-2 color-primary text-decoration-underline pointer ">
                                                    Resend Otp
                                                </Col>
                                                : <></>
                                            }
                                        </>
                                        }
                                    </Row>}




                                    <Row className="mt-4 pb-2">
                                        <Col className="text-center ">
                                            <button onClick={getOtp} className={'input-buttons w-100'}
                                                disabled={otpLoading}> {otpLoading && <><CircularProgress color="inherit"
                                                    className="mt-1"
                                                    size={16} /> &nbsp;&nbsp;</>} {otpButton}</button>
                                        </Col>
                                    </Row>
                                </Container>}
                        </Form>

                        {/*<p className={'text-center mb-2'}><small className="text-dark">
                            Your registration is not complete <br /> <b onClick={continueProcessNavigate}
                                className={"pointer color-primary"}>Click
                                Here</b> to resubmit

                        </small>
                        </p>*/}

                    </Col>
                </Row>
            </Container>

        </>
    )
}
export default LoginModal;