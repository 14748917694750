
import React from 'react';
import { Skeleton, useMediaQuery } from '@mui/material';
import { Card, Col } from 'react-bootstrap';

export default function SkeletonFavoriteService({ numberOfCols }) {
  const isXSmallScreen = useMediaQuery('(max-width:400px)');
  const isSmallScreen = useMediaQuery('(min-width:401px) and (max-width:600px)');
  const isMediumScreen = useMediaQuery('(min-width:601px) and (max-width:960px)');
  const isLargeScreen = useMediaQuery('(min-width:961px) and (max-width:1280px)');
  // const isXLargeScreen = useMediaQuery('(min-width:1281px)');

  const renderSkeletonCol = (index) => (
    <Col md={6} lg={6}  key={index}>
      <Card id="service" className="mt-4 shadow">
        <Card.Body className="">
          <Skeleton
            className="rounded"
            variant="rectangular"
            width={
              isXSmallScreen ? 30 : isSmallScreen ? 40 : isMediumScreen ? 50 : isLargeScreen ? 60 : 70
            }
            height={60}
          />
          <Skeleton
            height={30}
            width={isXSmallScreen ? 100 : isSmallScreen ? 150 : isMediumScreen ? 100 : isLargeScreen ? 100 : 250}
          />
          <Skeleton
            height={30}
            width={isXSmallScreen ? 200 : isSmallScreen ? 100 : isMediumScreen ? 250 : isLargeScreen ? 300 : 350}
          />
          <Skeleton
            height={30}
            width={isXSmallScreen ? 200 : isSmallScreen ? 100 : isMediumScreen ? 250 : isLargeScreen ? 300 : 350}
          />
          <Skeleton
            className="rounded mt-5"
            variant="rectangle"
            height={20}
            width={
              isXSmallScreen ? 50 : isSmallScreen ? 60 : isMediumScreen ? 80 : isLargeScreen ? 100 : 120
            }
          />
        </Card.Body>
      </Card>
    </Col>
  );

  return <>{Array(numberOfCols).fill().map((_, index) => renderSkeletonCol(index))}</>;
}





















// import React from 'react';
// import { Skeleton } from '@mui/material';
// import { Card, Col } from 'react-bootstrap';

// export default function SkeletonService() {
//   const numberOfCols = 4; // Change this to the desired number of columns

//   const generateSkeletonCols = () => {
//     const skeletonCols = [];
//     for (let i = 0; i < numberOfCols; i++) {
//       skeletonCols.push(
//         <Col md={6} key={i}>
//           <Card id="service" className="mt-4 shadow" style={{ borderRadius: '20px' }}>
//             <Card.Body className="">
//               <Skeleton className="rounded" variant="rectangular" width={60} height={60} />
//               <Skeleton height={30} width={200} />
//               <Skeleton height={30} width={600} />
//               <Skeleton height={30} width={600} />
//               <Skeleton className="rounded mt-5" variant="rectangle" height={20} width={100} />
//             </Card.Body>
//           </Card>
//         </Col>
//       );
//     }
//     return skeletonCols;
//   };

//   return <>{generateSkeletonCols()}</>;
// }




// import { Skeleton } from "@mui/material";
// import { Card, Col } from "react-bootstrap";

// export default function SkeletonService() {

//     return (
//         <>
//             <Col  md={6}>
//                 <Card id="service" className='mt-4 shadow' style={{ borderRadius: "20px" }}>
//                     <Card.Body className=''>
//                         <Skeleton className='rounded' variant="rectangular" width={60} height={60} />
//                         <Skeleton height={30} width={200} />
//                         <Skeleton height={30} width={600} />
//                         <Skeleton height={30} width={600} />
//                         <Skeleton className="rounded mt-5" variant="rectangle" height={20} width={100}/>
//                     </Card.Body>
//                 </Card>
//             </Col>
//             <Col  md={6}>
//                 <Card id="service" className='mt-4 shadow' style={{ borderRadius: "20px" }}>
//                     <Card.Body className=''>
//                         <Skeleton className='rounded' variant="rectangular" width={60} height={60} />
//                         <Skeleton height={30} width={200} />
//                         <Skeleton height={30} width={600} />
//                         <Skeleton height={30} width={600} />
//                         <Skeleton className="rounded mt-5" variant="rectangle" height={20} width={100}/>
//                     </Card.Body>
//                 </Card>
//             </Col>
//             <Col  md={6}>
//                 <Card id="service" className='mt-4 shadow' style={{ borderRadius: "20px" }}>
//                     <Card.Body className=''>
//                         <Skeleton className='rounded' variant="rectangular" width={60} height={60} />
//                         <Skeleton height={30} width={200} />
//                         <Skeleton height={30} width={600} />
//                         <Skeleton height={30} width={600} />
//                         <Skeleton className="rounded mt-5" variant="rectangle" height={20} width={100}/>
//                     </Card.Body>
//                 </Card>
//             </Col>
//             <Col  md={6}>
//                 <Card id="service" className='mt-4 shadow' style={{ borderRadius: "20px" }}>
//                     <Card.Body className=''>
//                         <Skeleton className='rounded' variant="rectangular" width={60} height={60} />
//                         <Skeleton height={30} width={200} />
//                         <Skeleton height={30} width={600} />
//                         <Skeleton height={30} width={600} />
//                         <Skeleton className="rounded mt-5" variant="rectangle" height={20} width={100}/>
//                     </Card.Body>
//                 </Card>
//             </Col>
         
//         </>
//     )
// }