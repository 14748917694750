import { useContext } from "react";
import { AuthContext } from "../Context/AuthProvider";
import { NotificationContext } from "../Context/NotificationProvider";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export const useNotification = () => useContext(NotificationContext);
export const useAuth = () => useContext(AuthContext);



