import React, {useState} from "react";
import {Card, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import noimage from "../images/noimage2.webp"
import "../css/Home.css"
import {cropText} from "../../hooks/useCustomHelpingMethod";

const NotificationMessage = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const styleCard = {
    backgroundColor: props.isRead ? "#f0fafc" : "white",
  };

  const styleMessage = {
    // whiteSpace: isExpanded ? 'normal' : 'nowrap',
    // overflow: 'hidden',
    // textOverflow: isExpanded ? 'none' : 'ellipsis',
    // fontFamily: "Montserrat",
  };

  const handleReadMore = () => {
    setIsExpanded(!isExpanded);
  };


  return (
    <Card className="mb-2" style={styleCard}>
      <Card.Body className="pt-2 pb-2">
        <Row className="d-flex align-items-center">
          <Col xl={1} lg={2} sm={2} md={4} className="ps-0 pe-0">
            <img style={{height: "40px", borderRadius: "5px"}} className="img-fluid rounded"
                 onError={(e) => e.target.src = noimage} src={props?.url_image} alt={"notify"}/>
          </Col>
          <Col xl={9} lg={9} sm={7} >
            <Link to={"/notification/" + props.id} className="text-decoration-none text-dark">
              <b>{props?.title || ""}</b>
              <p className="fontFamily m-0" style={styleMessage}><small> {cropText(props?.message || "",80)}</small></p>
            </Link>
          </Col>
          <Col xl={2} lg={2} sm={3} className="text-lg-end" style={{ whiteSpace: "nowwrap" }}>
            <Link className="text-decoration-none color-secondary float-end">{props?.formated_date}</Link>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default NotificationMessage;


