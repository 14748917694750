import {CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Col, Container, Form, Row} from "react-bootstrap";
import {getDataByUrl} from "../../API/HelpingMethod";
import {useNotification} from "../../../hooks";
import {useNavigate, useSearchParams} from "react-router-dom";


const StartupRequrements = ({data, handleBack, setData, handleUpdateWorkGroup}) => {
    var loginuser = JSON.parse(localStorage.getItem("userstorage"));
    const [btnLoading, setBtnLoading] = useState(false);
    const {showError, showSuccess} = useNotification();
    let [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const [requirement, setRequirement] = useState([])
    const [startupIsRunning, setStartupIsRunning] = useState(data?.startup_is_running || "no")

    const bodyData = {
        login_user_id: loginuser?.id,
        user_type: loginuser.user_type,
        id: searchParams.get("ideaId")
    }

    const updateRequirementOnLoad = (data) => {
        let tempRequirement = [];
        const re = data?.startup_requirement || "";
        if (re && (typeof re === 'string' || re instanceof String)) {
            tempRequirement = data?.startup_requirement.split(',');
        }
        // console.log(reqes);
        setRequirement([...tempRequirement]);
    }

    useEffect(() => {
        if (data) updateRequirementOnLoad(data);
    }, [data])

    const handleCheckbox = (e) => {
        if (e.target.checked === true) {
            setRequirement([...requirement, e.target.value])
        } else {
            const a = requirement?.filter(function (value, index, arr) {
                return value !== e.target.value;
            });
            setRequirement(a)
        }
    }

    const handleSubmit = async () => {
        setBtnLoading(true);

        bodyData.values = {
            startup_is_running: startupIsRunning || "no",
            startup_requirement: requirement.join(","),
            status: "pending",
        }
        const response = await getDataByUrl("startup_idea_update", bodyData);
        // console.log("response----", response)
        // console.log("bodyData----", bodyData)
        if (response?.error === false) {
            showSuccess(response?.message)
            if (response?.data) setData(response?.data);
            handleUpdateWorkGroup(response?.data);
            // setActiveStep(8);
            // navigate("/submit-idea?ideaId=" + response?.data?.id)
        } else {
            showError(response?.message)
        }
        setBtnLoading(false)
    }


    return (
        <>
            <Col>
                <Container>
                    <Row><Col sm={12} className="mx-auto h6 mt-3">What your requirements for your startup*</Col></Row>
                    <Row className=" ">
                        <Col className="mt-3 " sm={6} md={4}>
                            <Form.Check
                                onChange={handleCheckbox}
                                value={'Technical Support'}
                                label="Technical Support"
                                className="fontFamily"
                                // checked={requirement.includes('Technical Support') ? true : false}
                                checked={requirement.includes('Technical Support')}
                                type="checkbox"
                                id="radio4"
                                name="requirements" />
                        </Col>
                        <Col className="mt-3 " sm={6} md={4}>
                            <Form.Check className="fontFamily"
                                onChange={handleCheckbox}
                                value={'MVP Development'}
                                checked={requirement.includes('MVP Development')}
                                label="MVP Development"
                                type="checkbox"
                                id="radio5"
                                name="requirements" />
                        </Col>
                        <Col className="mt-3 " sm={6} md={4}>
                            <Form.Check className="fontFamily"
                                onChange={handleCheckbox}
                                value={'Digital Marketing'}
                                checked={requirement.includes('Digital Marketing')}
                                label="Digital Marketing"
                                type="checkbox"
                                id="radio6"
                                name="requirements" />
                        </Col>
                        <Col className="mt-3 " sm={6} md={4}>
                            <Form.Check className="fontFamily"
                                onChange={handleCheckbox}
                                value={'Idea Validation'}
                                checked={requirement.includes('Idea Validation')}
                                label="Idea Validation"
                                type="checkbox"
                                id="radio7"
                                name="requirements" />
                        </Col>
                        <Col className="mt-3 " sm={6} md={4}>
                            <Form.Check className="fontFamily"
                                onChange={handleCheckbox}
                                value={'Funding Support'}
                                checked={requirement.includes('Funding Support')}
                                label="Funding Support"
                                type="checkbox"
                                id="radio8"
                                name="requirements" />
                        </Col>
                        <Col className="mt-3 " sm={6} md={4}>
                            <Form.Check className="fontFamily"
                                        onChange={handleCheckbox}
                                        value={'Business Plan Design'}
                                        checked={requirement.includes('Business Plan Design')}
                                        label="Business Plan Design"
                                        type="checkbox"
                                        id="radio9"
                                        name="requirements"/>
                        </Col>
                    </Row>

                    <Row className={'mt-5'}>
                        <Col sm={12} className="h6">What your status of Your startup*</Col>
                        <Col sm={12} className="mt-3 ">
                            <FormControl>
                                {/*<FormLabel id="demo-radio-buttons-group-label">Status Of your Startup</FormLabel>*/}
                                <RadioGroup
                                    row
                                    aria-labelledby="status-startup-radio-buttons-group-label"
                                    defaultValue={startupIsRunning || "no"}
                                    name="startup_is_running"
                                >
                                    <FormControlLabel value="yes" control={<Radio color="primary"/>} label="Running"/>
                                    <FormControlLabel value="no" control={<Radio color="default"/>}
                                                      label="Not Yet started"/>
                                </RadioGroup>
                            </FormControl>
                        </Col>

                    </Row>


                    <Row>
                        <Col className="d-flex justify-content-center mt-5">
                            <div className="me-2">
                                <button onClick={handleBack} className="primary-button">
                                    Back
                                </button>
                            </div>

                            <div className="ms-2">
                                <button disabled={btnLoading} className={btnLoading ? "disabled-btn" : "slide-button1"}
                                        onClick={handleSubmit}>
                                    {btnLoading && <><CircularProgress color="inherit" className="mt-1"
                                                                       size={16}/>&nbsp;</>}Submit
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </>
    )
}

export default StartupRequrements;