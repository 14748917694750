import React, {useEffect, useRef, useState} from "react";
import {FiChevronRight} from "react-icons/fi";
import {Container, Row, Col, Card, Carousel, Badge} from "react-bootstrap";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Chip, CircularProgress, Skeleton, Stack} from "@mui/material";
import {amountFormate, getDataByUrl} from "../API/HelpingMethod";
import defaultimg from "../images/noimage2.webp";
import {BsEye} from "react-icons/bs";
import image from "../images/Looper-2 (3).png";
import SkeletonProjectIdeaDetails from "../Homepage/Skeleton-files/SkeletonProjectIdeaDetails";
import {useNotification} from "../../hooks";
import {FaArrowLeft, FaArrowRight, FaExternalLinkAlt} from "react-icons/fa";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import {HelmetCustomInclude} from "../../hooks/HelmetCustomInclude";
import {cropText, get_date_formatted} from "../../hooks/useCustomHelpingMethod";

const MarketplaceProductDetails = () => {
    const navigate = useNavigate();
    const loginuser = JSON.parse(localStorage.getItem("userstorage"));
    const [searchParams] = useSearchParams();
    useEffect(() => {
        if (!loginuser) {
            // navigate("/");
        }
    }, [loginuser, navigate]);

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }, []);

    const [marketplaceDetail, setMarketplaceDetail] = useState({});
    const [loading, setLoading] = useState(false);
    const {showWarning, showSuccess, setLoginModal, setLocation, setPrevLocation} = useNotification();
    const [button, setButton] = useState("Checkout");
    const [linkDemo, setLinkdemo] = useState([]);
    const [productcategories, setProductcategories] = useState([]);
    const [productIndustries, setProductIndustries] = useState([]);
    const [images, setImages] = useState([]);
    const [buttonClicked, setButtonClicked] = useState(false);
    const {slugMarketProduct} = useParams();

    const [pageTitle, setPageTitle] = useState("Marketplace Product");
    const [metaKeywords, setMetaKeywords] = useState("");
    const [metaDescription, setMetaDescription] = useState("");
    const [metaImageUrl, setMetaImageUrl] = useState(null);
    const [userCurrency, setSetUserCurrency] = useState("USD")

    const bodydata = {slug_product: slugMarketProduct};
    const checkoutdata = {
        login_user_id: loginuser?.id || "",
        action_type: "product_add",
        product_id: searchParams.get("productid"),
        type: "purchase",
    };

    const [contributor, setContributor] = useState({})
    const apiCall = async () => {
        setLoading(true);
        try {
            const response = await getDataByUrl("marketplace_product", bodydata);
            console.log(response);
            setSetUserCurrency(response?.data?.amount_currency_user_country || userCurrency);
            setMarketplaceDetail(response?.data);
            setLinkdemo(response?.data?.link_url_demo);
            setProductIndustries(response?.data?.industries || []);
            setProductcategories(response?.data?.product_categories || []);
            setContributor(response?.data?.user_contributor);
            if (!Array.isArray(response?.data?.url_images)) {
                setImages(Object.values(response?.data?.url_images))
            } else {
                setImages(response?.data?.url_images)
            }

            if (response?.data?.title) {
                setPageTitle(response?.data?.title)
            } else if (response?.data?.name) {
                setPageTitle(response?.data?.name)
            }
            if (response?.data?.url_image) setMetaImageUrl(response?.data?.url_image)

            if (response?.data?.page_description) {
                setMetaDescription(response?.data?.page_description)
            }
            if (response?.data?.page_keywords) {
                setMetaKeywords(response?.data?.page_keywords)
            }

        } catch (error) {
            // console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        apiCall();
    }, []);

    const handleclick = async () => {
        checkoutdata.product_id = marketplaceDetail?.id
        setButtonClicked(true)
        const response = await getDataByUrl(
            "marketplace_product_cart_action",
            checkoutdata
        );


        if (!response?.error) {
            showSuccess(response?.message);
            navigate("/cart");
        } else {
            showWarning(response?.message);
            // navigate("/Marketplace");
        }

        setButtonClicked(false)
    };

    const styleFont = {fontFamily: "Montserrat"};

    const [lightboxOpen1, setLightboxOpen1] = useState(false);
    const [lightboxIndex1, setLightboxIndex1] = useState(0);

    const openLightbox1 = () => {
        setLightboxIndex1(0);
        setLightboxOpen1(true);
    };

    const closeLightbox1 = () => {
        setLightboxOpen1(false);
    };

    const handleOpenModal = () => {
        setLoginModal(true)
        setLocation("/cart")
        setPrevLocation(localStorage.getItem("prevLocation"))
    }
    const signDiscount = <b className={"text-success"}>(-)</b>;
    const signCharge = <b className={"text-danger"}>(+)</b>;

    return (
        <div className="pt-3">

            <HelmetCustomInclude url={"marketplace/product/" + slugMarketProduct} ref_id={marketplaceDetail?.id}
                                 ref_type={'marketplace_product'}
                                 title={pageTitle} image={metaImageUrl}
                                 description={metaDescription}
                                 keywords={metaKeywords}/>

            <img id="Servicebgimage" src={image} alt="image"/>
            <Container className="mt-3 pt-4 pb-3" fluid id="Project_idea_details">
                <Container className="mb-5">
                    <Row>
                        {loading ? <></> : <div className="d-flex flex-row mt-3">
                            <div className="">
                                <Link to="/" className="text-decoration-none color-secondary"
                                      style={{whiteSpace: "nowrap", overflow: "hidden"}}>
                                    Home <FiChevronRight color="#ffffff"/>
                                </Link>
                            </div>
                            <div className="">
                                <Link to="/marketplace" className="text-decoration-none color-secondary"
                                      style={{whiteSpace: "nowrap", overflow: "hidden"}}>
                                    Marketplace <FiChevronRight color="#ffffff"/>
                                </Link>
                            </div>
                            <div
                                style={{whiteSpace: "nowrap", overflow: "hidden"}}
                                className="text-light"
                            >
                                {marketplaceDetail?.title}
                            </div>
                        </div>}
                    </Row>


                    <br/>
                    <Row>
                        {loading ? (
                            <SkeletonProjectIdeaDetails/>
                        ) : (
                            <>
                                <Col md={9} className="mt-4">
                                    {marketplaceDetail && (
                                        <h1
                                            style={{fontFamily: "'Nunito Sans', sans-serif"}}
                                            className="text-white fw-bold"
                                        >
                                            {marketplaceDetail?.title}
                                        </h1>
                                    )}

                                    {/*{marketplaceDetail && (
                                        <p style={styleFont} className="text-white">
                                            {cropText(marketplaceDetail?.description || "", 100)}
                                        </p>
                                    )}*/}
                                </Col>
                                <Col md={3} className="mb-2">
                                    {marketplaceDetail && (
                                        <img
                                            onError={(e) => (e.target.src = defaultimg)}
                                            className="w-100 rounded-3 shadow"
                                            src={marketplaceDetail?.url_image || defaultimg}
                                            alt="img"
                                        />
                                    )}
                                </Col>
                            </>
                        )}
                    </Row>
                </Container>
            </Container>
            <Container className="mt-5">
                <Row className="billcrm-title-container">
                    <Col lg={8} className="billcrm-title-img">
                        {loading ? (
                            <Skeleton className="rounded mb-4" Width={1000} variant="rectangle" height={500}/>
                        ) : (
                            images && Array.isArray(images) && images.length > 0 ? <>
                                <Carousel prevIcon={<FaArrowLeft className="fs-2"/>}
                                          nextIcon={<FaArrowRight className="fs-2"/>}>
                                    {images.map((image, index) => {
                                        return <Carousel.Item key={index}>
                                            <img onClick={openLightbox1}
                                                 onError={(e) => (e.target.src = defaultimg)}
                                                 style={{height: "100%", width: "100%"}}
                                                 className="rounded mb-4"
                                                 src={image || defaultimg} alt="Slider"/>
                                            {lightboxOpen1 && (
                                                <Lightbox
                                                    mainSrc={image}
                                                    // nextSrc={image}
                                                    // prevSrc={image}
                                                    onCloseRequest={closeLightbox1}
                                                    // onMovePrevRequest={() => setLightboxIndex1(0)}
                                                    // onMoveNextRequest={() => setLightboxIndex1(0)}
                                                />
                                            )}
                                        </Carousel.Item>;
                                    })}
                                </Carousel>
                            </> : <></>
                        )}

                        {loading ? (
                            <Container className={"mt-3"}>
                                <Row>
                                    {" "}
                                    <Col>
                                        <Skeleton className="rounded" variant="rectangle" width={500}
                                                  height={20}
                                        />
                                    </Col>{" "}
                                </Row>
                                <Row>
                                    {" "}
                                    <Col>
                                        <Skeleton className="rounded mt-3" variant="rectangle" width={800}
                                                  height={20}/>
                                    </Col>{" "}
                                </Row>
                            </Container>
                        ) : (
                            <Container className={"mt-3"}>
                                <Row className="fw-bold h4 mt-3"
                                     style={{fontFamily: "'Nunito Sans', sans-serif"}}>
                                    <Col xs={12}>  {(marketplaceDetail && marketplaceDetail?.title) || ""}</Col>
                                </Row>
                                <Row className="fontFamily">
                                    <Col xs={12}>
                                        <div
                                            dangerouslySetInnerHTML={{__html: marketplaceDetail?.description || ""}}/>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    {loading ? (
                                        <>
                                            <Container>
                                                <Row>
                                                    <Col>
                                                        <Skeleton/>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </>
                                    ) : (
                                        <Col className="p-0 mb-5">
                                            <button
                                                className={`slide-button text-decoration-none ${isHoveredViewMore ? "hovered" : ""}`}
                                                onMouseEnter={() => setIsHoveredViewMore(true)}
                                                onMouseLeave={() => setIsHoveredViewMore(false)}>
                                                View Demo <BsEye/>
                                            </button>
                                        </Col>
                                    )}
                                </Row>*/}
                            </Container>
                        )}
                    </Col>
                    <Col lg={4} className="">
                        {loading && <div className="skeleton-m-p"><Skeleton width="100%" height={300}/></div>}
                        {!loading &&
                        <Card className="shadow billcrm-title-card border-0 mb-3 ">
                            <Card.Body>
                                <h6 className="fw-bold">Price</h6>
                                <Row className="mt-3">
                                    <Col xs={5}>MRP:</Col>
                                    <Col
                                        className="text-end">{amountFormate(marketplaceDetail?.amount_mrp_user_country)} {userCurrency} </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col xs={5}>Discount:</Col>
                                    <Col className="text-end ">
                                        <span>{signDiscount} {amountFormate(marketplaceDetail?.amount_discount_user_country)} {userCurrency}</span>
                                    </Col>
                                </Row>
                                {/* <Row className="mt-2">
                                    <Col xs={5}>Price:</Col>
                                    <Col
                                        className="text-end">{amountFormate(marketplaceDetail?.amount_rate_user_country)} {userCurrency}
                                    </Col>
                                </Row>*/}
                                <Row className="mt-2">
                                    <Col xs={5}>Tax:</Col>
                                    <Col className="text-end">
                                        {signCharge} {amountFormate(marketplaceDetail?.amount_tax_user_country)} {userCurrency}
                                    </Col>
                                </Row>
                                <hr/>
                                <Row className="mt-2 fw-bold">
                                    <Col xs={5}>Total:</Col>
                                    <Col className="text-end">
                                        <h6>{amountFormate(marketplaceDetail?.amount_total_user_country)} {userCurrency}
                                        </h6>
                                    </Col>
                                </Row>
                                <Row className=" ms-1 me-1 mt-2 pt-0  pt-lg-2 pt-xl-5 ">
                                    <div style={{position: "relative"}}>
                                        {!loading && buttonClicked && (
                                            <Row>
                                                <button className='fontFamily text-decoration-none mb-4 disabled-btn'
                                                        disabled>
                                                    <>
                                                        <CircularProgress style={{color: "var(--secondary)"}}
                                                                          className="mt-1"
                                                                          size={16}/>&nbsp;&nbsp; Loading...
                                                    </>
                                                </button>
                                            </Row>
                                        )}
                                        {!loading && !buttonClicked && (
                                            <>
                                                {marketplaceDetail?.is_users_cart === "yes" ? <>
                                                    <button onClick={() => {
                                                        navigate("/cart")
                                                    }}
                                                            className={`input-buttons fontFamily text-decoration-none mb-4 w-100 `}>
                                                        View Cart
                                                    </button>
                                                </> : (loginuser ? loginuser?.user_type === "startup" ?
                                                    <button onClick={handleclick}
                                                            className={`slide-button1 fontFamily text-decoration-none mb-4 w-100 `}>
                                                        {button}
                                                    </button> : <></> : <button
                                                    onClick={handleOpenModal}
                                                    className={`slide-button1 fontFamily text-decoration-none mb-4 w-100 `}>
                                                    {button}
                                                </button>)
                                                }
                                            </>

                                        )}
                                    </div>
                                </Row>
                            </Card.Body>
                        </Card>}

                        {loading && <div className="skeleton-m-p mt-3"><Skeleton width="100%" height={200}/></div>}
                        {!loading && <Card className="shadow border-0  mb-3">
                            <Card.Body>
                                <Row className={""}>
                                    <Col xs={7} className="fw-bold h6">Latest Update </Col>
                                    <Col xs={5}
                                         className="text-end">{get_date_formatted(marketplaceDetail?.updated_at)}</Col>
                                </Row>
                                <Row>
                                    <Col xs={12} className="mb-1 mt-2 fw-bold">
                                        Categories
                                    </Col>
                                    {productcategories?.map((item, index) => {
                                        return <Col key={index} className="mb-2">
                                            <Chip size={"small"} label={item?.title} color="success"
                                                  variant="outlined"/> <br/>
                                            {/*<Badge pill bg="secondary">{item?.title}</Badge>*/}
                                        </Col>;
                                    })}
                                </Row>
                                <Row>
                                    <Col xs={12} className="fw-bold mb-1 mt-2">
                                        Industries
                                    </Col>
                                    {productIndustries?.map((item, index) => {
                                        // console.log(item);
                                        return <Col key={index} className="mb-2">
                                            <Chip size={"small"} label={item} color="primary" variant="outlined"/> <br/>
                                            {/*<Badge pill bg="primary">{item}</Badge>*/}
                                        </Col>;
                                    })}

                                </Row>

                            </Card.Body>
                        </Card>}

                        {loading && <div className="skeleton-m-p mt-3"><Skeleton width="100%" height={200}/></div>}

                        {!loading && <Card className="shadow border-0  mb-3">
                            <Card.Body>
                                <Row className={"mb-2"}>
                                    <Col xs={12} className="fw-bold h6">Product Demos</Col>
                                </Row>
                                <Row>
                                    {linkDemo?.map((item, index) => {
                                        return <Col xs={12} key={index} className="mb-2">
                                            <Card className={'shadow'}>
                                                <Card.Body className={"py-2"}>
                                                    <b><small>{(index + 1)}. {item?.title}</small></b>
                                                    <FaExternalLinkAlt size={16} color={"primary"} onClick={() => {
                                                        window.open(item?.url || "", "_blank")
                                                    }}
                                                                       className={'float-end pointer hover mt-1 text-primary'}/>
                                                </Card.Body>
                                            </Card>
                                        </Col>;
                                    })}
                                </Row>
                            </Card.Body>
                        </Card>}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default MarketplaceProductDetails;
