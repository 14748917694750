import React, { useEffect, useState } from "react";
import logoFooter from "../../assets/logos/logo-footer.webp";
import { Link, NavLink } from "react-router-dom";
import { Col, Container, InputGroup, Row, Form } from "react-bootstrap";
import { Avatar, Stack } from "@mui/material";
import "../css/Home.css"
import noimage from "../images/noimage2.webp"

import {
  FaAndroid,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";
import FooterMailSubscription from "./FooterMailSubscription"; 

const Footer = () => {
   
  const styleSocialIcon = {
    bgcolor: "var(--primary)",
    width: 35,
    height: 35,
  };
  const styleFooter = { background: "#e6fcf1" };
  const sizeSocialIcon = 20;
  const styleImage = { maxWidth: "200px" };
  const styleFont = { fontFamily: "Montserrat", marginTop: "20px" };
  const footerText =
    "A startup support system which will help new startups to move form phase to implementation phase. Startup Starter which is an initiative of Aimerse Technologies India Pvt. Ltd., is a startup...";

  const socialMediaLinks = [
    {
      url: "https://fb.com/startupstarter.official",
      icon: <FaFacebookF size={sizeSocialIcon} />,
      alt: "FaFacebook",
    },
    {
      url: "https://www.instagram.com/startupstarter.official",
      icon: <FaInstagram size={sizeSocialIcon} />,
      alt: "FaInstagram",
    },
    {
      url: "https://www.linkedin.com/showcase/startupstarter-official?_l=en_US",
      icon: <FaLinkedinIn size={sizeSocialIcon} />,
      alt: "Linkedin",
    },
    {
      url: "https://www.youtube.com/@StartupStarter.Official",
      icon: <FaYoutube size={sizeSocialIcon} />,
      alt: "youtube",
    },
    {
      url: "https://play.google.com/store/apps/details?id=com.aimerse.startupstarter",
      icon: <FaAndroid size={sizeSocialIcon} />,
      alt: "Android",
    },
  ];

  const links = [
    { to: "/about", label: "About Us" },
    { to: "/contact", label: "Contact Us" },
    { to: "/privacy", label: "Privacy Policy" },
    { to: "/refund", label: "Refund Policy" },
    { to: "/terms", label: "Terms & Condition" },
    { to: "/faq", label: "Faq" },
    // { to: "/faq-type", label: "Faq type" },
  ];

  // const faqlinks = [
  //   { to: "/faq", label: "Faq" },
  //   { to: "/faq-type", label: "Faq type" },
  // ];

  const usefulLink = "Useful Link";
  const FaqLink = "FAQ";
  const footerBottom =
    "Copyright © 2023 Aimerse Technologies India. All  rights reserved.";
  return (
    <div>
      <Container fluid className="pb-3" style={styleFooter}>
        <Container>
          <Row className="pt-5">
            <Col xl={5} lg={5} md={4} sm={12} xs={12} className="">
              <div className="mt-4 mb-2">
                <img
                  className="img-fluid"
                  src={logoFooter}
                  alt="logo"
                  style={styleImage}
                  onError={(e) => e.target.src = noimage}
                />
                <p style={styleFont}>{footerText}</p>
              </div>
              <div className="my-2">
                <Row>
                  <Col>
                    <Stack
                      direction="row"
                      spacing={1}
                      className={"mx-auto mx-md-0"}
                    >
                      {socialMediaLinks.map((socialMedia, index) => (
                        <Avatar
                          key={index}
                          onClick={() => window.open(socialMedia.url)}
                          className={"social-icon"}
                          sx={styleSocialIcon}
                          alt={socialMedia.alt}
                        >
                          {socialMedia.icon}
                        </Avatar>
                      ))}
                    </Stack>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col xl={2} lg={3} md={4} sm={12} xs={12} className="">
              <div className="mt-4">
                <h5 className="color-primary fw-bold mb-3" style={{fontFamily: "Montserrat"}}>{usefulLink}</h5>
                <div className="footer-item">
                  {links.map((link, index) => (
                    <div key={index} className="mb-3" style={{  transition: "transform 0.3s" }}

                      onMouseOver={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
                      onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")}>
                      <NavLink to={link.to} className="footer-link fontFamily" >
                        {link.label}
                      </NavLink>
                    </div>
                  ))}
                </div>
              </div>
            </Col>






            <Col xl={4} lg={4} md={4} sm={12} xs={12} className="">
              <FooterMailSubscription />
            </Col>
          </Row>
          <div className="">
            <h6 className="text-center mt-5 fontFamily">
              {footerBottom}
            </h6>
          </div>
        </Container>
      </Container>
    </div>
  );
};
export default Footer;
