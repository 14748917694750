import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import img from "../../images/noimage.png"
import { Avatar, Chip, CircularProgress, Skeleton } from "@mui/material";
import image from "../../images/Looper-2 (1).png";
import { Link, useNavigate } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import noimage from "../../images/noimage.png"
import UserProfileSidebar from "../../UserProfile/UserProfileSidebar";
import { amountFormate, getDataByUrl } from "../../API/HelpingMethod";
import { loginUserData } from "../../API/client";
import { get_date_formatted } from "../../../hooks/useCustomHelpingMethod";
const Order = () => {
    const [order, setOrder] = useState([]);
    const [status, setStatus] = useState("success");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [offset, setOffset] = useState(0);
    const [btnLoading, setBtnLoading] = useState(false);


    var loginuser = JSON.parse(localStorage.getItem("userstorage"));
    const inputData = {
        offset: offset,
        limit: 10,
        // login_user_id: loginuser?.id,
        login_user_id: 200,
        login_user_user_type: loginuser?.user_type,
    };




    const getOrder = async () => {
        setLoading(true);
        const response = await getDataByUrl("marketplace_order", inputData)
        console.log("response---", response)
        setOrder(response?.data)

        setLoading(false);

    }

    useEffect(() => {
        getOrder();
        setOffset(0);
    }, [])


    const nextData = async () => {
        setBtnLoading(true);

        const inputData = { offset: offset, limit: 10 }

        const response = await getDataByUrl("marketplace_order", { ...inputData, ...inputData })

        console.log("response---next", response)
        if (response?.error === false) {
            setOrder((prev) => [...new Set([...prev, ...response?.data])]);

        }
        setBtnLoading(false);

    }

    useEffect(() => {
        if (offset !== 0) {
            nextData();
        }
    }, [offset]);


    const loadMoreItems = () => {
        setOffset(offset + 1);
    };


    const styleBorder = {
        border: "1px solid var(--primary)",
        paddingBottom: "45px"
    }
    return (
        <>
            <Container className="mt-5 mb-4">

                <img id="Servicebgimage" src={image} alt="img" onError={(e) => e.target.src = noimage} />
                <Row className="mt-2 mb-2">
                    <Col className="ms-3">
                        <Link to="/" className="text-decoration-none color-secondary">Home</Link>&nbsp;<BsChevronRight />&nbsp;<span>MarketPlace Order</span>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} xs={12} md={5} lg={4} xl={3} className="pb-5">
                        <UserProfileSidebar />
                    </Col>
                    <Col sm={12} xs={12} xl={9} md={7} lg={8}>

                        <Container>
                            <Row className="">
                                <Card style={styleBorder}>
                                    <Card.Body>
                                        <Container>
                                            <Row>
                                                <Col className="ps-0">
                                                    <span className="fw-bold h5">MarketPlace Order List</span>
                                                </Col>
                                            </Row>
                                            {loading && <>
                                                <Col className="mx-auto skeleton-m-p mt-3"><Skeleton width="100%" height={100} /></Col>
                                                <Col className="mx-auto skeleton-m-p mt-3"><Skeleton width="100%" height={100} /></Col>
                                                <Col className="mx-auto skeleton-m-p mt-3"><Skeleton width="100%" height={100} /></Col>
                                                <Col className="mx-auto skeleton-m-p mt-3"><Skeleton width="100%" height={100} /></Col>
                                            </>}
                                            <Row>
                                                {!loading && order && Array.isArray(order) && order.map((data) => {
                                                    return (
                                                        <Col xl={6}>
                                                            <Card onClick={() => navigate("/order/" + data?.token)} className="mt-3 shadow order-list-product">
                                                                <Card.Body className=" ">
                                                                    <Row>
                                                                        <Col md={12} sm={2} lg={2} xl={2}>
                                                                            <img onError={(e) => e.target.src = img}
                                                                                style={{ width: 56, height: 56 }}
                                                                                src={data?.product?.url_image}
                                                                                className="rounded" />
                                                                        </Col>
                                                                        <Col md={12} sm={10} lg={10} xl={10}>
                                                                            <div className="h6">{data?.product?.title}</div>
                                                                        </Col>

                                                                        <Col xs={6} md={6} lg={6}  >
                                                                            <div >Price</div>
                                                                            <div className="h6">{amountFormate(data?.product?.amount_total_user_country)} &nbsp;{data?.product?.amount_currency_user_country}</div>
                                                                        </Col>

                                                                        <Col className="text-end" xs={6} md={6} lg={6} >
                                                                            <div >Order Date</div>
                                                                            <div className="h6">{get_date_formatted(data?.created_at)} </div>
                                                                        </Col>


                                                                        <Col xs={6} md={6} lg={6} >
                                                                            <div className=" ">Payment</div>
                                                                            <div className="order-status"> <Chip label={data?.status_payment}
                                                                                color={data?.status_payment === "pending" ? "warning" : data?.status_payment === "success" ? "success" : "primary"}
                                                                                variant="outlined" /></div>
                                                                        </Col>
                                                                        <Col className="text-end " xs={6} md={6} lg={6} >
                                                                            <div className=" ">Status</div>
                                                                            <div className="order-status"> <Chip label={data?.status}
                                                                                color={data?.status === "pending" ? "warning" : data?.status === "success" ? "success" : "primary"}
                                                                            /></div>
                                                                        </Col>

                                                                    </Row>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    )
                                                })
                                                }
                                            </Row>

                                            <Row>
                                                <Col>
                                                    {(order?.length >= 3 &&
                                                        (btnLoading ? <div className="mt-3 text-center">
                                                            <button disabled className={`slide-button text-decoration-none loading-hover`} >
                                                                <CircularProgress className="mt-1" color="inherit" size={16} />&nbsp; Load More
                                                            </button>
                                                        </div> :
                                                            <div className="mt-3 text-center">
                                                                <button
                                                                    className={`slide-button text-decoration-none`}
                                                                    onClick={loadMoreItems} >
                                                                    Load More
                                                                </button>
                                                            </div>)
                                                    )}
                                                </Col>
                                            </Row>



                                        </Container>
                                    </Card.Body>
                                </Card>
                            </Row>

                        </Container>


                    </Col>

                </Row>






            </Container>
        </>
    )
}
export default Order;
