import React, {useEffect, useState} from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
// import { Skeleton } from "@mui/material";
import {getDataByUrl} from "../API/HelpingMethod";
import SkeletonProjectIdea from "../Homepage/Skeleton-files/SkeletonProjectIdea";
import {FaLongArrowAltRight} from "react-icons/fa";
import image from "../images/Looper-2 (2).png";
import {useNotification} from "../../hooks";
import "../css/ReadMore.css";
import {HelmetCustomInclude} from "../../hooks/HelmetCustomInclude";
import noimage from "../images/noimage2.webp"

const ProjectIdea = ({  }) => {
  var loginuser = JSON.parse(localStorage.getItem("userstorage"));
  const [idea, setIdea] = useState([]);
  const [loading, setLoading] = useState(false);
  const {setShow} = useNotification();

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: "smooth"});
  }, []);

  const [pageTitle, setPageTitle] = useState("Project Idea");

  const inputData = {
    // slug_projectidea: searchParams.get("slug"),
    offset: 0,
    limit: 10,
  };

  const apiCall = async () => {
    if (loginuser) {
      inputData.login_user_id = loginuser?.id || 0;
      inputData.login_user_type = loginuser?.user_type || "startup";
    }
    setLoading(true);
    const response = await getDataByUrl("front_project_idea", inputData);
    if (!response?.error) {
      setIdea(response?.data);
      setLoading(false); 
    } else {
      // console.log("Error fetching data:", response?.message);
    }
  };
  useEffect(() => {
    apiCall();
  }, []);
  const CroppedText = ({ text, maxLength }) => {
    const croppedText =
      text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

    return <div>{croppedText}</div>;
  };
  const regex = /(<([^>]+)>)/gi;

  const styleFont = { fontFamily: "Montserrat" };
  const projectIdeaText = "PROJECT IDEAS";
  const projectIdeaParagraph = "Ignite Your Imagination, Transforming";
  const projectIdeaParagraphBrake = "Ideas into Reality";
  const projectIdeaParagraphText ="Elevate your creativity with a variety of innovative project ideas. Whether you're a student looking for inspiration or a";
  const professional = "a professional seeking new";
  const readMore = " Read More";

  let titleDescriptionWidth;
  let cardImageWidth;
  let titleWidth;

  const styleContent = {
    paddingLeft: "0px",
    paddingRight: "0px",
  };
  return (
    <div className="pt-3">
      <HelmetCustomInclude url={"project-idea"} title={pageTitle} />

      <Container fluid className="mt-3 pb-4" id="Project_Idea">
        <img id="Servicebgimage" src={image} alt="image" />
        <Container>
          <Row>
            <span className="text-center mt-5 text-light">
              {projectIdeaText}
            </span>
            <h2 className="text-center text-light mt-4 fw-bold fontFamily" >
              {projectIdeaParagraph}
              <br /> {projectIdeaParagraphBrake}
            </h2>
            <p style={styleFont} className="text-light mt-4 m-0">
              {projectIdeaParagraphText}
            </p>
            <p style={styleFont} className="text-center text-light">
              {professional}
            </p>
          </Row>
        </Container>
      </Container>

      <Container className="mb-4" style={styleContent}>
        <Row className="container mt-4 pt-4 g-4">
          {loading ? (
            <SkeletonProjectIdea
              titleDescriptionWidth={titleDescriptionWidth}
              cardImageWidth={cardImageWidth}
              titleWidth={titleWidth}
            />
          ) : (
            <>
              {idea.map((projectItem, ind) =>
                ind % 2 === 0 ? (
                  <>
                    <Row className="mt-2">


                      <Col md={5} lg={4} xl={4} key={ind} className="mb-3 mb-md-0 order-1" >
                        <Card style={{ cursor: "pointer", transition: "transform 0.3s" }}
                          onMouseOver={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
                          onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")} className="shadow border-0">
                          <Card.Body>
                            <Card.Img style={{ height: "300px" }} 
                            className="w-100 rounded-3 shadow"
                              src={projectItem.url_image}
                              onError={(e) => e.target.src = noimage}
                              alt="./"  />
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={7} lg={8} xl={8} className="order-2">
                        <h4 className="fw-bold fontFamily">{projectItem.name}</h4>
                        <p className="fontFamily m-0">
                          <CroppedText
                            className="fontFamily"
                            text={projectItem.description.replace(regex, " ")}
                            maxLength={500}
                          />
                        </p>

                        <Link
                          // to={
                          //   "/project-idea-detail?project-ideaid=" +
                          //   projectItem.id
                          // }
                          to={`/project-idea/${projectItem.slug}`}
                          className="text-decoration-none nav-link fontFamily"
                          style={{ color: "var(--secondary)" }}
                        >
                          {readMore} <FaLongArrowAltRight />
                        </Link>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row className="mt-2">
                      <Col md={7} lg={8} xl={8} key={ind} className="order-md-1 order-2">
                        <h4 className="fw-bold">{projectItem.name}</h4>
                        <p className="fontFamily m-0">
                          <CroppedText
                            className="fontFamily"
                            text={projectItem.description.replace(regex, " ")}
                            maxLength={450}
                          />
                        </p>

                        <Link
                          // to={
                          //   "/project-idea-detail?project-ideaid=" +
                          //   projectItem.id
                          // }
                          to={`/project-idea/${projectItem.slug}?slug=${projectItem.slug}`}
                          className="text-decoration-none nav-link"
                          style={{ color: "var(--secondary)" }}
                        >
                          {readMore} <FaLongArrowAltRight />
                        </Link>
                      </Col>
                      <Col md={5} lg={4} xl={4} className="mb-3 mb-md-0 order-md-2 order-1">
                        <Card className="shadow border-0" style={{ cursor: "pointer", transition: "transform 0.3s" }} 
                          onMouseOver={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
                          onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")} >
                          <Card.Body>
                            <Card.Img
                              style={{ height: "300px" }}
                              className="w-100 rounded-3 shadow"
                              src={projectItem.url_image}
                              onError={(e) => e.target.src = noimage}
                              alt="./"
                            />
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </>
                )
              )}
            </>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default ProjectIdea;
