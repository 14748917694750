import { Skeleton } from '@mui/material'
import React from 'react'

export default function SkeletonTermsandcondition() {
  const skeletonData = Array.from({ length: 20 }, () => ({ width: 1200, height: 20 }));
  skeletonData.unshift({ width: 300, height: 30 }); // Add the first item

  const renderSkeletons = () => {
    return skeletonData.map((item, index) => (
      <Skeleton
        key={index}
        className='rounded mt-3'
        variant='rectangle'
        width={item.width}
        height={item.height}
      />
    ));
  };

  return (
    <div>
      {renderSkeletons()}
    </div>
  );
}
