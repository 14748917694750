import { Chip, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { LuUsers } from "react-icons/lu";
import { SlPencil } from "react-icons/sl";
// import { IoLogOutOutline } from 'react-icons/io5';
import { Link, useNavigate } from "react-router-dom";
import "../css/Logout.css";
import { BsBasket, BsBell, BsKey, BsPersonPlus } from "react-icons/bs";
import { loginUserData } from "../API/client";

function ProfileNav() {
    const [isFixed, setIsFixed] = useState(true);
    const isSmallScreen = useMediaQuery("(max-width: 960px)");
    const loginuser = loginUserData;

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            // You can set a threshold value to decide when to remove the fixed position
            const threshold = 400;
            setIsFixed(offset < threshold && !isSmallScreen);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isSmallScreen]);

    // Clean up the event listener on component unmount

    const navigate = useNavigate();
    const Logout = () => {
        localStorage.removeItem("userstorage");
        navigate("/verify");
    };

    const profileItems = [
        {
            to: "/profile",
            icon: <LuUsers />,
            text: "User Profile",
        },
        {
            // to: window.location.pathname === "/notification-detail" ? "/notification-detail" : "/notification",
            to: "/notification",
            icon: <BsBell />,
            text: "Notification",
        },
        {
            to: "/profile-update",
            icon: <SlPencil />,
            text: "Update Profile",
        },
        {
            to: "/upload-picture",
            icon: <BsPersonPlus />,
            text: "Upload Picture",
        },
        {
            to: "/change-password",
            icon: <BsKey />,
            text: "Change Password",
        },
        {
            to: "/order",
            icon: <BsBasket />,
            text: "MarketPlace Order",
        },

    ];
    const styleFont = {
        fontFamily: "Montserrat",
        whiteSpace: "nowrap",
        overflow: "hidden",
    };

    // console.log("window.location.pathname--", window.location.pathname.split('/')[1])

    return (

        <>
            <Row>
                <Col className="text-center">
                    <Chip label={loginuser?.user_type} color="success" size="small" variant="outlined" />
                </Col>
            </Row>
            {profileItems.map((item, index) => (
                <Link key={index} style={styleFont} className="text-decoration-none text-dark " to={item.to}>
                    <Row className="text-center">
                        <Col
                            lg={12} xl={12}
                            key={index}
                            className={`mt-3 profileitem d-flex p-2 ${"/" + window.location.pathname.split('/')[1] === item.to ? "active" : ""
                                }`}
                        >
                            {/* <NavLink style={styleFont} className="text-decoration-none profileitem"to={item.to}> */}
                            <div>{item.icon}</div>&nbsp;<div className=" ">{item.text}</div>
                            {/* </NavLink> */}
                        </Col>
                    </Row>
                </Link>

            ))}

        </>

    );
}

export default ProfileNav;
