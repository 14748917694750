const regex = /(<([^>]+)>)/gi;

const CroppedText = (text, maxLength) => {
    let textFinal = text;
    if (text) {
        textFinal = textFinal.replace(regex, " ");
    }

    textFinal =
        textFinal && textFinal.length > maxLength
            ? `${textFinal.substring(0, maxLength)}...`
            : textFinal;

    return (
        <div style={{overflow: "hidden", textOverflow: "ellipsis"}}>
            {textFinal}
        </div>
    );
};
const moneyFormatter = new Intl.NumberFormat('en-IN', {
    // maximumSignificantDigits: 3,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
})
const moneyFormatter2 = new Intl.NumberFormat('en-IN', {
    // maximumSignificantDigits: 3,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
})

const formatMoney = (amount, type = "double") => {
    if (type !== 'double') {
        return moneyFormatter2.format(amount || 0);
    } else {
        return moneyFormatter.format(amount || 0);
    }
};


export {CroppedText, regex, formatMoney};
