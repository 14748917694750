import React, {useEffect, useState} from "react";
import Accordion from "react-bootstrap/Accordion";
import {getDataByUrl} from "../API/HelpingMethod";
import {Col, Container, Row} from "react-bootstrap";
import SkeletonFaqType from "../Homepage/Skeleton-files/SkeletonFaqType";
import {Link, useParams} from "react-router-dom";
import {FaCircleArrowLeft} from "react-icons/fa6";

const FaqType = () => {
  const [faqtype, setFaqtype] = useState([]);
  const [loading, setLoading] = useState(false);
  const loginuser = JSON.parse(localStorage.getItem("userstorage"));
  const {slugFaqCategory} = useParams();

  const inputData = {
    // category_id: 1,
    category_slug: slugFaqCategory,
    limit: 10,
    offset: 0,
  };
  const apiCall = async () => {
    setLoading(true);
    if (loginuser) {
      inputData.login_user_id = loginuser?.id
      inputData.login_user_type = loginuser?.user_type
    }
    const response = await getDataByUrl("help_faq_type", inputData);

    if (!response?.error) {
      setFaqtype(response?.data);
    } else {
      // console.log("Error fetching data:", response?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    apiCall();
  }, []);
  return (
    <div>
      
    {/* <HelmetCustomInclude url={"product/" + slugPart} ref_id={part_details?.id} ref_type={'product_part'}
                                 title={pageTitle} image={metaImageUrl}
                                 description={metaDescription}
                                 keywords={metaKeywords}/> */}

      <Container className="mt-5 pt-5 mb-5">
        <Row className="mb-3">
          <p className={"py-2"}><Link to="/faq" className="color-secondary"><FaCircleArrowLeft/> Back</Link>
          </p>
        </Row>
        <Row className="mb-3">
          <Col className="fw-bold">FAQ TYPE</Col>
        </Row>

        {
          loading ? <SkeletonFaqType/> : <>
            {faqtype && Array.isArray(faqtype) && faqtype.length > 0 ? faqtype?.map((item, index) => {
              return (<Accordion key={index} className="mt-2 faqtype">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>{item?.title}</Accordion.Header>
                  <Accordion.Body className="fontFamily">{item?.description}</Accordion.Body>
                </Accordion.Item>
              </Accordion>);
            }) : <h6 className={'text-danger'}>Sorry! There is no FAQ's Available for this category.</h6>}
          </>
        }

      </Container>
    </div>
  );
};

export default FaqType;
