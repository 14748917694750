import React, { useEffect } from "react";
import { loginUserData } from "../API/client";
import UserProfile from "./UserProfile";
import ContributorProfile from "./ContributorProfile";
import InvestorProfile from "./InvestorProfile";
import { useNavigate } from "react-router-dom";

const AllUserProfile = () => {
    const loginuser = loginUserData;
    const navigate = useNavigate();

    useEffect(() => {
        if (!loginUserData) {
            navigate("/");
        }  
    }, [loginUserData])

    return (
        <>
            {loginuser?.user_type === "contributor" && <ContributorProfile />}
            {loginuser?.user_type === "startup" && <UserProfile />}
            {loginuser?.user_type === "investor" && <InvestorProfile />}
        </>
    )
}
export default AllUserProfile;