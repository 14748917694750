import React, { useEffect, useState } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, Badge } from "@mui/material";
import defaultImage from "../images/user-profile.jpg";
import { LuUsers } from "react-icons/lu";
import { SlPencil } from "react-icons/sl";
import { RiLockPasswordLine, RiShieldFlashLine } from "react-icons/ri";
import { PiLightbulbBold } from "react-icons/pi";
import { MdFavoriteBorder } from "react-icons/md";
import { IoLogOutOutline } from "react-icons/io5";
import { GoBell } from "react-icons/go";
import noimage from "../images/noimage2.webp"
import { BiBasket } from "react-icons/bi";

const UserDrop = ({ }) => {
  // const [data, setData] = useState()
  // const inputData = {
  //   offset: 0,
  //   limit: 4,
  // };
  const navigate = useNavigate();
  const [color, setColor] = useState(false);
  const [loginuser, setLoginuser] = useState({})
  useEffect(() => {
    setLoginuser(JSON.parse(localStorage.getItem("userstorage")))

  }, [localStorage.getItem("userstorage") && localStorage.getItem("userstorage")])

  const handleLogout = () => {
    localStorage.removeItem("userstorage");
    window.location.reload()
    // navigate("/verify");
    // setIsLoggedIn(null);
  };

  const [styleHeader, setStyleHeader] = useState({
    backgroundColor: "transparent",
    color: "white",
  });
  const [headerNav, setHeaderNav] = useState({
    color: "white",
  });
  const [styleTransparent, setStyleTransparent] = useState({
    backgroundColor: "transparent",
    color: "white",
  });
  const [styleOnscroll, setStyleOnscroll] = useState({
    backgroundColor: "transparent",
    color: "white",

  });

  const changeColor = () => {
    if (window.scrollY >= 100) {
      setStyleOnscroll({ color: "black" });
      setColor(true);
      setStyleHeader(styleOnscroll);
      setHeaderNav({ color: "black" });
    } else {
      setStyleOnscroll({ color: "white" });
      setColor(false);
      setStyleHeader(styleTransparent);
      setHeaderNav({ color: "white" });
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", changeColor);
    return () => {
      window.removeEventListener("scroll", changeColor);
    };
  }, []);


  return (
    <>
      <Container>
        <Row>
          <Col style={{ cursor: "pointer", transition: "transform 0.3s" }}
            onMouseOver={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
            onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")}>
            <Link to="notification" className="text-decoration-none text-dark" >
              <Badge badgeContent={6} variant="dot" color="secondary" className="mt-2">
                <GoBell size={25} />
              </Badge>
            </Link>
          </Col>
          <Col>
            <Dropdown drop="start">
              <Avatar>
                <Dropdown.Toggle style={{ cursor: "pointer" }} as="div">
                  <img src={loginuser?.url_profile || defaultImage} className="profileDialogueImage" alt={'profile'} />
                </Dropdown.Toggle>
              </Avatar>
              <div>
                <Dropdown.Menu style={{ zIndex: 1 }} className="mt-4 pt-0 user-dropdown" >
                  <Dropdown.Item className="p-0 pt-0">
                    <Container className="d-flex profile-top-bg py-4" style={{ borderRadius: "10px" }} >
                      <Row className="">
                        <Col xl={3} lg={3} md={3} xs={3} className="d-flex align-items-center" >
                          <div style={{ cursor: "pointer" }}>
                            <Avatar style={{ width: "70px", height: "70px" }} onError={(e) => e.target.src = noimage} src={loginuser?.url_profile || defaultImage} />
                          </div>
                        </Col>
                        <Col xl={9} lg={9} md={9} xs={9} className="">
                          <Container>
                            <Row>
                              <Col className="fw-bold fontFamily">
                                {loginuser?.name}
                              </Col>
                            </Row>
                            <Row>
                              <Col style={{ fontSize: "10px" }} className="fontFamily mt-2" >
                                {loginuser?.email}
                              </Col>
                            </Row>
                            <Row>
                              <Col className="color-secondary mt-2 fontFamily">
                                {loginuser?.mobile}
                              </Col>
                            </Row>
                          </Container>
                        </Col>
                      </Row>
                    </Container>
                    <Container className="mt-3">
                      <Row onClick={() => navigate("/profile")} className="ms-2 me-2 profileitem pt-2 pb-2  ">
                        <Col>
                          <Link className="text-decoration-none fontFamily" ><LuUsers className="color-secondary fs-4" /> View Profile</Link>
                        </Col>
                      </Row>
                      <Row onClick={() => navigate("/profile-update")} className="ms-2 me-2 profileitem pt-2 pb-2  ">
                        <Col>
                          <Link className="text-decoration-none fontFamily" >
                            <SlPencil className="color-secondary fs-4" /> Update Profile
                          </Link>
                        </Col>
                      </Row> 
                     {loginuser?.user_type === "startup" && <Row onClick={() => navigate("/startup-idea")} className="ms-2 me-2 profileitem pt-2 pb-2  ">
                        <Col>
                          <Link className="text-decoration-none fontFamily" >
                            <RiShieldFlashLine className="color-secondary fs-4" /> Startup Ideas
                          </Link>
                        </Col>
                      </Row>}
                     {loginuser?.user_type === "startup" && <Row onClick={() => navigate("/user-project")} className="ms-2 me-2 profileitem pt-2 pb-2  ">
                        <Col>
                          <Link className="text-decoration-none fontFamily" >
                            <PiLightbulbBold className="color-secondary fs-4" /> Project Ideas
                          </Link>
                        </Col>
                      </Row>}
                      <Row onClick={() => navigate("/favorite")} className="ms-2 me-2 profileitem pt-2 pb-2">
                        <Col>
                          <Link className="text-decoration-none  fontFamily"  >
                            <MdFavoriteBorder className="color-secondary fs-4" /> Favorite
                          </Link>
                        </Col>
                      </Row>
                      <Row onClick={() => navigate("/change-password")} className="ms-2 me-2 profileitem pt-2 pb-2 fontFamily">
                        <Col>
                          <Link className="text-decoration-none fontFamily" >
                            <RiLockPasswordLine className="color-secondary fs-4" /> Change Password
                          </Link>
                        </Col>
                      </Row>

                      <Row onClick={() => navigate("/order")} className="ms-2 me-2 profileitem pt-2 pb-2 fontFamily">
                        <Col>
                          <Link className="text-decoration-none fontFamily" >
                            <BiBasket className="color-secondary fs-4" /> MarketPlace Order
                          </Link>
                        </Col>
                      </Row>
                      <Row onClick={handleLogout} className="ms-2 me-2 profileitem pt-2 pb-2 fontFamily" >
                        {" "}
                        <Col>
                          <Link
                            //  to={pathname ? pathname + searchpath : searchpath}
                            className="text-decoration-none  fontFamily">
                            <IoLogOutOutline className="color-secondary  fs-4" />{" "}
                            Log Out{" "}
                          </Link>
                        </Col>
                      </Row>
                    </Container>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </div>
            </Dropdown>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default UserDrop;
