import React from 'react'
import { Skeleton } from "@mui/material";
import {  Col, Row } from "react-bootstrap";
import { useMediaQuery } from "@mui/material";
export default function SkeletonServiceDetails() {
  const isXSmallScreen = useMediaQuery('(max-width:400px)');
  const isSmallScreen = useMediaQuery('(min-width:401px) and (max-width:600px)');
  const isMediumScreen = useMediaQuery('(min-width:601px) and (max-width:960px)');
  const isLargeScreen = useMediaQuery('(min-width:961px) and (max-width:1280px)');
  const isXLargeScreen = useMediaQuery('(min-width:1281px)'); // New: Check for extra-large screens
  return (
    <div>
       <Col md={12}>
        <Skeleton width={isXSmallScreen ? 200 : (isSmallScreen ? 250 : (isMediumScreen ? 300 : (isLargeScreen ? 350 : (isXLargeScreen ? 400 : 450))))} height={50} />
      </Col>
      <Row className="mt-3">
        <Col md={7}>
          <Skeleton width={isXSmallScreen ? 250 : (isSmallScreen ? 350 : (isMediumScreen ? 300 : (isLargeScreen ? 400 : (isXLargeScreen ? 600 : 400))))} height={30} />
          <Skeleton className="mb-3" width={isXSmallScreen ? 250 : (isSmallScreen ? 350 : (isMediumScreen ? 300 : (isLargeScreen ? 400 : (isXLargeScreen ? 600 : 400))))} height={30} />
        </Col>
        <Col md={5}>
          <Skeleton
            variant="rectangle"
            className="rounded"
            height={isXSmallScreen ? 200 : (isSmallScreen ? 250 : (isMediumScreen ? 200 : (isLargeScreen ? 200 : (isXLargeScreen ? 300 : 300))))}
          />
        </Col>
        </Row>
    </div>
  )
}
