import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Avatar, Skeleton } from "@mui/material";
import { apiHepingMethod, getDataByUrl } from "../API/HelpingMethod";
import { Link } from "react-router-dom";
import { CroppedText } from "../../helper/custom_helper";
import SkeletonService from "../Homepage/Skeleton-files/SkeletonService";
import { FaLongArrowAltRight } from "react-icons/fa";
import image from "../images/Looper-2 (1).png";
import { useNotification } from "../../hooks";
import { Flip } from "react-reveal";

import { HelmetCustomInclude } from "../../hooks/HelmetCustomInclude";

import noimage from "../images/noimage2.webp"

var loginuser = JSON.parse(localStorage.getItem("userstorage"));
const Services = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10); // Dynamic limit
  const [offset, setOffset] = useState(0);
  const { setShow } = useNotification();
  const inputData = {
    limit: limit,
    offset: offset,
    type: "parent",
  };

  const [pageTitle, setPageTitle] = useState("services");

  const apiCall = async () => {
    setLoading(true);
    if (loginuser) {
      inputData.login_user_id = loginuser?.id
    }
    const response = await getDataByUrl("service", inputData);
    console.log("loginuser-----",response)

    if (!response?.error) {
      setServices(response?.data);
      setLoading(false);
    } else {
      // console.log("Error fetching data:", response?.message);
    }
  };
  useEffect(() => {
    apiCall();
  }, []);

  const numberOfCols = 10;
  const styleFont = { fontFamily: "Montserrat" };

  const headingText = "OUR SERVICES";
  const paragraphText = "  Helping Startups To achieve There";
  const businessGoal = "Business Goal";
  const paragraph =
    "     At Startup Starter, we are dedicated to empowering startups and fueling their journey towards business success. Our comprehensive range of services is specifically designed to support and accelerate your growth, ensuring that you achieve your business goals.";
  const styleServiceList = {
    bgcolor: "var(--primary)",
    width: 50,
    height: 50,
  };

  const styleLearnMoreButton = { color: "#066D98", fontFamily: "Montserrat" };
  const LearnMoreText = "Learn More";
  return (
    <div>

      <HelmetCustomInclude url={"service"} title={pageTitle} />

      <Container fluid className="mt-3 pb-4 bg-linear-top">
        <img id="Servicebgimage" src={image} alt="image" />
        <Container>
          <Row>
            <span className="text-center mt-5 text-light">
              {headingText}
            </span>
            <h2 className="text-center text-light mt-4 fw-bold fontFamily" >
              {paragraphText}
              <br /> {businessGoal}
            </h2>
            <p style={styleFont} className="text-light mt-4 m-0">
              {paragraph}
            </p>
          </Row>
        </Container>
      </Container>

      {/*<Container className="mt-3">
        <img id="Servicebgimage" src={image} alt="img" onError={(e) => e.target.src = noimage} />
        <Row className="text-center">
          <Col className="mt-5">
            <b className="color-secondary" style={{ fontFamily: "Montserrat" }}>
              {headingText}
            </b>
            <h3 className="text-center mt-4 fw-bold">
              {paragraphText}
              <br /> {businessGoal}
            </h3>
            <p style={styleFont} className="mt-4">
              {paragraph}
            </p>
          </Col>
        </Row>
      </Container>*/}

      <Container className="mb-5">
        <Row className="mt-3">
          {loading ? (
            <SkeletonService numberOfCols={numberOfCols} />
          ) : (
            <>
              {services.map((serviceItem, index) => {
                return (
                  <Col className="" lg={6} xl={6} md={6} sm={12} xs={12} key={index} >
                    <Link
                      className="text-decoration-none"
                      to={`/service/${serviceItem.slug}`}>
                      <Flip left>
                        <Card
                          // id="service"
                          style={{ cursor: "pointer", transition: "transform 0.3s" }}

                          onMouseOver={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
                          onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")}
                          className="mt-4 shadow rounded-4 p-2"
                        // style={styleHeight}
                        >
                          <Card.Body className="">
                            <Row>
                              <Col sm={12} lg={12} md={12} xl={12} xs={12}>
                                <Avatar
                                  sx={styleServiceList}
                                  variant="rounded"
                                  alt={serviceItem.url_image}
                                  src={serviceItem.url_image}
                                  onError={(e) => e.target.src = noimage}
                                ></Avatar>
                              </Col>
                            </Row>

                            <Row>
                              <Col sm={12} lg={12} md={12} xl={12} xs={12}>
                                <Card.Title className="mt-2 fw-bold fs-4">
                                  {serviceItem.name}
                                </Card.Title>
                              </Col>
                            </Row>

                            <Row>
                              <Col sm={12} lg={12} md={12} xl={12} xs={12}>
                                <Card.Text className="mt-2 fontFamily">
                                  <small>
                                    {serviceItem.description &&
                                      CroppedText(
                                        serviceItem?.description || "",
                                        140
                                      )}
                                  </small>
                                </Card.Text>
                              </Col>
                            </Row>

                            <Row>
                              <Col
                                sm={12}
                                lg={12}
                                md={12}
                                xl={12}
                                xs={12}
                                className="mt-2"
                              >


                                <Link
                                  className="text-decoration-none"
                                  style={styleLearnMoreButton}
                                  to={`/service/${serviceItem.slug}`}   >
                                  {LearnMoreText} <FaLongArrowAltRight />
                                </Link>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Flip>
                    </Link>
                  </Col>
                );
              })}
            </>
          )}
        </Row>
      </Container>
    </div>
  );
};
export default Services;
