import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {FiChevronRight} from "react-icons/fi";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {HelmetCustomInclude} from "../../hooks/HelmetCustomInclude";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {getDataByUrl} from "../API/HelpingMethod";
import {useNotification} from "../../hooks";
import StartuName from "./StartupIdeaSteps/StartuName";
import StartupIdea from "./StartupIdeaSteps/StartupIdea";
import StartupAudience from "./StartupIdeaSteps/StartupAudience";
import StartupProblems from "./StartupIdeaSteps/StartupProblems";
import StartupProcess from "./StartupIdeaSteps/StartupProcess";
import StartupUnequeness from "./StartupIdeaSteps/StartupUnequeness";
import StartupCompetitor from "./StartupIdeaSteps/StartupCompetitor";
import StartupRequrements from "./StartupIdeaSteps/StartupRequrements";
import {loginUserData} from "../API/client";
import {Backdrop, CircularProgress} from "@mui/material";

const SubmitIdeaStepper = () => {
    const navigate = useNavigate()
    const [pageTitle, setPageTitle] = useState("Submit Startup Idea");
    const [metaKeywords, setMetaKeywords] = useState("idea, startup idea, custom idea, submit startup idea");
    const [metaDescription, setMetaDescription] = useState("Submit your idea and our experts helps you to grow your business.");
    const DisclaimerText = "We never claim this idea just on the submission of your idea in this form. This form is only intended to know general details about your startup idea that so that our experts can analyse";
    const [metaImageUrl, setMetaImageUrl] = useState(null);
    const [pageLoading, setPageLoading] = useState(false);
    let [searchParams] = useSearchParams()
    const {startupIdeaId} = useParams();

    const styleBridgeCrumb = {
        textDecoration: "none",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    };
    const breadcrumbs = [
        {to: "/", label: "Home"},
        {to: "/startupidea", label: "Idea"},
        {label: "Tell us about your Idea", style: styleBridgeCrumb},
    ];
    let authUserData = loginUserData;
    useEffect(() => {
        authUserData = localStorage.getItem("userstorage");
        if (!loginUserData) {
            navigate("/")
        }
    }, [localStorage.getItem("userstorage")])
    const {showError, showSuccess} = useNotification();
    const [data, setData] = useState(null)

    const steps = ['step1', 'step2', 'step3', 'step4', 'step5', 'step6', 'step7', 'step8'];
    const [activeStep, setActiveStep] = React.useState(0);

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const bodyData = {
        login_user_id: authUserData?.id,
        user_type: authUserData.user_type
    }

    const updateStepOnDataLoad = (data) => {
        if (data) {
            if (!data?.startup_name) {
                setActiveStep(0);
            } else if (!data?.startup_idea) {
                setActiveStep(1);
                // } else if (!data?.startup_idea_description) {
                //     setActiveStep(1);
            } else if (!data?.startup_audience) {
                setActiveStep(2);
            } else if (!data?.startup_problem) {
                setActiveStep(3);
            } else if (!data?.startup_process) {
                setActiveStep(4);
            } else if (!data?.startup_uniqueness) {
                setActiveStep(5);
            } else if (!data?.startup_competitor) {
                setActiveStep(6);
            } else if (!data?.startup_requirement) {
                setActiveStep(7);
            } else {
                setActiveStep(7);
            }
        }
    }

    const getStartupIdea = async () => {
        if (startupIdeaId) {
            bodyData.startup_idea_id = startupIdeaId
        }
        setPageLoading(true);
        const response = await getDataByUrl("startup_idea", bodyData);
        // console.log("response---", response)

        if (response?.error === false) {
            setData(response?.data)
            updateStepOnDataLoad(response?.data);
        }

        setPageLoading(false);
    }

    useEffect(() => {
        if (startupIdeaId) {
            getStartupIdea().then(r => r);
        }
    }, [startupIdeaId])


    const handleUpdateWorkGroup = async (newData) => {
        setPageLoading(true);
        let inputData = {
            login_user_id: loginUserData?.id || 0,
            login_user_type: loginUserData?.user_type || "startup",
            ref_type: "startup_idea",
            // slug_startup_idea: newData?.slug || newData?.id || startupIdeaId,
        }
        if (newData?.slug) {
            inputData.slug_startup_idea = newData?.slug;
        } else {
            inputData.ref_id = newData?.id || startupIdeaId;
        }
        const response = await getDataByUrl("user_work_group_update", inputData);
        //  console.log("response----", response);
        //  console.log("inputData----", inputData);

        if (response.error === false) {
            showSuccess(response?.message)
            navigate("/select-service/" + response?.data.token);
        } else {
            showError(response?.message);
        }
        setPageLoading(false);
    };

    return (
        <>
            <HelmetCustomInclude url={"submit-idea"}
                                 title={pageTitle} image={metaImageUrl}
                                 description={metaDescription}
                                 keywords={metaKeywords}/>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={pageLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Container className="mt-5 pt-3 mb-4">
                <div className="d-flex flex-row">
                    {breadcrumbs.map((breadcrumb, index) => (
                        <div key={index} className="">
                            {breadcrumb.to ? (
                                <Link to={breadcrumb.to} style={styleBridgeCrumb} className="color-secondary">
                                    {breadcrumb.label} <FiChevronRight color="#000000"/>
                                </Link>
                            ) : (
                                <div style={styleBridgeCrumb}>{breadcrumb.label}</div>
                            )}
                        </div>
                    ))}
                </div>
                <Row className="mt-4">
                    <Col className="idea-stepper">
                        <Box sx={{width: '100%'}}>
                            <Stepper activeStep={activeStep}>
                                {activeStep !== null && steps.map((label, index) => {
                                    return (<Step key={label}> <StepLabel> </StepLabel> </Step>);
                                })}
                            </Stepper>

                        </Box>
                    </Col>
                </Row>
                <Row className="mt-5">
                    {activeStep === 0 && <StartuName data={data} setData={setData} setActiveStep={setActiveStep}/>}
                    {activeStep === 1 && <StartupIdea data={data} setData={setData} setActiveStep={setActiveStep}/>}
                    {/*{activeStep === 1 &&*/}
                    {/*<StartupDescription data={data} setData={setData} handleBack={handleBack}*/}
                    {/*                    setActiveStep={setActiveStep}/>}*/}
                    {activeStep === 2 &&
                    <StartupAudience data={data} setData={setData} handleBack={handleBack}
                                     setActiveStep={setActiveStep}/>}
                    {activeStep === 3 &&
                    <StartupProblems data={data} setData={setData} handleBack={handleBack}
                                     setActiveStep={setActiveStep}/>}
                    {activeStep === 4 &&
                    <StartupProcess data={data} setData={setData} handleBack={handleBack}
                                    setActiveStep={setActiveStep}/>}
                    {activeStep === 5 &&
                    <StartupUnequeness data={data} setData={setData} handleBack={handleBack}
                                       setActiveStep={setActiveStep}/>}
                    {activeStep === 6 &&
                    <StartupCompetitor data={data} setData={setData} handleBack={handleBack}
                                       setActiveStep={setActiveStep}/>}
                    {activeStep === 7 &&
                    <StartupRequrements data={data} setData={setData}
                                        handleBack={handleBack}
                                        setActiveStep={setActiveStep} handleUpdateWorkGroup={handleUpdateWorkGroup}/>}
                </Row>
                <Row className="mt-5">
                    <Col>
                        <p className="fontFamily" style={{color: "#960B0B"}}><strong
                            className="fontFamily">Disclaimer:</strong>{DisclaimerText}</p>
                    </Col>
                </Row>


            </Container>
        </>
    )
}
export default SubmitIdeaStepper