import { Button, Col, Form, InputGroup } from "react-bootstrap";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getDataByUrl } from "../API/HelpingMethod";
import { Alert, CircularProgress } from "@mui/material";
import * as PropTypes from "prop-types";
import { useNotification } from "../../hooks";
import "../css/mailbutton.css"
class CheckCircleOutlineIcon extends React.Component {
  render() {
    return null;
  }
}

CheckCircleOutlineIcon.propTypes = { fontSize: PropTypes.string };
export default function FooterMailSubscription() {
  const [email, setEmail] = useState("");
  const [resultMessage, setResultMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { showError, showSuccess } = useNotification();
  const [isHoveredMail, setIsHoveredMail] = useState(false);
  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscription = async (e) => {
    e.preventDefault();
    const inputData = {
      email: email,
    };

    setLoading(true);
    const response = await getDataByUrl("mail_subscription_update", inputData);
    if (!response?.error) {
      showSuccess(response?.message);
    } else {
      showError(response?.message);
    }
    setResultMessage(response);
    setEmail("");
    setLoading(false);

    setInterval(() => {
      // setResultMessage(null)
    }, 1000);
  };
  const styleFont = { fontFamily: "Montserrat", marginTop: "20px" };
  // const styleButton = { border: "none", borderRadius: "3px" };
  return (
    <>
      <h5 className="mt-4 fw-bold color-primary fontFamily">Stay Tuned</h5>
      <p style={styleFont}>
        Join our mailing list. We write rarely, but only the best content.
      </p>

      {/* <InputGroup className="mb-3">
        <Form.Control
          className="fontFamily"
          size={"md"}
          name={"email"}
          value={email}
          onChange={handleChange}
          placeholder="Email Address"
          aria-label="Email Address"
          aria-describedby="basic-addon1"
        />
        <button
          onClick={handleSubscription}
          className={`slide-buttonmail text-decoration-none ${isHoveredMail ? "hovered" : ""
            }`}
          onMouseEnter={() => setIsHoveredMail(true)}
          onMouseLeave={() => setIsHoveredMail(false)}

        >
          {loading ? (
            <CircularProgress className="mx-4" color="inherit" size={16} />
          ) : (
            "Subscribe"
          )}
        </button>
      </InputGroup> */}

      <InputGroup className="mb-3 footer-input">
        <Form.Control
          size={"md"}
          name={"email"}
          value={email}
          onChange={handleChange}
          placeholder="Email Address"
          aria-label="Username"
          aria-describedby="basic-addon1"
          className="border-right"
        />
        <InputGroup.Text id="basic-addon1" onClick={handleSubscription} > {loading ? (
          <CircularProgress className="mx-4" color="inherit" size={16} />
        ) : (
          "Subscribe"
        )} </InputGroup.Text>

      </InputGroup>




      <span className={"d-block px-2 text-capitalize"}>
        {resultMessage &&
          (resultMessage?.error ? (
            <small className="text-danger">
              {resultMessage?.message ||
                "Something went wrong. Please try again."}
            </small>
          ) : (
            <small className="text-success">
              {resultMessage?.message || "You subscribed successfully"}
            </small>
          ))}
      </span>
    </>
  );
}
