import React, { useEffect } from "react";
import { Container, Col, Nav, Navbar, Row } from "react-bootstrap";
import logoHeader from "../../assets/logos/logo-square-web.webp";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useState } from "react";
import Footer from "./Footer";
import { BiMenu } from "react-icons/bi";
import "../css/Home.css";
import UserDrop from "./UserDrop";
import { useNotification } from "../../hooks";
import noimage from "../images/noimage2.webp"

const Navigation = ( ) => {
  const { showError, showSuccess, setLoginModal, setLocation, setPrevLocation } = useNotification();

  const [isHovered, setIsHovered] = useState(false);
  const [show, setShow] = useState(false);
  const [color, setColor] = useState(false);
  
  const location = useLocation()
  const [activeStatus, setActiveStatus] = useState("");
  const [styleHeader, setStyleHeader] = useState({
    backgroundColor: "transparent",
    color: "black",

  });
  const [styleTransparent, setStyleTransparent] = useState({
    backgroundColor: "transparent",
    color: "black",
  });

  const [styleOnscroll, setStyleOnscrollOnscroll] = useState({
    backgroundColor: "white",
    color: "black",
    boxShadow: "0 1px 6px 0px gray"
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  var loginuser = JSON.parse(localStorage.getItem("userstorage"));
  useEffect(() => {
    const url = location.pathname.split('/');
    setActiveStatus(url[1] || "")
  }, [location])
 

  const changeColor = () => {
    if (window.scrollY >= 100) {
      setColor(true);
      setStyleHeader(styleOnscroll);
    } else {
      setColor(false);
      setStyleHeader(styleTransparent);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", changeColor);

    return () => {
      window.removeEventListener("scroll", changeColor);
    };
  }, [changeColor]);

  const navigationLinks = [
    { id: "navlink-home", to: "/", text: <div className={activeStatus === 'home' ? 'active-nav' : ''}>Home</div> },
    { id: "navlink-marketplace", to: "/marketplace", text: "Marketplace" },
    { id: "navlink-service", to: "/service", text: "Services" },
    { id: "navlink-idea", to: "/project-idea", text: "Ideas" },
    { id: "navlink-resources", to: "/blog", text: "Resources" },
  ];


  const activeNav = () => {
    let activeClass = ''
    if (activeStatus === 'home') {
      activeClass = 'active-nav'
    }
    if (activeStatus === 'marketplace') {
      activeClass = 'active-nav'
    }
    if (activeStatus === 'service') {
      activeClass = 'active-nav'
    }
    if (activeStatus === 'project-idea') {
      activeClass = 'active-nav'
    }
    if (activeStatus === 'blog') {
      activeClass = 'active-nav'
    }
    return activeClass
  }


  const styleWidth = {
    width: "60%",
  };

  const loginText = "Login";
  return (
    <div>
      <Container fluid className="fixed-top" style={{
        backgroundColor: "white",
        boxShadow: "0 1px 6px 0px gray",
        top: 0,
        zIndex: 2, } } >
        <Navbar className="pt-0 pb-0">
          <Container>
            <Navbar.Brand>
              <Nav className="d-lg-none d-block">
                <BiMenu size={35} className="d-lg-none d-block" style={{ cursor: "pointer" }} onClick={handleShow} />
              </Nav>
              <Nav className="d-lg-block d-none">
                <Link to="/">
                  <img src={logoHeader} onError={(e) => e.target.src = noimage} style={{ width: "40px", height: "40px" }}
                    className="img-fluid"
                   alt={'logo'}/>
                </Link>
              </Nav>
            </Navbar.Brand>
            <Offcanvas style={styleWidth} show={show} onHide={handleClose}>
              <Offcanvas.Header className="pt-1" closeButton>
                <Offcanvas.Title>
                  <img
                    src={logoHeader}
                    style={{ width: "50px", height: "50px" }}
                    className="img-fluid"
                  />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Container>
                  <Row>
                    <Col className="ps-0">
                      <Nav className="ms-auto">
                        <Nav>
                          {navigationLinks &&
                            Array.isArray(navigationLinks) &&
                            navigationLinks.map((navs, index) => {
                              return (
                                 
                                  <Nav style={{ fontSize: "17px" }} key={index} >
                                    <NavLink to={navs.to} className={"nav-link fontFamily" + activeNav}>
                                      {navs?.text}
                                    </NavLink>{" "}
                                  </Nav>
                                
                              );
                            })}
                        </Nav>
                      </Nav>
                    </Col>
                  </Row>
                </Container>
              </Offcanvas.Body>
            </Offcanvas>
            <Nav className="ms-auto d-lg-block d-none">
              <Nav>
                {navigationLinks &&
                  Array.isArray(navigationLinks) &&
                  navigationLinks.map((navs, index) => (
                    <Nav
                      className="ms-3"
                      style={{ transition: "transform 0.3s" }}
                      onMouseOver={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
                      onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")}
                      key={index} >
                      <NavLink to={navs.to} className={"nav-link fontFamily" + activeNav} >
                        {navs?.text}
                      </NavLink>
                    </Nav>
                  ))}
              </Nav>
            </Nav>

            <Nav className="ms-auto">
              {loginuser ? (
                <div>
                  <UserDrop />
                </div>
              ) : (
                <div
                  //  to="/verify" 
                  onClick={setLoginModal}
                  className={` slide-button1 text-decoration-none ${isHovered ? "hovered" : ""}`} >
                  {loginText}
                </div>
              )}
            </Nav>
          </Container>
        </Navbar>
      </Container>
      <Outlet />
      <Footer />
    </div>
  );
};
export default Navigation;
