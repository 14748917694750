import React, { useEffect, useState } from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import { FiChevronRight } from "react-icons/fi";
import card from "../images/image 6about.png";
import { Link } from "react-router-dom";
import AboutContent from "./AboutContent";
import { Helmet } from "react-helmet";
import logoFooter from "../../assets/logos/logo-footer.webp";
import { cropTextHelmet, getFullPageTitle } from "../../hooks/useCustomHelpingMethod";
import image from "../images/Looper-2 (3).png";
import { HelmetCustomInclude } from "../../hooks/HelmetCustomInclude";

const AboutUs = () => {
  
  const [pageTitle, setPageTitle] = useState("About Us | Our Company is Providing High-Quality Products and Service"); 
   const [metaKeywords, setMetaKeywords] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaImageUrl, setMetaImageUrl] = useState(null);

  const aboutTitle = "Unlock the Power of Ideas";
  const aboutDes =
    "  From Inception to Realization. Our Startup Support System Transforms Ideas into Action, Guiding New Ventures through Every Stage of Growth. Empowering Startups with the Tools, Resources, and Expertise to Transition from Concept to Execution, Accelerating their Journey towards Success.";
  const withText = "with";
  const starterStartupText = " Startup Starter";

  const menuItems = [
    { label: "Home", link: "/" },
    { label: "About Us", link: "/about-us" },
    // Add more items as needed
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const styleBridgcroumb = {
    whiteSpace: "nowrap",
    overflow: "hidden",
  };
  return (
    <div className="pt-3">
      
      <HelmetCustomInclude url={"about"} title={pageTitle} image={metaImageUrl}/>


      <img id="Servicebgimage" src={image} alt="image" />
      <Container fluid className="primaryColor  pb-5">
        <Container>
          <Row>
            <div className="d-flex flex-row mt-5">
              {/* <div className="p-2" ><Link to="/" className=" text-light text-decoration-none">Home<FiChevronRight /></Link></div>
                        <div className="p-2 text-light">About Us</div> */}
              {menuItems.map((item, index) => (
                <div key={index} className="p-2">
                  {index < menuItems.length - 1 ? (
                    <Link
                      style={styleBridgcroumb}
                      to={item.link}
                      className="text-light text-decoration-none"
                    >
                      {item.label}
                      <FiChevronRight />
                    </Link>
                  ) : (
                    <div style={styleBridgcroumb} className="text-light">
                      {item.label}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </Row>
          <br />
          <Row>
            <Col md={8} sm={12} xs={12}>
              <h1 className="text-light">{aboutTitle}</h1>
              <h1 className="text-light">
                {withText}
                <font className="color-secondary fs-2">
                  {starterStartupText}
                </font>
              </h1>
              <p className="mt-3 text-light fontFamily">{aboutDes}</p>
            </Col>
            <Col md={4}>
              <Card md={6} lg={6} className="border-0">
                <Card.Img variant="top" src={card} />
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
      <AboutContent />
    </div>
  );
};
export default AboutUs;
