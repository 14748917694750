import React, {useEffect, useState} from "react";
import {Col, Container, Form, Row} from "react-bootstrap";
import {FiChevronRight, FiMapPin, FiPhoneCall} from "react-icons/fi";
import {Link} from "react-router-dom";
import axios from "axios";
import image from "../images/Looper-2 (1).png";
import {useNotification} from "../../hooks";
import {MdOutlineMailOutline} from "react-icons/md";
import {CircularProgress} from "@mui/material";
import {HelmetCustomInclude} from "../../hooks/HelmetCustomInclude";
import {appAPiBasicInput, appBaseUrl} from "../API/client";

// var loginuser = JSON.parse(localStorage.getItem("userstorage"));
const ContactUs = () => {
  const [isHovered, setIsHovered] = useState(false);
  const {showError, showSuccess} = useNotification();
  const [button, setButton] = useState("Send Message");
  const [buttonClicked, setButtonClicked] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    subject: "",
    message: "",
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const inputData = {
    // "device_type":"android",
    // "sstoken":"95vdb549w0zp58lchu",
    offset: 0,
    limit: 5,
    ...formData,
  };
  const [pageTitle, setPageTitle] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaImageUrl, setMetaImageUrl] = useState(null);


  const handleSubmit = async (e) => {
    setButtonClicked(true)
    e.preventDefault();
    setButton(<CircularProgress color="inherit" size={16} />);
    const apiEndpoint = appBaseUrl + "user_client_query";
    const inputData = {
      device_type: "web",
      sstoken: appAPiBasicInput?.sstoken,
      offset: 0,
      limit: 5,
    };

    try {
      const requestData = { ...formData, ...inputData };
      // Send POST request to the API using Axios
      const response = await axios.post(apiEndpoint, {
        ...requestData,
        device_token: localStorage.getItem('device_token'),
        device_latitude: localStorage.getItem('device_latitude'),
        device_longitude: localStorage.getItem('device_longitude')
      });
      if (!response?.error) {
        showSuccess(response?.data?.message);
      } else {
        showError(response?.message);
        // console.log("Error fetching data:", response?.message);
      }
      setButton("Send Message");
      // Handle the response as needed
      // console.log("API Response:", response.data);
    } catch (error) {
      // Handle errors
      // console.error("Error submitting form:", error);
    }
    setButtonClicked(false)
  };

  const handlechange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const stylePara = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    // textOverflow: "ellipsis",
  };



  const menuItems = [
    { label: "Home", link: "/" },
    { label: "Contact Us", link: "/contact-us" },
    // Add more items as needed
  ];

  const contactusText = "Contact Us";
  const contactusPara = "Feel free to contact us at any time";

  const src =
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14662.296878719662!2d77.46252606931152!3d23.25857612771077!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397c427923e8c8d7%3A0xbe739f095e90a10b!2sAimerse%20Technologies%20India%20Private%20Limited!5e0!3m2!1sen!2sin!4v1707556217848!5m2!1sen!2sin";

  const styleBorder = {
    border: "none",
  };

  return (
    <div>
      <HelmetCustomInclude url={"contact-us"} title={pageTitle} image={metaImageUrl} />
      <img id="Servicebgimage" src={image} alt="img" />
      <Container className="mt-5">
        <Row>
          <div className="d-flex flex-row mt-3">
            {/* <div className="p-2" ><Link to="/" className=" text-light text-decoration-none">Home<FiChevronRight /></Link></div>
                        <div className="p-2 text-light">About Us</div> */}
            {menuItems.map((item, index) => (
              <div key={index} className="p-2">
                {index < menuItems.length - 1 ? (
                  <Link style={stylePara} to={item.link} className="color-secondary text-decoration-none" >
                    {item.label}
                    <FiChevronRight />
                  </Link>
                ) : (
                  <div style={stylePara} className="text-dark">
                    {item.label}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className=" mt-3">
            <h3 style={stylePara} className="fw-bold">
              {contactusText}
            </h3>
            <Row>
              <Col sm={12} xs={12} md={12} lg={12}>
                <p className="mt-4 fontFamily" >
                  {contactusPara}
                </p>
              </Col>
            </Row>
          </div>
        </Row>
        <Row className="mt-5">
          <Col xl={6} lg={6} md={6} sm={12} xs={12} className="mb-5">


            <Form className="mb-5" onSubmit={handleSubmit}>
              <div className="mb-3">
                <Form.Label className="fw-bold fontFamily m-0">Name</Form.Label>
                <Form.Control required placeholder="Name" type="text" name="name"
                  value={formData?.name} onChange={handlechange}
                  className="fontFamily"
                />
              </div>

              <div className="mb-3">
                <Form.Label className="fw-bold fontFamily m-0">
                  Email ID*
                </Form.Label>
                <Form.Control
                  required
                  placeholder="Email ID"
                  type="email"
                  name="email"
                  value={formData?.email}
                  onChange={handlechange}
                  className="fontFamily"
                />
              </div>

              <div className="mb-3">
                <Form.Label className="fw-bold fontFamily m-0">
                  Mobile Number
                </Form.Label>
                <Form.Control
                  required
                  placeholder="Mobile Number"
                  type="text"
                  name="mobileNumber"
                  value={formData?.mobileNumber}
                  onChange={handlechange}
                  className="fontFamily"
                />
              </div>

              <div className="mb-3">
                <Form.Label className="fw-bold fontFamily m-0">
                  Subject
                </Form.Label>
                <Form.Control
                  required
                  placeholder="Subject"
                  type="text"
                  name="subject"
                  value={formData?.subject}
                  onChange={handlechange}
                  className="fontFamily"
                />
              </div>

              <div className="mb-3">
                <Form.Label className="fw-bold fontFamily m-0">
                  Message
                </Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  rows={3}
                  name="message"
                  value={formData?.message}
                  onChange={handlechange}
                  className="fontFamily"
                />
              </div>
              <div className="text-center">
                {
                  buttonClicked && (
                    <button className='fontFamily text-decoration-none mb-4 disabled-btn w-100' disabled>
                      <>
                        <CircularProgress style={{ color: "var(--secondary)" }} className="mt-1" size={16} />&nbsp;&nbsp; Loading...
                      </>
                    </button>
                  )

                }
                {
                  !buttonClicked && (
                    <button
                      className={`slide-button1 text-decoration-none w-100 ${isHovered ? "hovered" : ""
                        }`}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                      type="submit"
                    >
                      {button}
                    </button>
                  )

                }
              </div>


            </Form>
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12} className="mb-5">
            <Row className="">
              <Col sm={6} className="mt-3 text-center ">
                <Col className="card mx-3 shadow">
                  <Col className="card-body">
                    <FiPhoneCall className="color-secondary fs-2" />
                    <h5 className="card-title mt-2 fontFamily fw-bold">
                      Call Us
                    </h5>
                    <p className="card-text">+91 7898382222</p>
                  </Col>
                </Col>
              </Col>
              <Col sm={6} className="mt-3 text-center">
                <Col className="card mx-3 shadow">
                  <Col className="card-body">
                    <MdOutlineMailOutline className="color-secondary fs-2" />

                    <h5 className="card-title fontFamily fw-bold">Email Us</h5>
                    <p className="card-text">support@startupstarter.in</p>
                  </Col>
                </Col>
              </Col>
            </Row>


            <Row className="mb-5">
              <Col className="mt-5 text-center">
                <Col className="card mx-3 shadow">
                  <Col className="card-body">
                    <FiMapPin className="color-secondary fs-2" />

                    <h5 className="card-title mt-2 fontFamily fw-bold">
                      Startup Starter Location
                    </h5>
                    <p className="card-text">
                      130, C-Sector, Indrapuri,Bhopal (462001),Madhya
                      Pradesh,India
                    </p>
                  </Col>
                </Col>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <iframe
            className="mb-5"
            src={src}
            width="600"
            height="450"
            style={styleBorder}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </Row>
      </Container>
    </div>
  );
};
export default ContactUs;
