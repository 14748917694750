import React, { useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import ReadMoreText from "../ReadmoreText/ReadmoreText";
import { apiHepingMethod, getDataByUrl } from "../API/HelpingMethod";
import SkeletonTermsandcondition from "../Homepage/Skeleton-files/SkeletonTermsandcondition";
import { HelmetCustomInclude } from "../../hooks/HelmetCustomInclude";
const AboutContent = () => {
  const [about, setAbout] = useState({});
  const [loading, setLoading] = useState(false);
  const [pageTitle, setPageTitle] = useState("About Us | Our Company is Providing High-Quality Products and Service");
  const [metaKeywords, setMetaKeywords] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaImageUrl, setMetaImageUrl] = useState(null);

  const apicall = async () => {
    setLoading(true);
    const response = await getDataByUrl("about", {}); 
    if (!response?.error) {
      setAbout(response?.data);
    } else {
      // console.log("Error fetching data:", response?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    apicall();
  }, []);


  return (
    <div>
 


      <Container className="mb-5">
        {loading ? (
          <SkeletonTermsandcondition />
        ) : (
          <>
            <h5 className="fw-bold mt-5">{about.title}</h5>
            <div className="fontFamily"
              dangerouslySetInnerHTML={{ __html: about?.description || "" }}
            />
          </>
        )}
        {/* <Row>
          <Col xs={12} className="mt-5">
            <Row>
              <h3 className=" text-center">
                What We Do At{" "}
                <font className="color-secondary fs-2">Startup Starter</font>
              </h3>
              <p className="mt-4">
                <ReadMoreText text={longText} maxLength={319} />
              </p>
            </Row>
            <Row>
              <p>
                Youngsters are now more enthusiast and excited for starting
                startup but new entrepreneurs are facing issues for finding
                solutions for software development, digital marketing, branding
                and promotions etc. Startup Starter, India’s most trusted
                startup starter support platform app, allows you to do the same,
                anytime, anywhere. You can consult an expert online across 10+
                countries. We provide services to our startup entrepreneurs for
                various platforms to help startup. Online idea validation from
                the comfort of your home with less efforts
              </p>
            </Row>
            <Row>
              <p>
                You can find various suggestions on your idea for your startup
                to validate the idea before you start working on it. You can
                share your problems related to your startup you are facing in
                any phase from idea validation to implementation, development,
                deployment, marketing or branding. If you are an entrepreneur
                and searching for an idea to start with, you can find best ideas
                in Startup Starter app.
              </p>
            </Row>
          </Col>
        </Row> */}
      </Container>
    </div>
  );
};
export default AboutContent;
