import {CircularProgress} from "@mui/material";
import React, {useState} from "react";
import {Col, Container, Form, Row} from "react-bootstrap";
import {getDataByUrl} from "../../API/HelpingMethod";
import {useNotification} from "../../../hooks";
import {useNavigate, useSearchParams} from "react-router-dom";

const StartuName = ({data, setData, setActiveStep}) => {
    var loginuser = JSON.parse(localStorage.getItem("userstorage"));
    const [btnLoading, setBtnLoading] = useState(false);
    const {showError, showSuccess} = useNotification();
    let [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const [formData, setFormData] = useState({startup_name: data?.startup_name || loginuser?.name_brand || ""});
    const [startupIdeaId, setStartupIdeaId] = useState(searchParams.get("ideaId"));

    const bodyData = {
        login_user_id: loginuser?.id,
        user_type: loginuser.user_type,
    }

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = async () => {
        if (startupIdeaId) {
            bodyData.id = startupIdeaId
            bodyData.values = formData
        } else {
            bodyData.status = "incomplete"
            bodyData.startup_name = formData.startup_name
        }

        setBtnLoading(true);
        const response = await getDataByUrl("startup_idea_update", bodyData);
        //console.log(response);
        if (response?.error === false) {
            if (response?.data) setData(response?.data);

            showSuccess(response?.message)
            setActiveStep(1);
            if (startupIdeaId) {
                navigate("/submit-idea?ideaId=" + startupIdeaId)
            } else {
                navigate("/submit-idea?ideaId=" + response?.data?.id)
            }
        } else {
            showError(response?.message)
        }

        setBtnLoading(false)
    }


    return (
        <>
            <Col>
                <Container>
                    <Row><Col sm={8} className="mx-auto h6 mt-3">Write down name of your Startup*</Col></Row>
                    <Row>
                        <Col sm={8} className="mx-auto">
                            <Form.Control
                                as="textarea"
                                className="idea-stepper-input"
                                type="text"
                                name="startup_name"
                                value={formData.startup_name || ""}
                                onChange={handleInputChange}
                                placeholder="Please enter your startup name"/>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="text-center mt-4">
                            <button disabled={btnLoading} className={btnLoading ? "disabled-btn" : "slide-button1"}
                                onClick={handleSubmit}>
                                {btnLoading && <><CircularProgress color="inherit" className="mt-1" size={16} />&nbsp;</>}Proceed
                            </button>
                        </Col>
                    </Row>
                </Container>
            </Col>

        </>
    )
}
export default StartuName;