import axios from "axios";
import logoFooter from "../../assets/logos/logo-footer.webp";
import logo from "../../assets/logos/logo.webp";
import favicon from "../../assets/logos/favicon.png";

const appBaseUrl = "https://testing.startupstarter.in/api/Application/";
// const appBaseUrl = "https://startupstarter.in/api/Application/";

const appAuthToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiNjVmMWJmMTAzMmNiNWY2YzA2NWFkNDdhZTkzMmRiMWUzN2RjYzZmZTNjNWRkYWY5N2YwNzkzYTUzMDUzYzA0YTU0ZWZjYjAxYjZjN2M0OTEiLCJpYXQiOjE2MjgyNTA2NzUuMDY0MDIzLCJuYmYiOjE2MjgyNTA2NzUuMDY0MDMxLCJleHAiOjE2NTk3ODY2NzUuMDE5ODQ5LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.WsJw_x8nPxdWmCjE5Ex6NcYFdKJ6K0ur5IEOxN4mgQpujsexjuCFh8D_PZm_4C2I3Yfl7z2EJ4SS1rlx9ISzuIpjHavbgp4pSxhsuNYKoWB1kfJbrpoXzDF-982EPQJOkcT-jywtn4oli23Ge0vbEuye06jJ3faZyQZmPnCyUMyQyu4zsKqI3yLvNyp0C9MmAGU-Mm-Vl_CzTl8Cjnh_055vNhjFjHVr57_ZnyXT_wJpi0LEaekCkamUdZGoxc4D8g4UxEjPZYXdz1Tng46GHz2_sgzsmzcGEdCUPZtGa2DEjIGOte_BVeyuTPnGprDwCfN0R4khigIhHGNODGHhmwpzX0dT3Gwp9LJ6uw7suGQ5cdDYc9a0TpBMzimDdhGXYkQPERoaq4bxUObdEVs0NbuyqZ6f5O-2-F_ke0PbShR93RIaWKFYbJoHgeG3s4ZxBXNiYuC8VilO4alLQwoayO2T_XfM8_8UVofnHw72gnZBYqZ7uWrdD9NkQ4dMyF79dY7KTVP1BcCp4EfA7w_woSxylFIKMp-DsNt8q69OfCTtvVOmQ-E9LI8d6OBdcXz80maAGF0qWY80_rSLf6WwNv8hAQSW4vqWivnkEWf9rv4O2jVzuYBLQuma22gGyUKOxuT9uIas6kf9KpVn0BZq_umN-hPhtXaJ4ZW7OqFXMGI";

//testing
const appApiSstoken = "95vdb549w0zp58lchu";
//live
// const appApiSstoken="NBsQomtj3hVPYBu1W5";

const loginUserData = JSON.parse(localStorage.getItem('userstorage'));


const appApiHeader = {
    Authorization: "Bearer " + appAuthToken,
    Accept: "application/json",
    ContentType: "application/json",
};

const appApiHeaderUpload = {
    Authorization: "Bearer " + appAuthToken,
    ContentType: "multipart/form-data",
};

const client = axios.create({baseURL: appBaseUrl});

const appAPiBasicInput = {
    device_type: "web",
    sstoken: appApiSstoken,
    device_token: localStorage.getItem('device_token'),
    device_latitude: localStorage.getItem('device_latitude'),
    device_longitude: localStorage.getItem('device_longitude')
};

const paymentGatewayCurrency = "USD";
// const paymentGatewayCurrency = "USERCURRENCY";
const appFooterLogo = logoFooter;
const appLogo = logo;
const appFavicon = favicon;
const isAppOTPShow = true;
const isAppPaymentShow = true;
const isAppTempDown = false;
const appTitle = "Startup Starter";
const appAddress = "130, C-Sector, Indrapuri, Bhopal (462001), Madhya Pradesh, India";

function removeHtmlTags(str) {
    const deTagged = str.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '');
    let deNewlined = deTagged.replace(/\n/g, '');
    deNewlined = deNewlined.replaceAll("&nbsp;", ' ');
    return deNewlined;
    // return str.split(/<[^>]*>/).join('');
}

export default client;
export {
    removeHtmlTags,
    paymentGatewayCurrency,
    appTitle,
    appAddress,
    isAppTempDown,
    isAppOTPShow,
    isAppPaymentShow,
    appBaseUrl,
    appAuthToken,
    appApiHeader,
    appApiHeaderUpload,
    appAPiBasicInput,
    loginUserData,
    appFooterLogo,
    appLogo,
    appFavicon,
}