


import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap"; 
import { getDataByUrl } from "../API/HelpingMethod";
import { Skeleton } from "@mui/material";
import { Link } from "react-router-dom";  
import { FaLongArrowAltRight, FaRegUserCircle } from "react-icons/fa";
import { SlCalender } from "react-icons/sl";  
import { Swiper, SwiperSlide } from 'swiper/react'; 
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import noimage from "../images/noimage2.webp"

import { Autoplay, Pagination, Navigation } from 'swiper/modules';
const RelatedBlog = () => {
  const [blog, setBlog] = useState([]);
  const [loading, setLoading] = useState(false);
  const blogData = { 
    login_user_id: 7,
    login_user_type: "startup", 
    limit: 10,
    offset: 0, 
  };


  const RelatedblogApicall = async () => {
    setLoading(true);
    const response = await getDataByUrl("blog", blogData);
    if (!response?.error) {
      setBlog(response?.data); 
      setLoading(false);
    } else { 
    }
  };
  useEffect(() => {
    RelatedblogApicall();
  }, []);

  const CroppedText = ({ text, maxLength }) => {
    const croppedText =
      text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

    return <div>{croppedText}</div>;
  };


  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };
  const styleCardstext = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "22px",
  };
  const styleCardHeight = { height: "500px" };

  const SkeletonRelatedBlog = ({ titleDescriptionWidth, cardImageWidth, titleWidth }) => {
    return (
      <>
        <Col md={4} className="mb-3">
          <Card className="shadow mx-3" style={styleCardHeight}>
            <Card.Body>
              <Skeleton variant="rectangle" className="rounded" width={cardImageWidth} height={250} />
              <Card.Text className="fw-bold mt-3">
                <Skeleton variant="rectangle" className="rounded mt-3" width={titleDescriptionWidth} height={30} />
              </Card.Text>
              <Skeleton variant="rectangle" className="rounded mt-3" width={titleDescriptionWidth} height={20} />
              <Skeleton variant="rectangle" className="rounded mt-3" width={titleWidth} height={20} />
              <Skeleton variant="rectangle" className="rounded mt-3" width={100} height={20} /> 
              <div className="d-flex mt-3">
                <Skeleton variant="circular" className="" width={20} height={20} />
                <Skeleton variant="rectangle" className="rounded mx-2" width={20} height={20} />
                <Skeleton variant="rectangle" className="rounded mx-3" width={20} height={20} />
                <Skeleton variant="rectangle" className="rounded mx-3" width={100} height={20} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </>
    );
  };

  let titleDescriptionWidth;
  let cardImageWidth;
  let titleWidth;
  const numberOfItems = 3

  

  return (
    <div>
      <Container>
        <Row className="h3 mt-4 fw-bold mx-3">Related Blogs</Row>
        <br />
        <Row>
          {loading ? (
            Array.from({ length: numberOfItems }, (_, index) => (
              <SkeletonRelatedBlog titleDescriptionWidth={titleDescriptionWidth}
                cardImageWidth={cardImageWidth}
                titleWidth={titleWidth} key={index} />
            ))
          )
            :
            <>
              <Swiper
                slidesPerView={4}
                centeredSlides={true}

                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                // className="mySwiper"
                breakpoints={{
                  // when window width is >= 1200px (xl)
                  1200: {
                    slidesPerView: 3,
                  },
                  // when window width is >= 992px (lg) and < 1200px (xl)
                  992: {
                    slidesPerView: 3,
                  },
                  // when window width is >= 768px (md) and < 992px (lg)
                  768: {
                    slidesPerView: 2,
                  },
                  // when window width is >= 576px (sm) and < 768px (md)
                  576: {
                    slidesPerView: 1,
                  },
                  // when window width is < 576px (xs)
                  0: {
                    slidesPerView: 1,
                  },
                }}
              >
                {
                  blog.length > 0 ? (
                    blog.map((blogItem, index) => (

                      <SwiperSlide key={index}>
                        <Col sm={12} xs={12} md={12} lg={12} key={index}>
                          <Card className="shadow mx-3 mb-5 border-0" >
                            <Card.Body className="">
                              <Card.Img
                                className="w-100"
                                src={blogItem.media_url}
                                alt="./"
                                onError={(e) => e.target.src = noimage}
                              />
                              <Card.Text
                                className="fw-bold mt-3"
                                style={styleCardstext}
                              >
                                {blogItem.title}
                              </Card.Text>
                              <p className="fontFamily">
                                <CroppedText
                                  text={blogItem.page_description}
                                  maxLength={70}
                                />
                              </p>
                              <Link
                                style={{ fontSize: "17px" }}

                                to={`/blog/${blogItem.slug}?slug=${blogItem.slug}`}
                                className="color-secondary text-decoration-none"
                              >
                                Read More <FaLongArrowAltRight />
                              </Link>
                              <div className="d-flex mt-3">
                                <FaRegUserCircle className="color-secondary" />

                                <h6 className="mx-2">Startup Starter</h6>

                                <SlCalender className="color-secondary mx-2" />
                                <h6 className="mx-2">
                                  {formatDate(blogItem.date_schedule)}
                                </h6>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>

                      </SwiperSlide>

                    ))
                  ) : (
                    <SwiperSlide>
                      <div>No blog posts available</div>
                    </SwiperSlide>

                  )
                }


              </Swiper>


            </>
          }
        </Row>
      </Container>
    </div>
  );
};
export default RelatedBlog;

