import { Autocomplete, Box, CircularProgress, FormControl, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import PropTypes from 'prop-types';
import { useNotification } from "../../../hooks";



function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const RegisterType = ({ setActiveStep, handleBack, setBodyData, bodyData }) => {
    const [btnLoading, setBtnLoading] = useState(false);
    const [value, setValue] = useState(0);
    const [rol, setRol] = React.useState('');
    const { showError, showSuccess, setOpenBackDrop } = useNotification();



    const handleChangeTab = (event, newValue) => {
        console.log("newValue--", newValue)
        if (newValue === 1) {
            setBodyData({ ...bodyData, contributor_user_type: 'company' });
        } else if (newValue === 0) {
            delete bodyData?.contributor_user_role
            delete bodyData?.contributor_team_size
            setBodyData({ ...bodyData, contributor_user_type: 'individual' });
        }

        setValue(newValue);
        setRol('');
    };


    const userRole = [
        { id: 1, role: "Founder" },
        { id: 2, role: "CEO" },
        { id: 3, role: " Director" },
        { id: 4, role: "Project Manager" },
        { id: 5, role: "Project Coordinator" },
        { id: 6, role: "Team Leader" },
        { id: 7, role: "Designer" },
        { id: 8, role: "Database" },
        { id: 9, role: "Digital Marketer" },
        { id: 10, role: "DevOps" },
        { id: 11, role: "Developer" },
        { id: 12, role: "Tester" }
    ]


    const handleChange = (event) => {
        setRol(event.target.value);
        setBodyData({ ...bodyData, contributor_user_role: event.target.value });
    };
    const handleSubmit = () => {
        if (bodyData?.contributor_user_type === 'company' && !bodyData?.contributor_user_role) {
            showError("Please Enter Your Rol In Your Company");
        } else {
            setActiveStep(3)
        }
    }
    return (
        <>
            <Container>
                <Row xs={{ cols: 1 }}>

                    <Col className="text-center mb-3 mt-4 pt-2 fw-bold"> <span>What's Best Describes You ?</span> </Col>
                    <Col className="mt-3 mx-auto d-flex justify-content-center">
                        <Container>
                            <Row className=''>
                                <Col className="register-type">
                                    <Tabs className="login-switch" textColor="white" variant="fullWidth" value={value} aria-label="basic tabs example" centered>
                                        <Tab onClick={(e) => handleChangeTab(e, 0)} defaultChecked={true} label="Individual"
                                            className={bodyData.contributor_user_type === 'individual' ? "selected-register-type" : "unselect-register-type"} {...a11yProps(0)} />
                                        <Tab onClick={(e) => handleChangeTab(e, 1)} label="Company" className={bodyData.contributor_user_type === 'company' ? "selected-register-type" : "unselect-register-type"} {...a11yProps(1)} />
                                    </Tabs>
                                </Col>
                            </Row>

                        </Container>
                    </Col>
                    {bodyData.contributor_user_type === 'company' && <Col className="mt-4 h6  ">
                        <span>What's Your Role In Your Company?</span>
                    </Col>}

                    {bodyData.contributor_user_type === 'company' && <Col className="mt-1 mx-auto d-flex justify-content-center mui-select">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Role</InputLabel>
                            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={rol} label="Role" onChange={handleChange} >
                                {
                                    Array.isArray(userRole) && userRole.map((data, index) => {
                                        return (
                                            <MenuItem key={index} selected={bodyData?.contributor_user_role} value={data.role}>{data.role} </MenuItem>

                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Col>}
                    <Col className="d-flex justify-content-center mt-5">
                        <div className="me-2">
                            <button onClick={handleBack} className="input-primary-buttons" >
                                Back
                            </button>
                        </div>
                        <div className="ms-2">
                            <button disabled={btnLoading} className={btnLoading ? "disabled-btn" : "input-buttons"} onClick={handleSubmit}>
                                {btnLoading && <><CircularProgress color="inherit" className="mt-1" size={16} />&nbsp;</>}Next
                            </button>
                        </div>
                    </Col>
                </Row>

            </Container>
        </>
    )
}
export default RegisterType