import React, { useEffect } from "react";
import { loginUserData } from "../API/client";
import ContributerProfileUpdate from "./ContributerProfileUpdate";
import UserProfileUpdate from "./UserProfileUpdate";
import InvestorProfileUpdate from "./InvestorProfileUpdate";
import { useNavigate } from "react-router-dom";

const ProfileUpdate = () => {
    const loginuser = loginUserData;

    const navigate = useNavigate();

    useEffect(() => {
        if (!loginUserData) {
            navigate("/");
        }  
    }, [loginUserData])
    return (
        <>
        { loginuser?.user_type === "contributor" && <ContributerProfileUpdate/> }
        { loginuser?.user_type === "startup" && <UserProfileUpdate/> }
        { loginuser?.user_type === "investor" && <InvestorProfileUpdate/> }


        </>
    )
}
export default ProfileUpdate;