import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getDataByUrl } from "../../API/HelpingMethod";
import { useNotification } from "../../../hooks";

const SelectCountry = ({ setActiveStep, setBodyData, bodyData }) => {
    const [btnLoading, setBtnLoading] = useState(false);
    const [country, setCountry] = useState('')
    const [countries, setCountries] = useState([]);
    const { showError, showSuccess, setOpenBackDrop } = useNotification();



    async function countrys() {
        setOpenBackDrop(true);
        let response = await getDataByUrl("country", {
            limit: 0,
            offset: 0
        })


        if (response.error === false) {
            setCountries(response?.data || []);
        } else {
            setCountries(response?.data || []);
        }
        setOpenBackDrop(false);

    }

    useEffect(() => {
        countrys().then((e) => e);

    }, []);

    const handlechange = (e, value) => {
        setCountry(value?.label)
        setBodyData({ ...bodyData, country_name: value?.name })
    }


    const handleSubmit = () => {
        if (!bodyData?.country_name) {
            showError("Please Select Country")
        } else {
            setActiveStep(1)
        }
    }

    return (
        <>
            <Container>
                <Row xs={{ cols: 1 }}>
                    <Col className="mb-3 mt-4 pt-2 text-center"> <span className=" fw-bold">Select Country</span> </Col>

                    <Col className=" h6 mt-3"> <span>Select Your Country</span> </Col>
                    <Col className="mt-1 mx-auto startup-register-autocomplete autocomplete-border">
                        <Autocomplete
                            disablePortal
                            fullWidth
                            id="combo-box-demo"
                            onChange={handlechange}
                            options={countries}
                            getOptionLabel={(option) => option?.name || bodyData?.country_name}
                            value={(bodyData?.country_name)}
                            renderInput={(params) => <TextField  {...params} label="Country" />}
                        />
                    </Col>

                    <Col className="text-center mt-5">
                        <button disabled={btnLoading} className={btnLoading ? "disabled-btn" : "input-buttons"}
                            onClick={handleSubmit}
                        >
                            {btnLoading && <><CircularProgress color="inherit" className="mt-1" size={16} />&nbsp;</>}Next
                        </button>
                    </Col>
                </Row>

            </Container>
        </>
    )
}
export default SelectCountry