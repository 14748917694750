import React, { useRef } from "react";
import { Container, Row, Col, Card, Button, } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react"; 
import { Checkbox, CircularProgress} from "@mui/material";
import { getDataByUrl } from "../API/HelpingMethod"; 
import SkeletonMarketplace from "../Homepage/Skeleton-files/SkeletonMarketplace";
import image from "../images/Looper-2 (3).png";
import { useNotification } from "../../hooks";
import ListProduct from "./ListProduct";
import { HelmetCustomInclude } from "../../hooks/HelmetCustomInclude";
import noimage from "../images/noimage2.webp"

const Marketplace = ({ }) => {

  var loginuser = JSON.parse(localStorage.getItem("userstorage"));

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []); 
  const [offset, setOffset] = useState(0);
  const [marketplaceProducts, setMarketplaceProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredMarketplaceProducts, setFilteredMarketplaceProducts] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [marketplacecategory, setMarketplacecategory] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isLike, setIsLike] = useState('');

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const { setShow } = useNotification();

  const bodydata = {
    // limit: itemsPerPage + visibleItems,
    limit: 5,
    search_text: searchInput,
    offset: 0,
  };

  const apiCall = async () => {
    if (loginuser) {
      bodydata.login_user_id = loginuser?.id
    }
    const inputData = {
      offset: 0
    }
    setLoading(true);
    const response = await getDataByUrl("marketplace_product", {
      ...bodydata, ...inputData,
      categories: selectedCategories.join(","), // Add this line
    }); 
    if (!response?.error) {
      setMarketplaceProducts(response?.data);
    } else {
      // console.log("Error fetching data:", response?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    apiCall();
  }, [itemsPerPage, searchInput, selectedCategories, localStorage.getItem("userstorage")]);




  useEffect(() => {
    const filteredItems = marketplaceProducts.filter((item) =>
      item.title.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredMarketplaceProducts(filteredItems);
  }, [searchInput, marketplaceProducts]);

  const loadMoreItems = () => {
    setOffset(offset + 1);
  };






  const nextData = async () => {
    setBtnLoading(true);

    const inputData = {
      offset: offset || 0
    }

    const response = await getDataByUrl("marketplace_product", { ...bodydata, ...inputData })

    if (response?.error === false) {
      setMarketplaceProducts((prev) => [...new Set([...prev, ...response?.data])]);

    }
    setBtnLoading(false);

  }





  useEffect(() => {
    if (offset !== 0) {
      nextData();
    }
  }, [offset]);










  const styleFont = { fontFamily: "Montserrat" };


  const MarketplaceCategory = async () => {
    setLoading(true);
    const response = await getDataByUrl(
      "marketplace_product_category",
      bodydata
    );
    if (!response?.error) {
      setMarketplacecategory(response?.data);

    } else {
      // console.log("Error fetching data:", response?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    MarketplaceCategory();
  }, []);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const styleImageBg = {
    position: "sticky",
  };


  const marketplaceText = "Templates & Themes";
  const marketplacePara =
    "Discover the Marketplace Mobile App, your gateway to a world of opportunities. With our app, you can:";

  const marketplaceParaText =
    "- Easily browse and discover a wide range of products and services";

  const loadMore = "Load More";



  return (
    <div>




      <HelmetCustomInclude url={"marketplace"}
        title={"marketplace"}

      // title={pageTitle} image={metaImageUrl}
      // description={metaDescription}
      // keywords={metaKeywords}
      />


      <Container fluid>
        <Row>
          <Col>
            <Container fluid className="mt-3 pt-3">
              <Row>
                <div style={styleImageBg}>
                  <img id="Servicebgimage" src={image} alt="image" onError={(e) => e.target.src = noimage} />
                </div>
              </Row>
              <Row className="text-center text-light" id="marketplace">
                <h1 className="mt-5 fw-bold">{marketplaceText}</h1>
                <p style={styleFont} className="mt-3 mb-5 pb-4">
                  {marketplacePara}
                  <br /> {marketplaceParaText}
                </p>
              </Row>
            </Container>

            <Container className="mb-4">
              <Row className="mt-4">
                {/* <Col lg={4} xl={3} className="mt-4">
                  <InputGroup className="marketplace-search-input" style={styleCard}>
                    <InputGroup.Text id="basic-addon2" style={{ backgroundColor: "white" }} >
                      {
                        searchInput ? <BsXLg onClick={() => setSearchInput("")} color="var(--primary)" style={{ cursor: "pointer" }} /> :
                          <BsSearch color="var(--primary)" />
                      }
                    </InputGroup.Text>
                    <Form.Control
                      className="marketplace-search"
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      onChange={(e) => setSearchInput(e.target.value)}
                      value={searchInput}
                    />

                  </InputGroup>
                  <Card className="mt-3" style={{ border: "1px solid var(--primary)" }} >
                    <Card.Body>
                      <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                          <Typography>
                            <strong style={{ fontFamily: "'Nunito Sans', sans-serif", }} >
                              {categoryText}
                            </strong>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <Row className="g-3">
                              {loading ? (
                                Array.from(
                                  { length: numberOfItems },
                                  (_, index) => (
                                    <SkeletonMarketPlaceCategory key={index} />
                                  )
                                )
                              ) : (
                                <>
                                  {marketplacecategory &&
                                    marketplacecategory.map((item, ind) => (
                                      <>
                                        <Col xs={2} sm={1} lg={2} key={ind}>
                                          {" "}
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={() =>
                                              handleCategoryCheckbox(item.title)
                                            }
                                            checked={selectedCategories.includes(
                                              item.title
                                            )}
                                          />
                                        </Col>
                                        <Col xs={10} sm={11} lg={10} className="fontFamily" style={styleFontSize} >
                                          {item.title}
                                        </Col>
                                      </>
                                    ))}
                                </>
                              )}
                            </Row>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion className="mt-2">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header" >
                          <Typography>
                            <strong style={{ fontFamily: "'Nunito Sans', sans-serif", }} >
                              {priceText}
                            </strong>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <Row>
                              <Col xs={2} sm={1} lg={2}>
                                {" "}
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  onChange={() =>
                                    handleCategoryCheckbox("(0-100)")
                                  }
                                  checked={selectedCategories.includes(
                                    "(0-100)"
                                  )}
                                />
                              </Col>
                              <Col xs={10} sm={11} lg={10} className="fontFamily" >
                                {" "}
                                (0-100){" "}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={2} sm={1} lg={2}>
                                {" "}
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  onChange={() =>
                                    handleCategoryCheckbox("(100-200)")
                                  }
                                  checked={selectedCategories.includes(
                                    "(100-200)"
                                  )}
                                />
                              </Col>
                              <Col xs={10} sm={11} lg={10} className="fontFamily" >
                                {" "}
                                (100-200)
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={2} sm={1} lg={2}>
                                {" "}
                                <input className="form-check-input" type="checkbox" onChange={() =>
                                  handleCategoryCheckbox("(200-300)")
                                }
                                  checked={selectedCategories.includes(
                                    "(200-300)"
                                  )}
                                />
                              </Col>
                              <Col xs={10} sm={11} lg={10} className="fontFamily" >
                                (200-300)
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={2} sm={1} lg={2}>
                                <input className="form-check-input" type="checkbox"
                                  onChange={() =>
                                    handleCategoryCheckbox("(400-500)")
                                  }
                                  checked={selectedCategories.includes(
                                    "(400-500)"
                                  )}
                                />
                              </Col>
                              <Col xs={10} sm={11} lg={10} className="fontFamily" >
                                (400-500)
                              </Col>
                            </Row>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion className="mt-2">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header" >
                          <Typography>
                            <strong style={{ fontFamily: "'Nunito Sans', sans-serif" }} >
                              {propertyText}
                            </strong>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <Row>
                              {" "}
                              <Col sm={10} className="mt-2"></Col>{" "}
                            </Row>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Card.Body>
                  </Card>
                </Col> */}
                <Col className="ms-0 me-0 me-sm-5 ms-sm-5">
                  {loading ? (
                    <SkeletonMarketplace />
                  ) : (
                    <>
                      {(searchInput === "" ? marketplaceProducts : filteredMarketplaceProducts)
                        // .slice(0, visibleItems)
                        .map((product, index) => (
                          <ListProduct setIsLike={setIsLike} key={index} product={product} />
                        ))}


                      {(marketplaceProducts?.length >= 5 &&
                        (btnLoading ? <div className="mt-3 text-center">
                          <button disabled className={`slide-button text-decoration-none loading-hover`} >
                            <CircularProgress className="mt-1" color="inherit" size={16} />&nbsp; {loadMore}
                          </button>
                        </div> : <div className="mt-3 text-center">
                          <button className={`slide-button text-decoration-none`} onClick={loadMoreItems} >
                            {loadMore}
                          </button>
                        </div>)
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Marketplace;
