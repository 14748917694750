import { Skeleton } from "@mui/material";
import { Col } from "react-bootstrap";
import { useMediaQuery } from '@mui/material';
export default function SkeletonProjectIdeaDetails() {

  const isXSmallScreen = useMediaQuery('(max-width: 600px)');
  const isSmallScreen = useMediaQuery('(min-width: 601px) and (max-width: 960px)');
  const isMediumScreen = useMediaQuery('(min-width: 961px) and (max-width: 1280px)');
  const isLargeScreen = useMediaQuery('(min-width: 1281px)');
  const isXLargeScreen = useMediaQuery('(min-width: 1920px)');
  let titleDescriptionWidth;
  let cardImageWidth;
  let titleWidth;

  // Set width for description based on screen size
  if (isXSmallScreen) titleDescriptionWidth = 300;
  else if (isSmallScreen) titleDescriptionWidth = 320;
  else if (isMediumScreen) titleDescriptionWidth = 460;
  else if (isLargeScreen) titleDescriptionWidth = 800;
  else if (isXLargeScreen) titleDescriptionWidth = 1000;
  else titleDescriptionWidth = 700; // Default width

  // Set width for card image based on screen size
  if (isXSmallScreen) cardImageWidth = 300;
  else if (isSmallScreen) cardImageWidth = 270;
  else if (isMediumScreen) cardImageWidth = 300;
  else if (isLargeScreen) cardImageWidth = 350; 
  else if (isXLargeScreen) cardImageWidth = 600;
  else cardImageWidth = 500; // Default width

  // Set width for title based on screen size
  if (isXSmallScreen) titleWidth = 100;
  else if (isSmallScreen) titleWidth = 200;
  else if (isMediumScreen) titleWidth = 300;
  else if (isLargeScreen) titleWidth = 400;
  else if (isXLargeScreen) titleWidth = 1000;
  else titleWidth = 850;

  return (
    <>
      <Col lg={8} xl={9} md={8} className="mt-4">
        <Skeleton variant="rectangle" className="rounded" width={titleWidth} height={30} />
        <Skeleton variant="rectangle" className="rounded mt-3" width={titleDescriptionWidth} height={20} />
        <Skeleton variant="rectangle" className="rounded mt-3 mb-3" width={titleDescriptionWidth} height={20} />
      </Col>
      <Col lg={4} xl={3} md={4} className="mb-2">
        <Skeleton variant="rectangle" className="rounded" width={cardImageWidth} height={300} />
      </Col>
    </>
  );
}
