import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { BiUserCircle, BiCalendar, BiTime, BiChat } from "react-icons/bi";
import { FiChevronRight } from "react-icons/fi";
import { Link, useParams, useSearchParams } from "react-router-dom";
import RelatedBlog from "./RelatedBlog";
import { useState } from "react";
import { useEffect } from "react";
import { Skeleton } from "@mui/material";
import { getDataByUrl } from "../API/HelpingMethod";
import image from "../images/Looper-2 (1).png";
import { useNotification } from "../../hooks";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { HelmetCustomInclude } from "../../hooks/HelmetCustomInclude";
import noimage from "../images/noimage2.webp"
const BlogDetail = () => {
  var loginuser = JSON.parse(localStorage.getItem("userstorage"));
  const [blogDetail, setBlogdetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [likeBlog, setLikeBlog] = useState({})
  const [likeCount, setLikeCount] = useState(0);

  const [pageTitle, setPageTitle] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaImageUrl, setMetaImageUrl] = useState(null);
  const { slugBlog } = useParams();
  const [likeButtonLoading, setLikeButtonLoading] = useState(false)

  const { showError, showSuccess, setLoginModal } = useNotification();
  const inputData = {
    slug_blog: slugBlog,
    login_user_id: loginuser?.id || "",
    login_user_type: loginuser?.user_type || "",

  };

  const apiCall = async () => {
    setLoading(true);
    setLikeButtonLoading(true);

    const response = await getDataByUrl("blog", inputData); 
    if (!response?.error) {
      setBlogdetail(response?.data);


      if (response?.data?.title) {
        setPageTitle(response?.data?.title)
      } else {
        setPageTitle(response?.data?.name)
      }

      if (response?.data?.url_image) {
        setMetaImageUrl(response?.data?.url_image)
      } else {
        setMetaImageUrl(response?.data?.media_url)
      }
      if (response?.data?.page_description) {
        setMetaDescription(response?.data?.page_description)
      } else {
        setMetaDescription(response?.data?.description)
      }
      if (response?.data?.page_keywords) {
        setMetaKeywords(response?.data?.page_keywords)
      } else {
        setMetaKeywords(response?.data?.name)
      }

      setLikeButtonLoading(false);
      setLoading(false);
    } else {
      // console.log("Error fetching data:", response?.message);
    }
  };

  useEffect(() => {
    apiCall();
  }, [slugBlog, likeBlog]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };

  const inputDataBlogLike = {
    // "device_type": "android",
    // "sstoken": "95vdb549w0zp58lchu",
    "login_user_id": loginuser?.id || "",
    "login_user_type": loginuser?.user_type || "",
    "ref_type": "blog",
    "action_type": "like"
  }
  const handleClick = async () => {
    setLikeButtonLoading(true);
    inputDataBlogLike.ref_id = blogDetail?.id 
    const response = await getDataByUrl("user_interested_action", inputDataBlogLike); 
    if (!response?.error) {
      showSuccess(response?.message)
      setLikeBlog(response?.data)
      setLikeCount(response?.data.count_user_like);
    } else {
      showError(response?.message) 
    }
    setLikeButtonLoading(false);

  }

  const styleBridgcroumb = {
    textDecoration: "none",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
  const styleColor = { color: "#1cc571" };
  const breadcrumbItems = [
    { to: "/", label: "Home" },
    { to: "/blog", label: "Blog" },
    { label: blogDetail?.title },
  ];

  return (
    <div>

      <HelmetCustomInclude url={"blog/" + slugBlog} ref_id={blogDetail?.id} ref_type={'blog'}
        title={pageTitle} image={metaImageUrl}
        description={metaDescription}
        keywords={metaKeywords} />
      <img id="Servicebgimage" src={image} alt="img" />
      <Container className="mt-5 pt-3">
        <Row>
          <div className="d-flex flex-row">
            {loading ? <></> : breadcrumbItems.map((item, index) => (
              <div key={index}>
                {item.to ? (
                  <Link to={item.to} className="color-secondary" style={styleBridgcroumb} >
                    {item.label}
                    {index < breadcrumbItems.length - 1 && <FiChevronRight />}
                  </Link>
                ) : (
                  <div style={styleBridgcroumb}>{item.label}</div>
                )}
              </div>
            ))}
          </div>
        </Row>

        <Row className="h1 mb-4 text-center">
          {loading ? (
            <Skeleton className="rounded mt-5 mx-auto" variant="rectangle" height={20} width={400}
            />
          ) : (
            blogDetail && <h4 className="mt-5 fw-bold">{blogDetail.title}</h4>
          )}
        </Row>
        <Row className="mb-4 text-center">
          <Col>
            {loading ? (
              <Skeleton
                className="rounded mx-auto"
                variant="rectangle"
                height={20}
                width={200}
              />
            ) : (
              <>
                {" "}
                <BiUserCircle style={styleColor} />&nbsp;
                Startup Starter &nbsp;&nbsp;&nbsp; <BiCalendar style={styleColor} />{" "}
                {formatDate(blogDetail.date_schedule)}{" "}
              </>
            )}
          </Col>
        </Row>
        <Row>
          {loading ? (
            <Col className="text-center">
              <Skeleton className="rounded " variant="rectangle" height={400} />
            </Col>
          ) : (
            <Col className="text-center">
              <img className="rounded w-75" src={blogDetail.media_url}
               onError={(e) => e.target.src = noimage} alt={"img"} />
            </Col>
          )}
          <div className="fontFamily mt-3" dangerouslySetInnerHTML={{ __html: blogDetail?.caption || "" }} />
        </Row>
        {loading ? <><Skeleton height={60} width={100} /> </> :
          <Row className="mt-3">
            {likeButtonLoading ? <Col > <div className={`slide-button1 fontFamily text-decoration-none pt-2 pb-2 ps-5 pe-5`}><div class="loader-product ">
              <div class="circle-product"></div>
              <div class="circle-product"></div>
              <div class="circle-product"></div>
              <div class="circle-product"></div>
            </div></div> </Col> : <Col>
              {
                blogDetail?.is_user_like_type === 'no' ? <div
                  onClick={loginuser ? () => handleClick() : () => setLoginModal(true)}
                  className={`slide-button1 fontFamily text-decoration-none `}

                >
                  <FaRegHeart /> &nbsp; Like
                </div> : <div onClick={loginuser ? () => handleClick() : () => setLoginModal(true)}
                  className={`slide-button1 fontFamily text-decoration-none ${isHovered ? "hovered" : ""}`} >
                  <FaHeart />  &nbsp; Liked
                </div>
              }
            </Col>}

          </Row>
        }


      </Container>

      <RelatedBlog />
      {/* <Comment /> */}
    </div>
  );
};
export default BlogDetail;

