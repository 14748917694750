import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import logoFooter from "../../assets/logos/logo-footer.webp";
import {cropTextHelmet, get_date_formatted, getFullPageTitle} from "../../hooks/useCustomHelpingMethod";
import { apiHepingMethod, getDataByUrl } from "../API/HelpingMethod";
import image from "../images/Looper-2 (3).png";
import SkeletonTermsandcondition from "../Homepage/Skeleton-files/SkeletonTermsandcondition";
import { Skeleton } from "@mui/material";
import { HelmetCustomInclude } from "../../hooks/HelmetCustomInclude";
import noimage from "../images/noimage2.webp"

const PrivacyPolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState({});
  const [loading, setLoading] = useState(false);
  const regex = /(<([^>]+)>|&nbsp|&ldquo|&rdquo;)/gi;
  const data = {
    // device_type: "android",
    // sstoken: "95vdb549w0zp58lchu",
    offset: 0,
    limit: 5,
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);


  const [pageTitle, setPageTitle] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaImageUrl, setMetaImageUrl] = useState(null);


  const apiCall = async () => {
    setLoading(true);
    const response = await getDataByUrl("privacy_policy", data);
    if (!response?.error) {
      // console.log(response?.data)
       setPrivacyPolicy(response?.data);
    } else {
      // console.log("Error fetching data:", response?.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    apiCall();
  }, []);

  return (
    <div className="pt-3"> 
      <HelmetCustomInclude url={"privacy"} title={pageTitle} image={metaImageUrl}/>

      <img id="Servicebgimage" src={image} onError={(e) => e.target.src = noimage} alt="image" />
      <Container className="pt-5" fluid id="privacy_policy">
        <Row>
          <Col className="text-center mt-5 mb-5 text-light">
          {
              loading ? <Skeleton className="rounded mx-auto" variant="rectangle" width={200} height={20}/>:<>
              <h6>Effective Date: {get_date_formatted(privacyPolicy?.updated_at)}</h6>
              </>
            }
         
            {
              loading ? <Skeleton className="rounded mx-auto mt-5" variant="rectangle" width={300} height={40}/>:<>
              <h1 className="mt-4 fw-bold" style={{textTransform:"uppercase"}}>{privacyPolicy?.title}</h1>
              </>
            }
         
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col className="mb-5 mt-5">
            {loading ? (
              <SkeletonTermsandcondition />
            ) : (
              <>
                {/* {privacyPolicy && (
                  <h5 className="fs-4" style={{ fontWeight: "bold" }}>
                    {privacyPolicy.title}
                  </h5>
                )} */}

                <div
                  className="fontFamily mt-3"
                  dangerouslySetInnerHTML={{
                    __html: privacyPolicy?.description || "",
                  }}
                />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default PrivacyPolicy;


