import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import from_idea from "../images/IdeaImage.png";
import { Fade } from "react-reveal";
import noimage from "../images/noimage2.webp"

const FormIdea = () => {
    const styleFont={fontFamily:"Montserrat"}
 
    const headingText="Overview"
    const headingTextPara="From Idea to Implementation"
    const headingParaDes="Welcome to Startup Starter, where we empower new startups in their journey from the idea phase to the implementation phase. We understand the challenges and excitement that come with turning an innovative concept into a thriving business. Our comprehensive support system is designed to provide you with the guidance, resources, and expertise needed to navigate the critical stages of your startup's development."
    return ( 
        <div>
        <Fade right> 
            <Container>
                <Row className="color-primary mt-5"><b style={styleFont}>{headingText}</b></Row>
                <Row>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12} className="mt-3">
                        <h3 className="fw-bold" style={styleFont}>{headingTextPara}</h3>
                        <p style={styleFont}>{headingParaDes}</p>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <img src={from_idea} className="img-fluid" onError={(e) => e.target.src = noimage}/>
                    </Col>
                </Row>
            </Container>
            </Fade>

        </div>
    )
}
export default FormIdea;