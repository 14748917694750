import React, {useEffect, useState} from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {amountFormate, apiHepingMethod, getDataByUrl} from "../API/HelpingMethod";
import {Alert, CircularProgress, Skeleton, Snackbar} from "@mui/material";
import mylogo from "../images/mylogo.webp";
import image from "../images/ShoppingCartbg.png";
import {useNotification} from "../../hooks";
import ProductCard from "./ProductCard";
import noimage from "../images/noimage2.webp"
import {appAPiBasicInput, loginUserData, paymentGatewayCurrency} from "../API/client";
import handlePaymentTransaction from "../razorpay/handel_payment_razorpay";
import useRazorpay from "react-razorpay";
import {formatMoney, getMoneyDecimal} from "../../hooks/useCustomHelpingMethod";

const ShoppingCart = () => {
    const {showError, showSuccess} = useNotification();
    const [isHoveredPayment, setIsHoveredPayment] = useState(false);
    const [producterror, setProducterror] = useState(true);
    const [marketplaceDetail, setMarketplaceDetail] = useState({});
    const [loading, setLoading] = useState(false);
    const [opensuccess, setOpensuccess] = useState(false);
    const [openerror, setError] = useState(false);
    const [message, setMessage] = useState();
    const [buttonPayment, setButtonPayment] = useState("Process Payment");
    const [deleteCard, setDeleteCard] = useState({})
    const [buttonClicked, setButtonClicked] = useState(false);
    const [amountPayable, setAmountPayable] = useState(0.00);
    const [userCurrency, setSetUserCurrency] = useState("USD")
    const [amountPayableUSD, setAmountPayableUSD] = useState(0.00);
    const [userCurrencyUSD, setSetUserCurrencyUSD] = useState("USD")
    const [Razorpay] = useRazorpay();
    const navigate = useNavigate();

    let loginuser = loginUserData;

    useEffect(() => {
        loginuser = JSON.parse(localStorage.getItem('userstorage'));
        if (!loginuser) {
            navigate("/marketplace");
        }
    }, []);

    const handleClose = () => {
        setOpensuccess(false);
        setError(false);
    };

    const data = {
        device_type: "web",
        sstoken: appAPiBasicInput?.sstoken,
        login_user_id: loginuser?.id,
        limit: 10,
        offset: 0,
    };

    const checkoutdata = {
        device_type: "web",
        sstoken: appAPiBasicInput?.sstoken,
        login_user_id: loginuser?.id,
        action_type: "checkout",
    };

    const apicall = async () => {
        setLoading(true);
        const response = await getDataByUrl("marketplace_product_cart_detail", data);
        console.log(response)
        if (!response?.error) {
            if (response?.data) {
                if (response?.data?.amount_total) {
                    setAmountPayableUSD(getMoneyDecimal(response?.data?.amount_total))
                } else {
                    setAmountPayableUSD(getMoneyDecimal((response?.data?.amount_rate) + (response?.data?.amount_tax)))
                }

                if (response?.data?.amount_total_user_country) {
                    setAmountPayable(getMoneyDecimal(response?.data?.amount_total_user_country));
                } else {
                    setAmountPayable(getMoneyDecimal((response?.data?.amount_rate_user_country) + (response?.data?.amount_tax_user_country)))
                }
                setSetUserCurrency(response?.data?.amount_currency_user_country)
                setSetUserCurrencyUSD(response?.data?.amount_currency)
            }
            // amount_tax_user_country
            setMarketplaceDetail(response?.data);
            setLoading(false);
        } else {
            showError(response?.message || "Error encounter, Please try again")
            // console.log("Error fetching data:", response?.message);
        }
    };

    useEffect(() => {
        apicall();
    }, [!producterror, deleteCard]);

    const redirectOnSuccess = (response) => {
        showSuccess(response?.message || response?.data?.message || "Payment successfully completed, Thank you for your interest with our services, our executive will contact you soon.");
        navigate("/marketplace");
    }
    const redirectOnError = (response) => {
        showError(response?.message || response?.data?.message || "Payment failed or cancelled so process unable to complete. Please try again later");
        // navigate("/user-project");
    }

    const handleCheckout = async () => {
        setButtonClicked(true)
        setButtonPayment(<CircularProgress color="inherit" className="mx-4" size={16}/>,);
        let inputBodyData = {
            device_type: "web",
            sstoken: appAPiBasicInput?.sstoken,
            login_user_id: loginuser?.id,
            payment_source: "razorpay",
            ref_id: "",
            ref_type: "checkout",
            amount: amountPayableUSD,
            amount_currency: userCurrencyUSD,
            txn_order_id: "",
            txn_ref: "",
            type: 'full',
        }
        console.log(inputBodyData);
        //Checkout cart because ref_type=='checkout
        const result = await handlePaymentTransaction({
            Razorpay: Razorpay,
            inputBodyData: inputBodyData,
            data: marketplaceDetail,
            amount_payable: paymentGatewayCurrency === 'USD' ? amountPayableUSD : amountPayable,
            amount_currency: paymentGatewayCurrency === 'USD' ? userCurrencyUSD : userCurrency,
            // amount_payable: 1,
            // amount_currency: "INR",
            // ref_id: loginuser?.id,
            ref_type: "checkout",
            redirectOnSuccess: redirectOnSuccess,
            redirectOnError: redirectOnError,
            setLoading: setLoading
        });
        console.log("payment", result?.data);
        // if (result) {
        //     if (!result?.error) {
        //         setMessage(result.message);
        //         setOpensuccess(true);
        //         navigate("/marketplace");
        //     } else {
        //         setOpensuccess(false);
        //     }
        // }


        // if (payment?.data && !payment?.data?.error) {
        //     const result = await apiHepingMethod(
        //         "marketplace_product_cart_action",
        //         {...checkoutdata, 'token_payment': payment?.data?.token || payment?.data?.data?.token || ""}
        //     );
        //     console.log("cart action", result);
        //
        //     if (!result?.data?.error) {
        //         setMessage(result.data.message);
        //         setOpensuccess(true);
        //         navigate("/marketplace");
        //     } else {
        //         setOpensuccess(false);
        //     }
        //     setButtonPayment("Process Payment")
        // }
        setButtonPayment("Process Payment")

    };

    const styleSnackbar = {width: "100%", marginTop: "5px"};
    const styleHeight = {height: "200px"};
    const styleImage = {maxWidth: "200px"};
    const styleFont = {fontFamily: "Montserrat"};
    const signDiscount = <b className={"text-success"}>(-)</b>;
    const signCharge = <b className={"text-danger"}>(+)</b>;

    return (
        <div className="">
            <Snackbar
                sx={styleSnackbar}
                anchorOrigin={{vertical: "top", horizontal: "right"}}
                open={opensuccess}
                autoHideDuration={6000}
                onClose={handleClose}>
                <Alert onClose={handleClose} className="bg-success text-light w-100" severity="success">
                    {message}
                </Alert>
            </Snackbar>

            <img id="Servicebgimage" src={image} alt="image" onError={(e) => e.target.src = noimage}/>

            <Container fluid style={styleHeight} className="bg-black pt-5">
                <Row>
                    <Col md={5} className="mt-5">
                        <img style={styleImage} src={mylogo} alt={mylogo}
                             onError={(e) => e.target.src = noimage} className="img-fluid"/>
                    </Col>
                    <Col className="mt-5">
                        <h4 className="color-secondary" style={styleFont}>
                            Total
                            ({marketplaceDetail.products && Array.isArray(marketplaceDetail?.products) ? marketplaceDetail.products?.length : 0} Items)
                            :
                            <strong
                                className="text-white h4"> {amountFormate(amountPayable || marketplaceDetail.amount_total_user_country || 0)} {userCurrency}</strong>
                        </h4>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row>
                    <Col>

                        <Container className="mt-5 mb-4 ">
                            <Row>
                                <Col xl={8}>
                                    <Row>
                                        {!loading && <Col className="h5 fw-bold">Shopping Cart</Col>}
                                    </Row>
                                    {loading ? (
                                        <div className="skeleton-m-p"><Skeleton width="100%" height={180}/></div>) : (
                                        <>
                                            {marketplaceDetail.products && Array.isArray(marketplaceDetail?.products)
                                            && marketplaceDetail.products?.length < 1 ?
                                                <b className="text-danger">Product Is Not Available</b> :
                                                marketplaceDetail.products && Array.isArray(marketplaceDetail?.products)
                                                && marketplaceDetail?.products.map((data, index) => {
                                                    return (
                                                        <ProductCard setDeleteCard={setDeleteCard} index={index}
                                                                     data={data}/>
                                                    );
                                                })}
                                        </>
                                    )}
                                </Col>

                                <Col xl={4} className="mt-xl-0 mt-3">
                                    <Container>
                                        <Row>
                                            <Col xl={12} lg={6}>

                                                {loading &&
                                                <div className="skeleton-m-p"><Skeleton width="100%" height={300}/>
                                                </div>}

                                                {!loading && <Card className="shadow border-0">
                                                    <Card.Body>
                                                        <Container>
                                                            {!loading && <h5 className=" fw-bold"> Order Summary</h5>}
                                                            <Row className="mt-3">
                                                                <Col sm={6} className="fontFamily mb-2">Total Cost</Col>
                                                                <Col sm={6} className="text-end">
                                                                    {amountFormate(marketplaceDetail?.amount_mrp_user_country)} {" "} {userCurrency}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm={6} className="fontFamily mb-2"> Discount </Col>
                                                                <Col sm={6} className=" text-end">
                                                                    <span> {signDiscount} {amountFormate(marketplaceDetail.amount_discount_user_country)}{" "}{userCurrency}</span>
                                                                </Col>
                                                            </Row>

                                                            <Row className="">
                                                                <Col sm={6} className="fontFamily mb-2">Tax</Col>
                                                                <Col sm={6} className="text-end">
                                                                    {signCharge} {amountFormate(marketplaceDetail?.amount_tax_user_country)} {userCurrency}{" "}
                                                                </Col>
                                                            </Row>
                                                            <hr/>
                                                            <Row className="fw-bold">
                                                                <Col sm={6} className="fontFamily"> Amount
                                                                    Payable </Col>
                                                                <Col sm={6} className=" text-end">
                                                                    {amountFormate(amountPayable || marketplaceDetail?.amount_total_user_country || 0)} {userCurrency}
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </Card.Body>
                                                </Card>}
                                            </Col>
                                        </Row>
                                        {!loading && <Row className="mt-3">
                                            <Col xl={12} lg={6} className="text-center">
                                                {buttonClicked && (
                                                    <button
                                                        className='fontFamily text-decoration-none mb-4 disabled-btn w-100'
                                                        disabled><CircularProgress style={{color: "var(--secondary)"}}
                                                                                   className="mt-1"
                                                                                   size={16}/>&nbsp;&nbsp; Loading...
                                                    </button>
                                                )}

                                                {!buttonClicked && (<button
                                                    className={`slide-button1  text-decoration-none w-100 ${isHoveredPayment ? "hovered" : ""
                                                    }`}
                                                    onMouseEnter={() => setIsHoveredPayment(true)}
                                                    onMouseLeave={() => setIsHoveredPayment(false)}
                                                    onClick={handleCheckout}>
                                                    {buttonPayment}
                                                </button>)}
                                            </Col>
                                            <Col xl={0} lg={0} xs={0}></Col>
                                        </Row>}
                                    </Container>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default ShoppingCart;
