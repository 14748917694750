import React, {useState} from "react";
import {Row, Container, Form, Col, Card, Button} from "react-bootstrap";
import {amountFormate, getDataByUrl} from "../API/HelpingMethod";
import {CircularProgress} from "@mui/material"
import defaultimg from "../images/noimage2.webp";
import {useSearchParams} from "react-router-dom";
import {useNotification} from "../../hooks";
import {BsTrash3} from "react-icons/bs";
import {cropText} from "../../hooks/useCustomHelpingMethod";

const ProductCard = ({setDeleteCard, index, data}) => {
    const {showError, showSuccess} = useNotification();
    const [openerror, setError] = useState(false);
    const [isHoveredDelete, setIsHoveredDelete] = useState(false)
    const [buttonClicked, setButtonClicked] = useState(false);
    const [buttonClicked2, setButtonClicked2] = useState(false);
    const [btnId, setBtnId] = useState()
    const styleCard = {
        border: "1px solid var(--primary) none",
    };
    const handelProductRemove = async (partId) => {
        setButtonClicked2(true)
        var loginuser = JSON.parse(localStorage.getItem("userstorage"));

        const response = await getDataByUrl("marketplace_product_cart_action", {
            login_user_id: loginuser?.id,
            action_type: "product_delete",
            product_id: partId,
        });

        if (!response?.error) {
            setDeleteCard(response?.data)
            showSuccess(response?.message);
        } else {
            showError(response?.message);
            //    console.log("Error fetching data:", response?.message);
        }
        setButtonClicked(false)
        setButtonClicked2(false)

    };


    return (
        <>
            <Card key={index} className="mt-2 shadow" style={styleCard}>
                <Card.Body>
                    <Container>
                        <Row>
                            <Col md={3}>
                                <img onError={(e) => (e.target.src = defaultimg)}
                                     className="w-100 h-100" src={data?.url_image || defaultimg} alt={'product'}/>
                            </Col>
                            <Col md={6} xl={6}>
                                <Container>
                                    <Row className="fw-bold" style={{fontFamily: "'Nunito Sans', sans-serif",}}>
                                        {data?.title}
                                    </Row>
                                    <Row className="fontFamily">
                                        <p><small>{cropText(data?.description || "", 100)}</small></p>
                                        {/*{data?.description}*/}
                                    </Row>
                                </Container>
                            </Col>
                            <Col md={3} xl={3} className="d-flex align-items-end ">
                                <Container>
                                    <Row className="text-end fw-bold pb-5">
                                        {/* <Col>
                                            <del className={"text-danger"}>
                                                {amountFormate(data?.amount_mrp_user_country || 0, 'int')} {data?.amount_currency_user_country}
                                            </del>
                                            &nbsp;
                                            {amountFormate(data?.amount_rate_user_country || 0, 'int')} {data?.amount_currency_user_country}
                                        </Col>*/}
                                        {" "}
                                        <Col>{amountFormate(data?.amount_total_user_country)} {data?.amount_currency_user_country}</Col>
                                    </Row>
                                    <Row className="text-end">
                                        {buttonClicked2 && (
                                            <button className='fontFamily text-decoration-none disabled-btn w-100'
                                                    disabled><CircularProgress style={{color: "var(--secondary)"}}
                                                                               className="mt-1"
                                                                               size={16}/>&nbsp;&nbsp; Loading...</button>
                                        )
                                        }
                                        {
                                            !buttonClicked2 && (
                                                <button onClick={() => handelProductRemove(data?.id)}
                                                        className={`button-danger text-decoration-none ${isHoveredDelete[data?.id] ? "hovered" : ""}`} >
                                                    <BsTrash3 className="mb-1"/>{" "} Remove
                                                </button>
                                            )
                                        }
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>
        </>
    )
}

export default ProductCard;