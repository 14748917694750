import React, {useEffect, useState} from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import {getDataByUrl} from "../API/HelpingMethod";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import NotificationDetailSidebar from "./NotificationDetailSidebar";
import image from "../images/Looper-2 (1).png";
import {BsChevronRight} from "react-icons/bs";
import {Skeleton} from "@mui/material";
import noimage from "../images/noimage2.webp"
import {loginUserData} from "../API/client";
import NotificationMessage from "./NotificationMessage";
import {FaCircleArrowLeft} from "react-icons/fa6";
import {FaAngleLeft} from "react-icons/fa";
import UserProfileSidebar from "./UserProfileSidebar";

const NotificationDetail = () => {
    const navigate = useNavigate()

    var loginuser = JSON.parse(localStorage.getItem("userstorage"));
    useEffect(() => {
        if (!localStorage.getItem("userstorage")) {
            navigate("/");
        }
    }, [localStorage.getItem("userstorage")])
    const [notification, setNotification] = useState([]);
    const [loading, setLoading] = useState(false);
    const [markAllRead, setMarkAllRead] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [searchParems] = useSearchParams();
    const {slugNotification} = useParams();

    const handleMarkAllRead = () => {
        setMarkAllRead(true);
    };
    const inputData = {
        login_user_id: loginUserData?.id || 0,
        login_user_type: loginUserData?.user_type || "startup",
        offset: 0,
        limit: 6,
        notification_id: slugNotification
        // slug_notification: searchParems.get("slug")
    }; 

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const apiCall = async () => {
        setLoading(true);
        const response = await getDataByUrl("user_notification", inputData);
        if (!response?.error) {
            setNotification(response?.data);
        } else {
            // console.log("Error fetching data:", response?.message);
        }
        setLoading(false);
    };
    useEffect(() => {
        apiCall();
    }, []);

    const styleBorder = {
        border: "1px solid var(--primary)",
        paddingBottom: "45px"
    }


    return (


        <Container className="pt-5 notification-detail-container"> 
            <img id="Servicebgimage" src={image} alt="img" onError={(e) => e.target.src = noimage} />
            <Row className="mt-2 mb-2">
                <Col className="ms-3">
                    <Link to="/" className="text-decoration-none color-secondary">Home</Link>&nbsp;<BsChevronRight /><Link to="/notification" className="text-decoration-none color-secondary">Notification</Link>&nbsp;<BsChevronRight />&nbsp;<span> {notification.title}</span>
                </Col>
            </Row>
            <Row>
                <Col sm={12} xs={12} md={5} lg={4} xl={3} className="pb-5">
                <UserProfileSidebar />
                </Col>
                <Col sm={12} xs={12} xl={9} md={7} lg={8}>
                    <Container className=" mb-5">
                        <Card style={styleBorder} className="notification-detail">
                            <Card.Body>
                                <Row >
                                    <Col>
                                        <h5 className="fw-bold">
                                            <Link to="/"
                                                  className="color-secondary"><FaAngleLeft size={30}/></Link> Notification
                                        </h5>
                                    </Col>
                                </Row>
                                <hr className="mt-3" />
                                {
                                    loading ? <>
                                        <Row className="ps-2 pe-2">
                                            <Skeleton height={80}/>
                                        </Row>
                                        <Row className="ps-2 pe-2">
                                            <Skeleton/>
                                            <Skeleton/>
                                        </Row>
                                    </> : <>  <NotificationMessage
                                        url_image={notification?.url_image}
                                        title={notification?.title}
                                        message={notification?.message}
                                        updated_at={notification?.updated_at}
                                        formated_date={notification?.datetime_formatted || notification?.updated_at || notification?.created_at}
                                        id={notification?.id}
                                    />
                                        <Row>
                                            <Col>{notification?.message}</Col>
                                        </Row>
                                    </>
                                }


                            </Card.Body>
                        </Card>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};

export default NotificationDetail;



