import React, { useState, useEffect } from "react";
import "../css/Button.css";
import { Col, Container, Row } from "react-bootstrap";
import { FiChevronRight } from "react-icons/fi";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CircularProgress, Skeleton } from "@mui/material";
import { getDataByUrl } from "../API/HelpingMethod";
import SkeletonProjectIdeaDetails from "../Homepage/Skeleton-files/SkeletonProjectIdeaDetails";
import image from "../images/Looper-2 (2).png";
import { FaLongArrowAltRight } from "react-icons/fa";
import { useNotification } from "../../hooks";

import { HelmetCustomInclude } from "../../hooks/HelmetCustomInclude";
import noimage from "../images/noimage2.webp"

// import "../css/Home.css";
const ProjectIdeaDetails = () => {
  var loginuser = JSON.parse(localStorage.getItem("userstorage"));
  let [searchParams, setSearchParams] = useSearchParams();
  const [projectIdea, setProjectIdea] = useState({});
  const { showError, showSuccess, setLoginModal } = useNotification();
  const [button, setButton] = useState({
    text: "Interested ",
    icon: <FaLongArrowAltRight />,
  });
  const [buttonClicked, setButtonClicked] = useState(false);
  const [projectToken, setProjectToken] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [projectIdeaDetail, setProjectIdeaDetail] = useState()

  const [pageTitle, setPageTitle] = useState(null);
  const [metaKeywords, setMetaKeywords] = useState(null);
  const [metaDescription, setMetaDescription] = useState(null);
  const [metaImageUrl, setMetaImageUrl] = useState(null);
  const { slugIdea } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("userstorage")) {
      // navigate("/");
    }
  }, [localStorage.getItem("userstorage")]);

  const inputData = {
    login_user_id: loginuser?.id,
    login_user_type: "startup",
    slug_project_idea: slugIdea,
  };
  const apiCall = async () => {
    setLoading(true);
    const response = await getDataByUrl("front_project_idea", inputData);
    if (!response?.error) {
      setProjectIdea(response?.data);
      setLoading(false);
      if (response?.data?.name) setPageTitle(response?.data?.name)
      if (response?.data?.url_image) setMetaImageUrl(response?.data?.url_image)

      if (response?.data?.page_description) {
        setMetaDescription(response?.data?.page_description)
      } else {
        setMetaDescription(response?.data?.description)
      }
      if (response?.data?.page_keywords) {
        setMetaKeywords(response?.data?.page_keywords)
      } else {
        setMetaKeywords(response?.data?.name)
      }




    } else {
      // console.log("Error fetching data:", response?.message);
    }
  };
  useEffect(() => {
    apiCall();
  }, []);
  const navigate = useNavigate();


  const handleClick = async () => {
    setButtonClicked(true);
    // setLoading(true);

    try {
      const response = await getDataByUrl("user_work_group_update", {
        login_user_id: loginuser?.id,
        login_user_type: loginuser?.user_type,
        ref_id: projectIdea?.id,
        ref_type: "project_idea",
      });

      if (!response?.error) {
        setProjectToken(response?.data.token);
        showSuccess(response?.message);
        navigate("/select-service/" + response?.data.token);
      } else {
        showError(response?.message);
        // console.log("Error fetching data:", response?.message);
      }
    } catch (error) {
      // console.error("Error during async operation:", error);
    } finally {
      // setLoading(false);
    }
  };
  const styleBridgeCrumb = {
    textDecoration: "none",
    color: "white",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
  const styleButton = { backgroundColor: "#1CC571", fontFamily: "Montserrat" };
  const styleFont = { fontFamily: "Montserrat" };

  const breadcrumbItems = [
    { to: "/", label: "Home" },
    { to: "/project-idea", label: "Ideas" },
    { label: projectIdea?.name },
  ];
  return (
    <div className="pt-3">
      <HelmetCustomInclude url={"/project-idea/" + slugIdea} ref_id={projectIdea?.id} ref_type={'/project_idea'}
        title={pageTitle} image={metaImageUrl}
        description={metaDescription}
        keywords={metaKeywords} />
      <img id="Servicebgimage" src={image} alt="image" onError={(e) => e.target.src = noimage} />
      <Container className="mt-3 pt-4 pb-3" fluid id="Project_idea_details">
        <Container>
          <Row>
            <div className="d-flex flex-row mt-2">
              {breadcrumbItems.map((item, index) => (
                <div key={index} className="">
                  {item.to ? (
                    <Link to={item.to} style={styleBridgeCrumb}>
                      {item.label}
                      {index < breadcrumbItems.length - 1 && <FiChevronRight />}
                    </Link>
                  ) : (
                    <div style={styleBridgeCrumb} className="text-light">
                      {item.label}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </Row>
          <br />
          <Row>
            {loading ? (
              <SkeletonProjectIdeaDetails />
            ) : (
              <>
                <Col md={9} className="mt-2">
                  {projectIdea && (
                    <h1 className="text-white fw-bold" style={styleFont}>{projectIdea.name}</h1>
                  )}

                  {projectIdea && (
                    <p style={styleFont} className="text-white">
                      {projectIdea.page_description}
                    </p>
                  )}
                </Col>
                <Col md={3} className="mb-2">
                  {
                    <img
                      className="w-100 rounded-3 shadow"
                      src={projectIdea.url_image}
                      alt="img"
                    />
                  }
                </Col>
              </>
            )}
          </Row>
        </Container>
      </Container>
      <Container className="mt-4">
        {loading ? (
          <Skeleton
            variant="rectangle my-2"
            className="rounded"
            width={500}
            height={20}
          />
        ) : (
          <>
            <div
              className="fontFamily"
              dangerouslySetInnerHTML={{
                __html: projectIdea?.description || "",
              }}
            />
          </>
        )}
        {loading ? (
          <Skeleton
            variant="rectangle my-4"
            className="rounded"
            width={1200}
            height={20}
          />
        ) : (
          <>
            <div
              className="fontFamily mb-4"
              dangerouslySetInnerHTML={{
                __html: projectIdea?.description || "",
              }}
            />
          </>
        )}


        {
          loading ? <></> : <div style={{ position: "relative" }}>
            {buttonClicked && (
              // <CircularProgress style={{ color: "var(--secondary)" }} className="mb-4" size={25} />
              <button className='fontFamily text-decoration-none mb-4 disabled-btn' disabled={true} >
                <>
                  <CircularProgress style={{ color: "var(--secondary)" }} className="mt-1" size={16} />&nbsp;&nbsp; Loading...
                  {/* <span>{button?.text}</span>
                  <span>{button?.icon}</span> */}
                </>
              </button>
            )}
            {!buttonClicked && (
              <>
 
                {loginuser ? loginuser?.user_type === "startup" ? <button
                  onClick={handleClick}
                  className={`slide-button1 fontFamily text-decoration-none mb-4 ${isHovered ? "hovered" : ""}`}
                >
                  <span>{button?.text}</span>
                  <span>{button?.icon}</span>

                </button> : <></> :
                  <button
                    onClick={() => setLoginModal(true)}
                    className={`slide-button1 fontFamily text-decoration-none mb-4 ${isHovered ? "hovered" : ""}`}
                  >
                    <span>{button?.text}</span>
                    <span>{button?.icon}</span>

                  </button>

                }





              </>
            )}
          </div>
        }



      </Container>
    </div>
  );
};
export default ProjectIdeaDetails;
