import {Avatar, CircularProgress, Container, useMediaQuery,} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Card, Col, Form, Modal, Row} from "react-bootstrap";
import {getDataByUrl} from "../API/HelpingMethod";
import {Link, useNavigate, useParams} from "react-router-dom";
import SkeletonSelectService from "../Homepage/Skeleton-files/SkeletonSelectService";
import {FiChevronRight} from "react-icons/fi";
import image from "../images/Looper-2 (1).png";
import {FaLongArrowAltRight} from "react-icons/fa";
import {useNotification} from "../../hooks";
import noimage from "../images/noimage2.webp"

const SelectService = () => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredChild, setIsHoveredChild] = useState(false);
  const [services, setServices] = useState([]);
  let [selectedService, setSelectedService] = useState([]);
  const [selectedChildservices, setSelectedChildservices] = useState([]);
  const [selectedChildServices, setSelectedChildServices] = useState([]);
  const [child_services, setChild_services] = useState([]);
  const [currentService, setCurrentservice] = useState(0);
  const [serviceToken, setServiceToken] = useState([]);
  const { showError, showSuccess, setOpenBackDrop } = useNotification();
  const [loading, setLoading] = useState(false);
  const {tokenWorkGroup} = useParams();
  const [selectedTitle, setSelectedTitle] = useState([])



  const [button, setButton] = useState({
    text: "Proceed ",
    icon: <FaLongArrowAltRight />,
  });

  const [buttonClicked, setButtonClicked] = useState(false);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("userstorage")) {
      navigate("/project-idea");
    }
  }, [localStorage.getItem("userstorage")]);

  const [show, setShow] = useState(false);
  const activeService = services.find(
      (service) => service.id === currentService
  );

  const isFound = (key) =>
    selectedService.some((element) => {
      if (element === key) {
        return true;
      } else {
        return false;
      }
    });

  const isFoundChild = (key) =>
    selectedChildservices.some((element) => {
      if (element === key) {
        return true;
      } else {
        return false;
      }
    });

  const handleClose = () => {
    setShow(false);

  };

  var loginuser = JSON.parse(localStorage.getItem("userstorage"));

  const inputData = {
    login_user_id: loginuser?.id,
    limit: 10,
    offset: 0,
    type: "parent",
  };

  const apiCall = async () => {
    setLoading(true);
    const response = await getDataByUrl("service", inputData);

    console.log("response-----",response)
    if (!response?.error) {
      setServices(response?.data);
      setLoading(false);
    } else {
    }
  };

  useEffect(() => {
    apiCall();
  }, []);

  const handleClickService = (e, serviceId, id, childs, token) => {
    if (e.target.checked === true) {
      setServiceToken([...serviceToken, token]);
      setShow(true)
    }
    else {
      var a = serviceToken.filter(function (value, index, arr) {
        return value !== token;
      });

      var b = selectedTitle.filter(function (value, index, arr) {
        return value?.parent_id !== id;
      });

      setSelectedTitle(b);

      childs?.map((child) => {
        a = a.filter(function (value, index, arr) {
          return value !== child.token;
        });
      })


      setServiceToken(a);
    }
    const fd = isFound(serviceId, id, childs);

    if (fd === true) {
      const items = selectedService.filter((item) => item !== serviceId);
      setSelectedService(items);

    } else {
      setSelectedService([...selectedService, id, serviceId, childs]);

      setChild_services(childs);
    }

  };

  const handleClickChildService = (e, id, childs, token) => {
    if (e.target.checked === true) {
      setServiceToken([...serviceToken, token]);
      setSelectedTitle([...selectedTitle, childs])
    } else {

      var a = serviceToken.filter(function (value, index, arr) {
        return value !== token;
      });

      var b = selectedTitle.filter(function (value, index, arr) {
        return value?.id !== id;
      });

      setSelectedTitle(b);


      setServiceToken(a);
    }

    const isSelected = isFoundChild(id);

    if (isSelected) {

      const updatedChildServices = selectedChildservices.filter(
        (item) => item !== id
      );
      setSelectedChildservices(updatedChildServices);

    } else {
      setSelectedChildservices((prevServices) => [...prevServices, id]);
    }
  };




  const [serviceBodydata, setServiceBodydata] = useState({
    login_user_id: loginuser?.id,
    ref_type: "service",
    group_token: tokenWorkGroup,
  });

  const ServiceUpdate = async () => {
    setOpenBackDrop(true);
    serviceBodydata.ref_id = serviceToken;
    setButtonClicked(true);

    const response = await getDataByUrl(
      "user_work_group_update",
      serviceBodydata
    );
    if (!response?.error) {
      showSuccess(response?.message);
      navigate("/proceed/" + response?.data.token);
      setLoading(false);
    } else {
      showError(response?.message);
      // console.log("Error fetching data:", response?.message);
    }
    setOpenBackDrop(false);
    setButtonClicked(false)
  };

  const CroppedText = ({ text, maxLength }) => {
    const croppedText =
      text && text.length > maxLength
        ? `${text.substring(0, maxLength)}...`
        : text;

    return (
      <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
        {croppedText}
      </div>
    );
  };

  const styleBridgeCrumb = {
    textDecoration: "none",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };


  const styleImage = { height: "100%" };
  const styleFont = { fontFamily: "Montserrat" };

  const styleImageAvatar = {
    bgcolor: "var(--primary)",
    width: 50,
    height: 50,
  };
  const styleDiv = { position: "sticky" };
  const breadcrumbs = [
    { to: "/", label: "Home" },
    { to: "/project-idea", label: "Ideas" },
    // { to: "/project-idea/" + tokenWorkGroup, label: "Project Idea Details" },
    { label: "Select Service" },
  ];
  const selectServices = "Select Services";
  const selectServicesPara =
      "You can select multiple services for your project requirement.Please select at least one service to continue";
  const addService = "Add Services";

  let titleWidth;
  let iconWidth;

  const isXSmallScreen = useMediaQuery("(max-width: 600px)");
  const isSmallScreen = useMediaQuery("(min-width: 601px) and (max-width: 960px)");
  const isMediumScreen = useMediaQuery("(min-width: 961px) and (max-width: 1280px)");
  const isLargeScreen = useMediaQuery("(min-width: 1281px)");
  const isXLargeScreen = useMediaQuery("(min-width: 1920px)");

  if (isXSmallScreen) {
    titleWidth /= 1;
    iconWidth = 40;
  } else if (isSmallScreen) {
    titleWidth *= 0.8;
    iconWidth *= 0.8;
  } else if (isMediumScreen) {
    titleWidth *= 1;
    iconWidth *= 1;
  } else if (isLargeScreen) {
    titleWidth *= 1.2;
    iconWidth *= 1.2;
  } else if (isXLargeScreen) {
    titleWidth *= 1.5;
    iconWidth *= 1.5;
  }


  const CheckArray = (id) => {
    const a = serviceToken.includes(id);
    return a;
  }

  const SelectAll = (e, child) => {
    let prevToken = serviceToken;
    let tokenService = [];
    let serviceName = [];
    child.forEach(element => {
      tokenService.push(element?.token)
      serviceName.push(element);
    });

    if (e.target.checked === true) {
      setServiceToken([...serviceToken, ...tokenService]);
      setSelectedTitle([...selectedTitle, ...serviceName])
    }

    else { 
      tokenService.map((data) => {
        const a = serviceToken.filter(function (value, index, arr) {
          return value !== data;
        });
        // console.log("serviceToken---", a)
      })
    }
  }

  return (
    <div>
      <div style={styleDiv}>
        <img id="Servicebgimage" src={image} alt="img" onError={(e) => e.target.src = noimage} />
      </div>
      <Container>
        <Row>
          <Col>
            <Container className=" pt-5 mb-5">
              <Row className="mt-3">
                <div className="d-flex flex-row">
                  {breadcrumbs.map((breadcrumb, index) => (
                    <div key={index} className="">
                      {breadcrumb.to ? (
                        <Link to={breadcrumb.to} style={styleBridgeCrumb} className="color-secondary" >
                          {breadcrumb.label}
                          <FiChevronRight color="#000000" />
                        </Link>
                      ) : (
                        <div style={styleBridgeCrumb}>{breadcrumb.label}</div>
                      )}
                    </div>
                  ))}
                </div>
              </Row>

              <Modal size="lg" show={show} onHide={handleClose} backdrop="static" keyboard={false} >
                <Modal.Header closeButton>
                  <Col className="fw-bold"> {child_services[0]?.parent_name} Service  </Col>
                </Modal.Header>
                <Modal.Body>
                  <h4 className="fw-bold">
                    {activeService && activeService.name}
                  </h4>
                  <Row>
                    <Col>
                      <Form.Check
                        onChange={(e) => SelectAll(e, child_services)}
                        reverse
                        label={"Select All"}
                        type={'checkbox'}
                        id={`select_all`}
                      />
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    {child_services &&
                      child_services.map((childserviceItem, index) => {
                        const isActive2 = isFoundChild(childserviceItem.id);

                        return (
                          <Col lg={6} md={6} className="" key={childserviceItem.id}  >
                            <Card
                              // className={
                              //   (isActive2 === true ? "card-active" : "") +
                              //   " card mb-3 card-shadow "
                              // }
                              className={CheckArray(childserviceItem.token) === true ? "card-active card mb-3 shadow" : "card mb-3 shadow"} >
                              <label htmlFor={(childserviceItem?.id || index) + "sCheck"}>
                                <Card.Body>
                                  <Container className="pt-2 pb-2">
                                    <Row className="d-flex align-items-center">
                                      <Col md={4} xs={4} className="p-sm-0 ps-2"  >
                                        <img style={styleImage} className="img-fluid rounded" onError={(e) => e.target.src = noimage} src={childserviceItem.url_image} />
                                      </Col>
                                      <Col md={6} xs={6} className=" mt-1 fw-bold"   >
                                        <CroppedText text={childserviceItem.name} maxLength={50} />
                                      </Col>
                                      <Col md={2} xs={2} className="text-end mt-2"  >
                                        <input
                                          className="form-check-input"
                                          id={(childserviceItem?.id || index) + "sCheck"}
                                          type="checkbox"
                                          checked={serviceToken.includes(childserviceItem.token)}
                                          onChange={(e) =>
                                            handleClickChildService(
                                              e,
                                              childserviceItem.id,
                                              childserviceItem,
                                              childserviceItem.token
                                            )
                                          }
                                        />
                                      </Col>
                                    </Row>
                                  </Container>
                                </Card.Body>
                              </label>
                            </Card>
                          </Col>
                        );
                      })}
                  </Row>
                  <button
                    onClick={() => {
                      // Perform any other actions you need before closing the modal
                      // ...

                      // Update the state for selected child services

                      if (Array.isArray(selectedChildServices)) {
                        // Update the state for selected child services
                        setSelectedChildServices(selectedChildServices);
                      }

                      handleClose();
                    }}
                    className={`slide-button1 fontFamily text-decoration-none ${isHoveredChild ? "hovered" : ""
                      }`}
                    onMouseEnter={() => setIsHoveredChild(true)}
                    onMouseLeave={() => setIsHoveredChild(false)}
                  >
                    {addService}
                  </button>
                </Modal.Body>
              </Modal>

              <Row className="mt-5">
                <Col>
                  <h2 className="fw-bold">{selectServices}</h2>
                  <p style={styleFont}>{selectServicesPara}</p>
                </Col>
              </Row>

              <Row className="mt-3">
                {loading ? (
                  <SkeletonSelectService />
                ) : (
                  <>
                    {services.map((serviceItem, index) => {
                      const isActive = isFound(serviceItem.id);
                      // console.log (isActive);
                      // const isActive = true;
                      return (
                        <Col md={6} sm={6} className="" key={serviceItem.id}>
                          <Card
                            id="service"
                            // className={
                            //   (isActive === true ? "card-active" : "") +
                            //   " card mb-3  p-1"
                            // }
                            className={CheckArray(serviceItem.token) === true ? "card-active card mb-3  p-1" : "card mb-3  p-1"}
                          >
                            <label
                              htmlFor={(serviceItem?.id || index) + "sCheck"}
                            >
                              <Card.Body>
                                <Container className="pt-0 pt-sm-2 pb-2 ms-sm-1 ms-0 ">
                                  <Row className="d-flex  align-items-center">
                                    <Col md={2} sm={2} xs={3} className=" p-0">
                                      <Avatar sx={styleImageAvatar} className="img-fluid rounded" onError={(e) => e.target.src = noimage} src={serviceItem.url_image} ></Avatar>
                                    </Col>
                                    <Col md={8} sm={8} xs={7} >
                                      <div className="fw-bold "> {serviceItem.name}</div>
                                      {/* {selectedTitle?.parent_id === serviceItem?.id ? <div>{selectedTitle?.name}</div> : <></>} */}
                                      {
                                        selectedTitle.map((data) => {
                                          return (
                                            <>
                                              {data?.parent_id === serviceItem?.id ?
                                                <div style={{ fontSize: "12px" }}> {data?.name + ","}</div> : <></>}
                                            </>
                                          )
                                        })
                                      }
                                    </Col>
                                    <Col md={2} sm={1} xs={1} className=" text-center text-sm-end" >
                                      <input className="form-check-input"
                                        id={(serviceItem?.id || index) + "sCheck"}
                                        type="checkbox"
                                        // checked={isActive === true}
                                        onChange={(e) =>
                                          handleClickService(e, serviceItem.id, serviceItem.id, serviceItem.child_services, serviceItem.token
                                          )
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </Container>
                              </Card.Body>
                            </label>
                          </Card>
                        </Col>
                      );
                    })}
                  </>
                )}
              </Row>

              <Row className=" mt-5">
                <Col className="text-center">
                  {
                    loading ? <></> : <div style={{ position: "relative" }}>
                      {buttonClicked && (
                        <button className='fontFamily text-decoration-none mb-4 disabled-btn' disabled>
                          <>
                            <CircularProgress style={{ color: "var(--secondary)" }} className="mt-1" size={16} />&nbsp;&nbsp; Loading...
                          </>
                        </button>
                      )}
                      {!buttonClicked && (
                        <button

                          onClick={ServiceUpdate}
                          className={`slide-button1 fontFamily text-decoration-none mb-4 ${isHovered ? "hovered" : ""}`}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <span>{button?.text}</span>
                          <span>{button?.icon}</span>
                        </button>
                      )}
                    </div>
                  }

                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SelectService;
