import React, {useEffect, useState} from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import {FiChevronRight} from "react-icons/fi";
import {Link, useParams, useSearchParams} from "react-router-dom";
import {getDataByUrl} from "../API/HelpingMethod";
import SkeletonServiceDetails from "../Homepage/Skeleton-files/SkeletonServiceDetails";
import SkeletonServiceDetailsChild from "../Homepage/Skeleton-files/SkeletonServiceDetailsChild";
import image from "../images/Looper-2 (1).png";
import noimage from "../images/noimage2.webp"

import {HelmetCustomInclude} from "../../hooks/HelmetCustomInclude";
import {loginUserData} from "../API/client";

const ServiceDetails = () => {
  const loginuser = loginUserData;
  let [searchParams, setSearchParams] = useSearchParams();
  const [child_services, setChild_services] = useState([]);
  const [loading, setLoading] = useState(false);
  const [responsedata, setResponsedata] = useState({});

  const [pageTitle, setPageTitle] = useState("");
  const [metaKeywords, setMetaKeywords] = useState(null);
  const [metaDescription, setMetaDescription] = useState(null);
  const [metaImageUrl, setMetaImageUrl] = useState(null);
  const { slugService } = useParams();


  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => { 
    if (!localStorage.getItem("userstorage")) {
      // navigate("/");
    }
  }, [searchParams, localStorage.getItem("userstorage")])
  const inputData = {
    // service_id: searchParams.get("serviceid"),
    slug_service: slugService,
    offset: 0,
    limit: 10
  }; 

  const styleBridgcroumb = {
    textDecoration: "none",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
  const styleImage = { borderRadius: "10px" };

  const apiCall = async () => {
    setLoading(true);
    if (loginuser) {
      inputData.login_user_id = loginuser?.id
    }

    try {
      const response = await getDataByUrl("service", inputData); 
      if (!response?.error) {
        setChild_services(response?.data.child_services || []);
        setResponsedata(response?.data || {});
        if (response?.data?.name) setPageTitle(response?.data?.name)
        if (response?.data?.url_image) setMetaImageUrl(response?.data?.url_image)

        if (response?.data?.page_description) {
          setMetaDescription(response?.data?.page_description)
        } else {
          setMetaDescription(response?.data?.description)
        }
        if (response?.data?.page_keywords) {
          setMetaKeywords(response?.data?.page_keywords)
        } else {
          setMetaKeywords(response?.data?.name)
        }


        setLoading(false);
      } else {
        // console.log("Error fetching data:", response?.message);
      }
    } catch (error) {
      // console.error("Error making API call:", error);
    }
  };

  useEffect(() => {
    apiCall();
  }, []);
  const breadcrumbItems = [
    { to: '/', label: 'Home' },
    { to: '/service', label: 'Services' },
    { label: responsedata?.name },
  ];

  const numberOfServiceDetailsChild = 2;
  return (
    <div>

      <HelmetCustomInclude url={"service/" + slugService}
        ref_id={responsedata?.id} ref_type={'service'}
        title={pageTitle} image={metaImageUrl}
        description={metaDescription}
        keywords={metaKeywords} />

      <Container fluid>
        <Row>
          <Col>
            <img id="Servicebgimage" src={image} alt="img" onError={(e) => e.target.src = noimage} />
            <Container className="mt-1 pt-5">
              <Row>
                <div className="d-flex flex-row mt-2">
                  {breadcrumbItems?.map((item, index) => (
                    <div key={index}>
                      {item.to ? (
                        <Link to={item.to} className="color-secondary" style={styleBridgcroumb}>
                          {item.label}
                          {index < breadcrumbItems.length - 1 && <FiChevronRight color="#000000" />}
                        </Link>
                      ) : (
                        <div style={styleBridgcroumb}>
                          {item.label}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </Row>
              <Row className="mt-3">
                {loading ? (
                  <SkeletonServiceDetails />
                ) : (
                  <>
                    <Col md={12} xs={12} sm={12}>
                      <img
                        className="img-fluid m-2 service-detail-image" 
                        src={responsedata.url_image}
                        alt={responsedata.url_image}
                        onError={(e) => e.target.src = noimage}
                      />
                      {responsedata && (
                        <h1 className="mb-4 fw-bold">{responsedata.name}</h1>
                      )}

                      <div
                        className="fontFamily"
                        dangerouslySetInnerHTML={{
                          __html: responsedata?.description || "",
                        }}
                      />
                    </Col>
                  </>
                )}
              </Row>

              <Row className="my-5">
                {loading ? (
                  <SkeletonServiceDetailsChild
                    numberOfServiceDetailsChild={numberOfServiceDetailsChild}
                  />
                ) : (
                  <>
                    {child_services?.map((child_servicesItem, ind) => (
                      <Col key={ind} xs={12} sm={12} md={5} lg={4} className="g-3" >
                        <Link
                            // to={"/service-plan?serviceid=" + child_servicesItem.id}
                            to={`/service-plan/${child_servicesItem?.slug}`}
                            className="text-decoration-none"
                        >
                          <Card className="shadow w-100 p-2 child-service-card">
                            <Card.Body className="d-flex align-items-center">
                              <Col sm={4} xs={6} md={4} xl={3}>
                                <img
                                    className="w-100 rounded"
                                    src={child_servicesItem.url_image}
                                    alt={child_servicesItem.url_image}
                                    onError={(e) => e.target.src = noimage}
                                />
                              </Col>
                              <Col xs={5} md={8} sm={8} className="mx-3 fontFamily">
                                {child_servicesItem.name}
                              </Col>
                            </Card.Body>
                          </Card>
                        </Link>
                      </Col>
                    ))}
                  </>
                )}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>

    </div>
  );
};

export default ServiceDetails;
