import { Skeleton } from '@mui/material';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const SkeletonFaqCategory = ({ numberOfItems = 6 }) => {
  const renderSkeletonItems = () => {
    const skeletonItems = [];

    for (let i = 0; i < numberOfItems; i++) {
      skeletonItems.push(
        <Col
          key={i}
          xl={6} md={6} lg={6} sm={12} xs={12}
          className="mb-3"
        >
          <Card id="service">
            <Card.Body>
              <Row className="d-flex align-items-center">
                <Col>
                  <Skeleton width={100} height={100} variant='circular' />
                </Col>
                <Col>
                  <Card.Title className="fontFamily">
                    <Skeleton width={300} height={30} variant='rectangle' className='rounded' />
                  </Card.Title>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      );
    }

    return skeletonItems;
  };

  return (
    <>
      {renderSkeletonItems()}
    </>
  );
};

export default SkeletonFaqCategory;




// import { Skeleton } from '@mui/material'
// import React from 'react'
// import { Card, Col, Row } from 'react-bootstrap'

// const SkeletonFaqCategory = () => {
//     return (
//         <>
//             <Col
//                 xl={6}
//                 md={6}
//                 lg={6}
//                 sm={12}
//                 xs={12}

//                 className="mb-3"
//             >
//                 <Card id="service">
//                     <Card.Body>
//                         <Row className="d-flex align-items-center">
//                             <Col>
//                                 <Skeleton width={100} height={100} variant='circular'/>
//                             </Col>
//                             <Col>
//                                 <Card.Title className="fontFamily">
//                                 <Skeleton width={300} height={30} variant='rectangle' className='rounded'/>
//                                 </Card.Title>
//                             </Col>
//                         </Row>
//                     </Card.Body>
//                 </Card>
//             </Col>
//             <Col
//                 xl={6}
//                 md={6}
//                 lg={6}
//                 sm={12}
//                 xs={12}

//                 className="mb-3"
//             >
//                 <Card id="service">
//                     <Card.Body>
//                         <Row className="d-flex align-items-center">
//                             <Col>
//                                 <Skeleton width={100} height={100} variant='circular'/>
//                             </Col>
//                             <Col>
//                                 <Card.Title className="fontFamily">
//                                 <Skeleton width={300} height={30} variant='rectangle' className='rounded'/>
//                                 </Card.Title>
//                             </Col>
//                         </Row>
//                     </Card.Body>
//                 </Card>
//             </Col>
//             <Col
//                 xl={6}
//                 md={6}
//                 lg={6}
//                 sm={12}
//                 xs={12}

//                 className="mb-3"
//             >
//                 <Card id="service">
//                     <Card.Body>
//                         <Row className="d-flex align-items-center">
//                             <Col>
//                                 <Skeleton width={100} height={100} variant='circular'/>
//                             </Col>
//                             <Col>
//                                 <Card.Title className="fontFamily">
//                                 <Skeleton width={300} height={30} variant='rectangle' className='rounded'/>
//                                 </Card.Title>
//                             </Col>
//                         </Row>
//                     </Card.Body>
//                 </Card>
//             </Col>
//             <Col
//                 xl={6}
//                 md={6}
//                 lg={6}
//                 sm={12}
//                 xs={12}

//                 className="mb-3"
//             >
//                 <Card id="service">
//                     <Card.Body>
//                         <Row className="d-flex align-items-center">
//                             <Col>
//                                 <Skeleton width={100} height={100} variant='circular'/>
//                             </Col>
//                             <Col>
//                                 <Card.Title className="fontFamily">
//                                 <Skeleton width={300} height={30} variant='rectangle' className='rounded'/>
//                                 </Card.Title>
//                             </Col>
//                         </Row>
//                     </Card.Body>
//                 </Card>
//             </Col>
//             <Col
//                 xl={6}
//                 md={6}
//                 lg={6}
//                 sm={12}
//                 xs={12}

//                 className="mb-3"
//             >
//                 <Card id="service">
//                     <Card.Body>
//                         <Row className="d-flex align-items-center">
//                             <Col>
//                                 <Skeleton width={100} height={100} variant='circular'/>
//                             </Col>
//                             <Col>
//                                 <Card.Title className="fontFamily">
//                                 <Skeleton width={300} height={30} variant='rectangle' className='rounded'/>
//                                 </Card.Title>
//                             </Col>
//                         </Row>
//                     </Card.Body>
//                 </Card>
//             </Col>
//             <Col
//                 xl={6}
//                 md={6}
//                 lg={6}
//                 sm={12}
//                 xs={12}

//                 className="mb-3"
//             >
//                 <Card id="service">
//                     <Card.Body>
//                         <Row className="d-flex align-items-center">
//                             <Col>
//                                 <Skeleton width={100} height={100} variant='circular'/>
//                             </Col>
//                             <Col>
//                                 <Card.Title className="fontFamily">
//                                 <Skeleton width={300} height={30} variant='rectangle' className='rounded'/>
//                                 </Card.Title>
//                             </Col>
//                         </Row>
//                     </Card.Body>
//                 </Card>
//             </Col>
          
//         </>
//     )
// }

// export default SkeletonFaqCategory
