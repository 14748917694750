import React, { useState } from "react";
import { FaCircleArrowLeft } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logos/logo.webp";
import noimage from "../images/noimage2.webp"
import { Autocomplete, Box, CircularProgress, FormControl, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Typography } from "@mui/material";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import PropTypes from 'prop-types';
import { useNotification } from "../../hooks";
import { BsExclamationCircleFill } from "react-icons/bs";




function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const SelectUserType = () => {
    const styleImage = { maxHeight: "50px", alignContent: "center" }
    const [btnLoading, setBtnLoading] = useState(false);
    const [value, setValue] = useState(0);
    const { showError, showSuccess, setOpenBackDrop } = useNotification();
    const [userType, setUserType] = useState('investor')
    const navigate = useNavigate()
    const [isChecked, setIsChecked] = useState(false);


    const handleChangeTab = (event, newValue) => {
        console.log("newValue---", newValue)
        if (newValue === 1) {
            setUserType("startup");
        } else if (newValue === 2) {
            setUserType("contributor");
        }  
        setValue(newValue);
    };

    const handleCheck = (e) => {
        setIsChecked(e.target.checked)
    }

    const handleSubmit = () => {
        setBtnLoading(true);
        if (!isChecked) {
            showError("please check and confirm ")
        } else {
            navigate("/register/" + userType)
        }
        // setActiveStep(2)
        setBtnLoading(false);

    }

    return (
        <>
            <Container fluid className="forms-container" >
                <Row>
                    <Col lg={6} md={6} className="p-0">
                        <div className="login-image-container"></div>
                    </Col>

                    <Col lg={6} md={6} style={{ overflow: 'scroll', height: "99vh" }}>

                        <Row>
                            <Col className="">
                                <Link to="/" className="color-secondary">
                                    <FaCircleArrowLeft /> Back to home
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <img
                                    src={logo}
                                    alt="logo"
                                    style={styleImage}
                                    onError={(e) => e.target.src = noimage}
                                />
                            </Col>
                        </Row>

                        <Row xs={{ cols: 1 }} className="ps-1 pe-1">
                            <Col className="text-center mb-3 mt-4 pt-2 fw-bold"> <span>What's Best Describes You ?</span> </Col>

                            <Col className="mt-3 mx-auto ps-0 pe-0 d-flex justify-content-center">
                                <Container>
                                    <Row className='ps-1 pe-1'>
                                        <Col className="register-type ps-0 pe-0">
                                            <Tabs className="login-switch" textColor="white" variant="fullWidth" value={value}
                                                aria-label="basic tabs example" centered>
                                                <Tab
                                                    label="Investor"
                                                    className={value === 0 ? "selected-register-type" : "unselect-register-type"}
                                                    {...a11yProps(0)}
                                                    onClick={(e) => handleChangeTab(e, 0)}
                                                />
                                                <Tab
                                                    label="Startup"
                                                    className={value === 1 ? "selected-register-type" : "unselect-register-type"}
                                                    onClick={(e) => handleChangeTab(e, 1)}
                                                    {...a11yProps(1)}
                                                />
                                                <Tab
                                                    label="Contributor"
                                                    className={value === 2 ? "selected-register-type" : "unselect-register-type"}
                                                    onClick={(e) => handleChangeTab(e, 2)}
                                                    {...a11yProps(2)}
                                                />
                                            </Tabs>
                                        </Col>
                                    </Row>

                                </Container>
                            </Col>

                            <Col className="mt-4 mx-auto d-flex justify-content-center">
                                <Card className="shadow">
                                    <Card.Body>
                                        <Row xs={{ cols: 1 }}>
                                            <Col>
                                                Register as an {userType} <BsExclamationCircleFill color="var(--secondary)" />
                                            </Col>
                                            <Col>
                                                You can be an investor if you are intrested to help new startups with funds and want to help in startup success.
                                            </Col>
                                            <Col className=" d-flex justify-content-end">
                                                <Form.Check
                                                    onChange={handleCheck}
                                                    type={'checkbox'}
                                                    id={`checkbox1`}
                                                    label={`i understand`}
                                                />
                                            </Col>
                                        </Row>

                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col className="d-flex justify-content-center mt-5">
                                <div className="me-2">
                                    {/* <button
                                     onClick={handleBack} className="primary-button" >
                                        Back
                                    </button> */}
                                </div>

                                <div className="ms-2">
                                    <button disabled={btnLoading} className={btnLoading ? "disabled-btn" : "input-buttons"} onClick={handleSubmit}>
                                        {btnLoading && <><CircularProgress color="inherit" className="mt-1" size={16} />&nbsp;</>}Proceed
                                    </button>
                                </div>
                            </Col>
                        </Row>

                    </Col>

                </Row>



            </Container>

        </>
    )
}
export default SelectUserType