import { Autocomplete, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { Bs1CircleFill } from "react-icons/bs";
import { useNotification } from "../../../hooks";

const CompanyDetails = ({ setActiveStep, handleBack, setBodyData, bodyData }) => {
    const [btnLoading, setBtnLoading] = useState(false);
    const [member, setMember] = useState('');
    const { showError, showSuccess, setOpenBackDrop } = useNotification();

    const teamSize = [
        { id: 1, size: "0 to 10 Members" },
        { id: 2, size: "10 to 20 Members" },
        { id: 3, size: "20 to 50 Members" },
        { id: 4, size: "50 to 100 Members" },
        { id: 5, size: "100 to 150 Members" },
        { id: 6, size: "150 to 200 Members" },
        { id: 7, size: "200 to 300 Members" },
        { id: 8, size: "300 to 500 Members" },
        { id: 9, size: "500+ Members" }
    ]


    const handlechange = (e) => {
        const { name, value } = e.target;
        setBodyData({ ...bodyData, [name]: value });
    }

    const handleSubmit = () => {

        if (!bodyData?.name_company) {
            showError("Please Enter Your Company Name");
        } else if (!bodyData?.contributor_team_size && bodyData?.contributor_user_type === "company") {
            showError("Please Enter Your Company Team Size");
        }
        else {
            setActiveStep(4)
        }
    }


    return (
        <>
            <Container>
                <Row xs={{ cols: 1 }}>
                    <Col className=" text-center mb-3 mt-4 pt-2 fw-bold"> <span>Company Details</span> </Col>

                    <Col className=" mt-3"><span className="h6">What's Your Company Name?</span></Col>
                    <Col className="mt-1 mx-auto d-flex justify-content-center">
                        <Form.Control type="text"
                            className="idea-stepper-input"
                            onChange={handlechange}
                            value={bodyData?.name_company}
                            name="name_company" />
                    </Col>

                    {bodyData?.contributor_user_type === "company" && <Col className=" mt-3"><span className="h6">What's Your Company Team Size?</span></Col>}
                    {bodyData?.contributor_user_type === "company" && <Col className="mt-1 mx-auto d-flex justify-content-center mui-select">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Members</InputLabel>
                            <Select labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={bodyData?.contributor_team_size}
                                label="Members"
                                name="contributor_team_size"
                                onChange={handlechange}
                            >
                                {
                                    Array.isArray(teamSize) && teamSize.map((data, index) => {
                                        return (
                                            <MenuItem key={index} value={data.size}>{data.size}</MenuItem>
                                        )
                                    })
                                }


                            </Select>
                        </FormControl>
                    </Col>}

                    <Col className="d-flex justify-content-center mt-5">
                        <div className="me-2">
                            <button onClick={handleBack} className="input-primary-buttons" >
                                Back
                            </button>
                        </div>

                        <div className="ms-2">
                            <button disabled={btnLoading} className={btnLoading ? "disabled-btn" : "input-buttons"} onClick={handleSubmit}>
                                {btnLoading && <><CircularProgress color="inherit" className="mt-1" size={16} />&nbsp;</>}Next
                            </button>
                        </div>
                    </Col>
                </Row>

            </Container>
        </>
    )
}
export default CompanyDetails