import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {getDataByUrl} from "../API/HelpingMethod";
import image from "../images/Looper-2 (3).png";
import SkeletonTermsandcondition from "../Homepage/Skeleton-files/SkeletonTermsandcondition";
import {Skeleton} from "@mui/material";
import noimage from "../images/noimage2.webp"
import {HelmetCustomInclude} from "../../hooks/HelmetCustomInclude";
import {get_date_formatted} from "../../hooks/useCustomHelpingMethod";

const Terms_Condition = () => {
  const [termsCondition, setTermsCondition] = useState({});
  const [loading, setLoading] = useState(false);

  const [pageTitle, setPageTitle] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaImageUrl, setMetaImageUrl] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const apiCall = async () => {
    setLoading(true);
    const response = await getDataByUrl("terms", {});
    if (!response?.error) {
      // console.log(response?.data)
      setTermsCondition(response?.data);
    } else {
      // console.log("Error fetching data:", response?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    apiCall();
  }, []);

  return (
    <div className="pt-3">
      
      <HelmetCustomInclude url={"terms"} title={pageTitle} image={metaImageUrl}/>

      <img id="Servicebgimage" src={image} alt="image" onError={(e) => e.target.src = noimage} />
      <Container className="pb-5 pt-5" fluid id="privacy_policy">
        <Row>
          <Col className="text-center mt-5  mb-2 text-light">
          {
              loading ? <Skeleton className="rounded mx-auto" variant="rectangle" width={200} height={20}/>:<>
              <h6>Effective Date: {get_date_formatted(termsCondition?.updated_at||"")}</h6>
              </>
            }

            {loading ? (
              <div className="d-flex align-items-center justify-content-center">
                <Skeleton
                  className="rounded mt-5"
                  variant="rectangle"
                  width={400}
                  height={30}
                />
              </div>
            ) : (
              <h1 className="mt-4 fw-bold" style={{textTransform:"uppercase"}}>{termsCondition?.title}</h1>
            )}
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="mb-5">
          <Col className="">
            {loading ? (
              <SkeletonTermsandcondition />
            ) : (
              <>
                <div
                  className="fontFamily mt-5"
                  dangerouslySetInnerHTML={{
                    __html: termsCondition?.description || "",
                  }}
                />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Terms_Condition;
