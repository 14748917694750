// import { Skeleton } from "@mui/material";
// import React from "react";
// import { Card, Col, Row } from "react-bootstrap";

// export default function SkeletonProjectideaCard() {
//   const numberOfSkeletonsProjectCard = 8;

//   const skeletonArray = Array.from({ length:  numberOfSkeletonsProjectCard }, (_, index) => (
//     <Col key={index} xl={6} lg={6} md={6} sm={12} xs={12} className="mb-3">
//       <Card className="shadow" id="service">
//         <Row>
//           <Col xl={3} lg={3} md={12} sm={12} xs={12} className="">
//             <Skeleton variant="rectangle" className="p-2 rounded m-2" width={120} height={120} />
//           </Col>
//           <Col xl={9} lg={9} md={12} sm={12} xs={12} className="d-flex align-items-center">
//             <Card.Body>
//               <Skeleton variant="rectangle" className="rounded" width={300} height={20} />
//               <Row className="d-flex flex-row">
//                 <Col className="card.text">
//                   <Skeleton variant="rectangle" className="rounded mt-3" width={20} height={20} />
//                 </Col>
//                 <Col className="card.text">
//                   <Skeleton variant="rectangle" className="rounded mt-3" width={100} height={20} />
//                 </Col>
//                 {/* <Col md={3} sm={3}></Col> */}
//               </Row>
//             </Card.Body>
//           </Col>
//         </Row>
//       </Card>
//     </Col>
//   ));

//   return <Row>{skeletonArray}</Row>;
// }

import { Skeleton } from "@mui/material";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";

export default function SkeletonProjectideaCard({cardImg, titleWidth, IconWidth, textWidth}) {
  const numberOfSkeletonsProjectCard = 8;

  const skeletonArray = Array.from(
    { length: numberOfSkeletonsProjectCard },
    (_, index) => (
      <Col key={index} xl={6} lg={6} md={6} sm={12} xs={12} className="mb-3">
        <Card className="shadow" id="service">
          <Row>
            <Col xl={3} lg={3} md={3} sm={3} xs={3} className="">
              <Skeleton
                variant="rectangle"
                className="p-2 rounded m-2"
             
                width={cardImg}

                height={120}
              />
            </Col>
            <Col
              xl={9}
              lg={9}
              md={9}
              sm={9}
              xs={9}
              className="d-flex align-items-center"
            >
              <Card.Body>
                <Skeleton
                  variant="rectangle"
                  className="rounded"
                  width={titleWidth}
                  height={20}
                />
                <Row className="d-flex flex-row">
                  <Col lg={5} xl={5} md={5}  xs={5} sm={5} className="card.text">
                    <Skeleton
                      variant="rectangle"
                      className="rounded mt-3"
                      width={IconWidth}
                      height={20}
                    />
                  </Col>
                  <Col xs={7} sm={7} lg={7} md={7} xl={7} className="card.text">
                    <Skeleton
                      variant="rectangle"
                      className="rounded mt-3"
                      width={textWidth}
                      height={20}
                    />
                  </Col>
                  {/* <Col md={3} sm={3}></Col> */}
                </Row>
              </Card.Body>
            </Col>
          </Row>
        </Card>
      </Col>
    )
  );

  return <Row>{skeletonArray}</Row>;
}
