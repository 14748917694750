import React, { useEffect, useState } from "react";
import { Col, Container, Row, Card, Button, Placeholder } from "react-bootstrap";
import { BiChevronLeftCircle } from "react-icons/bi";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import requirmentimg from "../images/rquarment.png";
import image from "../images/Looper-2 (1).png";
import { apiHepingMethod, getDataByUrl } from "../API/HelpingMethod";
import { useNotification } from "../../hooks";
import { FaLongArrowAltRight } from "react-icons/fa";
import { CircularProgress, Skeleton } from "@mui/material";
import { BsChevronLeft, BsClock } from "react-icons/bs"; 
import noimage from "../images/noimage2.webp"


const SelectRequirement = () => {
  const { showError, showSuccess } = useNotification();
  const [button, setButton] = useState({
    text: "Request Quotation ",
    icon: <FaLongArrowAltRight />,
  });



  const [isHovered, setIsHovered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const { tokenWorkGroup } = useParams();

  const navigate = useNavigate();
  var loginuser = JSON.parse(localStorage.getItem("userstorage"));
  useEffect(() => {
    if (!localStorage.getItem("userstorage")) {
      navigate("/project-idea")
    }
  }, [localStorage.getItem("userstorage")])
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  let [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({});
  const inputData = {
    login_user_type: "startup",
    login_user_id: loginuser?.id,
    token_user_preference: loginuser?.token,
    token: tokenWorkGroup,
    offset: 0,
  };

  const bodydata = {
    login_user_id: loginuser?.id,
    token: tokenWorkGroup,
    values: {
      status: "requested",
    },
  };

  const apiCall = async () => {
    setLoading(true);
    const response = await getDataByUrl("user_work_group", inputData);
    if (!response?.error) {
      setData(response?.data);
 
    } else {
      // console.log("Error fetching data:", response?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    apiCall();
  }, []);

  const handleClick = async () => {
    // setButton({ icon: <CircularProgress color="inherit" className="mx-5" size={20} />, });
    setButtonClicked(true)
    const response = await getDataByUrl("user_work_group_update", bodydata);
    if (!response?.error) {
      showSuccess(response?.message);
      // setData(response?.data);
      navigate("/selected-summary/" + response?.data?.token); 
    } else {
      showError(response?.message);
      // console.log("Error fetching data:", response?.message);
    }
    // setButton({ text: "Request Quotation", icon: <FaLongArrowAltRight /> });
    setButtonClicked(false)
  };

  const backButton = "Back";
  const requirement = "Requirement";
  const projectText = " Your project cost has been prepared";
  const costText = "This cost can buy you";
  const appleText = "Apple MacBook Pro 16 inch";
  const investText = "but you can earn much after this invest";

  // const now = "now";
  const formattedDate = new Date(data?.updated_at).toLocaleString('en-US', {
    day: '2-digit', // Two-digit day
    month: 'short', // Abbreviated month name
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    // second: 'numeric',
    hour12: true, // Change to false if you want 24-hour format
  });
 

  return (
    <div>

      <img id="Servicebgimage" src={image} alt="img" onError={(e) => e.target.src = noimage} />
      <Container className="mt-3 pt-5">
        {/* <Link
          to={"/select-service/" + tokenWorkGroup}
          className="color-secondary text-decoration-none"
        >
          {" "}
          <BsChevronLeft color="#000000" />&nbsp; {backButton}{" "}
        </Link> */}
        <Row>
          <Col className="mt-4 mb-5">
            <Card>
              <Card.Body>
                <Col className="h3 mt-3 fw-bold">{requirement}</Col>
                {loading && <div className="skeleton-m-p"><Skeleton width="100%" height={100} /></div>}

                {!loading &&
                  <Card className="mt-3">
                    <Card.Body>
                      <Card.Title>
                        <>{data?.title}</>
                      </Card.Title>
                      <Card.Text>
                        <Row className="color-secondary">
                          <Col xl={9} lg={9}>
                            <BsClock className="me-2" />    {formattedDate}  </Col>
                          <Col xl={3} lg={3} className="text-end">
                            {data?.status}
                          </Col>
                        </Row>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                }

                <Container>

                  <Row className="mt-5 ">

                    {!loading && <Col className="text-center">{projectText}</Col>}
                  </Row>

                  {loading && <>
                    <Row>
                      <Col xs={2} md={3} lg={4}></Col>
                      <Col xs={8} md={6} lg={4} className="">
                        <div className="skeleton-m-p"><Skeleton width="100%" height={300} /></div>
                      </Col>
                      <Col xs={2} md={3} lg={4}></Col>
                    </Row>
                  </>}
                  {!loading && <Row className="mt-4 text-center">
                    <Col className=" "> <img className="img-fluid" onError={(e) => e.target.src = noimage} src={requirmentimg} /> </Col>
                  </Row>}

                  {loading ? <Row>
                    <Col xs={5}></Col>
                    <Col xs={2} className=""><Skeleton /> </Col>
                    <Col xs={5}></Col>
                  </Row> : <Row>
                    <Col className="text-center ">{costText}</Col>
                  </Row>}
                  {loading ? <Row>
                    <Col xs={3}></Col>
                    <Col xs={6} className=""><Skeleton /> </Col>
                    <Col xs={3}></Col>
                  </Row> : <Row> <Col className="text-center fw-bold color-primary fs-4">  {appleText} </Col>
                  </Row>}

                  <Row>
                    {!loading && <Col className="text-center">{investText}</Col>}
                  </Row>


                  <Row className="mt-3 mb-4">
                    {
                      loading ? <Row>
                        <Col ms={4} xs={2}></Col>
                        <Col ms={4} xs={8} className=""><Skeleton height={60} /> </Col>
                        <Col ms={4} xs={2}></Col>
                      </Row> : <div style={{ position: "relative" }} className="text-center">
                        {buttonClicked && (
                          <button className='fontFamily text-decoration-none mb-4 disabled-btn' disabled>
                            <>
                              <CircularProgress style={{ color: "var(--secondary)" }} className="mt-1" size={16} />&nbsp;&nbsp; Loading...
                            </>
                          </button>
                        )}
                        {!buttonClicked && (
                          <button

                            onClick={handleClick}
                            className={`slide-button1 fontFamily text-decoration-none  mb-4 ${isHovered ? "hovered" : ""}`}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                          >
                            <span>{button?.text}</span>
                            <span>{button?.icon}</span>
                          </button>
                        )}
                      </div>
                    }



                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default SelectRequirement;
