import axios from "axios";
import React from "react";
import logoFooter from "../../assets/logos/logo-footer.webp"
import logo from "../../assets/logos/logo.webp"
import favicon from "../../assets/logos/favicon.png"
import {appAPiBasicInput, appApiHeader, appApiHeaderUpload, appBaseUrl, loginUserData} from "./client";
import {formatMoney} from "../../helper/custom_helper";

const appFooterLogo = logoFooter;
const appLogo = logo;
const appFavicon = favicon;
const baseUrl = appBaseUrl;
const header = appApiHeader;
const headerUpload = appApiHeaderUpload;
const basicInput = appAPiBasicInput;
const loginUser = loginUserData;

const apiHepingMethod = async (url, InputData) => {

    return await axios.post(
        baseUrl + "" + url,
        {...InputData, ...basicInput},
        {headers: {...header}}
    ).then((r) => r).catch((e) => {
        // console.log(e)
        // console.log(e.response.data);
        if (e?.response?.data) {
            return e.response.data;
        } else {
            return {
                "error": true,
                "message": e.message + ". Please try after sometime.",
                "status": 200,
                "data": null
            }
        }

    });
};

const apiHepingMethodUplod = async (url, formData) => {
    // const formData=new FormData();

    formData.append("device_type", basicInput?.device_type);
    formData.append("sstoken", basicInput?.sstoken);
    formData.append("device_token", basicInput?.device_token);
    formData.append("device_latitude", basicInput?.device_latitude);
    formData.append("device_longitude", basicInput?.device_longitude);

    return await axios.post(baseUrl + "" + url, formData, {
        headers: {...headerUpload},
    });
};

const getDataByUrl = async (url, inputData) => {
    let inputBody = {...basicInput};
    if (loginUser) {
        const loginDetail = {
            "login_user_type": loginUser?.user_type || "",
            "login_user_id": loginUser?.id || "",
        }
        inputBody = {...inputBody, ...loginDetail}
    }
    if (inputData) {
        inputBody = {...inputBody, ...inputData}
    }

    try {
        // console.log("liked",inputBody)
        return await axios.post(baseUrl + "" + url, {
            ...inputBody,
            device_token: localStorage.getItem('device_token'),
            device_latitude: localStorage.getItem('device_latitude'),
            device_longitude: localStorage.getItem('device_longitude')
        }, {headers: {...header}})
            .then((r) => r.data).catch((e) => {
                // console.log(e)
                // console.log(e.response.data);
                if (e?.response?.data) {
                    return e.response.data;
                } else {
                    return {
                        "error": true,
                        "message": e.message + ". Please try after sometime.",
                        "status": 200,
                        "data": null
                    }
                }

            })
    } catch (error) {
        const {response} = error;

        let message = "";
        if (response?.data) {
            message = response?.data?.message;
        }
        // return {error: error.message || error};
        return {
            "error": true,
            "message": message || error?.message || error || "Something went wrong",
            "status": 200,
            "data": null
        };
    }
};

const amountFormate = (value, type = 'double') => {
    // var res = value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // return res;

    return formatMoney(value, type);
}

async function ApiCallModal(url, inputData) {
    let bodyBata = {
        ...basicInput, ...inputData,
        device_token: localStorage.getItem('device_token'),
        device_latitude: localStorage.getItem('device_latitude'),
        device_longitude: localStorage.getItem('device_longitude')
    }
    const response = await axios.post(baseUrl + "" + url, bodyBata, {headers: {...header}})
        .then((r) => r.data).catch((e) => {
            // console.log("apiError", e);
        })
    return response;
}

export {
    apiHepingMethod,
    apiHepingMethodUplod,
    getDataByUrl,
    amountFormate,
    appFooterLogo,
    appLogo,
    appFavicon,
    ApiCallModal
};
