import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import { Skeleton } from "@mui/material";
import { amountFormate, apiHepingMethod, getDataByUrl } from "../API/HelpingMethod";
import SkeletonBuisnessplan from "../Homepage/Skeleton-files/SkeletonBuisnessplan";
import SkeletonBuisnessplanChild from "../Homepage/Skeleton-files/SkeletonBuisnessplanChild";
import image from "../images/Looper-2 (1).png";
import { useNotification } from "../../hooks";
import noimage from "../images/noimage2.webp"

import { HelmetCustomInclude } from "../../hooks/HelmetCustomInclude";

const BusinessPlan = () => {
  const [loading, setLoading] = useState(false);
  const [plan, setPlan] = useState([]);
  const [buisnessDetail, setBuisnessDetail] = useState({});
  const { setLoginModal, setLocation, setPrevLocation } = useNotification();
  const { slugServicePlan } = useParams();

  const [hoveredLinks, setHoveredLinks] = useState(
    Array(plan.length).fill(false)
  );



  const [pageTitle, setPageTitle] = useState(null);
  const [metaKeywords, setMetaKeywords] = useState(null);
  const [metaDescription, setMetaDescription] = useState(null);
  const [metaImageUrl, setMetaImageUrl] = useState(null);




  const handleHover = (index, isHovered) => {
    const updatedHoveredLinks = [...hoveredLinks];
    updatedHoveredLinks[index] = isHovered;
    setHoveredLinks(updatedHoveredLinks);
  };
  var loginuser = JSON.parse(localStorage.getItem("userstorage"));

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const inputData = {
    // service_id: searchParams.get("serviceid"),
    slug_service: slugServicePlan,
  };

  const apiCall = async () => {
    setLoading(true);
    if (loginuser) {
      inputData.login_user_id = loginuser?.id
    }
    const response = await getDataByUrl("service", inputData);
    // console.log("response---", response)
    if (!response?.error) {
      setBuisnessDetail(response?.data);
      setPlan(response?.data.plans);

      if (response?.data?.name) setPageTitle(response?.data?.name)
      if (response?.data?.url_image) setMetaImageUrl(response?.data?.url_image)

      if (response?.data?.page_description) {
        setMetaDescription(response?.data?.page_description)
      } else {
        setMetaDescription(response?.data?.description)
      }
      if (response?.data?.page_keywords) {
        setMetaKeywords(response?.data?.page_keywords)
      } else {
        setMetaKeywords(response?.data?.name)
      }

      setLoading(false);
    } else {
      // console.log("Error fetching data:", response?.message);
    }
  };
  useEffect(() => {
    apiCall();
  }, []);

  const CroppedText = ({ text, maxLength }) => {
    const croppedText =
      text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
    const styleText = { overflow: "hidden", textOverflow: "ellipsis" };
    return (
      <div>
        <div style={styleText}>{croppedText}</div>
      </div>
    );
  };
  const styleBridgecrumb = {
    textDecoration: "none",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
  const styleCard = { border: "1px solid #1CC571", height: "100%" };

  const breadcrumbItems = [
    { to: "/", label: " Home " },
    { to: "/service", label: " Services " },
    { to: "/service", label: buisnessDetail?.parent_name },
    { label: buisnessDetail?.name },
  ];





  return (
    <div>
      <HelmetCustomInclude url={"service-plan/" + slugServicePlan}
        ref_id={buisnessDetail?.id}
        ref_type={'buisness_plan'}
        title={pageTitle} image={metaImageUrl}
        description={metaDescription}
        keywords={metaKeywords} />

      <Container fluid>
        <Row>
          <Col>
            <img id="Servicebgimage" src={image} alt="img" onError={(e) => e.target.src = noimage} />
            <Container className="mt-1 pt-5">
              {loading ? <></> : <Row className="mt-2">
                <div className="d-flex flex-row">
                  {breadcrumbItems.map((item, index) => (
                    <div key={index}>
                      {item.to ? (
                        <Link
                          to={item.to}
                          className="color-secondary"
                          style={styleBridgecrumb}
                        >
                          {item.label}
                          {index < breadcrumbItems.length - 1 && (
                            <FiChevronRight color="#000000" />
                          )}
                        </Link>
                      ) : (
                        <div style={styleBridgecrumb}>{item.label}</div>
                      )}
                    </div>
                  ))}
                </div>
              </Row>}

              <Row className="mt-3">
                {loading ? (
                  <SkeletonBuisnessplan />
                ) : (
                  <>
                    <Col xs={12} md={9}>
                      {buisnessDetail && (
                        <h2 className="fw-bold">{buisnessDetail.name}</h2>
                      )}
                      <div
                        className="fontFamily"
                        dangerouslySetInnerHTML={{
                          __html: buisnessDetail?.description || "",
                        }}
                      />
                    </Col>
                    <Col xs={12} md={3}>
                      <Card>
                        <Card.Body>
                          <img
                            className="w-100 rounded"
                            src={buisnessDetail.url_image}
                            alt={buisnessDetail.name}
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                  </>
                )}
              </Row>

              <Row>
                {loading ? (
                  <SkeletonBuisnessplanChild />
                ) : (
                  <>
                    {plan.map((Business_PlanItem, ind) => (
                      <Col key={ind} xs={12} md={6} lg={4} xl={4} className="mt-3 mb-4" >
                        <Card style={styleCard}>
                          <Card.Body>
                            <h6 className="fw-bold">
                              <CroppedText
                                text={Business_PlanItem.title}
                                maxLength={80}
                              />
                            </h6>
                            <p className="fontFamily">
                              <CroppedText
                                text={Business_PlanItem.description}
                                maxLength={40}
                              />
                            </p>
                            <h5>
                              {loginuser ? loginuser?.user_type === "startup" ? <Link
                                to={Business_PlanItem.slug ? "/project-idea?slug=" + Business_PlanItem.slug : "/project-idea"}
                                className={`slide-button1 text-decoration-none ${hoveredLinks[ind] ? "hovered" : ""}`}
                              >
                                Subscribe
                              </Link> : <></> :
                                <div onClick={() => { setLoginModal(true) }}
                                  className={`slide-button1 text-decoration-none ${hoveredLinks[ind] ? "hovered" : ""
                                    }`} >
                                  Subscribe
                                </div>
                              }
                              <span className="float-end">
                                <div className="d-inline">
                                  <h6 className="d-inline">
                                    {amountFormate(Business_PlanItem.total_value_user_country)}
                                  </h6>{" "}
                                  <span className="d-inline">
                                    {
                                      Business_PlanItem.factor_value_user_country_currency
                                    }
                                  </span>
                                </div>
                              </span>
                            </h5>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </>
                )}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BusinessPlan;
