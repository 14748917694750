import {Button, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import {BsFillEyeFill, BsFillEyeSlashFill} from "react-icons/bs";
import {Link, useNavigate} from "react-router-dom"; 
import React, {useEffect, useState} from "react";
import {apiHepingMethod, getDataByUrl} from "../API/HelpingMethod"; 
import {useNotification} from "../../hooks";
import {FaCircleArrowLeft} from "react-icons/fa6";
import {FaEdit} from "react-icons/fa";
import { CircularProgress } from "@mui/material";
  
export default function VerifyOtpForm() {
    const navigate = useNavigate();
    const [passwordType, setPasswordType] = useState("password");
    const [buttonText, setButtonText] = useState("Verify Otp");
    const {showError, showSuccess} = useNotification();
    const [inputOtp, setInputOtp] = useState(null);
    const [bodyData, setBodyData] = useState({
        username: localStorage.getItem('email') || "",
        device_token: localStorage.getItem('device_token'),
        device_latitude: localStorage.getItem('device_latitude'),
        device_longitude: localStorage.getItem('device_longitude')
    });

    
    const handleOtp = (e) => {
        const {name, value} = e.target;
        setInputOtp(value);
    }


    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    function alertFunc() {
        navigate("/");
    }

    async function handleClick(e) {
        e.preventDefault();
        setButtonText(<CircularProgress color="inherit"/>);

        let response = await getDataByUrl("verify_user_by_otp", {...bodyData, otp: inputOtp}) 
        if (response.error === false) {
            showSuccess("OTP verified successfully");
            setTimeout(() => {
                navigate("/register")
                setButtonText("Verify")
            }, 2000);
        } else {
            showError(response.message);
            setButtonText("Verify")
        }

    }

    async function sendOtp() {
        let response = await getDataByUrl("verify_user_by_otp", bodyData) 
        if (response.error === false) {
            setInputOtp(response?.data?.otp);
            showSuccess(response.message);
        } else {
            showError(response.message);
        }
    }

    useEffect(() => {
        sendOtp().then((e) => e);

        if (!localStorage.getItem("email")) {
            navigate("/verify");
        }
    },[]);
    const styleToggle={cursor: "pointer"}
    return (
        <>

            <Container className="my-5">
                <p className={"py-2 text-end"}><Link to="/" className="color-secondary"><FaCircleArrowLeft/> Back to
                    home</Link>
                </p>
                <section className={'py-4'}>
                    {/*<p className={"my-2 text-center"}><img src={logo} alt="logo" style={{maxHeight: "50px", alignContent: "center"}}/></p>*/}
                    <h4 className={" fw-bold text-center"}>OTP Verification</h4>
                    <p className={"mb-2 text-center text-muted"}>Please provide following details to verify</p>

                    <form onSubmit={handleClick}>
                        <Row xs={{cols: 1}} className={"g-3 mt-2"}>
                            <Col>
                                <label htmlFor="">Email Address*</label>
                                <InputGroup>
                                    <Form.Control placeholder={"Please enter your email address"} type="email"
                                                  name="username" disabled={true} value={bodyData?.username}
                                                  aria-required={"true"}/>
                                    <InputGroup.Text className="basic-addon1" style={styleToggle}
                                                     onClick={() => {
                                                         navigate("/verify")
                                                     }}><FaEdit
                                        color={"var(--secondary)"}/></InputGroup.Text>
                                </InputGroup>
                            </Col>

                            <Col>
                                <label>OTP*</label>
                                <InputGroup>
                                    <Form.Control
                                        placeholder={"Please enter otp here, which sent to your email address"}
                                        type={passwordType} value={inputOtp} onChange={handleOtp}
                                        name="otp" required/>
                                    <InputGroup.Text className="basic-addon1" style={styleToggle}
                                                     onClick={togglePassword}>{passwordType === 'password' ?
                                        <BsFillEyeSlashFill color={"var(--secondary)"}/> :
                                        <BsFillEyeFill color={"var(--secondary)"}/>}</InputGroup.Text>
                                </InputGroup>
                            </Col>
                            <Col className={"text-end"}>
                                <span className="color-secondary link" onClick={sendOtp}>Resend Otp</span>
                            </Col>
                            {/* <Col>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="flexRadioDefault"
                                           id="flexRadioDefault1" required/>
                                    <label style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
                                           className="form-check-label" htmlFor="flexRadioDefault1">
                                        Keep me signed in
                                    </label>
                                </div>
                            </Col>*/}

                            {/*<Col className="text-end"
                         style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}><Link
                        to="/forgot_password" className="color-secondary text-decoration-none">Forgot
                        password?</Link></Col>*/}
                            <Col className="pe-0 ">
                                <Button type={"submit"} className="btn-custom">{buttonText}</Button>
                            </Col>
                        </Row>
                    </form>
                    <hr/>
                    {/*<Row className="">
                        <Col className="text-center"> or</Col>
                    </Row>*/}


                    <div className="mt-2">
                        {/*<button className="form-control"><FcGoogle className="me-2"/>Continue With Google</button>*/}
                        <p className="my-2 text-center">Not your email address?
                            <Link to="/verify" className="color-secondary"> go back</Link>
                        </p>
                    </div>
                </section>

            </Container>
        </>
    )
}