import React, {useEffect, useState} from "react";
import {Card, Col, Container, Modal, Row} from "react-bootstrap";
import app from "../images/setting-2setting.png";
import {Link, useNavigate, useParams} from "react-router-dom";
import {amountFormate, getDataByUrl} from "../API/HelpingMethod";
import {BsChevronLeft, BsClock, BsXLg} from "react-icons/bs";
import image from "../images/Looper-2 (1).png";
import {CircularProgress, Skeleton} from "@mui/material";
import {FaLongArrowAltRight} from "react-icons/fa";
import {useNotification} from "../../hooks";
import "../SelectService/Summary.css";
import noimage from "../images/noimage2.webp"
import {loginUserData} from "../API/client";
import {get_date_formatted, getFirstWordCap} from "../../hooks/useCustomHelpingMethod";

const Summary = () => {
  let authLoginUser = loginUserData;
  const [isHoveredConfirm, setIsHoveredConfirm] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [data, setData] = useState();
  const navigate = useNavigate();
  const [servicePlans, setServicePlans] = useState([]);
  const [servicePlan, setServicePlan] = useState({});
  const [serviceName, setServiceName] = useState({});
  const [serviceImg, setServiceImg] = useState({});
  const [planCardActive, setPlanCardActive] = useState("disable");
  const [serviceToken, setServiceToken] = useState({});
  const [planToken, setPlanToken] = useState();
  const { showError, showSuccess } = useNotification();
  const [loading, setLoading] = useState(false);
  const [activePlan, setActivePlan] = useState(servicePlan?.token);
  const [changePlan, setChangePlan] = useState({})
  const [buttonClicked2, setButtonClicked2] = useState(false);
  const { tokenWorkGroup } = useParams();

  const [button, setButton] = useState({
    text: "Confirm ",
    icon: <FaLongArrowAltRight />,
  });
  useEffect(() => {
    authLoginUser = localStorage.getItem("userstorage");
    if (!authLoginUser) {
      navigate("/project-idea")
    }
  }, [localStorage.getItem("userstorage")])

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const inputData = {
    // token_user_preference: authLoginUser?.token,
    token: tokenWorkGroup,
    login_user_id: authLoginUser?.id,
    login_user_type: authLoginUser?.user_type,
  };

  const apiCall = async () => {
    setLoading(true);
    const response = await getDataByUrl("user_work_group", inputData);
    console.log(response);
    if (!response?.error) {
      setData(response?.data);
    } else {
      // console.log("Error fetching data:", response?.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    apiCall();
  }, [changePlan]);

  const handleConfirmOrder = () => {
    // navigate("/confirm-order?token=" + data?.token);
    navigate("/confirm-order/" + data?.token);
  };
  const [isExpanded, setIsExpanded] = useState(false);

  const styleMessage = {
    whiteSpace: isExpanded ? 'normal' : 'nowrap',
    overflow: 'hidden',
    textOverflow: isExpanded ? 'none' : 'ellipsis',
    fontFamily: "Montserrat",
  };

  const selectPlan = (token, status) => {
    setServicePlan(token)
    setPlanToken(token);
    setPlanCardActive(status);
    setActivePlan(token);
    // console.log("token......", token);
  };

  const handleChangePlan = (plans, plan, token, name, image) => {
    setServicePlans(plans);
    setServicePlan(plan);
    // console.log("servicePlans------", plan);
    setServiceToken(token);
    setServiceName(name);
    setServiceImg(image);
    handleShow();
  };

  const plansbodydata = {
    group_token: tokenWorkGroup,
    token_service: serviceToken,
    token_service_plan: planToken || servicePlan?.token,
    login_user_id: authLoginUser?.id,
  };

  const handleSelectPlan = async () => {
    setButtonClicked2(true)
    const response = await getDataByUrl(
      "user_work_group_service_update",
      plansbodydata
    );

    if (response?.error === false) {
      setChangePlan(response?.data)
      showSuccess(response?.message);
      handleClose();
    } else {
      showError(response?.message);
      // console.log("Error fetching data:", response?.message);
    }
    setButtonClicked2(false)
  };


  const x = 125465778;
  const res = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");


  const styleDiv = { height: "80px", width: "80px" };
  const styleImage = { height: "100%", width: "100%" };
  const styleCross = { cursor: "pointer" };
  const styleChangeButton = {
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
  };
  const titleMaxLength = 70;
  const limitTitleLength = (title, maxLength) => {
    if (title.length > maxLength) {
      return title.substring(0, maxLength) + "...";
    }
    return title;
  };
 
  return (
    <div>

      <img id="Servicebgimage" src={image} alt="img" onError={(e) => e.target.src = noimage} />
      <Container className="mt-3 pt-5">
        {/* <Link
          to={"/proceed/" + tokenWorkGroup}
          className="color-secondary text-decoration-none">
          {" "}
          <BsChevronLeft color="#000000" />&nbsp;   Back{" "}
        </Link> */}
      </Container>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Body className="plans-modal">
          <Container>
            <Row className="plans-modal-header">
              <Col className="p-0">
                <div style={styleDiv}>
                  <img className="rounded" style={styleImage} src={serviceImg || app} onError={(e) => e.target.src = noimage}  alt={'im'}/>
                </div>
              </Col>
              <Col className="text-end">
                {" "}
                <BsXLg style={styleCross} onClick={handleClose} />{" "}
              </Col>
            </Row>
            <Row>
              <Col className="fw-bold p-0">{serviceName}</Col>
            </Row>
            <Row> <Col className=" p-0"> Select a service plan to change for your project requirement. </Col> </Row>
            <Row>
              {servicePlans && Array.isArray(servicePlans) && servicePlans.map((data,index) => {
                  return ( <Col key={data?.token||index} style={{ cursor: "pointer" }} onClick={() => { selectPlan(data?.token, data?.status) }} sm={6} className=" mt-2">
                        <Card className={(servicePlan?.token === data?.token || activePlan === data?.token) ? "active-plan-card p-1": " p-1"}>
                          <Container>
                            <Row className="mt-1">
                              <Col>{data?.title}</Col>
                            </Row>
                            <Row className="mt-2 mb-1">
                              <Col className="fw-bold">
                                {Math.ceil(data?.total_value_user_country)} {data?.factor_value_user_country_currency}
                              </Col>
                            </Row>
                          </Container>
                        </Card>
                      </Col>);
                })}
            </Row>
          </Container>
        </Modal.Body>
        <Container>
          <Row className="mb-3">
            <Col>
              {/* <button
                onClick={handleSelectPlan}
                className={`slide-button1 fontFamily text-decoration-none ${isHovered ? "hovered" : ""
                  }`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <span>{button?.text}</span>
                <span>{button?.icon}</span>
              </button> */}

              <div style={{ position: "relative" }}>
                {buttonClicked2 && (
                  <button className='fontFamily text-decoration-none mb-4 disabled-btn' disabled>
                    <>
                      <CircularProgress style={{ color: "var(--secondary)" }} className="mt-1" size={16} />&nbsp;&nbsp; Loading...
                    </>
                  </button>
                )}
                {!buttonClicked2 && (
                  <button
                    onClick={handleSelectPlan}
                    className={`slide-button1 fontFamily text-decoration-none mb-4  ${isHovered ? "hovered" : ""}`}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    <span>{button?.text}</span>
                    <span>{button?.icon}</span>
                  </button>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </Modal>


      <Container className=" pt-3 mb-5 ">
        <Row className="ms-sm-0 ms-1 me-sm-0  me-1">
          <Card>
            <Card.Body>
              <Container>
                <Row className="mb-2 fw-bold h3">Summary</Row>
                <Row>
                  {loading && <div className="skeleton-m-p"><Skeleton width="100%" height={100} /></div>}
                  {!loading && <Card>
                    <Card.Body>
                      <Container>
                        <Row> <Col className=""> {data?.title} </Col> </Row>
                        <Row>
                          <Col xl={10} lg={10} className="color-secondary">
                            <BsClock /> &nbsp; {get_date_formatted(data?.created_at||data?.updated_at||null,'date-time')}
                          </Col>
                          <Col xl={2} lg={2} className="color-secondary text-end" > {getFirstWordCap(data?.status)} </Col>
                        </Row>
                      </Container>
                    </Card.Body>
                  </Card>}
                </Row>

                <Row className="mb-2 mt-4 fw-bold">Selected Idea</Row>
                {loading && <div className="skeleton-m-p"><Skeleton width="100%" height={70} /></div>}

                {!loading && <Row> {data?.startup_ideas && Array.isArray(data?.startup_ideas) && data?.startup_ideas.map((startupIdea) => {
                      return (
                        <Card key={startupIdea?.token}>
                          <Card.Body>
                            <Container>
                              <Row>{startupIdea?.name}</Row>
                            </Container>
                          </Card.Body>
                        </Card>
                      );
                    })}
                </Row>}
                <Row className="mb-2 mt-4 fw-bold">Select Services</Row>


                {loading && <div className="skeleton-m-p"><Skeleton width="100%" height={100} /></div>}

                {!loading && data?.services.map((data) => {
                  return (
                    <>
                      <Row className="p-0 mt-3">
                        <Card className="p-2">
                          <Card.Body className="p-0">
                            <Container>
                              <Row className="p-0">
                                <Col lg={1} md={2} className=" text-md-start text-center " >
                                  <Container className="p-0">
                                    <Row className="p-0">
                                      <Col md={0} xs={3} className="p-0 d-md-none d-block" ></Col>
                                      <Col md={12} xs={6} className="ps-0 pe-0 mt-4" >
                                        <img className="rounded" alt="" style={{ width: "100%", height: "100%", }}
                                          src={data?.url_image || app} />
                                      </Col>
                                    </Row>
                                  </Container>
                                </Col>
                                <Col lg={11} md={10}>
                                  <Container className="">
                                    <Row className="p-0">
                                      <Col sm={6} xs={12} className="fw-bold ps-0 text-sm-start text-center"  >
                                        {data?.name}
                                      </Col>
                                      <Col sm={6} xs={12} className="text-sm-end text-center fw-bold " >
                                        {amountFormate(data?.total_value_user_country)} {data?.factor_value_user_country_currency}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col className="ps-0 fontFamily text-sm-start text-center text-primary">
                                         <i>(Include all services)</i>
                                      </Col>
                                    </Row>
                                    {data?.child_services.map( (child_services_data) => {
                                        return (
                                          <>
                                            <Row className="mt-1 pt-1 pb-1 ps-1 pe-1 border rounded">
                                              <Col sm={8} xs={12} md={9} lg={10} xl={10} className="ps-0 ">
                                                <p className=" d-flex mb-0">
                                                  <small>
                                                     {child_services_data?.name} (<small>{ limitTitleLength(child_services_data?.plan?.title, titleMaxLength) }</small>)
                                                   {/* <div style={styleMessage}>
                                                    </div>*/}
                                                  </small>
                                                </p>
                                              </Col>
                                              <Col md={3} lg={2} xl={2} sm={4} xs={12}
                                                className="text-end mt-sm-0 mt-1 text-decoration-underline color-secondary"
                                              >
                                                <div
                                                  style={{
                                                    cursor: "pointer",
                                                    transition: "transform 0.3s"
                                                  }}

                                                  onMouseOver={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
                                                  onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")}

                                                  onClick={() => {
                                                    handleChangePlan(
                                                        child_services_data?.plans,
                                                        child_services_data?.plan,
                                                        child_services_data?.token,
                                                        child_services_data?.name,
                                                        child_services_data?.url_image
                                                    );
                                                  }}
                                                >
                                                  Change
                                                </div>
                                              </Col>
                                            </Row>
                                          </>
                                        );
                                      } )}
                                  </Container>
                                </Col>
                              </Row>
                            </Container>
                          </Card.Body>
                        </Card>
                      </Row>
                    </>
                  );
                })}

                <Row className="mb-2 mt-4 fw-bold">Services Duration</Row>
                <Row>
                  {loading && <div className="skeleton-m-p"><Skeleton width="100%" height={70} /></div>}

                  {!loading && <Card>
                    <Card.Body>
                      <Container>
                        <Row className="fontFamily">
                          <b>This work order includes 1 year maintenance and support.</b>
                        </Row>
                      </Container>
                    </Card.Body>
                  </Card>}
                </Row>
                <Row className="mb-2 mt-4">
                  {!loading && <Container>
                    <Row>
                      <Col className="ps-0 pe-0">
                        <Container>
                          <Row>Total Services</Row>
                          <Row className="mt-2">
                            <Col className="ps-0">
                              <div
                                  className="h4 fw-bold mb-0">{amountFormate(data?.amount_total_payable_user_country)}&nbsp;
                                {data?.amount_currency_user_country}{" "}</div>
                              <div style={{fontSize: "12px", color: "red"}}>
                                <b>(You save 18% on market price in this order.)</b>
                              </div>

                            </Col>
                            <Col className="text-end">
                              {loading ? <></> : <button
                                  onClick={handleConfirmOrder}
                                  className={`slide-button1 fontFamily text-decoration-none
                                     ${isHoveredConfirm ? "hovered" : ""}`}
                                  onMouseEnter={() => setIsHoveredConfirm(true)}
                                  onMouseLeave={() => setIsHoveredConfirm(false)}>
                                Confirm
                              </button>}
                            </Col>
                          </Row>
                          <Row className="">
                            <Col sm={12} xs={12} className="mt-2 color-primary ps-0" style={styleChangeButton} >
                              <b><i>(GST@18% Applicable on all services)</i></b>
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                  </Container>}
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Row>


      </Container>
    </div>
  );
};
export default Summary;


