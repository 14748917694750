import React from 'react';
import { Skeleton } from '@mui/material';
import { Col } from 'react-bootstrap';
import { useMediaQuery } from '@mui/material';;

export default function SkeletonBuisnessplan() {
  const isXSmallScreen = useMediaQuery('(max-width:400px)');
  const isSmallScreen = useMediaQuery('(min-width:401px) and (max-width:600px)');
  const isMediumScreen = useMediaQuery('(min-width:601px) and (max-width:960px)');
  const isLargeScreen = useMediaQuery('(min-width:961px) and (max-width:1280px)');
  const isXLargeScreen = useMediaQuery('(min-width:1281px)')
  return (
    <>
    <Col xs={12} md={9}>
    <Skeleton
          className="rounded"
          variant="rectangle"
          width={isXSmallScreen ? 150 : (isSmallScreen ? 180 : (isMediumScreen ? 200 : (isLargeScreen ? 220 : (isXLargeScreen ? 250 : 300))))}
          height={30}
        />
        <Skeleton
          className="rounded mt-3 mb-3"
          variant="rectangle"
          width={isXSmallScreen ? 250 : (isSmallScreen ? 350 : (isMediumScreen ? 500 : (isLargeScreen ? 520 : (isXLargeScreen ? 800 : 800))))}
          height={20}
        />
      </Col>
      <Col xs={12} md={3}>
      <Skeleton
          variant="rectangle"
          className="rounded"
          width={isXSmallScreen ? 150 : (isSmallScreen ? 180 : (isMediumScreen ? 180 : (isLargeScreen ? 220 : (isXLargeScreen ? 250 : 300))))}
          height={isXSmallScreen ? 100 : (isSmallScreen ? 150 : (isMediumScreen ? 180 : (isLargeScreen ? 200 : (isXLargeScreen ? 220 : 250))))}
        />
      </Col>
    </>
  );
}
 