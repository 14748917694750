import React, { useEffect, useState } from "react";
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { BsChevronRight, BsGearFill, BsPencilFill, BsXLg } from "react-icons/bs";
import { getDataByUrl } from "../API/HelpingMethod";
import { CircularProgress, Skeleton } from "@mui/material";
import image from "../images/Looper-2 (1).png";
import "../css/Home.css";
import UserProfileSidebar from "./UserProfileSidebar";
import { useNotification } from "../../hooks";
import { FaEdit } from "react-icons/fa";

const UserProfile = () => {
  const navigate = useNavigate()
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [prefrence, setPrefrence] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);



  var loginuser = JSON.parse(localStorage.getItem("userstorage"));
  const [activePreference, setActivePreference] = useState(loginuser?.name_user_preference);
  const [activeStage, setActiveStage] = useState(loginuser?.startup_stage);

  const [btnLoading, setBtnLoading] = useState(false);
  const { showError, showSuccess, setOpenBackDrop } = useNotification();
  const [startupStage, setStartupStage] = useState([]);

  useEffect(() => {
    if (!loginuser) {
      navigate("/");
    }


  }, [])


  const inputData = {
    login_user_id: loginuser?.id,
    login_user_type: loginuser?.user_type || "startup",
  };


  const [formData, setFormData] = useState({
    name_user_preference: loginuser?.name_user_preference || "",
  });

  const bodyData = {
    login_user_id: loginuser?.id || 0,
    login_user_type: loginuser?.user_type || "startup",
    token: loginuser?.token,
    values: { ...formData },
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    apiCall();
  }, []);

  const apiCall = async () => {
    setLoading(true);
    const response = await getDataByUrl("user_profile", inputData);
    if (!response?.error) {
      // setData(response?.data);
      setData(response?.data);
      setLoading(false);
    } else {
      // console.log("Error fetching data:", response?.message);
    }
  };

  async function getPrefrence() {
    // setOpenBackDrop(true);
    let response = await getDataByUrl("user_preference", {
      limit: 0,
      offset: 0
    })
    if (response.error === false) {
      setPrefrence(response?.data || []);
    } else {
      setPrefrence([]);
    }
    // setOpenBackDrop(false);

  }
  useEffect(() => {
    getPrefrence().then((e) => e);
  }, []);


  async function getStartupStage() {
    // setOpenBackDrop(true);
    let response = await getDataByUrl("user_startup_stage", {
      limit: 0,
      offset: 0
    })
    if (response.error === false) {
      setStartupStage(response?.data || []);
    } else {
      setStartupStage(response?.data || []);
    }
    // setOpenBackDrop(false);
  }
  useEffect(() => {
    getStartupStage().then((e) => e);
  }, []);



  const ChangePpeference = (value, token) => {
    setActivePreference(value);
    setFormData({ ...formData, token_user_preference: token })
  };

  const changeStage = (value) => {
    setActiveStage(value);
    setFormData({ ...formData, startup_stage: value })
  };




  const UpdatePreference = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    const response = await getDataByUrl("user_profile_update", bodyData);
    console.log("response---", response)
    if (!response?.error) {
      localStorage.setItem("userstorage", JSON.stringify(response?.data));
      showSuccess(response?.message);
      //  setLoading(false) 
      setShow2(false)
      setShow(false)
      window.location.reload()
    } else {
      showError(response?.message);
    }
    setBtnLoading(false);
  };



  const textState = "State"
  const textCity = "City"
  var dob = "15 Aug 2001";
  const styleSpan = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
  const textUserProfile = "User Profile";
  const UserProfileInformation = "Personal Information";
  const textName = "Name";
  const emailText = "Email";
  const mobileNumber = "Mobile Number";
  const textGender = "Gender";

  const dateOfBirth = "Date Of Birth";
  const company = "Company";
  const AddressDetails = "Address Details";
  const brand = "Brand";
  const textAddress = "Address";
  const CompanyDetails = "Company Details";
  const UserPreferenceSelect = "User Preference Select";
  const UserPreference = "User Preference"

  const styleBorder = {
    border: "1px solid var(--primary)",
    paddingBottom: "52px"
  }
  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Body className="plans-modal">
          <Container>
            <Row>
              <Col xs={10}>
                <div><span className="h6">Update Preference :</span> <span>{activePreference}</span></div>
              </Col>
              <Col xs={2} className="text-end pe-0">
                <div>
                  <BsXLg className="close-button" size={30} onClick={() => setShow(false)} />
                </div>
              </Col>
            </Row>
            <Row sm={{ cols: 2 }} xs={{ cols: 1 }} className="change-startup-stage-box">
              {prefrence && Array.isArray(prefrence) && prefrence.map((data, index) => {
                return (<Col key={data?.token || index}
                  onClick={() => { ChangePpeference(data?.name, data.token) }}
                  className=" mt-2 ">
                  <Card className={(activePreference === data?.name) ? "active-startup-stage startup-stage-card " : " startup-stage-card"}>
                    <Container>
                      <Row className="">
                        <Col>
                          {/* <BsGearFill size={17} color="var(--secondary)" /> &nbsp; */}
                          <span className="h6">{data?.name}</span>
                        </Col>
                      </Row>
                    </Container>
                  </Card>
                </Col>);
              })}
            </Row>
          </Container>
        </Modal.Body>
        <Container>
          <Row className="mb-3 text-center">
            <Col>
              <div className="ms-2">
                <button disabled={btnLoading} className={btnLoading ? "disabled-btn" : "slide-button1"}
                  onClick={UpdatePreference}
                >
                  {btnLoading && <><CircularProgress color="inherit" className="mt-1" size={16} />&nbsp;</>}Update
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal>


      <Modal show={show2} onHide={() => setShow2(false)} size="lg">
        <Modal.Body className="plans-modal">
          <Container>
            <Row>
              <Col xs={10}>
                <div><span className="h6">Update Startup Stage :</span> <span>{activeStage}</span></div>
              </Col>
              <Col xs={2} className="text-end pe-0">
                <div>
                  <BsXLg className="close-button" size={30} onClick={() => setShow2(false)} />
                </div>
              </Col>
            </Row>
            <Row sm={{ cols: 2 }} xs={{ cols: 1 }} className="change-startup-stage-box">
              {startupStage && Array.isArray(startupStage) && startupStage.map((data, index) => {
                return (<Col key={data?.token || index}
                  onClick={() => { changeStage(data?.name) }}
                  className=" mt-2 ">
                  <Card className={(activeStage === data?.name) ? "active-startup-stage startup-stage-card " : " startup-stage-card"}>
                    <Container>
                      <Row className="">
                        <Col>
                          {/* <BsGearFill size={17} color="var(--secondary)" /> &nbsp; */}
                          <span className="h6">{data?.name}</span>
                        </Col>
                      </Row>
                    </Container>
                  </Card>
                </Col>);
              })}
            </Row>

          </Container>
        </Modal.Body>
        <Container>
          <Row className="mb-3 text-center">
            <Col>
              <div className="ms-2">
                <button disabled={btnLoading}
                  className={btnLoading ? "disabled-btn" : "slide-button1"}
                  onClick={UpdatePreference}
                >
                  {btnLoading && <><CircularProgress color="inherit" className="mt-1" size={16} />&nbsp;</>}Update
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal>

      <Container className=" pt-5 notification-container">
        <img id="Servicebgimage" src={image} alt="img" />
        <Row className="mt-2 mb-2">
          <Col className="ms-3">
            <Link to="/" className="text-decoration-none color-secondary">Home</Link>&nbsp;<BsChevronRight />&nbsp;<span>Profile</span>
          </Col>
        </Row>

        <Row className="">
          <Col sm={12} xs={12} md={5} lg={4} xl={3} className="mb-5" >
          <UserProfileSidebar />
          </Col>
          <Col sm={12} xs={12} xl={9} md={7} lg={8} className=" pb-5">
            <Card style={styleBorder} className="">
              <Card.Body className="">
                <Row>
                  <h5 className="fontFamily fw-bold ">{textUserProfile}</h5>
                  <h6 className="fontFamily fw-bold mt-2">{UserProfileInformation}</h6>
                  <Col md={12} sm={12} xs={12} className="">

                    {
                      loading ? <Row className="ps-2 pe-2">
                        <Skeleton height={80} />
                      </Row> : <Row> <Col className="mt-4"  >
                        <div className="card">
                          <div className="row g-0">
                            <Col >
                              <div className="card-body pt-2 pb-2">
                                <span className="card-text fontFamily">
                                  {textName}
                                </span>
                              </div>
                            </Col>

                            <Col className="text-end">
                              <div className="card-body pt-2">
                                <span className="h6  fontFamily"  >
                                  {data?.name || ""}
                                </span>
                              </div>
                            </Col>
                          </div>
                        </div>
                      </Col>
                      </Row>
                    }



                  </Col>
                </Row>

                <Row>

                  {
                    loading ? <Col xl={6} lg={12} md={12} sm={12} xs={12} className="ps-2 pe-2">
                      <Skeleton height={80} />
                    </Col> :
                      <Col xl={6} lg={12} md={12} sm={12} xs={12} className="mt-3">
                        <div className="card">
                          <div className="row g-0">
                            <Col xs={4}>
                              <div className="card-body pt-2 pb-2">
                                <span className="card-text fontFamily">
                                  {emailText}
                                </span>
                              </div>
                            </Col>

                            <Col xs={8} className="text-end">
                              <div className="card-body pt-2 pb-2">
                                {loading ? (
                                  <Skeleton
                                    className="rounded"
                                    variant="rectangle"
                                    width={250}
                                    height={20}
                                  />
                                ) : (
                                  <span
                                    className="h6  fontFamily"
                                  >
                                    {data?.email}
                                  </span>
                                )}
                              </div>
                            </Col>
                          </div>
                        </div>
                      </Col>
                  }



                  {
                    loading ? <Col xl={6} lg={12} md={12} sm={12} xs={12} className="ps-2 pe-2">
                      <Skeleton height={80} />
                    </Col> :
                      <Col xl={6} lg={12} md={12} sm={12} xs={12} className="mt-3">
                        <div className="card">
                          <div className="row g-0">
                            <Col xs={8}>
                              <div className="card-body pt-2 pb-2">
                                <span

                                  className="card-text mt-4 fontFamily"
                                >
                                  {/* <MdOutlineCall className="me-2 color-secondary" /> */}
                                  {mobileNumber}
                                </span>
                              </div>
                            </Col>

                            <Col xs={4} className="text-end">
                              <div className="card-body pt-2 pb-2">
                                {loading ? (
                                  <Skeleton
                                    className="rounded"
                                    variant="rectangle"
                                    width={100}
                                    height={20}
                                  />
                                ) : (
                                  <span
                                    className=" h6  fontFamily"
                                  >
                                    {data?.mobile}
                                  </span>
                                )}
                              </div>
                            </Col>
                          </div>
                        </div>
                      </Col>

                  }

                </Row>

                <Row>
                  {
                    loading ? <Col xl={6} lg={6} md={12} sm={12} xs={12} className="ps-2 pe-2">
                      <Skeleton height={80} />
                    </Col> :
                      <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mt-2">
                        <div className="card">
                          <div className="row g-0">
                            <Col xs={6}>
                              <div className="card-body pt-2 pb-2">
                                <span className="card-text mt-4 fontFamily">
                                  {/* <CiUser className="me-2 color-secondary" /> */}
                                  {textGender}
                                </span>
                              </div>
                            </Col>

                            <Col xs={6} className="text-end">
                              <div className="card-body pt-2 pb-2">

                                <span className=" h6  fontFamily">
                                  {data?.gender || ""}
                                </span>
                              </div>
                            </Col>
                          </div>
                        </div>
                      </Col>
                  }
                  {
                    loading ? <Col xl={6} lg={6} md={12} sm={12} xs={12} className="ps-2 pe-2">
                      <Skeleton height={80} />
                    </Col> :
                      <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mt-2">
                        <div className="card">
                          <div className="row g-0">
                            <Col xs={6} lg={6} md={6} xl={6}>
                              <div className="card-body pt-2 pb-2">
                                <span
                                  className="card-text mt-4 fontFamily"
                                  style={styleSpan}
                                >
                                  {dateOfBirth}
                                </span>
                              </div>
                            </Col>

                            <Col xs={6} lg={6} md={6} xl={6} className="text-end">
                              <div className="card-body pt-2 pb-2">
                                <span
                                  className=" h6  fontFamily"
                                  style={styleSpan}
                                >
                                  {data?.dob || dob}
                                </span>
                              </div>
                            </Col>
                          </div>
                        </div>
                      </Col>
                  }

                </Row>

                <Row>
                  <Col className="mt-3">
                    {loading ? <> </> : <h6 className="fontFamily fw-bold">{AddressDetails}</h6>}
                  </Col>
                </Row>

                <Row>

                  {loading ? <Col xl={6} lg={6} md={12} sm={12} xs={12} className="ps-2 pe-2">
                    <Skeleton height={80} />
                  </Col> : <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mt-2">
                    <div className="card">
                      <div className="row g-0">
                        <Col xs={6}>
                          <div className="card-body pt-2 pb-2">
                            <span className="card-text mt-4 fontFamily">
                              {textCity}
                            </span>
                          </div>
                        </Col>

                        <Col xs={6} className="text-end">
                          <div className="card-body pt-2 pb-2">
                            <span className=" h6  fontFamily">
                              {data?.city || ""}
                            </span>
                          </div>
                        </Col>
                      </div>
                    </div>
                  </Col>}

                  {loading ? <Col xl={6} lg={6} md={12} sm={12} xs={12} className="ps-2 pe-2">
                    <Skeleton height={80} />
                  </Col> : <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mt-2">
                    <div className="card">
                      <div className="row g-0">
                        <Col xs={6}>
                          <div className="card-body pt-2 pb-2">
                            <span className="card-text mt-4 fontFamily">
                              {textState || ""}
                            </span>
                          </div>
                        </Col>

                        <Col xs={6} className="text-end">
                          <div className="card-body pt-2 pb-2">
                            <span className=" h6  fontFamily" >
                              {data?.state || ""}
                            </span>
                          </div>
                        </Col>
                      </div>
                    </div>
                  </Col>}
                </Row>

                <Row>

                  {loading ? <Col className="ps-2 pe-2">
                    <Skeleton height={80} />
                  </Col> : <Col className="mt-2">
                    <div className="card">
                      <div className="row g-0">
                        <Col>
                          <div className="card-body pt-2 pb-2">
                            <span
                              style={styleSpan}
                              className="card-text mt-4 fontFamily"
                            >
                              {textAddress}
                            </span>
                          </div>
                        </Col>

                        <Col className="text-end">
                          <div className="card-body pt-2 pb-2">
                            <span className=" h6  fontFamily">
                              {data?.address ||
                                "2nd Floor, Plot No. 10"}
                            </span>
                          </div>
                        </Col>
                      </div>
                    </div>
                  </Col>}
                </Row>

                <Row>
                  <Col className="mt-3">
                    {loading ? <> </> : <h6 className="fontFamily fw-bold">{CompanyDetails}</h6>}
                  </Col>
                </Row>

                <Row>
                  {loading ? <Col xl={6} lg={6} md={12} sm={12} xs={12} className="ps-2 pe-2">
                    <Skeleton height={80} />
                  </Col> : <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mt-2">
                    <div className="card">
                      <div className="row g-0">
                        <Col xs={6}>
                          <div className="card-body pt-2 pb-2">
                            <span className="card-text mt-4 fontFamily">
                               Company Name
                            </span>
                          </div>
                        </Col>

                        <Col xs={6} className="text-end">
                          <div className="card-body pt-2 pb-2">
                            <span className=" h6  fontFamily">
                              {data?.name_company || "Aimerse"}
                            </span>
                          </div>
                        </Col>
                      </div>
                    </div>
                  </Col>}
                  {loading ? <Col xl={6} lg={6} md={12} sm={12} xs={12} className="ps-2 pe-2">
                    <Skeleton height={80} />
                  </Col> : <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mt-2">
                    <div className="card">
                      <div className="row g-0">
                        <Col xs={6}>
                          <div className="card-body pt-2 pb-2">
                            <span className="card-text mt-4 fontFamily">
                              {brand}
                            </span>
                          </div>
                        </Col>

                        <Col xs={6} className="text-end">
                          <div className="card-body pt-2 pb-2">
                            <span
                              className=" h6  fontFamily"
                            >
                              {data?.name_brand || "startup"}
                            </span>
                          </div>
                        </Col>
                      </div>
                    </div>
                  </Col>}
                </Row>

                <Row>
                  <Col className="mt-3">
                    {loading ? <></> : <h6 className="fontFamily fw-bold">{UserPreferenceSelect}</h6>}
                  </Col>
                </Row>


                <Row>
                  {loading ? <Col xl={6} lg={12} md={12} sm={12} xs={12} className="ps-2 pe-2">
                    <Skeleton height={80} />
                  </Col> : <Col xl={6} lg={12} md={12} sm={12} xs={12} className="mt-2">
                    <div className="card mb-3">
                      <div className="row g-0">
                        <Col xs={6}>
                          <div className="card-body pt-2 pb-2">
                            <span className="card-text mt-4 fontFamily">
                              {UserPreference}
                            </span>
                          </div>
                        </Col>

                        <Col xs={6} className="text-end">
                          <div className="card-body pt-2 pb-2">
                            <span className=" h6  fontFamily">
                              {data?.user_preference?.name} &nbsp; <FaEdit
                                style={{ transition: "transform 0.3s" }}
                                onMouseOver={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
                                onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")}
                                className="pointer" color="var(--secondary)" onClick={handleShow} />
                            </span>
                          </div>
                        </Col>
                      </div>
                    </div>
                  </Col>}
                  {loading ? <Col xl={6} lg={12} md={12} sm={12} xs={12} className="ps-2 pe-2">
                    <Skeleton height={80} />
                  </Col> : <Col xl={6} lg={12} md={12} sm={12} xs={12} className="mt-2">
                    <div className="card mb-3">
                      <div className="row g-0">
                        <Col xs={6}>
                          <div className="card-body pt-2 pb-2">
                            <span className="card-text mt-4 fontFamily">
                              Startup Stage
                            </span>
                          </div>
                        </Col>

                        <Col xs={6} className="text-end">
                          <div className="card-body pt-2 pb-2">
                            <span
                              className=" h6  fontFamily"
                            >
                              {data?.startup_stage || "Problem"}&nbsp; <FaEdit
                                style={{ transition: "transform 0.3s" }}
                                onMouseOver={(e) => (e.currentTarget.style.transform = "translateY(-5px)")}
                                onMouseOut={(e) => (e.currentTarget.style.transform = "translateY(0)")}
                                className="pointer" color="var(--secondary)" onClick={() => setShow2(true)} />
                            </span>
                          </div>
                        </Col>
                      </div>
                    </div>
                  </Col>}
                </Row>

              </Card.Body>
            </Card>

          </Col>
        </Row>
      </Container>
    </>
  );
};
export default UserProfile;


