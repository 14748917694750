import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import UserProfileSidebar from "../../UserProfile/UserProfileSidebar";
import { Link, useParams } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import noimage from "../../images/noimage.png"
import image from "../../images/Looper-2 (1).png"
import img from "../../images/Frame 104Customized_Development.png"
import { amountFormate, getDataByUrl } from "../../API/HelpingMethod";
import { get_date_formatted } from "../../../hooks/useCustomHelpingMethod";
import { Chip, Skeleton } from "@mui/material";
import { FaEdit } from "react-icons/fa";

const OrderDetail = () => {
    const { slugorder } = useParams();
    const [order, setOrder] = useState({});
    const [loading, setLoading] = useState(false);
    var loginuser = JSON.parse(localStorage.getItem("userstorage"));
    const inputData = {
        token: slugorder,
        // login_user_id: loginuser?.id,
        login_user_id: 200,
        login_user_user_type: loginuser?.user_type,
    };
    const getDetail = async () => {
        setLoading(true);
        const response = await getDataByUrl("marketplace_order", inputData)
        console.log("response---", response)
        setOrder(response?.data)

        setLoading(false);

    }

    useEffect(() => {
        getDetail()
    }, [])

    const styleBorder = {
        border: "1px solid var(--primary)",
        paddingBottom: "45px"
    }
    return (
        <>
            <Container className="mt-5 mb-4">
                <img id="Servicebgimage" src={image} alt="img" onError={(e) => e.target.src = noimage} />
                <Row className="mt-2 mb-2">
                    <Col className="ms-3">
                        <Link to="/" className="text-decoration-none color-secondary">Home</Link>&nbsp;<BsChevronRight />&nbsp;<span>MarketPlace Order</span>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} xs={12} md={5} lg={4} xl={3} className="pb-5">
                        <UserProfileSidebar />
                    </Col>
                    <Col sm={12} xs={12} xl={9} md={7} lg={8}>
                        <Container>
                            <Row className="">
                                <Card style={styleBorder}>
                                    <Card.Body>
                                        <Row>
                                            <Col className="ps-0">
                                                <span className="fw-bold h5">MarketPlace Order Details</span>
                                            </Col>
                                        </Row>


                                        <Row className="mt-3">

                                            {loading && <>
                                                <Col xl={6} className="mx-auto skeleton-m-p ">
                                                    <Skeleton width="100%" height={300} />
                                                </Col>
                                                <Col xl={6} className="mx-auto mt-xl-0 mt-3 skeleton-m-p ">
                                                    <Skeleton width="100%" height={300} />
                                                </Col>
                                            </>}

                                            {!loading && <Col xl={6}>
                                                <Card>
                                                    <Card.Body>
                                                        <Row>
                                                            <span className="h6 fw-bold">Order Details</span>
                                                        </Row>
                                                        <Row className="mt-2  ">
                                                            <Col xs={4}  >
                                                                <div className="">Order No.</div>
                                                            </Col>
                                                            <Col className="text-end h6" xs={8}  >
                                                                <div> #{order?.token}</div>
                                                            </Col>
                                                        </Row>


                                                        <Row className="mt-2  ">
                                                            <Col xs={4}  >
                                                                <div className="">Order Date</div>
                                                            </Col>
                                                            <Col className="text-end h6" xs={8} >
                                                                <div> {get_date_formatted(order?.created_at)}</div>
                                                            </Col>
                                                        </Row>

                                                        <Row className="mt-3 ">
                                                            <Col xs={4}  >
                                                                <div className="">Status</div>

                                                            </Col>
                                                            <Col className="text-end " xs={8}>
                                                                <div className="order-status"> <Chip label={order?.status}
                                                                    color={order?.status === "pending" ? "warning" : order?.status === "success" ? "success" : "primary"}
                                                                /></div>
                                                            </Col>
                                                        </Row>


                                                        <Row className="mt-3 ">
                                                            <Col xs={4}  >
                                                                <div className=""> Amount Total</div>
                                                            </Col>
                                                            <Col className="text-end h6" xs={8} >
                                                                <div> {amountFormate(order?.amount_total_user_country)} {order?.amount_currency_user_country}</div>
                                                            </Col>
                                                        </Row>



                                                        <Row className="mt-3 ">
                                                            <Col xs={4} >
                                                                <div className=""> Amount Pending</div>
                                                            </Col>
                                                            <Col className="text-end h6" xs={8} >
                                                                <div> {amountFormate(order?.amount_pending_user_country)}  {order?.amount_currency_user_country}</div>
                                                            </Col>
                                                        </Row>

                                                    </Card.Body>
                                                </Card>
                                            </Col>}
                                            {!loading && <Col xl={6} className="mt-xl-0 mt-3">
                                                <Card>
                                                    <Card.Body>
                                                        <Row>
                                                            <span className="h6 fw-bold">Pyment Details</span>
                                                        </Row>
                                                        <Row className="mt-3 ">
                                                            <Col xs={4} >
                                                                <div className="">Payment</div>
                                                            </Col>
                                                            <Col className="text-end" xs={8} >
                                                                <div className="order-status"><Chip variant="outlined" label={order?.status_payment}
                                                                    color={order?.status_payment === "pending" ? "warning" : order?.status_payment === "success" ? "success" : "primary"}
                                                                /></div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mt-3 ">
                                                            <Col xs={6} md={6} lg={6}  >
                                                                <div className=""> Amount Paid</div>
                                                            </Col>
                                                            <Col className="text-end h6" xs={6} md={6} lg={6}  >
                                                                <div> {amountFormate(order?.amount_paid_user_country)}  {order?.amount_currency_user_country}</div>
                                                            </Col>
                                                        </Row>


                                                        <Row className="mt-3 ">
                                                            <Col xs={4} >
                                                                <div className="">Pyment Sourse</div>
                                                            </Col>
                                                            <Col className="text-end h6" xs={8} >
                                                                <div> {order?.payment_source}</div>
                                                            </Col>
                                                        </Row>


                                                        <Row className="mt-3 ">
                                                            <Col xs={4} >
                                                                <div className="">Pyment Ref</div>
                                                            </Col>
                                                            <Col className="text-end h6" xs={8} >
                                                                <div> {order?.payment_ref}</div>
                                                            </Col>
                                                        </Row>

                                                        <Row className="mt-2 ">
                                                            <Col xs={4} >
                                                                <div className=""> Pyment On</div>
                                                            </Col>

                                                            <Col className="text-end h6" xs={8} >
                                                                <div>{get_date_formatted(order?.payment_on)}</div>
                                                            </Col>
                                                        </Row>

                                                    </Card.Body>
                                                </Card>
                                            </Col>}

                                        </Row>
                                        <Row className="mt-3">
                                            {loading && <>
                                                <Col className="mx-auto skeleton-m-p ">
                                                    <Skeleton width="100%" height={230} />
                                                </Col>
                                            </>}
                                            {!loading && <Col>
                                                <Card>
                                                    <Card.Body>
                                                        <Row>
                                                            <Col className="">
                                                                <span className="h6 fw-bold">Product Details</span>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mt-2">
                                                            <Col sm={3} xl={1}  >
                                                                <img onError={(e) => e.target.src = img}
                                                                    style={{ width: 60, height: 60 }}
                                                                    src={order?.product?.url_image}
                                                                    className="rounded me-2" />
                                                            </Col>
                                                            <Col sm={9} xl={11}>
                                                                <div className="h6 ms-2">{order?.product?.title}</div>
                                                            </Col>

                                                            <Col className="mt-3" xs={6} lg={4} xl={4}  >
                                                                <div className="">Price</div>
                                                                <div className="h6"> {amountFormate(order?.amount_rate_user_country)} {order?.amount_currency_user_country} </div>
                                                            </Col>
                                                            <Col className="mt-3 text-lg-start text-end" xs={6} lg={4} xl={4}>
                                                                <div >Tax</div>
                                                                <div className="h6 "> {amountFormate(order?.amount_tax_user_country)} &nbsp; {order?.amount_currency_user_country} </div>
                                                            </Col>
                                                            <Col className="mt-3" xs={6} lg={4} xl={4}>
                                                                <div >Amount Total</div>
                                                                <div className="h6"> {amountFormate(order?.amount_total_user_country)} &nbsp; {order?.amount_currency_user_country} </div>
                                                            </Col>

                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>}
                                        </Row>
                                        <Row className="mt-2">
                                            <Col>
                                                <Card>
                                                    <Card.Body>
                                                        <Row>
                                                            <Col className="">
                                                                <span className="h6 fw-bold">Product Demos</span>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            Array.isArray(order?.link_url_download) && order?.link_url_download.map((data, index) => {
                                                                return (
                                                                    <Row className="mt-2" key={index} xs={{ cols: 1 }}>
                                                                        <Col>
                                                                            <Card>
                                                                                <Card.Body>
                                                                                    <Col className="d-flex">
                                                                                        <div className="h6"> {index + 1}.{data?.title}</div>
                                                                                        <div className="ms-auto">
                                                                                            <Link onClick={() => window.open(data?.url || "_blank")} ><FaEdit /></Link>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Card.Body>
                                                                            </Card>
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            })
                                                        }

                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>






                                    </Card.Body>
                                </Card>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default OrderDetail;