import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import man from "../images/man.png";
import "../css/Home.css";
import { Link } from "react-router-dom";
import { BiBulb } from "react-icons/bi";
import { Fade, Reveal, Slide } from "react-reveal";
import "../css/ShareIdeaButton.css";
import { useNotification } from "../../hooks";
import noimage from "../images/noimage2.webp"
import { getDataByUrl } from "../API/HelpingMethod";

const ShareIdea = () => {
  const [data, setData] = useState()
  const inputData = {
    offset: 0,
    limit: 4,
  };
  const { setLoginModal, setLocation, setPrevLocation } = useNotification();
  var loginuser = JSON.parse(localStorage.getItem("userstorage"));
 
  const styleMan = {
    width: "300px",
  };
  const styleBg = { background: "black" };

  const headingText = "Do You Have A Startup Idea ?";
  const paragraph = "Tell us about your idea. We can’t wait to hear from you! We are here to help startups with fabulous idea with technology and investments. We invite you to share your idea with us, to get initial support to develop your MVP.";
  const shareIdea = "Share Idea";

  const styleFont = { fontFamily: "Montserrat" };
  const openModal = () => {
    setLoginModal(true)  
  }

  const apiCall = async () => {
    if (loginuser) {
      inputData.login_user_id = loginuser?.id
    }
    const response = await getDataByUrl("front_home", inputData);
    if (!response?.error) {
      setData(response?.data);
    }
  };
  useEffect(() => {
    apiCall();
  }, []);


  return (
    <div>
      <Container fluid style={styleBg} className="mt-5">
        <Container>
          <Fade top>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12} className="text-light py-5" >
                <h3 className="my-2 fw-bold " style={styleFont}>{headingText}</h3>
                <p style={styleFont}>{paragraph}</p>
                {
                  loginuser ? <Link to={data?.startup_idea === null ? "/submit-idea" : "/startup-idea"}
                    className={`slide-button3 text-decoration-none `} >
                    {shareIdea} <BiBulb className="fs-4" />{" "}
                  </Link> : <Link onClick={openModal} className={`slide-button3 text-decoration-none `} >
                    {shareIdea} <BiBulb className="fs-4" />{" "}
                  </Link>
                }
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12} className="text-center py-5" >
                <img
                  style={styleMan}
                  className="startupideaman"
                  src={man}
                  alt="man"
                  onError={(e) => e.target.src = noimage}
                />
              </Col>
            </Row>
          </Fade>
        </Container>
      </Container>
    </div>
  );
};
export default ShareIdea;
