import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { getDataByUrl } from "../../API/HelpingMethod";
import { useNotification } from "../../../hooks";
import { useNavigate, useSearchParams } from "react-router-dom";

const StartupUnequeness = ({ data, handleBack, setActiveStep, setData }) => {
    var loginuser = JSON.parse(localStorage.getItem("userstorage"));
    const [btnLoading, setBtnLoading] = useState(false);
    const { showError, showSuccess } = useNotification();
    let [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const [formData, setFormData] = useState({ startup_uniqueness: data?.startup_uniqueness || "" });

    const bodyBata = {
        login_user_id: loginuser?.id,
        user_type: loginuser.user_type,
        id: searchParams.get("ideaId")
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleSubmit = async () => {
        setBtnLoading(true);
        bodyBata.values = formData
        const response = await getDataByUrl("startup_idea_update", bodyBata);

      //  console.log("response----", response)

        if (response?.error === false) {
            if (response?.data) setData(response?.data);

            showSuccess(response?.message)
            setActiveStep(6);
            navigate("/submit-idea?ideaId=" + response?.data?.id)

        } else {
            showError(response?.message)
        }

        setBtnLoading(false)
    }


    return (
        <>
            <Col>
                <Container>
                    <Row><Col sm={8} className="mx-auto h6 mt-3"> What is different in your idea?</Col></Row>
                    <Row>
                        <Col sm={8} className="mx-auto">
                            <Form.Control type="text"
                                as="textarea"
                                rows={3}
                                className="idea-stepper-input"
                                name="startup_uniqueness"
                                value={formData.startup_uniqueness || ""}
                                onChange={handleInputChange}
                                placeholder="Please describe the difference in your idea" />
                        </Col>
                    </Row>

                    <Row>
                        <Col className="d-flex justify-content-center mt-4">
                            <div className="me-2">
                                <button onClick={handleBack} className="primary-button" >
                                    Back
                                </button>
                            </div>

                            <div className="ms-2">
                                <button disabled={btnLoading} className={btnLoading ? "disabled-btn" : "slide-button1"} onClick={handleSubmit}>
                                    {btnLoading && <><CircularProgress color="inherit" className="mt-1" size={16} />&nbsp;</>}Next
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Col>

        </>
    )
}
export default StartupUnequeness;