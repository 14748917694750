import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap"; 
import { apiHepingMethod } from "../API/HelpingMethod";
import SkeletonBenefit from "./Skeleton-files/SkeletonBenefit";
import { FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Flip } from "react-reveal";
import noimage from "../images/noimage2.webp"

const Benefit = (props) => {
  const [benefit, setBenefit] = useState([]);
  const [loading, setLoading] = useState(false);
  var loginuser = JSON.parse(localStorage.getItem("userstorage"));
  const data = {
    device_type: "web",
    sstoken: "95vdb549w0zp58lchu",
    // "login_user_id": "8",
    login_user_id: loginuser?.id || 8,
    login_user_type: "startup",
    limit: 10,
    offset: 0,
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);


  const apicall = async () => {
    setLoading(true);
    try {
      const response = await apiHepingMethod("startup_benefits", data); 
      setBenefit(response.data.data);
    } catch (error) {
      // console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    apicall();
  }, []);

  const numberOfColsBenefit = 10;
  const styleFont = { fontFamily: "Montserrat", fontWeight: "lighter" };

  const navigationItems = [
    { path: '/', label: 'Home' },
    { path: '/benefit', label: 'Benefit' },
    // Add more items as needed
  ];
  const styleBridgecroumb = {
    whiteSpace: 'nowrap', overflow: 'hidden',
  }
  return (
    <Container className="mt-5 pt-3  mb-5">


  {/* <HelmetCustomInclude url={"product/" + slugPart} ref_id={part_details?.id} ref_type={'product_part'}
                                 title={pageTitle} image={metaImageUrl}
                                 description={metaDescription}
                                 keywords={metaKeywords}/> */} 


      <Row className="">
       
        <div className="d-flex flex-row">
          {navigationItems.map((item, index) => (
            <div key={index} className="">
              <Link
                to={item.path}
                className="color-secondary text-decoration-none"
                style={styleBridgecroumb}
              >
                {item.label}
                {index < navigationItems.length - 1 && <FiChevronRight />}
              </Link>
            </div>
          ))}
        </div>
      </Row> 
      <Container className="mt-3">
        <Row className="mb-5">
          {loading ? (
            <SkeletonBenefit numberOfColsBenefit={numberOfColsBenefit} />
          ) : (
            <>
              {benefit.map((benefitItem, index) => {
                return (
                  <Col lg={6} xl={6} sm={12} xs={12} md={6} className="mb-3" key={index}
                  >
                    <Flip left>
                      <Card className="shadow border-0">
                        <Card.Body>
                          <Row className="d-flex align-items-center">
                            <Col sm={4} xs={4} lg={3} xl={3} md={3}>
                              <Card.Img
                                className="w-100"
                                src={benefitItem.url_image}
                                alt="./"
                                onError={(e) => e.target.src = noimage}
                              />
                            </Col>
                            <Col lg={9} xl={9} md={9} className="">
                              <Card.Title
                                className="fw-bold  overflow-hidden"
                                style={{
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                }}
                              >
                                {benefitItem.title}
                              </Card.Title>
                              <Card.Text
                                className="fw-lighter fontFamily  overflow-hidden"
                                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden',  }}
                              >
                                {benefitItem.description}
                              </Card.Text>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Flip>
                  </Col>
                );
              })}
            </>
          )}
        </Row>
      </Container>
    </Container>
  );
};

export default Benefit;
