

import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import EditIcon from "@mui/icons-material/Edit";
import { Card, Col, Container, Row, Spinner } from "react-bootstrap";
import userDefault from "../images/Frame 427319638user_profile.png"; 
import { useNotification } from "../../hooks";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiHepingMethodUplod } from "../API/HelpingMethod";
import { CircularProgress } from "@mui/material";
import UploadPictureSidebar from "./UploadPictureSidebar";
import image from "../images/Looper-2 (1).png";
import { Link, useNavigate } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import UserProfileSidebar from "./UserProfileSidebar";
 
function UploadProfilePicture() {
  const navigate = useNavigate()
  const [isHovered, setIsHovered] = useState(false);
  const styleImage = { width: "250px", height: "250px" };
  const uploadPicture = "Upload Picture";
  const styleHeight = {
     paddingBottom: "115px",
    border: "1px solid var(--primary)", 
  };
  const UpdateProfilePicture = "Update Profile Picture";
  const UpdateProfileText = "Make sure the image is below 10mb";
  useEffect(() => {
    // window.scrollTo({ top: 110, left: 0, behavior: "smooth" });
  }, []);

  useEffect(()=>{
    if(!localStorage.getItem("userstorage")){
      navigate("/")
    }
  },[localStorage.getItem("userstorage")])

  const [file, setFile] = useState();
  const [imagePreview, setImagePreview] = useState(null);
  const { showError, showSuccess } = useNotification();
  const [button, setButton] = useState("Save Changes");
  const [buttonClicked, setButtonClicked] = useState(false);
  var loginuser = JSON.parse(localStorage.getItem("userstorage"));
 

  const handleFileUpload = async () => { 
    if (!file) {
      showError("Please select a profile picture.");
      return;
    }
    setButtonClicked(true);
    let formData1 = new FormData();
    formData1.append("url_profile", file);
    formData1.append("login_user_id", loginuser?.id);
    formData1.append("login_user_type", loginuser?.user_type);  
    const response = await apiHepingMethodUplod(
      "user_profile_upload",
      formData1
    );
    localStorage.setItem("userstorage", JSON.stringify(response?.data?.data));
    window.scrollTo({ top: 1, left: 0, behavior: "smooth" });   
    if (!response?.error) {
      showSuccess(response?.data?.message); 
 
    } else {
      showError(response?.data?.message); 
    }
    // setButton("Save Changes");
    setButtonClicked(false);

  };
  const onChangeFile = (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);
    const imageUrl = URL.createObjectURL(e.target.files[0]); 
    setImagePreview(imageUrl);
  };
  const styleInput = {
    display: "none",
  };
  
  return (
    <Container className=" pt-5">  
     <img id="Servicebgimage" src={image} alt="img" />
      <Row className="mt-2 mb-2">
        <Col className="ms-3">
          <Link to="/" className="text-decoration-none color-secondary">Home</Link>&nbsp;<BsChevronRight />&nbsp;<span>Upload Picture</span>
        </Col>
      </Row>
      <Row> 
        <Col sm={12} xs={12} md={5} lg={4} xl={3} className="pb-5">
        <UserProfileSidebar />
        </Col>
        <Col sm={12} xs={12} xl={9} md={7} lg={8} className="">
          <Container className="mb-5">
            <Card style={styleHeight} className="">
              <Card.Body>
                <Row>
                  <Col>
                    <h5 className="fw-bold">{uploadPicture}</h5>
                  </Col>
                </Row>
                <Row className="">
                  <Col className="d-flex flex-column align-items-center justify-content-center mt-5 pt-5">
                    <label htmlFor="upload-input" className="position-relative">
                      <Avatar
                        style={styleImage}
                        className="img-fluid -start mt-3 upload-image"
                        src={
                          imagePreview || loginuser?.url_profile || userDefault
                        }
                      />
                      <EditIcon className="uploadIcon" fontSize="large" />
                    </label>
                    <input
                      id="upload-input"
                      type="file"
                      onChange={onChangeFile}
                      style={styleInput}
                    />
                  </Col>
                  <h5 className="color-primary fontFamily text-center fw-bold">
                    {UpdateProfilePicture}
                  </h5>
                  <p className="fontFamily text-center">{UpdateProfileText}</p>
                </Row>
                <Row>
                  <Col className="text-center mt-5">
                    {/* <button
                      onClick={handleFileUpload}
                      className={`slide-button text-decoration-none ${
                        isHovered ? "hovered" : ""
                      }`}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      {button}
                    </button> */}

                    <div style={{ position: "relative" }}>
                          {buttonClicked && (
                            <button className='fontFamily text-decoration-none mb-4 disabled-btn-primary mt-3' disabled={true}>
                            <>
                              <CircularProgress style={{ color: "var(--primary)" }} className="mt-1" size={16} />&nbsp;&nbsp; Loading...
                            </>
                          </button>
                          )}
                          {!buttonClicked && (
                            <button
                             onClick={handleFileUpload}
                              className={`slide-button fontFamily text-decoration-none mb-4 ${isHovered ? "hovered" : ""}`}
                              // onMouseEnter={() => setIsHovered(true)}
                              // onMouseLeave={() => setIsHovered(false)}
                            >
                              {button}
                            </button>
                          )}
                        </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
}

export default UploadProfilePicture;