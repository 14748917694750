import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import NotificationProvider from "./Context/NotificationProvider";
import AuthProvider from "./Context/AuthProvider";
import {BrowserRouter} from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';
import WebNotification from "./Notification/WebNotification";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ScrollToTop/>
            <NotificationProvider>
                <AuthProvider>
                    <WebNotification/>
                    <App/>
                </AuthProvider>
            </NotificationProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
