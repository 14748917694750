import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FiChevronRight } from "react-icons/fi";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { loginUserData } from "../../API/client";
import { useNotification } from "../../../hooks";
import { getDataByUrl } from "../../API/HelpingMethod";
import { FaCircleArrowLeft } from "react-icons/fa6";
import noimage from "../../images/noimage2.webp"
import logo from "../../../assets/logos/logo.webp"; 
import InvestorCountrySelect from "./InvestorCountrySelect";
import InvestorPersonalDetails from "./InvestorPersonalDetails";
import InvestorCompanyDetails from "./InvestorCompanyDetails";
import InvestorSetPassword from "./InvestorSetPassword";

const InvestorRegistrationStep = () => {

    const navigate = useNavigate()
    const userDetails = JSON.parse(localStorage.getItem("user-register"));

    const [pageLoading, setPageLoading] = useState(false);
    let [searchParams] = useSearchParams()
    const { startupIdeaId } = useParams();
    const [bodyData, setBodyData] = useState({
        mobile: userDetails?.mobile || "",
        email: userDetails?.email || "",
        user_type: "investor"

    })

 

    const { showError, showSuccess } = useNotification();
    const [data, setData] = useState(null)

    const steps = ['Select Country', 'Describes', 'Personal Details', 'Describes2'];
    const [activeStep, setActiveStep] = React.useState(0);

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const styleImage = { maxHeight: "50px", alignContent: "center" }


    

    const getStartupIdea = async () => {
        if (startupIdeaId) {
            bodyData.startup_idea_id = startupIdeaId
        }
        setPageLoading(true);
        const response = await getDataByUrl("startup_idea", bodyData);
        // console.log("response---", response)

        if (response?.error === false) {
            setData(response?.data) 
        }

        setPageLoading(false);
    }

    useEffect(() => {
        if (startupIdeaId) {
            getStartupIdea().then(r => r);
        }
    }, [startupIdeaId])


    console.log("bodyDatabodyData-", bodyData)

    return (
        <>

            <Container fluid className="forms-container" >
                <Row>
                    <Col lg={6} md={6} className="p-0">
                        <div className="login-image-container"></div>
                    </Col>

                    <Col lg={6} md={6} style={{ overflow: 'scroll', height: "99vh" }}>

                        <Row>
                            <Col className="">
                                <Link to="/" className="color-secondary">
                                    <FaCircleArrowLeft /> Back to home
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <img
                                    src={logo}
                                    alt="logo"
                                    style={styleImage}
                                    onError={(e) => e.target.src = noimage}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-4">
                            <Col sm={10} className="mx-auto idea-stepper">
                                <Box sx={{ width: '100%' }}>
                                    <Stepper activeStep={activeStep}>
                                        {activeStep !== null && steps.map((label, index) => {
                                            return (<Step key={label}> <StepLabel> </StepLabel> </Step>);
                                        })}
                                    </Stepper>

                                </Box>
                            </Col>
                        </Row>
                        <Row className=" " >
                            <Col sm={10} className="mx-auto">
                                {activeStep === 0 && <InvestorCountrySelect bodyData={bodyData} setBodyData={setBodyData} setActiveStep={setActiveStep} />}
                                {activeStep === 1 &&
                                    <InvestorPersonalDetails bodyData={bodyData} setBodyData={setBodyData} handleBack={handleBack} setActiveStep={setActiveStep} />}
                                {activeStep === 2 &&
                                    <InvestorCompanyDetails bodyData={bodyData} setBodyData={setBodyData} handleBack={handleBack} setActiveStep={setActiveStep} />}
                                {activeStep === 3 &&
                                    <InvestorSetPassword bodyData={bodyData} setBodyData={setBodyData} handleBack={handleBack} setActiveStep={setActiveStep} />}


                            </Col>

                        </Row>
                    </Col>

                </Row>



            </Container>
        </>
    )
}
export default InvestorRegistrationStep