import {Backdrop, CircularProgress} from "@mui/material";
import {createContext, React, useState} from "react";
import {Col, Container, Modal, Row} from "react-bootstrap";
import {BsExclamationCircle} from "react-icons/bs";
import {Link} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {ApiCallModal} from "../components/API/HelpingMethod";
import {MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog} from "mdb-react-ui-kit";
import LoginModal from "./LoginModal";

export const NotificationContext = createContext();

export default function NotificationProvider({children}) {

  const showSuccess = (value) => {
    // console.log("hi")
    toast.success(value, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const showWarning = (value) => {
    toast.warn(value, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const showError = (value) => {
    toast.error(value, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };


  const [show, setShow] = useState(false);
  const [isHoveredCancel, setisHoveredCancel] = useState(false);
  const [location, setLocation] = useState()
  const [prevLocation, setPrevLocation] = useState()
  const [loading, setLoading] = useState(false)
  const [openBackDrop,setOpenBackDrop]=useState(false);

  const handleClose = () => {
    // if (location) {
    //   localStorage.setItem("prevLocation", location)
    // } else {
    //   localStorage.setItem("prevLocation", prevLocation)
    // } 
    setLoginModal(!loginModal)
    setShow(false)
  }




  const [LoginStep, setLoginStep] = useState("verify_user")
  const [isOtp, setIsOtp] = useState(false)
  const [bodydata, setBodydata] = useState({
    device_token: 'ABCDEFGHIJK',
    username: "",
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setBodydata({ ...bodydata, [name]: value })
  }
  const [loginModal, setLoginModal] = useState(false);

  const toggleOpen = () => setLoginModal(!loginModal);


  const handleSubmit = async (e) => {
    e.preventDefault()

    setLoading(true);

    if (LoginStep === 'user_login') {
      // bodydata.password = bodydata.password
      bodydata.device_latitude = "72.02"
      bodydata.device_longitude = "23.02"
    }

    const response = await ApiCallModal(LoginStep, bodydata);

    if (response.error === false && LoginStep === 'verify_user') {
      setLoginStep('user_login')
    } else if (response.error === true && LoginStep === 'verify_user') {
      showError(response.message)
    }

    if (response.error === false && LoginStep === 'user_login') {
      showSuccess(response.message)
      setLoginModal(!loginModal)
      localStorage.setItem("userstorage", JSON.stringify(response.data));
      window.scrollTo({ top: 1, left: 0, behavior: "smooth" });
    } else if (response.error === true && LoginStep === 'user_login') {
      showError(response.message)

      // setLoginModal(!loginModal)
    }

    if (response?.data?.otp && LoginStep === 'verify_user_by_otp') {
      bodydata.otp = response?.data?.otp
      setIsOtp(true)
    }
    setLoading(false);
    if (response?.data?.user && LoginStep === 'verify_user_by_otp') {
      showSuccess(response.message)
      setLoginModal(!loginModal)
      sessionStorage.setItem("userstorage", JSON.stringify(response.data.user));
      window.scrollTo({ top: 1, left: 0, behavior: "smooth" });
    } else if (response.error === true && LoginStep === 'verify_user_by_otp') {
      showError(response.message)
    }
  }

  return (
    <NotificationContext.Provider value={{ showSuccess, showWarning, showError, setShow, setLocation, setPrevLocation, setLoginModal ,setOpenBackDrop}}>


      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />


      <div>
        <Backdrop
          sx={{ color: 'var(--primary)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackDrop} 
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <Modal show={show} onHide={handleClose} animation={true} className="login-modal" centered>
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body >
          <Container>

            <Row className="fw-bold text-center mt-3">
              <Col >
                <BsExclamationCircle color="#fae481" size={100} />
              </Col>
            </Row>


            <Row className="fw-bold mt-4 text-center">
              <Col>
                Please Login to proceed with the process.
              </Col>
            </Row>
            <Row className="mt-3 mb-3">
              <Col className="text-end">
                <Link >
                  <button className={`slide-button1 text-decoration-none mt-3 `}
                    onClick={handleClose}>Login</button>
                </Link>
              </Col>
              <Col className="text-start">
                <button style={{ padding: "6px" }}
                  className={`slide-button text-decoration-none ${isHoveredCancel ? "hovered" : ""
                    }`}
                  onClick={() => setShow(false)}>Cancel</button>

              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>

      <MDBModal open={loginModal} tabIndex='-1' onClose={() => setLoginModal(false)}>
        <MDBModalDialog size='lg' centered>
          <MDBModalContent>
            <MDBModalBody className="pt-0 ps-0 pe-0 pb-0">
              <LoginModal />
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>


      {children}

    </NotificationContext.Provider>
  );
}


;
