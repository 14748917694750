import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import React, { useState } from "react";
import { apiHepingMethod } from "../API/HelpingMethod";
import logo from "../../assets/logos/logo.webp";
import { useNotification } from "../../hooks";
import { FaCircleArrowLeft } from "react-icons/fa6";
import { CircularProgress } from "@mui/material";
import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";
import noimage from "../images/noimage2.webp"

export default function LoginForm({ loginNavigate, loginLocation }) {
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("password");
  const [buttonText, setButtonText] = useState("Login");
  const { showError, showSuccess } = useNotification();
  const [useParems] = useSearchParams()
  const [buttonClicked, setButtonClicked] = useState(false);
  const [bodyData, setBodyData] = useState({
    username: useParems.get("username") || "",
    password: "",
    device_token: "ABCDEFGHIJK",
    device_latitude: "72.02",
    device_longitude: "23.02",
  });
  const [isHovered, setIsHovered] = useState(false)
  
  const handlechange = (e) => {
    const { name, value } = e.target;
    setBodyData({ ...bodyData, [name]: value });
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };


  function alertFunc() {
    // navigate(loginNavigate);
    navigate(localStorage.getItem("prevLocation"))
  }

  async function handleClick(e) {
    e.preventDefault(); 
    setButtonClicked(true)
    let resultt = await apiHepingMethod("user_login", bodyData); 

    if (resultt.data.error === false) {
      localStorage.setItem("userstorage", JSON.stringify(resultt.data.data));
      showSuccess(resultt.data.message);
      setTimeout(alertFunc, 1000);
    } else {
      showError(resultt.data.message);
    }
    // setButtonText("Login");
    setButtonClicked(false)
  }

  const styleImage = { maxHeight: "50px", alignContent: "center" }
  const stylePasswordButton = { cursor: "pointer" }
  const styleLabel = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }
  const styleLoginButton = {
    borderRadius: "5px",
    backgroundColor: "var(--secondary)",
  }
  return (
    <>
      <Container className="">
        <Row>
          <Col className="">
            <Link to="/" className="color-secondary">
              <FaCircleArrowLeft /> Back to home
            </Link>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <img
              src={logo}
              alt="logo"
              style={styleImage}
              onError={(e) => e.target.src = noimage}
            />
          </Col>
        </Row>


        <Row className="ps-4 ps-sm-0 pe-4 pe-sm-0 mb-5 mt-4">
          <Col sm={1} md={1} lg={2} xl={3}></Col>
          <Col sm={10} md={10} lg={8} xl={6} className="border rounded pb-5 ps-3 pe-3"> 
            <Row className="mt-3">
              <Col className="text-center h4 fw-bold">
                Login
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                Ignite Your Journey to Entrepreneurial Greatness.
              </Col>
            </Row>

            <form onSubmit={handleClick}>
              <Row xs={{ cols: 1 }} className={"g-3 mt-2"}>
                <Col>
                  <label className="mb-1" htmlFor="">Email/Mobile</label>
                  <Form.Control
                    className="input-border-secondary"
                    type="text"
                    name="username"
                    onChange={handlechange}
                    value={bodyData?.username}
                  />
                </Col>

                <Col>
                  <label>Password*</label>
                  <InputGroup className="login-password">
                    <Form.Control
                      type={passwordType}
                      value={bodyData?.password}
                      onChange={handlechange}
                      name="password"
                      className="input-border-secondary"
                    />
                    <InputGroup.Text
                      className="basic-addon1"
                      style={stylePasswordButton}
                      onClick={togglePassword}
                    >
                      {passwordType === "password" ? (
                        <FiEyeOff className="color-secondary fs-4" />
                      ) : (
                        <FiEye className="color-secondary fs-4" />
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                </Col>

                <Col>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label
                      style={styleLabel}
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Keep me signed in
                    </label>
                  </div>
                </Col>

                {/*<Col className="text-end"
                         style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}><Link
                        to="/forgot_password" className="color-secondary text-decoration-none">Forgot
                        password?</Link></Col>*/}
                <Col className="pe-0 text-center">
                  {/* <button
                type={"submit"}

                className={`slide-button1 fontFamily text-decoration-none w-100 ${isHovered ? "hovered" : ""
                  }`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {buttonText}
              </button> */}
                  {buttonClicked && (
                    <Row className="ms-1 me-1 text-center">
                      <Col>
                        <button className='fontFamily text-decoration-none mb-4 disabled-btn mb-4 w-sm-50 w-75' disabled>
                          <CircularProgress style={{ color: "var(--secondary)" }} className="mt-1" size={16} />&nbsp;&nbsp; Loading...
                        </button>
                      </Col>
                    </Row>
                  )}
                  {!buttonClicked && (
                    <button
                      // style={{ color: "var(--primary)" }}
                      type={"submit"}
                      className={`slide-button1 fontFamily text-decoration-none mb-4 w-50 ${isHovered ? "hovered" : ""}`}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      {buttonText}
                    </button>
                  )}
                </Col>
              </Row>
            </form>
            <hr />
            <Row className="">
              <Col className="text-center"> or</Col>
            </Row>

            <div className="mt-2">
              <button className="form-control">
                <FcGoogle className="me-2" />
                Continue With Google
              </button>
              <p className="my-2 text-center">
                Don’t have an Account?{" "}
                <Link to="/register" className="color-secondary">
                  Register
                </Link>
              </p>
            </div>

          </Col>
          <Col sm={1} md={1} lg={2} xl={3}></Col>
        </Row>







      </Container>
    </>
  );
}
