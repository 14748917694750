import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, InputGroup, Card } from "react-bootstrap";
// Button,
import { FiEyeOff } from "react-icons/fi";
import { FiEye } from "react-icons/fi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { getDataByUrl } from "../API/HelpingMethod";
import { useNotification } from "../../hooks";
import ProfileDialog from "./ProfileDialog";
import Footer from "../Homepage/Footer";
import { CircularProgress } from "@mui/material";
import ChangePasswordSidebar from "./ChangePasswordSidebar";
import image from "../images/Looper-2 (1).png";
import { BsChevronRight } from "react-icons/bs";
import UserProfileSidebar from "./UserProfileSidebar";

const PasswordChange = () => {
  const { showError, showSuccess } = useNotification();
  const [button, setButton] = useState("Update Password");
  const [buttonClicked, setButtonClicked] = useState(false);
  var loginuser = JSON.parse(localStorage.getItem("userstorage"));
  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
 
  const [currentPasswordType, setCurrentPasswordType] = useState("password");
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");

  const [isHovered, setIsHovered] = useState(false); 
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prevData) => ({ ...prevData, [name]: value }));
  };
  useEffect(() => {
    if (!localStorage.getItem("userstorage")) {
      navigate("/");
    }
  }, [localStorage.getItem("userstorage")])
  // };

  const togglePassword = (field) => {
    // Toggle password visibility for each field
    if (field === "currentPassword") {
      setCurrentPasswordType((prevType) =>
        prevType === "password" ? "text" : "password"
      );
    } else if (field === "newPassword") {
      setNewPasswordType((prevType) =>
        prevType === "password" ? "text" : "password"
      );
    } else if (field === "confirmPassword") {
      setConfirmPasswordType((prevType) =>
        prevType === "password" ? "text" : "password"
      );
    }
  }; 

  const inputData = {
    login_user_id: loginuser?.id,
    username: loginuser?.mobile,
    password: passwordData.newPassword,
    confirm_password: passwordData.confirmPassword,
  };
  const handlePasswordUpdate = async () => {
    // setButton(<CircularProgress size={20} color="inherit" className="mx-5" />);
    setButtonClicked(true);
    const response = await getDataByUrl("user_password_update", inputData);
    if (!response?.error) {
      showSuccess(response?.message);
      const updatedUser = {
        ...loginuser /* update other user properties if needed */,
      };
      localStorage.setItem("userstorage", JSON.stringify(updatedUser));
      navigate("/"); 
    } else {
      showError(response?.message);
      // console.log("Error fetching data:", response?.message);
    }
    setButtonClicked(false);
    // setButton("Update Password");
  };

  const changePasswordText = "Change Password";
  const currentPasswordText = "Current Password";
  const newPasswordText = "New Password";
  const confirmPasswordText = "Confirm Password";
  const styleFont = {
    fontFamily: "Montserrat",
  };
  // const updateNow = "Update Now";

  const styleHeight = {
    paddingBottom: "300px",
    border: "1px solid var(--primary)",


  };
  const styleCursor = {
    cursor: "pointer",
  };
  const styleText = {
    whiteSpace: "nowrap",
    overflow: "hidden",
  };

  return (
    <Container className="pt-5">
      <img id="Servicebgimage" src={image} alt="img" />
      <Row className="mt-2 mb-2">
        <Col className="ms-3">
          <Link to="/" className="text-decoration-none color-secondary">Home</Link>&nbsp;<BsChevronRight />&nbsp;<span>Upload Picture</span>
        </Col>
      </Row>
      <Row>
        <Col sm={12} xs={12} md={5} lg={4} xl={3} className="pb-5">
        <UserProfileSidebar />
        </Col>
        <Col sm={12} xs={12} xl={9} md={7} lg={8} className="">
          <Container className=" mb-5">
            <Row>
              <Col md={12}>
                <Card className="" style={styleHeight}>
                  <h5 className="p-3 fw-bold ">
                    {changePasswordText}
                  </h5>
                  <Card.Body> 
                    <Form className="row g-3">
                      <Row className="mt-4">
                        <Col md={12} lg={12} sm={12}>
                          <label className="fontFamily fw-bold">
                            {currentPasswordText}
                          </label>
                          <InputGroup>
                            <Form.Control
                              style={styleFont}
                              onChange={handleInputChange}
                              type={currentPasswordType}
                              name="currentPassword"
                              placeholder="Current Password"
                              aria-label="currentPassword"
                              aria-describedby="basic-addon1"
                            />
                            <InputGroup.Text
                              id="basic-addon1"
                              onClick={() => togglePassword("currentPassword")}
                            >
                              {currentPasswordType === "password" ? (
                                <FiEyeOff
                                  style={styleCursor}
                                  className="color-primary fs-4"
                                  icon={FiEyeOff}
                                />
                              ) : (
                                <FiEye
                                  style={styleCursor}
                                  className="color-primary fs-4"
                                  icon={FiEye}
                                />
                              )}
                            </InputGroup.Text>
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md={12} lg={6} sm={12}>
                          <label className="fontFamily fw-bold">
                            {newPasswordText}
                          </label>
                          <InputGroup>
                            <Form.Control
                              style={styleFont}
                              onChange={handleInputChange}
                              type={newPasswordType}
                              name="newPassword"
                              placeholder="New Password"
                              aria-label="newPassword"
                              aria-describedby="basic-addon1"
                            />
                            <InputGroup.Text
                              id="basic-addon1"
                              onClick={() => togglePassword("newPassword")}
                            >
                              {newPasswordType === "password" ? (
                                <FiEyeOff
                                  style={styleCursor}
                                  className="color-primary fs-4"
                                  icon={FiEyeOff}
                                />
                              ) : (
                                <FiEye
                                  style={styleCursor}
                                  className="color-primary fs-4"
                                  icon={FiEye}
                                />
                              )}
                            </InputGroup.Text>
                          </InputGroup>
                        </Col>
                        <Col
                          md={12}
                          lg={6}
                          sm={12}
                          className="mb-3 mt-lg-0 mt-2"
                        >
                          <label className="fontFamily fw-bold">
                            {confirmPasswordText}
                          </label>
                          <InputGroup>
                            <Form.Control
                              onChange={handleInputChange}
                              type={confirmPasswordType}
                              name="confirmPassword"
                              placeholder="Confirm Password"
                              aria-label="confirmPassword"
                              aria-describedby="basic-addon1"
                              style={styleFont}
                            />
                            <InputGroup.Text
                              id="basic-addon1"
                              onClick={() => togglePassword("confirmPassword")}
                            >
                              {confirmPasswordType === "password" ? (
                                <FiEyeOff
                                  style={styleCursor}
                                  className="color-primary fs-4"
                                  icon={FiEyeOff}
                                />
                              ) : (
                                <FiEye
                                  style={styleCursor}
                                  className="color-primary fs-4"
                                  icon={FiEye}
                                />
                              )}
                            </InputGroup.Text>
                          </InputGroup>
                        </Col>
                      </Row>
                    </Form>

                    <Row className="mt-2 text-center">
                      {/* <Col sm={12} xs={12} style={styleText}>
                        <button
                          className={`slide-button text-decoration-none ${
                            isHovered ? "hovered" : ""
                          }`}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                          onClick={handlePasswordUpdate}
                        >
                          {button}
                        </button>
                      </Col> */}

                      <Col sm={12} xs={12} style={styleText}>
                        <div style={{ position: "relative" }}>
                          {buttonClicked && (
                           <button className='fontFamily text-decoration-none mb-4 disabled-btn-primary mt-3' disabled>
                           <>
                             <CircularProgress style={{ color: "var(--primary)" }} className="mt-1" size={16} />&nbsp;&nbsp; Loading...
                           </>
                         </button>
                          )}
                          {!buttonClicked && (
                            <button
                              onClick={handlePasswordUpdate}
                              className={`slide-button fontFamily text-decoration-none mb-4 ${isHovered ? "hovered" : ""}`}
                              // onMouseEnter={() => setIsHovered(true)}
                              // onMouseLeave={() => setIsHovered(false)}
                            >
                              {button}
                            </button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default PasswordChange;
